import React from "react";
import { Box, Flex, Text, Heading, Spinner } from "noshery-ui";
import useUsers from "../../hooks/useUsers";
import SingleUser from "../../components/SingleUser";


const Users = () => {
  const { users, setUsersClaim, loading, loadingUsers } = useUsers();

  return (
    <Box pt={"100px"} pb={4} px="100px" mt={3}>
      <Heading fontSize={8} paddingBottom="2%"> Users: </Heading>   
      {loading ? <Spinner variant="small" /> : users.map(user => {
        return (
          <Flex flexDirection="column" height="100%" width="45%" mb={15} key={user.uid} border="2px solid #ececec" p={2}>
            <Flex mr={3} paddingBottom="3%" justifyContent="space-around"  width="100%">
              <Flex mr={3} paddingBottom="3%" flexDirection="column" >
                <Text fontWeight="800" fontSize={5}>
                  {user.displayName || user.email || user.uid} 
                </Text>
                <Text fontSize={5}>
                  {user.email || user.uid} 
                </Text>
              </Flex>
              <Text fontWeight="800" fontSize={5}>
                Users Claims <br />
                {
                  loadingUsers ?
                    <Spinner variant="small" /> :
                    user.customClaims && Object.keys(user.customClaims).map(claim => <Text key={claim} fontSize={3}> {claim}: {user.customClaims[claim] === true ? "true" : "false"} </Text>)
                }
              </Text>
            </Flex>
            <SingleUser user={user} setUsersClaim={setUsersClaim} />
          </Flex>
        );
        
      })} 
    </Box>
  );
};

export default Users;
