import React from "react";
import { Flex, Box, Text } from "noshery-ui";
import moment from "moment";
import Dropdown from "../../components/Dropdown";
// import LineChart from "../components/charts/LineChart";
// import PieChart from "../components/charts/PieChart";
import useCharts from "../../hooks/useCharts";
import styled from "styled-components";
import PageLoader from "../../components/PageLoader";
import { restaurantColors } from "../../constants";
import BestSellerTable from "../../components/mobile/MobileBestSeller";
import IngredientsTable from "../../components/mobile/MobileIngredients";
import { isDataEmpty } from "../../utils/validators";
import NoData from "../../components/NoData";
import LaborCost from "../../components/charts/LaborChart";
import ResponsiveLineChart from "../../components/ResponsiveLineChart";
import ResponsivePieChart from "../../components/ResponsivePieChart";
import useWindowSize from "../../hooks/useWindowSize";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

const dateRangeOption = {
  today: "Today",
  yesterday: "Yesterday",
  thisWeek: "This week",
  lastWeek: "Last week",
  thisMonth: "This month",
  lastMonth: "Last month",
  thisYear: "This year",
  allTime: "All time",
  custom: "Custom",
};

const locationsOption = {
  "All Stores": "All",
  "San Mateo": "93895000000000000",
  "San Carlos": "114032000000000000",
};

const StyledSelect = styled.select`
  border-radius: 10px;
  box-shadow: 0 0 3px 0 rgb(0, 0, 0, 0.2);
  border: none;
  background-color: white;
  font-size: ${(props) => (props.fontSize ? props.fontSize : "20px")}
  ${(props) =>
    props.position
      ? "position: absolute; right: 8px; top: 8px; z-index: 1;"
      : ""}
  padding: 12px;
  font-weight: 600;
  &:hover,
  &:focus {
    cursor: pointer;
  }
  :focus-visible {
    outline: solid orange 2px;
  }
`;

const ChartContainer = styled(Box)`
  margin-bottom: 20px;
  box-shadow: 0 0 3px 0 rgb(0, 0, 0, 0.2);
  width: 100%;
  height: fit-content;
  position: relative;
  background-color: white;
  border-radius: 10px;
  padding: 10px;
`;

const NumberSpan = styled.span`
  font-weight: 700;
`;

const StyledCard = styled(Flex)`
  flex-direction: ${(props) =>
    props.flexDirection ? props.flexDirection : "row"}
  border-radius: 10px;
  box-shadow: 0 0 3px 0 rgb(0, 0, 0, 0.2);
  margin-bottom: 20px;
  ${(props) =>
    props.fullwidth
      ? "width: auto; justify-content: space-around;"
      : props.datePicker
        ? "width: 50%; justify-content: space-around;"
        : "width: 100% !important;"}
  background-color: white;
  max-height: 600px;
  margin-right: 20px;
`;

const Divider = styled(Box)`
  height: 2px;
  background-color: black;
  margin: 0 8px;
`;

const CategoryRankingBox = styled(Box)`
  width: 100%;
  overflow-y: scroll;
  display: grid;
  grid-template-columns: 33% 33% 33%;
  padding: 24px;
  text-align: center;
`;

const SpacedBox = styled(Box)`
  display: grid;
  grid-template-columns: 33% 33% 33%;
  text-align: center;
`;

const GridBox = styled(Box)`
  grid-column: 1/4;
`;

const SmallSpan = styled.span`
  font-size: 12px;
`;

const colors = ["orange", "#FF8C69", "black", "darkblue", "teal"];

const gray = "#585858";
const createLegendData = (data, multiple, filter, topSellers, items) => {
  if (!multiple) {
    return Object.keys(data).map((key, i) => {
      return {
        name: key,
        symbol: { fill: items ? colors[i] : restaurantColors[key] },
      };
    });
  }
  if (topSellers !== null) {
    return topSellers.map((key, i) => {
      return {
        name: key.name,
        symbol: { fill: items ? colors[i] : restaurantColors[key] },
      };
    });
  } else {
    return filter
      ? Object.keys(data["netSales"][`${filter.value}`]).map((key, i) => {
        return {
          name: key,
          symbol: { fill: items ? colors[i] : restaurantColors[key] },
        };
      })
      : Object.keys(data["netSales"]).map((key, i) => {
        return {
          name: key,
          symbol: { fill: items ? colors[i] : restaurantColors[key] },
        };
      });
  }
};

const getMinMax = (data, type) => {
  let min = 5000;
  let max = 0;
  Object.keys(data).forEach((time) => {
    if (data[time].y < min) {
      min = data[time].y;
    }
    if (data[time].y > max) {
      max = data[time].y;
    }
  });
  if (min === 5000) min = 0;
  if (type === "Max") return max.toFixed(1).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  if (type === "Min") return min.toFixed(1).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const getPeakandLows = (chartData, range, type) => {
  if (range === "today" || range === "yesterday") {
    return getMinMax(chartData.total.hourly.netSales, type);
  }
  if (range === "lastWeek" || range === "thisWeek") {
    return getMinMax(chartData.total.daily.netSales, type);
  }
  if (range === "thisMonth" || range === "lastMonth") {
    return getMinMax(chartData.total.daily.netSales, type);
  }
  if (range === "thisYear" || range === "allTime") {
    return getMinMax(chartData.total.weekly.netSales, type);
  } else {
    return getMinMax(chartData.total.hourly.netSales, type);
  }
};

const createStorefronts = (data) => {
  if (!data["hourly"]) return { label: "", value: "" };
  let filters = Object.keys(data["hourly"]["netSales"]).map((name) => {
    return {
      label: name,
      value: name,
    };
  });
  return filters;
};

const Charts = () => {
  const {
    chartChosen,
    setChartChosen,
    setDateRange,
    startDate,
    endDate,
    chartData,
    loading,
    onSelectStartDate,
    onSelectEndDate,
    setLocation,
    dateRange,
  } = useCharts();

  const {width} = useWindowSize();

  const categories = [
    { title: "Brand split", type: "brand", name: "Brand" },
    { title: "Channel split", type: "channel", name: "Channel" },
    { title: "Storefront split", type: "storefront", name: "Storefront" },
    {
      title: "1st vs 3rd Party",
      type: "FirstVsThird",
      name: "First Vs Third Party",
    },
  ];

  const [splitFilter, setSplitFilter] = React.useState({
    label: "Noshery",
    value: "Noshery",
  });
  const [category, setCategory] = React.useState("Sales");
  const [topSellers, setTopSellers] = React.useState([
    { name: "", netSales: "", orderCount: "" },
  ]);

  return (
    <Box maxWidth="100%" paddingTop="5%" overflow="hidden">
      <Flex flexDirection="column" bg="#f2f2f2">
        <Text ml="1%" fontWeight="600" fontSize={5} mb={4}>
          Noshery Sales Dashboard
        </Text>
        <Flex justifyContent="space-between" mb={4} flexDirection="column">
          <Flex w="100%" justifyContent="space-between" flexDirection="column">
            <Flex w="100%" justifyContent="space-between" flexDirection="row">
              <Box paddingRight="10px">
                <StyledSelect
                  onChange={(e) => setLocation(locationsOption[e.target.value])}
                >
                  {Object.keys(locationsOption).map((d) => (
                    <option value={d} key={d}>
                      {d}
                    </option>
                  ))}
                </StyledSelect>
              </Box>
              <Box width="100%">
                <StyledSelect
                  value={dateRange}
                  onChange={(e) => setDateRange(e.target.value)}
                >
                  {Object.keys(dateRangeOption).map((d) => (
                    <option value={d} key={d}>
                      {dateRangeOption[d]}
                    </option>
                  ))}
                </StyledSelect>
              </Box>
            </Flex>
            <Box py="2%">
              <StyledSelect onChange={(e) => setCategory(e.target.value)}>
                <option value="Sales">Sales</option>
                <option value="Menu">Menu</option>
                <option value="Ingredients">Ingredients</option>
                <option value="Labor">Labor</option>
              </StyledSelect>
            </Box>
          </Flex>

          <Flex justifyContent="center" alignSelf="center" width="90%">
            <Flex
              style={{ borderRadius: "10px" }}
              py="10px"
              bg="white"
              px="3%"
              flexDirection="column"
            >
              <Flex
                alignItems="center"
                onClick={() => setDateRange("custom")}
                flexDirection="column"
                mb={"20px"}
              >
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DesktopDatePicker
                    label="Start Date"
                    inputFormat="YYYY-MM-DD"
                    minDate={moment("2021-11-12")}
                    maxDate={moment()}
                    value={moment.isMoment(startDate) ? startDate : moment(startDate)}
                    onChange={onSelectStartDate}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </Flex>
              <Flex
                alignItems="center"
                onClick={() => setDateRange("custom")}
                flexDirection="column"
              >
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DesktopDatePicker
                    label="End Date"
                    inputFormat="YYYY-MM-DD"
                    maxDate={moment()}
                    minDate={ moment.isMoment(startDate) ? startDate : moment(startDate)}
                    value={
                      moment.isMoment(endDate) ? endDate : moment(endDate)}
                    onChange={onSelectEndDate}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>

      {loading ? (
        <PageLoader />
      ) : isDataEmpty(chartData) ? (
        <NoData />
      ) : (
        <Flex
          flexDirection="column"
          minHeight="100vh"
          bg="#f2f2f2"
        >
          <StyledCard>
            <Text style={{fontSize: "16px"}} p={2}>
              Net Sales:{" "}
              <NumberSpan>
                $
                {chartData &&
                  chartData.total &&
                  chartData.total.netSales &&
                  chartData.total.netSales.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                  })}
              </NumberSpan>
            </Text>
            <Text style={{fontSize: "16px"}} p={2}>
              Total Orders:{" "}
              <NumberSpan>
                {chartData &&
                  chartData.total &&
                  chartData.total.totalOrders.toLocaleString(undefined, {})}
              </NumberSpan>
            </Text>
            <Text style={{fontSize: "16px"}} p={2}>
              AOV:{" "}
              <NumberSpan>
                $
                {chartData && chartData.total && chartData.total.aov
                  ? chartData.total.aov.toFixed(2)
                  : 0}
              </NumberSpan>
            </Text>
          </StyledCard>
          <StyledCard>
            <Text style={{fontSize: "16px"}} p={2}>
              AOV {"(Orders > $5)"}:{" "}
              <NumberSpan>
                $
                {chartData && chartData.total && chartData.total.adjustedAov
                  ? chartData.total.adjustedAov.toFixed(2)
                  : 0}
              </NumberSpan>
            </Text>
            <Text style={{fontSize: "16px"}} p={2}>
              Test/Employee Orders:{" "}
              <NumberSpan>
                {chartData &&
                  chartData.total &&
                  chartData.total.testOrders.toLocaleString(undefined, {})}
              </NumberSpan>
            </Text>
          </StyledCard>
          <Flex justifyContent="space-between" flexDirection="column">
            <StyledCard flexDirection="column" p="16px">
              <Flex justifyContent="space-between">
                <Text fontSize={5} p={2} mr="60px">
                  Net sales
                </Text>
                <Text fontSize={5} p={2} fontWeight={600}>
                  $
                  {chartData &&
                    chartData.total &&
                    chartData.total.netSales &&
                    chartData.total.netSales.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                    })}
                </Text>
              </Flex>
              <Flex justifyContent="space-between">
                <Text fontSize={4} p={2} mr="60px" color={gray}>
                  Taxes
                </Text>
                <Text fontSize={4} p={2} fontWeight={600} color={gray}>
                  $
                  {chartData &&
                    chartData.total &&
                    chartData.total.totalTax &&
                    chartData.total.totalTax.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                    })}
                </Text>
              </Flex>
              <Flex justifyContent="space-between">
                <Text fontSize={4} p={2} mr="60px" color={gray}>
                  Tips
                </Text>
                <Text fontSize={4} p={2} fontWeight={600} color={gray}>
                  $
                  {chartData &&
                    chartData.total &&
                    chartData.total.totalTip &&
                    chartData.total.totalTip.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                    })}
                </Text>
              </Flex>
              <Divider />
              <Flex justifyContent="space-between">
                <Text fontSize={5} p={2} mr="60px">
                  Total
                </Text>
                <Text fontSize={5} p={2} fontWeight={600}>
                  $
                  {chartData &&
                    chartData.total &&
                    chartData.total.netSales &&
                    chartData.total.totalTax &&
                    chartData.total.totalTip >= 0 &&
                    (
                      chartData.total.netSales +
                      chartData.total.totalTax + 
                      chartData.total.totalTip
                    ).toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                    })}
                  <Text as="span" alignText="right" paddingLeft={"50%"}>
                    {" "}
                    {chartData &&
                      chartData.percentDifferenceSales !== 0 &&
                      (chartData.percentDifferenceSales > 0 ? (
                        <Text color="#228C22" fontSize={3} alignText="right">
                          DoD: +{(chartData.percentDifferenceSales || 0).toFixed(0)}%
                        </Text>
                      ) : (
                        <Text color="red" fontSize={3} alignText="right">
                          DoD: {(chartData.percentDifferenceSales || 0).toFixed(0)}%
                        </Text>
                      ))}
                    {chartData &&
                      chartData.sdlw !== 0 &&
                      (chartData.sdlw > 0 ? (
                        <Text color="#228C22" fontSize={3} w="100%" alignText="right">
                          SDLW: +{(chartData.sdlw || 0).toFixed(0)}%
                        </Text>
                      ) : (
                        <Text color="red" fontSize={3} w="100%" alignText="right">
                          SDLW: {(chartData.sdlw || 0).toFixed(0)}%
                        </Text>
                      ))}
                  </Text>
                </Text>
              </Flex>
              <Text fontSize={2} p={2} fontWeight={600} color={gray}>
                Sales Updated at:
                {chartData &&
                  chartData.timeStamp &&
                  moment
                    .tz(chartData.timeStamp.writeDate, "America/Los_Angeles")
                    .format("MM/DD/YYYY h:mm a")}
              </Text>
            </StyledCard>
            {category === "Sales" &&
              chartData &&
              chartData.total &&
              chartData.total.hourly && (
              <NetSalesLineChart
                chartChosen={chartChosen}
                setChartChosen={setChartChosen}
                chartData={chartData.total}
                total={chartData.total}
                dataType="netSales"
                name="totalBoth"
                dateRange={dateRange}
              />
            )}
          </Flex>
          <StyledCard flexDirection="column" fullwidth>
            <Text fontSize={3} p={4}>
              # number of days:{" "}
              <NumberSpan>
                {chartData && chartData.total && chartData.total.days}
              </NumberSpan>
            </Text>
            <Text fontSize={3} p={4}>
              Avg. daily sales:{" "}
              <NumberSpan>
                {" "}
                $
                {chartData && chartData.total && chartData.total.adv
                  ? chartData.total.adv.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                  })
                  : 0}
              </NumberSpan>
            </Text>
            <Text fontSize={3} p={4}>
              Avg. daily orders:{" "}
              <NumberSpan>
                {" "}
                {chartData && chartData.total && chartData.total.ado
                  ? chartData.total.ado.toFixed(2)
                  : 0}
              </NumberSpan>
            </Text>
            <Text fontSize={3} p={4}>
              Peak Sales:
              <NumberSpan>
                $
                {chartData &&
                  chartData.total &&
                  getPeakandLows(chartData, dateRange, "Max")}
              </NumberSpan>
            </Text>
            <Text fontSize={3} p={4}>
              Low Sales:
              <NumberSpan>
                $
                {chartData &&
                  chartData.total &&
                  getPeakandLows(chartData, dateRange, "Min")}
              </NumberSpan>
            </Text>
          </StyledCard>
          {category === "Sales" &&
            chartData &&
            chartData["catering"] &&
            chartData["catering"]["split"] &&
            chartData["catering"]["split"].length !== 0 &&
            chartData["catering"]["netSalesPieChart"] &&
            chartData["total"] && (
            <Box >
              <Text ml="1%" fontWeight="600" fontSize={4} mt={4}>
                  Catering Split
              </Text>
              <Flex flexDirection="column" justifyContent="space-between" pt={4}>
                <StyledCard>
                  <CategoryRankingBox>
                    <Text fontWeight="600" fontSize={4}>
                        Type
                    </Text>
                    <Text fontWeight="600" fontSize={4}>
                        Net sales
                    </Text>
                    <GridBox>
                      {chartData["catering"]["split"].map((type) => {
                        return (
                          <SpacedBox key={type}>
                            <Box my={2}>
                              <Text fontWeight={600}>{type.channel}</Text>
                            </Box>
                            <Box my={2}>${type.netSales.toFixed(2)}</Box>
                          </SpacedBox>
                        );
                      })}
                    </GridBox>
                  </CategoryRankingBox>
                </StyledCard>
                <StyledCard height={(`${(width * 90)/100}px`)} paddingTop="5%" paddingBottom="15%" width="100%" flexDirection="column">
                  <Text
                    textAlign="center"
                    fontWeight={600}
                    fontSize={4}
                  >
                      Net sales breakdown
                  </Text>
                  <ResponsivePieChart
                    data={chartData["catering"]["netSalesPieChart"].map(
                      (d) => ({ id: d.channel, value: d.y.toFixed(2) })
                    )}
                  />
                </StyledCard>
              </Flex>
            </Box>
          )}
          {(category === "Sales" && (
            <>
              {categories.map((cat, index) => {
                return (
                  <Box key={index}>
                    <Text ml="1%" fontWeight="600" fontSize={4} mt={4}>
                      {cat.title}
                    </Text>
                    {cat.type === "storefront" &&
                    chartData &&
                    chartData[`${cat.type}s`] &&
                    chartData[`${cat.type}s`]["hourly"] ? (
                        <Dropdown
                          options={
                            chartData[`${cat.type}s`]["hourly"] &&
                          createStorefronts(chartData[`${cat.type}s`])
                          }
                          selected={splitFilter}
                          onSelect={setSplitFilter}
                          isClearable={false}
                          isSearchable
                        />
                      ) : null}
                    <Flex justifyContent="space-between" flexDirection="column" pt={4}>
                      <StyledCard>
                        <CategoryRankingBox>
                          <Text fontWeight="600" fontSize={4}>
                            {cat.name}
                          </Text>
                          <Text fontWeight="600" fontSize={4}>
                            {cat.type === "storefront"
                              ? "Orders part of"
                              : "Orders"}
                          </Text>
                          <Text fontWeight="600" fontSize={4}>
                            Net sales
                          </Text>
                          <GridBox>
                            <Flex flexDirection="column">
                              {chartData &&
                                chartData[`${cat.type}s`] &&
                                chartData[`${cat.type}s`].split.map((brand) => {
                                  if (
                                    cat.type === "storefront" &&
                                    brand.name !== splitFilter.value
                                  )
                                    return false;
                                  return (
                                    <SpacedBox
                                      justifyContent="space-around"
                                      key={`${brand.name}&${brand.channel}`}
                                    >
                                      <Box my={2}>
                                        <Text fontWeight={600}>
                                          {cat.type === "storefront"
                                            ? brand.channel
                                            : brand.name}
                                        </Text>
                                      </Box>
                                      <Box my={2}>
                                        <Text>
                                          {`${brand.orderCount} `}
                                          <SmallSpan>{`(${(
                                            (brand.orderCount /
                                              chartData.total.totalOrders) *
                                            100
                                          ).toLocaleString(undefined, {
                                            maximumFractionDigits: 1,
                                          })}%)`}</SmallSpan>
                                        </Text>
                                      </Box>
                                      <Box my={2}>
                                        <Text>
                                          $
                                          {`${brand.netSales.toLocaleString(
                                            undefined,
                                            {
                                              maximumFractionDigits: 0,
                                            }
                                          )} `}
                                          <SmallSpan>{`(${(
                                            (brand.netSales /
                                              chartData.total.netSales) *
                                            100
                                          ).toLocaleString(undefined, {
                                            maximumFractionDigits: 1,
                                          })}%)`}</SmallSpan>
                                        </Text>
                                      </Box>
                                    </SpacedBox>
                                  );
                                })}
                            </Flex>
                          </GridBox>
                        </CategoryRankingBox>
                      </StyledCard>
                      <StyledCard height={(`${(width * 90)/100}px`)} paddingTop="5%" paddingBottom="15%" width="100%" flexDirection="column">
                        <Text
                          textAlign="center"
                          fontWeight={600}
                          fontSize={4}
                        >
                          Net sales breakdown
                        </Text>
                        {cat.type === "storefront" ? (
                          <ResponsivePieChart
                            data={
                              chartData &&
                              chartData[`${cat.type}s`] &&
                              chartData[`${cat.type}s`].netSalesPieChart
                                .filter((d) =>
                                  d.name.includes(splitFilter.value)
                                )
                                .map((d) => ({
                                  id: d.label,
                                  value: d.y.toFixed(2),
                                }))
                            }
                          />
                        ) : (
                          <ResponsivePieChart
                            data={
                              chartData &&
                              chartData[`${cat.type}s`] &&
                              chartData[`${cat.type}s`].netSalesPieChart.map(
                                (d) => ({ id: d.label, value: d.y.toFixed(2) })
                              )
                            }
                          />
                        )}
                      </StyledCard>
                    </Flex>
                    <Flex flexDirection="column" width="100%">
                      {chartData &&
                        chartData[`${cat.type}s`] &&
                        chartData[`${cat.type}s`].hourly && (
                        <>
                          <NetSalesandOrderChart
                            chartChosen={chartChosen}
                            setChartChosen={setChartChosen}
                            chartData={chartData[`${cat.type}s`]}
                            total={chartData.total}
                            category
                            topSellers={null}
                            filter={
                              cat.type === "storefront" ? splitFilter : null
                            }
                            dataType="netSales"
                            name={`${cat.type}sNetSales`}
                            chartName={"Net Sales"}
                            dateRange={dateRange}
                          />
                          <NetSalesandOrderChart
                            chartChosen={chartChosen}
                            setChartChosen={setChartChosen}
                            chartData={chartData[`${cat.type}s`]}
                            total={chartData.total}
                            category
                            topSellers={null}
                            filter={
                              cat.type === "storefront" ? splitFilter : null
                            }
                            dataType="orderCount"
                            name={`${cat.type}sOrderCount`}
                            chartName={"Order Count"}
                            dateRange={dateRange}
                          />
                        </>
                      )}
                    </Flex>
                  </Box>
                );
              })}
            </>
          )) ||
            (category === "Menu" && (
              <>
                {chartData &&
                  chartData["items"] &&
                  chartData["discounts"] &&
                  chartData["discounts"]["daily"] && 
                  chartData["grossSales"] && 
                  chartData["grossSales"]["daily"] && 
                  chartData["items"].hourly &&
                  chartData["items"][`${chartChosen.toLowerCase()}`] && (
                  <>
                    <BestSellerTable
                      setTopSellers={setTopSellers}
                      globalData={chartData}
                      data={chartData["items"][chartChosen.toLowerCase()]}
                      time={chartChosen.toLowerCase()}
                    />

                    <Text
                      style={{
                        fontWeight: "bold",
                        fontSize: "20px",
                        paddingBottom: "10px",
                        marginLeft: "1%"
                      }}
                    >
                        Top 5 Best Sellers
                    </Text>
                    {topSellers !== null &&
                        topSellers !== undefined &&
                        chartData["items"] && 
                        chartData["items"][chartChosen.toLowerCase()] &&
                        chartData["items"][chartChosen.toLowerCase()]["netSales"] &&
                        topSellers[0] &&
                        topSellers[1] &&
                        topSellers[2] &&
                        topSellers[3] &&
                        topSellers[4] &&
                        chartData["items"][chartChosen.toLowerCase()]["netSales"][topSellers[0].name] && 
                        chartData["items"][chartChosen.toLowerCase()]["netSales"][topSellers[1].name] &&
                        chartData["items"][chartChosen.toLowerCase()]["netSales"][topSellers[2].name] &&
                        chartData["items"][chartChosen.toLowerCase()]["netSales"][topSellers[3].name] &&
                        chartData["items"][chartChosen.toLowerCase()]["netSales"][topSellers[4].name] && 
                        topSellers.filter((t) => t.name).length && (
                      <BestSellersChart
                        chartChosen={chartChosen}
                        setChartChosen={setChartChosen}
                        chartData={chartData["items"]}
                        total={chartData.total}
                        category
                        items={true}
                        topSellers={topSellers.filter((t) => t.name)}
                        filter={null}
                        dataType="orderCount"
                        name={"itemsOrderCount"}
                      />
                    )}
                  </>
                )}
              </>
            )) ||
            (category === "Ingredients" && (
              <IngredientsTable
                ingredients={chartData["ingredients"]}
                ingredientsByHour={chartData["ingredientsByHour"]}
              />
            )) ||
            (category === "Labor" &&
              chartData["laborCost"] &&
              chartData["laborCost"].length !== 0 && (
              <Box paddingBottom={"15%"}>
                <StyledCard justifyContent="center" flexDirection="row">
                  <Text
                    textAlign="center"
                    p={2}
                    fontWeight={600}
                    fontSize={4}
                  >
                      Net sales: ${" "}
                    {chartData &&
                        chartData.total &&
                        chartData.total.netSales &&
                        chartData.total.netSales.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                        })}
                  </Text>
                  <Text
                    textAlign="center"
                    p={2}
                    fontWeight={600}
                    fontSize={4}
                  >
                      Labor Cost:{" "}
                    {(chartData["totalLaborCost"] || 0).toFixed(2)}
                  </Text>
                  <Text
                    textAlign="center"
                    p={2}
                    fontWeight={600}
                    fontSize={4}
                  >
                      Labor Cost %:{" "}
                    {(
                      ((chartData["totalLaborCost"] || 0)  /
                          (chartData &&
                            chartData.total &&
                            chartData.total.netSales)) *
                        100
                    ).toFixed(2)}
                  </Text>
                </StyledCard>
                <LaborCost data={chartData} category="Labor" />
              </Box>
            ))}
        </Flex>
      )}
    </Box>
  );
};

const ChartTypeButton = ({ days, chartChosen, setChartChosen }) => {
  const chartOptions = createChartOptions(days);

  return (
    <StyledSelect
      onChange={(e) => {
        setChartChosen(e.target.value);
      }}
      fontSize="16px"
      position="absolute"
    >
      {chartOptions &&
        chartOptions.map((option) => (
          <option
            selected={chartChosen === option ? option : false}
            value={option}
            key={option}
          >
            {option}
          </option>
        ))}
    </StyledSelect>
  );
};

const createChartOptions = (days) => {
  if (days === 1) return ["Hourly"];
  if (days > 1 && days < 14) return ["Hourly", "Daily"];
  if (days >= 14 && days < 60) return ["Hourly", "Daily", "Weekly"];
  if (days >= 60) return ["Hourly", "Daily", "Weekly", "Monthly"];
  return ["None"];
};

const sort = (chartChosen, data) => {
  if (chartChosen.toLowerCase() === "hourly") {
    return data.sort((a, b) => parseInt(a.x) - parseInt(b.x));
  }

  return data.sort();
};

const NetSalesandOrderChart = ({
  chartChosen,
  setChartChosen,
  chartData,
  total,
  category,
  topSellers,
  filter,
  dataType,
  chartName,
}) => {
  let legendData = createLegendData(
    chartData[chartChosen.toLowerCase()],
    category,
    filter,
    topSellers,
    null
  );

  let data = legendData.map((l) => {
    return {
      id: l.name,
      color: l.symbol.fill,
      data: filter
        ? sort(
          chartChosen,
          chartData[chartChosen.toLowerCase()][dataType][filter.value][l.name]
        )
        : sort(
          chartChosen,
          chartData[chartChosen.toLowerCase()][dataType][l.name]
        ),
    };
  });
  if (chartChosen.toLowerCase() === "hourly") {
    data.sort((a, b) => parseInt(a.data[0].x) - parseInt(b.data[0].x));

    for (var i = 0; i < data.length; i++) {
      const intVersion = data[i].data.map((d) => parseInt(d.x));
      let smallest = intVersion[0];
      for (var a = 1; a < intVersion.length; a++) {
        let dd = intVersion[a];
        smallest += 1;
        if (smallest != dd) {
          data[i].data.splice(a, 0, { x: smallest.toString(), y: 0 });
          a--;
        } else {
          smallest = dd;
        }
      }
    }
    data.forEach((d) => d.data.sort((a, b) => parseInt(a.x) - parseInt(b.x)));
  } else {
    data.sort((a, b) => b.data.length - a.data.length);
  }

  return (
    <ChartContainer category={category}>
      <ChartTypeButton
        days={total.days}
        chartChosen={chartChosen}
        setChartChosen={setChartChosen}
      ></ChartTypeButton>
      <Box width="100%" height="300px">
        <ResponsiveLineChart
          data={data}
          legend={"Time"}
          axisLeftLegend={chartName === "Net Sales" ? "Sales" : "Count"}
          tickSize={chartChosen.toLowerCase() !== "hourly" ? 3 : 5}
          tickRotation={chartChosen.toLowerCase() !== "hourly" ? -50 : 0}
        />
      </Box>
    </ChartContainer>
  );
};

const NetSalesLineChart = ({
  chartChosen,
  setChartChosen,
  chartData,
  total,
}) => {
  let legendData = createLegendData(chartData[chartChosen.toLowerCase()]);
  let data = legendData.map((l) => {
    return {
      id: l.name,
      color: l.symbol.fill,
      data: chartData[chartChosen.toLowerCase()][l.name].sort(),
    };
  });
  if (chartChosen.toLowerCase() === "hourly") {
    data.sort((a, b) => parseInt(a.data[0].x) - parseInt(b.data[0].x));

    for (var i = 0; i < data.length; i++) {
      const intVersion = data[i].data.map((d) => parseInt(d.x));
      let smallest = intVersion[0];
      for (var a = 1; a < intVersion.length; a++) {
        let dd = intVersion[a];
        smallest += 1;
        if (smallest != dd) {
          data[i].data.splice(a, 0, { x: smallest.toString(), y: 0 });
          a--;
        } else {
          smallest = dd;
        }
      }
    }
    data.forEach((d) => d.data.sort((a, b) => parseInt(a.x) - parseInt(b.x)));
  } else {
    data.sort((a, b) => b.data.length - a.data.length);
  }
  if (chartChosen.toLowerCase() === "hourly") {
    data.sort((a, b) => parseInt(a.data[0].x) - parseInt(b.data[0].x));
  } else {
    data.sort((a, b) => b.data.length - a.data.length);
  }

  return (
    <ChartContainer>
      <ChartTypeButton
        days={total.days}
        chartChosen={chartChosen}
        setChartChosen={setChartChosen}
      ></ChartTypeButton>
      <Box width="100%" height="300px">
        <ResponsiveLineChart
          data={data}
          legend="Net Sales vs Order Count"
          axisLeftLegend="price"
          tickSize={chartChosen.toLowerCase() !== "hourly" ? 3 : 5}
          tickRotation={chartChosen.toLowerCase() !== "hourly" ? -50 : 0}
        />
      </Box>
    </ChartContainer>
  );
};

const BestSellersChart = ({
  chartChosen,
  setChartChosen,
  chartData,
  total,
  category,
  dataType,
  items,
  filter,
  topSellers,
}) => {
  let legendData = createLegendData(
    chartData[chartChosen.toLowerCase()],
    category,
    filter,
    topSellers,
    items
  );


  let data = legendData.map((l) => {
    
    console.log(chartChosen, dataType, l.name);
    return {
      id: l.name,
      color: l.symbol.fill,
      data: chartData[chartChosen.toLowerCase()][dataType][l.name].sort(),
    };
  });
  console.log("--------------------");
  if (chartChosen.toLowerCase() === "hourly") {
    data.sort((a, b) => parseInt(a.data[0].x) - parseInt(b.data[0].x));

    for (var i = 0; i < data.length; i++) {
      const intVersion = data[i].data.map((d) => parseInt(d.x));
      let smallest = intVersion[0];
      for (var a = 1; a < intVersion.length; a++) {
        let dd = intVersion[a];
        smallest += 1;
        if (smallest != dd) {
          data[i].data.splice(a, 0, { x: smallest.toString(), y: 0 });
          a--;
        } else {
          smallest = dd;
        }
      }
    }
    data.forEach((d) => d.data.sort((a, b) => parseInt(a.x) - parseInt(b.x)));
  } else {
    data.sort((a, b) => b.data.length - a.data.length);
  }
  if (chartChosen.toLowerCase() === "hourly") {
    data.sort((a, b) => parseInt(a.data[0].x) - parseInt(b.data[0].x));
  } else {
    data.sort((a, b) => b.data.length - a.data.length);
  }


  return (
    <ChartContainer category={category}>
      <ChartTypeButton
        days={total.days}
        chartChosen={chartChosen}
        setChartChosen={setChartChosen}
      ></ChartTypeButton>
      <Box width="100%" height="300px">
        <ResponsiveLineChart
          data={data}
          legend="Best Sellers Order Count"
          tickSize={chartChosen.toLowerCase() !== "hourly" ? 3 : 5}
          tickRotation={chartChosen.toLowerCase() !== "hourly" ? -50 : 0}
        />
      </Box>
    </ChartContainer>
  );
};

export default Charts;
