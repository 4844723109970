import React from "react"; 

const API_URL = process.env.REACT_APP_API_URL;

const useItemToPlating = () => {
  const [loading, setLoading] = React.useState(false);
  const [itemToPlating, setItemToPlating] = React.useState([]);
  const [modifierToPlating, setModifierToPlating] = React.useState([]);
  const [plating, setPlating] = React.useState([]);
  const [updateMappingLoading, setUpdateMappingLoading] = React.useState(false);

  const fetchItemPlating = async () => {
    setLoading(true);
    const result = await fetch(`${API_URL}/itemToPlating`, {
      method: "GET",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      }, 
    });
    const data = await result.json();
 
    setItemToPlating(data.data.items);
    setModifierToPlating(data.data.modifiers);
    setPlating(data.data.plating);

    setLoading(false);
  };

  const updateItemPlating = async (itemsAndModsMapping) => {
    setUpdateMappingLoading(true);

    const result = await fetch(`${API_URL}/updateItemToPlating`, {
      method: "POST",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      }, 
      body: JSON.stringify({ itemAndModsMppaing: itemsAndModsMapping })
    });  

    await fetchItemPlating();

    setUpdateMappingLoading(false);
  };

  React.useEffect(() => {
    fetchItemPlating();
  }, []);

  return {
    loading,
    itemToPlating,
    modifierToPlating,
    plating,
    setItemToPlating,
    setModifierToPlating,
    fetchItemPlating,
    updateItemPlating,
    updateMappingLoading,
  };

};

export default useItemToPlating;