import Select from "react-select";

const Dropdown = ({
  options,
  selected,
  isClearable,
  isDisabled,
  isSearchable,
  isLoading,
  isMulti,
  isRtl,
  onSelect,
}) => {
  return (
    <Select
      isDisabled={isDisabled}
      isLoading={isLoading}
      isClearable={isClearable}
      isRtl={isRtl}
      isMulti={isMulti}
      isSearchable={isSearchable}
      selected={selected}
      defaultValue={selected}
      onChange={onSelect}
      options={options}
    />
  );
};

export default Dropdown;
