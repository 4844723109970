import React from "react"; 
import { Box, Typography, Card, Checkbox, Autocomplete, TextField } from "@mui/material";
import { useItemsSelect } from "../../../hooks/useItemsSelect";
import Loading from "../../../components/Loading";
import { LoadingButton } from "@mui/lab";

const ItemSelectForEmail = () => {
  const {brands, items, isLoading, setBrands, setItems, updateChosenItemsAndBrands, updateLoading, success} = useItemsSelect();
  const [hasChanges, setHasChanges] = React.useState(false);

  const [selectAllItems, setSelectAllItems] = React.useState(false);
  const [selectAllBrands, setSelectAllBrands] = React.useState(false);
  
  const [filterItem, setFilterItem] = React.useState("");
  const [filterBrand, setFilterBrand] = React.useState("");

  const updateBrandOrItem = (type, name, chosen) => {
    setHasChanges(true);
    if(type === "brand") {
      const newBrands = brands.map((b) => {
        if(b.name === name) {
          return {...b, chosen: chosen};
        }
        return b;
      });
      setBrands(newBrands);
    } else {
      const newItems = items.map((b) => {
        if(b.name === name) {
          return {...b, chosen: chosen};
        }
        return b;
      });
      setItems(newItems);
    }
  };

  const updateChosenItemsAndBrandsMutation = async () => {
    await updateChosenItemsAndBrands();
    setHasChanges(false);
  };

  return (<>
    {
      isLoading ? <Loading /> :
        <Box sx={{
          pb: 3,
          px: "100px",
          pt: "100px"
        }}>
          <Box sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 3,
          
          }}>      
            <Typography sx={{
              fontWeight: "bold",
              fontSize: "30px",
            }}> Comparisons Email Item Select </Typography>

            <LoadingButton onClick={updateChosenItemsAndBrandsMutation} loading={updateLoading} disabled={!hasChanges} variant="contained"> 
                Save Changes
            </LoadingButton>
          </Box>


          <Box sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "top",
            mt: 3,
          }}>

            <Card sx={{
              width: "55%",
              m: 1,
              p: 3
            }}> 
              <Typography sx={{
                fontWeight: "bold", 
                fontSize: "24px"
              }}> Select Items  </Typography>
              <Autocomplete 
                options={items.map((i) => i.name)}
                value = {filterItem}
                onChange={(e, value) => setFilterItem(value || "")}
                onInputChange={(e, value) => setFilterItem(value || "")}
                renderInput={(params) => <TextField {...params} label="Filter Items" />}
              />
              <Box
                onClick={() => {
                  const newItems = items.map((i) => {
                    return {...i, chosen: !selectAllItems};
                  });
                  setSelectAllItems(!selectAllItems);
                  setItems(newItems);
                  setHasChanges(true);
                }}
                sx={{
                  display: "flex",
                  flexDirection: "row", 
                  alignContent: "center",
                  alignItems: "center",
                }}>
                <Checkbox sx={{
                  cursor: "pointer"
                }} checked={selectAllItems} 
                onClick={(e) => {
                  const newItems = items.map((i) => {
                    return {...i, chosen: !selectAllItems};
                  });
                  setSelectAllItems(!selectAllItems);
                  setItems(newItems);
                  setHasChanges(true);
                }} />

                <Typography sx={{
                  fontSize: "12px",  
                }}> Select All </Typography>

              </Box>
              {
                items.filter((i) => filterItem !== "" ? (i.name.toLowerCase()).includes(filterItem.toLowerCase()) : true).map((i) => {
                  return <Box key={i.name}
                    onClick={() => updateBrandOrItem("item", i.name, !i.chosen)}
                    sx={{
                      display: "flex",
                      flexDirection: "row", 
                      alignContent: "center",
                      alignItems: "center",
                    }}>
                    <Checkbox 
                      onClick={() => updateBrandOrItem("item", i.name, !i.chosen)} sx={{ 
                      }} checked={i.chosen} />
                    <Typography sx={{
                      fontSize: "12px", 
                      cursor: "pointer"
                    }}> {i.name} </Typography>
                  
                  </Box>; 
                })
              }
            </Card>

            <Card sx={{
              width: "55%",
              m: 1,
              p: 3
            }}> 
              <Typography sx={{
                fontWeight: "bold", 
                fontSize: "24px"
              }}> Select Brands  </Typography>
              <Autocomplete 
                options={brands.map((i) => i.name)}
                value = {filterBrand}
                onChange={(e, value) => setFilterBrand(value || "")}
                onInputChange={(e, value) => setFilterBrand(value || "")}
                renderInput={(params) => <TextField {...params} label="Filter Brands" />}
              />
              <Box
                onClick={() => {
                  const newBrands = brands.map((i) => {
                    return {...i, chosen: !selectAllBrands};
                  });
                  setSelectAllBrands(!selectAllBrands);
                  setBrands(newBrands);
                  setHasChanges(true);
                }}
                sx={{
                  display: "flex",
                  flexDirection: "rnewBrandsw", 
                  alignContent: "center",
                  alignItems: "center",
                }}>
                <Checkbox sx={{
                  cursor: "pointer"
                }} checked={selectAllBrands} 
                onClick={(e) => {
                  const newBrands = brands.map((i) => {
                    return {...i, chosen: !selectAllBrands};
                  });
                  setSelectAllBrands(!selectAllBrands);
                  setBrands(newBrands);
                  setHasChanges(true);
                }} />

                <Typography sx={{
                  fontSize: "12px",  
                }}> Select All </Typography>

              </Box>
              {
                brands.filter((i) => filterBrand !== "" ? (i.name.toLowerCase()).includes(filterBrand.toLowerCase()) : true).map((i) => {
                  return <Box key={i.name} 
                    onClick={() => updateBrandOrItem("brand", i.name, !i.chosen)}
                    sx={{
                      display: "flex",
                      flexDirection: "row", 
                      alignContent: "center",
                      alignItems: "center",
                    }}>
                    <Checkbox sx={{ 
                    }} checked={i.chosen} />
                    <Typography 
                      onClick={() => updateBrandOrItem("item", i.name, !i.chosen)} sx={{
                        fontSize: "12px", 
                        cursor: "pointer"
                      }}> {i.name} </Typography>
                  
                  </Box>; 
                })
              }
            </Card>

          </Box>
      
        </Box>
    } 
  </>
  );
};

export default ItemSelectForEmail;