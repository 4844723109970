import React from "react";
import { Box, Flex, Text, Heading } from "noshery-ui";
import useUsers from "../../hooks/useUsers";
import SingleUser from "../../components/SingleUser";


const Users = () => {
  const { users, setUsersClaim } = useUsers();

  return (
    <Box mt={3}>
      <Heading fontSize={6} paddingBottom="2%"> Users: </Heading>   
      {users.map(user => {
        return (
          <Flex flexDirection="column" height="100%" width="100%" mb={15} key={user.uid} border="2px solid #ececec" p={2}>
            <Box mr={3} paddingBottom="3%">
              <Text fontWeight="800" fontSize={5}>
                {user.displayName || user.email || user.uid} ----- 
                {user.customClaims && user.customClaims.admin ? "Admin" : null}
              </Text>
            </Box>
            <SingleUser user={user} setUsersClaim={setUsersClaim} />
          </Flex>
        );
      })} 
    </Box>
  );
};

export default Users;
