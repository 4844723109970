import  React from "react"; 
import { useEffect } from "react";

const API_URL = process.env.REACT_APP_API_URL;

export const useIngredientSelect = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [updateLoading, setUpdateLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [ingredients, setIngredients] = React.useState([]);
  

  const fetchData = async () => {
    setIsLoading(true);
    const res = await fetch(`${API_URL}/getIngredients`, {
      method: "GET",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      }, 
    });

    const { chosenIngredients } = await res.json();
    setIngredients(chosenIngredients);

    setIsLoading(false);

  };

  const updateChosenIngredients = async () => {
    setUpdateLoading(true);

    const chosenIngredients = ingredients.filter((b) => b.chosen).map((b) => b.name); 

    const result = await fetch(`${API_URL}/updateChosenIngredients`, {
      method: "POST",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ chosenIngredients })
    });

    await fetchData();

    setSuccess(true);

    setUpdateLoading(false);
  };

  useEffect(() => { 
    fetchData(); 
  }, []);

  return { isLoading, refetch: fetchData, ingredients, setIngredients, updateChosenIngredients, updateLoading, success,};

};
