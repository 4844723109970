

export const locations = {
  "All Stores": "All",
  "San Mateo": "93895000000000000",
  "San Carlos": "114032000000000000",
  "San Ramon": "146868000000000000" ,
};

export const locationsComparisons = {
  "All Stores": "All",
  "San Mateo": "93895000000000000",
  "San Carlos": "114032000000000000",
  "San Ramon": "146868000000000000" ,
};