import  React from "react";
import { Box, Flex, Text, } from "noshery-ui"; 
import { Card,  CircularProgress} from "@mui/material";
import CustomerTable from "../../components/CustomerMetricsTable"; 
import { useBbotCustomer } from "../../hooks/useCustomers";
import styled from "styled-components";
import { ResponsiveBar } from "@nivo/bar";
import { ResponsivePie } from "@nivo/pie";
import RetentionTable from "../../components/RetentionTable";
import { useLocationCharts } from "../../hooks/useLocation";
import useReadDb from "../../hooks/useReadDb";
import GeneralTable from "../../components/GeneralTable";
import BrandMixCount from "../../components/BrandMixCount";

const StyledSelect = styled.select`
  border-radius: 10px;
  box-shadow: 0 0 3px 0 rgb(0, 0, 0, 0.2);
  border: none;
  background-color: white;
  font-size: ${(props) => (props.fontSize ? props.fontSize : "20px")}
  ${(props) =>
    props.position
      ? "position: absolute; right: 8px; top: 8px; z-index: 1;"
      : ""}
  padding: 12px;
  font-weight: 600;
  &:hover,
  &:focus {
    cursor: pointer;
  }
  :focus-visible {
    outline: solid orange 2px;
  }
`;


const CustomerMetricsView = () => {   
  const {
    customers, 
    averages, 
    countCategories, 
    countSalesCategories, 
    totals, 
    isLoading, 
    retention,
    firstBrandSplit,
    brandMix,
    storeId, 
    setStoreId
  } = useBbotCustomer(); 

  const {locations: locationsOption} = useLocationCharts();
  const [lastDateUpdated, setLastDateUpdated] = React.useState("");
  const {readDocument, isLoading: readDocLoading} = useReadDb();

  React.useEffect(() => {
    if (Object.values(locationsOption).length > 0) {
      setStoreId(Object.keys(locationsOption)[0]);
    }
  }, [locationsOption]);

  React.useEffect(() => { 
    (async () => {
      const result = await readDocument("dailySalesCronTimes", "customerMetriccs"); 
      setLastDateUpdated(result.data.date);
    })();
  }, []);

  return (
    <Box>
      <Flex pt={"100px"} pb={4} px="100px" pr="100px" flexDirection="column" bg="#f2f2f2">
        <Text fontSize={6} fontWeight="600" mb="1%"> Customer Metrics </Text>
        <Flex alignItems="center" weight={"100%"} height={"80%"} mb={2} mr={2}>
          <StyledSelect
            value={storeId}
            onChange={(e) => setStoreId(e.target.value)}
          >
            {Object.keys(locationsOption).map((d) => (
              <option value={d} key={d}>
                {d}
              </option>
            ))}
          </StyledSelect>
        </Flex>
        {
          isLoading ?  <Card sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
          }}> <Flex style={{width: "85vw", height: "70vh", justifyContent: "center", alignItems: "center", alignContent: "center"}}> <CircularProgress />  </Flex> </Card> : 
            <>
              <Flex style={{justifyContent: "center", alignItems: "center", alignContent: "center", flexDirection: "row", flexWrap: "wrap"}}>
                {
                  Object.keys(totals).map((key) => (
                    <Card key={key} sx={{
                      margin: "1%",
                      padding: "1%",
                      width: "25%",
                      height: "10%",
                      display: "flex",
                      flexDirection: "column",
                      borderRadius: "5px"
                    }}> 
                      <Text fontSize={4} fontWeight="600"> {key.includes("Total") ? key : "Total " + key }: {key.includes("Spend") || key.includes("Discount") ? "$" : ""}{Math.abs(totals[key]).toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 2,
                      })} </Text>
                    </Card>
                  ))
                }
                {
                  Object.keys(averages).map((key) => (
                    <Card key={key} sx={{
                      margin: "1%",
                      padding: "1%",
                      width: "25%",
                      height: "10%",
                      display: "flex",
                      flexDirection: "column",
                      borderRadius: "5px"
                    }}> 
                      <Text fontSize={4} fontWeight="600"> Avg {key}: {key.includes("Spend") || key.includes("Discount") || key.includes("AOV") ? "$" : ""}{Math.abs(averages[key]).toFixed(2)} </Text>
                    </Card>
                  ))
                }
              </Flex>
              
              <Text fontSize={2} fontWeight="600" m="1%"> Last Updated: {lastDateUpdated} </Text>

              <Flex style={{justifyContent: "center", height: "470px", alignItems: "center", alignContent: "center", flexDirection: "column", backgroundColor: "white"}}>
                <Text style={{height: "50px", marginTop: "1%", fontWeight: "bold", fontSize: "24px", alignSelf: "start", marginLeft: "1%"}} > Customers By Order Count </Text>
                <Flex style={{justifyContent: "center", height: "420px", width: "90%", alignItems: "center", alignContent: "center", flexDirection: "row",}}>
                  {/* <Bar data={[countCategories]} label={"Count"} />
                  <Bar data={[countSalesCategories]} label={"Sales"} /> */}

                  <Pie data={Object.keys(countCategories).map((item) => {
                    return {id: item, label: item, value: countCategories[item]/totals["Total Unique Customers"]};
                  })} label={"Count"} />
                  <Pie data={Object.keys(countSalesCategories).map((item) => {
                    return {id: item, label: item, value: countSalesCategories[item]/totals["Life Time Spend"]};
                  })} label={"Sales"} />

                  <Pie data={Object.keys(firstBrandSplit).map((item) => {
                    return {id: item.includes("single") ? "Single Brand" : "Multiple Brand", label: item.includes("single") ? "Single Brand" : "Multiple Brand", value: firstBrandSplit[item]/totals["Total Unique Customers"]};
                  })} label={"First Order Brand Mix"} />
                </Flex>
              </Flex>
              <Card sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                p: "1%"
              }}>
                <Text style={{height: "50px", marginTop: "1%", fontWeight: "bold", fontSize: "24px"}} > Customer Retention </Text>
                <RetentionTable data={retention} />
              </Card>
              <Card sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                p: "1%"
              }}>
                <Text style={{height: "50px", marginTop: "1%", fontWeight: "bold", fontSize: "24px"}} > Customers Metrics </Text>
                <CustomerTable data={customers} />
              </Card>
              <Card sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                p: "1%"
              }}>
                <Text style={{height: "50px", marginTop: "1%", fontWeight: "bold", fontSize: "24px"}} > Order Brand Mix Count </Text>
                <BrandMixCount data={brandMix} />
              </Card>
            </>
        }
      </Flex>
    </Box>
  );
};

const Pie = ({ data, label /* see data tab */ }) => (
  <div style={{display: "flex", flexDirection: "column", width: "100%", height: "100%"}}>
    <Text style={{height: "50px", marginTop: "1%", fontWeight: "bold", fontSize: "24px", marginLeft: "1%"}} > {label} </Text>
    <ResponsivePie
      data={data}
      innerRadius={.5}
      padAngle={0.7}
      cornerRadius={3}
      margin={{ top: 30, right: 0, bottom: 120, left: 0 }}
      enableArcLinkLabels={true}
      activeOuterRadiusOffset={8}
      borderWidth={1}
      borderColor={{
        from: "color",
        modifiers: [["darker", 0.2]],
      }}
      valueFormat=" >-.2%"
      arcLinkLabelsSkipAngle={10}
      arcLinkLabelsTextColor="#333333"
      arcLinkLabelsThickness={2}
      arcLinkLabelsColor={{ from: "color" }}
      arcLabelsSkipAngle={10}
      arcLabelsTextColor={{
        from: "color",
        modifiers: [["darker", 2]],
      }}
      defs={[
        {
          id: "dots",
          type: "patternDots",
          background: "inherit",
          color: "rgba(255, 255, 255, 0.3)",
          size: 4,
          padding: 1,
          stagger: true,
        },
        {
          id: "lines",
          type: "patternLines",
          background: "inherit",
          color: "rgba(255, 255, 255, 0.3)",
          rotation: -45,
          lineWidth: 6,
          spacing: 10,
        },
      ]}
      legends={[
        {
          anchor: "bottom",
          direction: "row",
          justify: false,
          translateX: 0,
          translateY: 56,
          itemsSpacing: 0,
          itemWidth: 100,
          itemHeight: 18,
          itemTextColor: "#999",
          itemDirection: "left-to-right",
          itemOpacity: 1,
          symbolSize: 18,
          symbolShape: "circle",
          effects: [
            {
              on: "hover",
              style: {
                itemTextColor: "#000"
              }
            }
          ]
        }
      ]}
    />
  </div>
); 
const Bar = ({ data, label /* see data tab */ }) => (
  <ResponsiveBar
    data={data}
    keys={[
      "1",
      "2",
      "3",
      "4+",
    ]} 
    indexBy={() => "Customer " + label }
    valueFormat=" >-,.1f"
    margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
    padding={0.3}
    groupMode="grouped"
    valueScale={{ type: "linear" }}
    indexScale={{ type: "band", round: true }}
    colors={{ scheme: "nivo" }} 
    defs={[
      {
        id: "dots",
        type: "patternDots",
        background: "inherit",
        color: "#38bcb2",
        size: 4,
        padding: 1,
        stagger: true
      },
      {
        id: "lines",
        type: "patternLines",
        background: "inherit",
        color: "#eed312",
        rotation: -45,
        lineWidth: 6,
        spacing: 10
      }
    ]}
    borderColor={{
      from: "color",
      modifiers: [
        [
          "darker",
          1.6
        ]
      ]
    }}
    axisTop={null}
    axisRight={null}
    axisBottom={{
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0, 
      legendPosition: "middle",
      legendOffset: 32
    }}
    axisLeft={{
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: label === "Sales" ? "total sales" : "order count",
      legendPosition: "middle",
      legendOffset: -50
    }}
    labelSkipWidth={12}
    labelSkipHeight={12}
    labelTextColor={{
      from: "color",
      modifiers: [
        [
          "darker",
          1.6
        ]
      ]
    }}
    legends={[
      {
        dataFrom: "keys",
        anchor: "bottom-right",
        direction: "column",
        justify: false,
        translateX: 120,
        translateY: 0,
        itemsSpacing: 2,
        itemWidth: 100,
        itemHeight: 20,
        itemDirection: "left-to-right",
        itemOpacity: 0.85,
        symbolSize: 20,
        effects: [
          {
            on: "hover",
            style: {
              itemOpacity: 1
            }
          }
        ]
      }
    ]}
    role="application"
    ariaLabel="Count" 
  />
);

export default CustomerMetricsView;
