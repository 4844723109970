import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { TextField, Collapse, Box } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { CheckCircle } from "@mui/icons-material";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));


export default function MediaCard({name, image, addItem, menuItem, price}) {
  const [expanded, setExpanded] = React.useState(false);
  const [quantity, setQuantity] = React.useState(1);
  const [modifiers, setModifiers] = React.useState([]);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const hoverStyle = {
    "&:hover": {
      backgroundColor: "#d8870f",
    },
  };
  const handleClick = () => { 
    const modifiersPrice = modifiers.reduce((acc, mod) => acc += mod.price, 0);
    const toastItem = {
      "brandName": menuItem.brandName,
      "displayName": menuItem.displayName,
      "itemGroupId": menuItem.itemGroupId,
      "itemId": menuItem.itemId,
      "price": (menuItem.price + modifiersPrice),
      "quantity": quantity,
      "modifiers": modifiers,
      "restaurantName": "Noshery",
      "subtotal": (menuItem.price + modifiersPrice) * quantity,
      "tax": 1.58,
    };
    addItem(toastItem);
    setModifiers([]);
    setQuantity(1);
  };

  const handleAddModifier = (modifier) => {
    setModifiers([...modifiers, modifier]);
  };

  return (
    <Card sx={{ maxWidth: 345, m: "5%", }}>
      <CardMedia
        sx={{ height: 140 }}
        image={image}
        src={image}
        title="green iguana"
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div" sx={{color: "black", fontSize: "2vh"}}>
          {name} 
        </Typography>
        <Typography gutterBottom variant="h12" component="div" sx={{color: "black"}}>
          ${price} 
        </Typography>
      </CardContent>
      <CardActions sx={{alignItems: "center", alignContent: "center", justifyContent: "center"}}>
        <TextField id="filled-basic" value={quantity} onChange={(e) => setQuantity(parseInt(e.target.value))} label="Quantity" variant="filled" />
        <Button onClick={handleClick} sx={{ color: "white", fontWeight: "bold", ml: "2%", background: "#F1A637", ...hoverStyle,}} size="small">Add</Button>
      </CardActions>
      <CardActions disableSpacing>
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} timeout="auto">
        <CardContent>
          {
            menuItem.modifiers.map((modifier) => {
              return (
                <div style={{display: "flex", flexDirection: "row", marginTop: "1%", marginBottom: "1%", justifyContent: "space-between", width: "100%",  alignContent: "center", alignItems: "center" }} key={modifier.itemGuid}>
                  <Box sx={{display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%", alignContent: "center", alignItems: "center" }}> 
                    <Typography paragraph>{modifier.displayName}</Typography>
                    <Typography sx={{fontWeight: "bold"}} paragraph>${modifier.price}</Typography>
                  </Box>
                  <Box sx={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignContent: "center", alignItems: "center" }}>
                    {
                      modifiers.find((mod) => mod.itemGuid === modifier.itemGuid) ?
                        <CheckCircle /> :
                        <Button onClick={() => handleAddModifier(modifier)} sx={{ color: "white", fontWeight: "bold", ml: "2%", background: "#F1A637", ...hoverStyle,}} size="small">Add</Button> 
                    }
                  </Box>
                </div>  
              );
            })
          }
        </CardContent>
      </Collapse>
    </Card>
  );
}