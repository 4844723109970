import React from "react";
import { Route, Navigate, Routes } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { Box } from "noshery-ui";
import {adminDesktopPages, adminMobilePages} from "./adminPages";
import {brandDesktopPages, brandMobilePages} from "./brandPages";
import {accountingDesktopPages, accountingMobilePages} from "./accountingPages";
import useDeviceType from "./hooks/useDeviceType";
import MobileNavbar from "./components/mobile/MobileNavbar";
import Sidebar from "./components/Sidebar";

const App = ({ userClaims }) => {
  const { admin, accounting, brand } = userClaims;
  const {isDesktop} = useDeviceType();
  const adminPages = isDesktop ? adminDesktopPages : adminMobilePages;
  const brandPages = isDesktop ? brandDesktopPages : brandMobilePages;
  const accountingPages = isDesktop ? accountingDesktopPages : accountingMobilePages;
  return (
    <Box>
      {
        isDesktop ? 
          <Sidebar pages={admin ? adminPages : (accounting ? accountingPages : brandPages)} /> : 
          <MobileNavbar pages={admin ? adminPages : (accounting ? accountingPages : brandPages)} />   
      }
      <Routes>
        {admin
          ? adminPages.map(({ path, component }) => (
            <Route key={path} path={path} exact element={component} />
          ))
          : (accounting ? accountingPages.map(({ path, component }) => (
            <Route key={path} path={path} exact element={component} />
          )) :
            brandPages.map(({ path, component }) => (
              <Route key={path} path={path} exact element={component(brand)} />
            )))}
        <Route path="*" element={<Navigate replace to="/charts" />} />
      </Routes>
    </Box>
  );
};

export default App;
