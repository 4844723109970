/* eslint-disable no-undef */
import React, {useEffect, useState} from "react";
import { Flex, Box, Text } from "noshery-ui";
import readXlsxFile from "read-excel-file"; 
import { forkableMenu, draperMenu, ezcater2 } from "../../utils/catering";
import {InputLabel, MenuItem, FormControl, Grid, Select, Typography, Snackbar, CircularProgress, TextField, Modal, Button} from "@mui/material";
import * as MUI from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
import AlertTitle from "@mui/material/AlertTitle";
import { useUploadCateringOrder } from "../../hooks/useUploadCatering";
import { useReadRelish } from "../../hooks/useReadRelish";
import moment from "moment-timezone";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useReadEzcater } from "../../hooks/useEzcater";
import { useMenu } from "../../hooks/useMenu";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import MenuCard from "../../components/MenuCard";
import PageLoader from "../../components/PageLoader";
import { useLabels } from "../../hooks/useLabels";
import { useKitchenList } from "../../hooks/useKitchenList"; 
import { useLocationCharts } from "../../hooks/useLocation";

const channelOptions = {
  "Forkable": "Forkable",
  "Relish": "Relish",
  "ezcater": "ezcater",
  "Sharebite": "Sharebite",
  "Fooda": "Fooda",
  "Hungry": "Hungry",
  "Cater2me": "Cater2me",
  "Noshery Catering": "Noshery Catering",
  "Doordash Catering": "Doordash Catering",
};

const findDessert = (option, CateringMenu) => {
  let desserts = []; 
  if(option.includes("Chocolate Chunk")) desserts.push(CateringMenu["Chocolate Chunk"]);
  if(option.includes("White Chocolate & Cranberry")) {
    desserts.push(CateringMenu["White Chocolate & Cranberry"]);
  }
  if(option.includes("Oatmeal Raisin")) desserts.push(CateringMenu["Oatmeal Raisin"]);
  if(option.includes("Snickerdoodle")) desserts.push(CateringMenu["Snickerdoodle"]);
  return desserts; 
};

const findSide = (option, CateringMenu) => {
  let sides = [];

  if(option.includes("French Fries")) {
    sides.push(CateringMenu["French Fries"]);
    return sides;
  }
  
  if(option.includes("Fries, Cheddar Bacon Fries")) {
    sides.push(CateringMenu["Fries"]);
    sides.push(CateringMenu["Cheddar Bacon Fries"]);
  }
  if(option.includes("Fries, Garlic Parmesan Fries")) {
    sides.push(CateringMenu["Fries"]);
    sides.push(CateringMenu["Garlic Parmesan Fries"]);
  }
  else if(
    (option.includes("Fries") && (!option.includes("Cheddar Bacon Fries") && !option.includes("Garlic Parmesan Fries"))) 
    ||
    (option.includes("Fries,") && (!option.includes("Cheddar Bacon Fries") && !option.includes("Garlic Parmesan Fries")))) sides.push(CateringMenu["Fries"]);
  else if(option.includes("Cheddar Bacon Fries")) sides.push(CateringMenu["Cheddar Bacon Fries"]);
  else if(option.includes("Garlic Parmesan Fries")) sides.push(CateringMenu["Garlic Parmesan Fries"]);

  return sides;
};

const findMeal = (list, CateringMenu, isEzcater) => {
  let results = []; 
  list.forEach((line) => {
    if(!line) return; 
    line = line.trim(); 
    const menu = JSON.parse(JSON.stringify(CateringMenu)); 
    Object.keys(menu).forEach((itemType) => {
      itemType = itemType.trim(); 
      if(line.includes(itemType)) {
        if(line.includes("Add Dessert") && itemType === "Dessert") {
          let desserts = findDessert(line, menu[itemType]);
          desserts.forEach((i) => results.push(i));
          return; 
        }
        if(line.includes("Add Side") && itemType === "Fries") {
          let sides = findSide(line, menu[itemType]);
          sides.forEach((i) => results.push(i));
          return; 
        }
        if(line.includes("Add-Ons") && itemType === "Soup") {
          let item = menu[itemType]["Tomato Soup"];
          results.push(item);
          return;
        }
        if(line.includes("Add Side") && itemType === "Extra White Pita") {
          let item = menu[itemType]["Extra White Pita"];
          results.push(item);
          return;
        }
        if(line.includes("Add Side") && itemType === "Extra Gluten-Free Pita") {
          let item = menu[itemType]["Extra Gluten-Free Pita"];
          results.push(item);
          return;
        }
        else if(line.includes("Gluten Free Pita") && !isEzcater) {
          let item = results.pop(); 
          let modifiersArray = [];
          modifiersArray.push(menu[itemType]["Gluten Free Pita"]);
          item["modifiers"] = modifiersArray;
          item["price"] = item["price"] + menu[itemType]["Gluten Free Pita"]["price"];
          results.push(item);
          return; 
        }        
        else if(line.includes("White Pita") && !isEzcater) {
          let item = results.pop();
          let modifiersArray = [];
          modifiersArray.push(menu[itemType]["White Pita"]);
          item["modifiers"] = modifiersArray;
          results.push(item);
          return; 
        }
        if(menu[itemType][line]) {
          let item = menu[itemType][line];
          results.push(item);
        }
      }
    });
  });
  return results; 
};

const Charts = () => {
  const [location, setLocation] = useState("");
  const [channel, setChannel] = useState("");
  const [results, setResults] = useState([]);
  const [open, setOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [error, setError] = useState(false);
  const {menu, isLoading: MenuLoading, fetchMenu} = useMenu(); 
  const [CateringMenu, setCateringMenu] = useState({});
  const [createManualOrder, setCreateManualorder] = React.useState(false);
  const {uploadOrder, uploadLabelsList, isLoading, apiResults} = useUploadCateringOrder(); 
  const {isLoading: ezCaterLoad} = useReadEzcater();
  const [successPopup, setSucessPopup] = useState(false);
  const {readJson, isLoading: relishOrder} = useReadRelish(); 
  const [date, setDate] = useState(moment().hour(10).minute(0));
  const [discount, setDiscount] = useState(0); 
  const [expanded, setExpanded] = React.useState(false);
  const {generateLabels, isLoading: labelsLoading} = useLabels();
  const {genereateKitchenList, isLoading: kitchenListLoading} = useKitchenList(); 
  const [company, setCompany] = useState("");
  const [orderId, setOrderId] = useState("");

  const {locations: locationsOption} = useLocationCharts();

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleOpen = () => {
    setModalOpen(true);
    setCreateManualorder(true);
  };
  const handleClose = () => {
    setModalOpen(false);
    setCreateManualorder(false);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    height: "80vh",
    bgcolor: "white",
    border: "2px solid #000",
    boxShadow: 24,
    p: 2,
    overflowY: "scroll",
  };


  const onSubmit = async (file) => {
    if(channel === "Relish") {
      console.log("FILE", file);
      // eslint-disable-next-line no-undef
      const reader = new FileReader();
      reader.onloadend = async () => {
        let orderResults = await readJson(reader.result); 
        const {orderItems} = orderResults; 
        setResults(orderItems); 
      };
      reader.readAsDataURL(file);

      return; 
    }  
    else if (channel === "Noshery Catering") {
      let res = []; 
      await readXlsxFile(file).then((rows) => {
        const tmp = [...res, ...rows];
        res = tmp;
      });
      let order = [];
      res.forEach((line) => {
        let menuOrder = findMeal(line, forkableMenu);
        if(menuOrder.length !== 0) menuOrder.forEach((i) => order.push(i));
      });
      setResults(order); 
      return; 
    }
    let res = []; 
    await readXlsxFile(file).then((rows) => {
      const tmp = [...res, ...rows];
      res = tmp;
    });
    let order = [];
    let foundEndOfFile = false; 
    res.forEach((line) => {
      console.log(line); 
      if(foundEndOfFile) return;
      if(line.includes("Count\n(Totals from above)")) {
        foundEndOfFile = true;
        return; 
      }
      let menuOrder = findMeal(line, forkableMenu, channel === "ezcater");
      if(menuOrder.length !== 0) menuOrder.forEach((i) => order.push(i));
    });
    setResults(order); 
  };

  const saveCateringLabelsList = async () => {
    await uploadLabelsList(results, channel, location, discount, date.format("YYYY-MM-DDTHH:mm:00.000-0000"), company, orderId);
    return; 
  };

  const handleUpload = async () => {
    await uploadOrder(results, channel, location, discount, date.utc().format("YYYY-MM-DDTHH:mm:00.000-0000"));
    if(channel === "ezcater" || channel === "Sharebite" || channel === "Noshery Catering" || channel === "Doordash Catering" ) await saveCateringLabelsList();
  };

  const handleCancel = () => {
    setResults([]);
  };

  const addItem = (item) => {
    let tmp = [...results];
    tmp.push(item);
    setResults(tmp);
  };

  useEffect(() => {  
    if(apiResults.ok === true) {
      setSucessPopup(true);
      setResults([]);
      setChannel("");
      setLocation("");
      setDate(moment().hour(10).minute(0));
      setOpen(true);
      setError(false);
    } else if(apiResults.ok === false) {
      setError(true);
      setOpen(true);
    }
  }, [apiResults]);

  const hoverStyle = {
    "&:hover": {
      backgroundColor: "#d8870f",
    },
  };

  const linkRef = React.useRef();

  const onGenerateLabels = async () => {
    const data = await generateLabels(results, channel, location, discount, date.format("YYYY-MM-DDTHH:mm:00.000-0000"), company);
    const href = window.URL.createObjectURL(data);
    const a = linkRef.current;
    a.download = `${channel}-${date.format("MM-DD-YYYY")}-${company}-labels.docx`;
    a.href = href;
    a.click();
    a.href = "";

  };

  const onGenerateKitchenList = async () => {
    const data = await genereateKitchenList(results, channel, location, discount, date.format("YYYY-MM-DDTHH:mm:00.000-0000"), company, orderId);
    const href = window.URL.createObjectURL(data);
    const a = linkRef.current;
    a.download = `${channel}-${date.format("MM-DD-YYYY")}-${company}-kitchen-list.csv`;
    a.href = href;
    a.click();
    a.href = "";
  };
  
  return (
    <Box>
      <Flex pt={"100px"} pb={4} px="100px"  flexDirection="column" bg="white">
        <a ref={linkRef}/>
        <Flex justifyContent="center" flexDirection="column" bg="white" p={20} mb={4}>
          <Text fontWeight="600" fontSize={5} mb={4}>
            Catering Upload 
          </Text>
          <Flex flexDirection="column" justifyContent="center">
            <Flex justifyContent="center"> 
              <FormControl sx={{paddingBottom: "2%", width: "40%"}}>
                <InputLabel id="demo-simple-select-label">Location</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={location}
                  label="Location"
                  onChange={async (e) => {
                    setLocation(e.target.value);
                    let menuCateirng = await fetchMenu(e.target.value); 
                    setCateringMenu(menuCateirng);
                  }}
                >
                  {Object.keys(locationsOption).map((option) => {
                    return <MenuItem key={option} value={option}>{option}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            </Flex>
            <Flex justifyContent="center"> 
              <FormControl sx={{paddingBottom: "2%", width: "40%"}}>
                <InputLabel id="demo-simple-select-label">Channel</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={channel}
                  label="Location"
                  onChange={(e) => setChannel(e.target.value)}
                >
                  {Object.keys(channelOptions).map((option) => {
                    return <MenuItem key={option} value={option}>{option}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            </Flex>
            <Flex padding="2%" justifyContent="center"> 
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DateTimePicker
                  label="Order Date" 
                  value={moment.isMoment(date) ? date : moment(date)}
                  onChange={(e) => setDate(e)}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Flex>
            <Flex justifyContent="center">
              <Button sx={{marginBottom: "5%", marginTop: "2%", background: "#F1A637", ...hoverStyle, width: "30%"}} disabled={isLoading} variant="contained" component="label" onClick={handleOpen}>
               Add Item
              </Button> 

              { createManualOrder && <Button sx={{marginBottom: "5%", marginTop: "2%", background: "#F1A637", ...hoverStyle, width: "30%"}} disabled={isLoading} variant="contained" component="label" onClick={handleClose}>
               Cancel
              </Button>}
            </Flex>
            <Flex justifyContent="center"> 
              <Button disabled={location === "" || channel === ""} sx={{marginBottom: "5%", background: "#F1A637", ...hoverStyle, width: "30%"}} variant="contained" component="label">
                  Upload/Change File
                <input hidden  onChange={(e) => onSubmit(e.target.files[0])} multiple type="file" accept={".csv, .xlsx, application/pdf" }/>
              </Button>
            </Flex>
   
            {results.length !== 0 && <OrderTable rows={results} setResults={setResults} location={location} /> } 
            {results.length === 0 || error && <AlertErrors setOpen={setOpen} open={open} errorMessage={"Could not process file."} /> }
            {location === "" && error === true && <AlertErrors setOpen={setOpen} open={open} errorMessage={"Please Select A Location"} /> }
            {channel === "" && error === true && <AlertErrors setOpen={setOpen} open={open} errorMessage={"Please Select A Channel"} /> }
            {relishOrder || ezCaterLoad && <CircularProgress sx={{alignSelf: "center"}} /> }
            {results.length !== 0 &&
              <Flex justifyContent="center" flexDirection="column" paddingTop="5%">
                {channel === "Noshery Catering" && <Flex justifyContent="center">
                  <TextField id="outlined-basic" value={discount} onChange={e=> setDiscount(e.target.value)} label="Discount %" variant="outlined" />
                </Flex> }
                <Flex justifyContent="center">
                  <TextField id="outlined-basic" value={company} sx={{marginRight: "3%"}} onChange={e=> setCompany(e.target.value)} label="Company" variant="outlined" />
                  <TextField id="outlined-basic" value={orderId} onChange={e=> setOrderId(e.target.value)} label="Order Id" variant="outlined" />
                </Flex>
                <Flex justifyContent="center"> 
                  <Button sx={{marginTop: "5%", background: "#F1A637", ...hoverStyle, marginRight: "2%", width: "30%"}} disabled={isLoading} variant="contained" component="label" onClick={handleCancel}>
                    Cancel
                  </Button> 
                  <Button sx={{marginTop: "5%", background: "#F1A637", ...hoverStyle, marginRight: "2%", width: "30%"}} disabled={labelsLoading} variant="contained" component="label" onClick={onGenerateLabels}>
                    Labels
                  </Button> 
                  <Button sx={{marginTop: "5%", background: "#F1A637", ...hoverStyle, marginRight: "2%", width: "30%"}} disabled={kitchenListLoading} variant="contained" component="label" onClick={onGenerateKitchenList}>
                    Kitchen Summary
                  </Button> 

                  <Button sx={{marginTop: "5%", background: "#F1A637", ...hoverStyle, width: "30%"}} disabled={isLoading} variant="contained" component="label" onClick={handleUpload}>
                    {channel === "ezcater" || channel === "Sharebite" || channel === "Noshery Catering"  || channel === "Doordash Catering"  ? "Upload Order & Save Labels" : "Upload Order"}
                  </Button> 
                </Flex>
              </Flex>
            }
          </Flex>
          <Snackbar open={successPopup} autoHideDuration={5000} onClose={() => setSucessPopup(false)}>
            <Alert onClose={() => setSucessPopup(false)} severity="success" sx={{ width: "100%" }}>
              Order Successfully Uploaded!
            </Alert>
          </Snackbar>
        </Flex>
      </Flex>
 
      <Box>
        <Modal
          open={modalOpen}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{
            overflowY: "scroll",
          }}
        >
          <MUI.Box sx={style}>
            {
              MenuLoading ? <PageLoader /> : CateringMenu && Object.keys(CateringMenu).length > 0 && Object.keys(CateringMenu).map((key, index) => {
                if(!CateringMenu[key] || !Array.isArray(CateringMenu[key])) return null;
                if(CateringMenu[key] === "ok") return null;
                return (
                  <>
                    <Accordion expanded={expanded === `panel${index}`} TransitionProps={{ unmountOnExit: true }} onChange={handleChange(`panel${index}`)}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography>{key}</Typography>
                        
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container spacing={1}>
                          {
                            CateringMenu[key].map((item, index) => {
                              return <Grid item key={item.itemId} xs={4}>
                                <MenuCard name={item.displayName} menuItem={item} addItem={addItem} price={item.price} image={item.image} />
                              </Grid>;
                            })
                          }
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  </>
                );
              })
            }
          </MUI.Box>
        </Modal>
      </Box>
    </Box>
  );
};

const OrderTable = ({rows, location, setResults}) => {
  const [editing, setEditing] = useState(false);
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Quantity</TableCell>
            <TableCell>Name</TableCell>
            <TableCell align="right">Brand Name</TableCell>
            <TableCell align="right">Modifiers</TableCell>
            <TableCell align="right">Tax</TableCell>
            <TableCell align="right">Price</TableCell>
            <TableCell align="right">Subtotal</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((curRow, i) => {
            let row = curRow;
            return <TableRow
              key={row.displayName + i}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell>{row.quantity}</TableCell>
              <TableCell component="th" scope="row">
                {row.displayName}
              </TableCell>
              <TableCell align="right">{row.brandName}</TableCell>
              <TableCell align="right">{row.modifiers ? row.modifiers.map((i, index) => index === row.modifiers.length -1 ? i.displayName + " " : i.displayName + " ,") : ""}</TableCell>
              <TableCell align="right">{row.price * (location === "San Mateo" ? 0.09625 : 0.09375)}</TableCell>  
              <TableCell align="right" onDoubleClick={() => setEditing(true)}>
                {  
                  editing ?    
                    <TextField value={row.price}
                      type="number"
                      onChange={(e) => {
                        let tempRows = [...rows];
                        tempRows[i].price = parseFloat(e.target.value);
                        setResults(tempRows);
                      }} />
                    :
                    (row.price).toFixed(2)
                }
              </TableCell>
              <TableCell align="right">{(row.price * row.quantity).toFixed(2)}</TableCell>
            </TableRow>;
          })}
        </TableBody>
      </Table>
      <Typography sx={{paddingTop: "5%", paddingBottom: "5%"}} variant="body2" fontSize={"large"} align="center">
          Total Items: {rows.length} <br /> 
          Total Tax: ${rows.reduce((acc, cur) => acc + cur.price * (location === "San Mateo" ? 0.09625 : 0.09375), 0).toFixed(2)} <br />
          Order Total: ${rows.reduce((acc, cur) => acc += cur.price * cur.quantity,0).toFixed(2)}
      </Typography>
    </TableContainer>
  );
};

const AlertErrors = ({open, setOpen, errorMessage}) => {
  return (
    <Box style={{ width: "100%" }}>
      <Collapse in={open}>
        <Alert
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpen(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          <AlertTitle>Error</AlertTitle>
          {errorMessage}
        </Alert>
      </Collapse>
    </Box>
  );
};


export default Charts;
