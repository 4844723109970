import * as React from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";

export default function BrandMixCount({data}) {
  const columns = [
    {
      field: "brand",
      headerName: "Brand",
      width: 800,
      editable: false,
    },
    {
      field: "count",
      headerName: "Count",
      width: 200,
      editable: false,
    }
  ]; 

  const rows = Object.keys(data).map((k) => {
    return {
      id: k,
      brand: k,
      count: data[k], 
    };
  }).sort((a, b) => b.count - a.count);

  return (
    <Box sx={{ height: 800, width: "100%" }}>
      <DataGrid 
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 20,
            },
          },
        }}
        pageSizeOptions={[20]}  
      />
    </Box>
  );
}