/* eslint-disable no-undef */
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";

import React from "react";
import ReactDOM from "react-dom";
import { ThemeProvider } from "styled-components";
import { BrowserRouter } from "react-router-dom";
import { theme } from "noshery-ui";
import "normalize.css";
import Cookies from "js-cookie";

import "./datepicker.css";
import App from "./App";
import Login from "./Login";
import firebaseConfig from "./firebaseConfig";
import reportWebVitals from "./reportWebVitals";

firebase.initializeApp(firebaseConfig);

firebase.auth().onAuthStateChanged(async user => {
  let userClaimns;
  if (user) {
    const idTokenResult = await user.getIdTokenResult(true);
    userClaimns = idTokenResult.claims;
    Cookies.set("accessToken", idTokenResult.token);
  }

  const root = user ? <App userClaims={userClaimns} /> : <Login />;
  ReactDOM.render(
    <ThemeProvider theme={theme}>
      <BrowserRouter>{root}</BrowserRouter>
    </ThemeProvider>,
    document.getElementById("root")
  );
});
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
