import moment from "moment";

export const sortData = (data, sortType) => {
  if (!data) return;
  data.sort(sortOrder(sortType));
  return data;
};

export const sortDates = (data, sortType) => {
  if (!data) return;
  data.sort(sortOrderDates(sortType));
  return data;
};

const sortOrderDates = sortType => {
  return function (a, b) {
    if (moment(a[sortType]).isAfter(b[sortType])) {
      return 1;
    } else if (moment(b[sortType]).isAfter(a[sortType])) {
      return -1;
    }
    return 0;
  };
};

const sortOrder = sortType => {
  return function (a, b) {
    if (a[sortType] < b[sortType]) {
      return 1;
    } else if (a[sortType] > b[sortType]) {
      return -1;
    }
    return 0;
  };
};
