import React, { useEffect } from "react";
import { Button, Flex, Text } from "noshery-ui";
import ResponsiveLineChart from "../ResponsiveLineChart";
import { CSVLink } from "react-csv";
import moment from "moment";
import { Slider, Typography } from "@mui/material";
import * as MUI from "@mui/material";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";

const transformDataForCSV = (laborCost, netSales) => {
  let csvData = {}; 
  const laborCSV = laborCost.map((item) => {
    return {
      "Labor Cost": item.y,
      "Date": item.x,
    };
  });

  const salesCSV = netSales.map((item) => {
    return {
      "Net Sales": item.y,
      "Date": item.x,
    };
  });

  laborCSV.forEach((item, ) => {
    csvData[item.Date] = {
      "Labor Cost": item["Labor Cost"],
      "Net Sales": 0
    };
  }); 

  salesCSV.forEach((item, ) => {
    if (csvData[item.Date]) {
      csvData[item.Date]["Net Sales"] += item["Net Sales"];
    } else {
      csvData[item.Date] = {
        "Net Sales": item["Net Sales"],
      };
    }
  });

  let arrayCsvData = Object.keys(csvData).map((key) => {
    return {
      "Date": key,
      "Labor Cost": csvData[key]["Labor Cost"],
      "Net Sales": csvData[key]["Net Sales"],
    };
  }); 

  return { laborCSV, salesCSV, arrayCsvData };
}; 

const getHour = (hour) => {
  const toFix = (x) => {
    if (Math.abs(x) < 1.0) {
      let e = parseInt(x.toString().split("e-")[1]);
      if (e) {
        x *= Math.pow(10,e-1);
        x = "0." + (new Array(e)).join("0") + x.toString().substring(2);
      }
    } else {
      let e = parseInt(x.toString().split("+")[1]);
      if (e > 20) {
        e -= 20;
        x /= Math.pow(10,e);
        x += (new Array(e+1)).join("0");
      }
    }
    return x;
  };
  const truncate = (x, n) => {
    x = toFix(x); 

    // From here on the code is the same than the original answer
    const v = (typeof x === "string" ? x : x.toString()).split(".");
    if (n <= 0) return v[0];
    let f = v[1] || "";
    if (f.length > n) return `${v[0]}.${f.substr(0,n)}`;
    while (f.length < n) f += "0";
    return `${v[0]}.${f}`;
  };
  return truncate(hour, 0);
};

const updateData = (laborData, employeeData) => {
  const addedEmployeeLabor = {}; 
  let newLaborData = JSON.parse(JSON.stringify(laborData));
  Object.keys(employeeData).forEach((employee) => {
    for(let i = employeeData[employee].hours[0] + .1; i < employeeData[employee].hours[1]; i = i + .1) {
      if(addedEmployeeLabor[getHour(i)]) {
        addedEmployeeLabor[getHour(i)] += employeeData[employee].hourly * .1 * employeeData[employee].amount;
        continue;
      }
      addedEmployeeLabor[getHour(i)] = employeeData[employee].hourly * .1 * employeeData[employee].amount;
    }
  }); 
  newLaborData.forEach((item) => {
    if(addedEmployeeLabor[item.x]) {
      item.y += addedEmployeeLabor[item.x];
    }
  });
  return newLaborData;
};

const ItemChartContainer = ({ data }) => {
  const [range, setChosenRange] = React.useState("hourly");
  const laborCost = data[`${range ?? "hourly"}LaborCost`];
  const { total } = data;
  const [employees, setEmployees] = React.useState([]);
  const [editing, setEditing] = React.useState(false);
  const [editLaborCost, setEditLaborCost] = React.useState([]);

  const marks = laborCost.map((item) => {
    if(item.x === 1) return;
    return {
      "label": item.x >= 12 ? `${item.x !== 12 ? item.x - 12 :item.x }PM` : `${item.x}AM`,
      "value": item.x,
    };
  });

  const editingData = [
    {
      id: "Labor Cost",
      color: "hsl(177, 70%, 50%)",
      data: editLaborCost,
    },
    {
      id: "Net Sales",
      color: "hsl(338, 70%, 50%)",
      data: total.hourly.netSales,
    },
  ];

  const _data = [
    {
      id: "Labor Cost",
      color: "hsl(177, 70%, 50%)",
      data: laborCost,
    },
    {
      id: "Net Sales",
      color: "hsl(338, 70%, 50%)",
      data: total[range].netSales,
    },
  ];

  const playgroundLaborCost = editLaborCost.reduce((acc, item) => {
    acc += item.y;
    return acc;
  }, 0);

  const {arrayCsvData} = transformDataForCSV(laborCost, total.hourly.netSales);

  const addEmployee = (employee) => {
    if(!editing) setEditing(true);
    if(employee === "Greeter") setEmployees({
      ...employees, 
      [Object.keys(employees).length]: { 
        hourly: 18, 
        id: Object.keys(employees).length ,
        hours: [1,1],
        title: "Greeter",
        amount: 1
      }
    });
    else if(employee === "Cook") setEmployees({
      ...employees, 
      [Object.keys(employees).length]: { 
        hourly: 23, 
        id: Object.keys(employees).length ,
        hours: [1,1],
        title: "Cook",
        amount: 1
      }
    });
  }; 

  const reset = () => {
    setEditing(false); 
    setEditLaborCost([]);
    setEmployees([]);
  };

  const employeeHoursChange = (id, range) => {
    setEmployees({
      ...employees,
      [id]: {
        ...employees[id],
        hours: range
      }
    });
  };  

  const deleteEmployee = (id) => {
    const newEmployees = {...employees};
    delete newEmployees[id];
    setEmployees(newEmployees);
  };

  const setEmployeeAmount = (id, amount) => {
    setEmployees({
      ...employees,
      [id]: {
        ...employees[id],
        amount: amount
      }
    });
  };

  const setEmployeeWage = (id, wage) => {
    setEmployees({
      ...employees,
      [id]: {
        ...employees[id],
        hourly: wage
      }
    });
  };

  useEffect(() => {
    if(editing === true) {
      const newEditLaborCost = updateData(laborCost, employees);
      setEditLaborCost(newEditLaborCost);
    }
    else {
      setEditing(false);
      setEditLaborCost([]);
    }
  }, [editing, employees]);

  return (
    <Flex width="100%" flexDirection="column"> 
      
      <Flex width="100%" flexDirection="column" height="450px" bg="white" >
        <Flex flexDirection="row" justifyContent="space-between"> 
          <FormControl 
            sx={{margin: "1%"}}>
            <InputLabel> 
              Range
            </InputLabel>
            <Select
              value={range}
              onChange={(e) => setChosenRange(e.target.value)}
              label="Range"
            >
              {
                ["Hourly", "Daily", "Weekly", "Monthly"].map((option) => (
                  <MenuItem key={option} value={option.toLowerCase()}> 
                    {option}
                  </MenuItem>
                ))
              }
            </Select>
          </FormControl>
          <CSVLink
            style={{margin: "1%", }}
            data={arrayCsvData}
            filename={`sales-Vs-labor-${moment().format("YYYY-MM-DD")}.csv`}
          >
            <Button>Download Sales vs Labor Cost</Button>
          </CSVLink>
        </Flex>
        <ResponsiveLineChart
          data={editing ? editingData : _data}
          legend="Net Sales vs Labor Cost"
          tickSize={range === "hourly" ? 5 : 3}
          tickRotation={0}
        />
      </Flex>
      <Flex w="100%" bg="white" style={{height: "1000px", paddingTop: "5%"}} flexDirection="column">
        <Typography sx={{
          fontSize: "24px", 
          fontWeight: "bold",
          padding: "1%",
        }}> Labor Playground</Typography>
        <Flex w="100%"> 
          <Button style={{margin: "1%"}} onClick={() => addEmployee("Greeter")}> Add Greeter </Button>
          <Button style={{margin: "1%"}} onClick={() => addEmployee("Cook")}> Add Line Cook </Button>
          <Button style={{alignSelf: "flex-end", margin: "1%"}} onClick={() => reset()}> Reset </Button>
        </Flex>
        {editing && <Flex> 
          <Text
            textAlign="center"
            p={2}
            fontWeight={600}
            fontSize={4}
            style={{textDecoration: "underline"}}
          >
            Net sales: ${" "}
            {
              total &&
              total.netSales &&
              total.netSales.toLocaleString(undefined, {
                minimumFractionDigits: 2,
              })}
          </Text>
          <Text
            textAlign="center"
            p={2}
            fontWeight={600}
            fontSize={4}
            style={{textDecoration: "underline"}}
          >
            Playground Labor Cost:{" "}
            {(playgroundLaborCost || 0).toFixed(2)}
          </Text>
          <Text
            textAlign="center"
            p={2}
            fontWeight={600}
            fontSize={4}
            style={{textDecoration: "underline"}}
          >
           Playground Labor Cost %:{" "}
            {(
              ((playgroundLaborCost || 0)  /
                 (
                   total &&
                  total.netSales
                 )) *
               100
            ).toFixed(2)}
          </Text>
        </Flex>}
        {
          Object.values(employees).map((employee, index) => {
            return <Flex style={{width: "90%", marginLeft: "1%", padding: "1%", border: "1px solid #d1d1d1"}} key={index}> 
              <Typography sx={{marginRight: "1%"}} > {employee.title} #{employee.id+1}  </Typography>
              <Typography sx={{}} > Hourly Wage: <MUI.TextField type="number" title="#employee" value={employee.hourly} onChange={(e) => setEmployeeWage(employee.id, parseInt(e.target.value))} /> </Typography>
              <Slider
                style={{width: "100%", marginLeft: "5%", marginRight: "5%",}}
                min={7}
                value={employee.hours}
                onChange={(e, newvalue) => employeeHoursChange(employee.id, newvalue)}
                valueLabelDisplay="auto"
                defaultValue={[7, 7]}
                max={24}
                sx={{color: "#344734"}}
                step={.1}
                marks={marks}
              />
              <Flex mr="1%"> 
                <Typography sx={{marginRight: "1%"}}> # employees </Typography>
                <MUI.TextField type="number" title="#employee" value={employee.amount} onChange={(e) => setEmployeeAmount(employee.id, parseInt(e.target.value))} />
              </Flex>
              <Flex> 
                <MUI.Button onClick={() => deleteEmployee(employee.id)} sx={{color: "#F1A637",  backgroundColor: "#344734", "&:hover": {
                  backgroundColor: "#ff3838",
                }}}> DELETE </MUI.Button>
              </Flex>
            </Flex>;
          })
        }

      </Flex>
    </Flex>
  );
};

export default ItemChartContainer;
