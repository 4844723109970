import React from "react";
import { Box, Typography, Card, Checkbox, MenuItem, Autocomplete, TextField } from "@mui/material"; 
import useItemToPlating from "../../../hooks/useItemToPlating";
import Loading from "../../../components/Loading";
import { LoadingButton } from "@mui/lab";

const ItemToPlating = () => { 
  const {loading, itemToPlating, modifierToPlating, plating, setItemToPlating, setModifierToPlating, updateMappingLoading, updateItemPlating} = useItemToPlating();
  const [hasChanges, setHasChanges] = React.useState(false);

  const [selectAllItems, setSelectAllItems] = React.useState(false);
  const [selectAllItemsWithoutPlating, setSelectAllItemsWithoutPlating] = React.useState(false);

  const [selectAllModifiers, setSelectAllModifiers] = React.useState(false);
  const [selectAllModifiersWithoutPlating, setSelectAllWithouModifiersWithoutPlating] = React.useState(false);

  return  (
    <Box sx={{
      display: "flex",
      flexDirection: "column", 
      width: "100%",
    }}>
      {
        loading ? <Loading /> : <>
          <Box sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}> 
            <Typography sx={{
              fontWeight: "bold",
              fontSize: "30px",
            }}>Item Plating</Typography>
            <LoadingButton 
              disabled={!hasChanges} 
              loading={updateMappingLoading} 
              onClick={
                async () => {
                  await updateItemPlating([...itemToPlating, ...modifierToPlating]);
                  setHasChanges(false);
                }}
              variant="contained"
              sx={{
                width: "fit-content",
              }}>
                Save Changes
            </LoadingButton>
          </Box>

          <Box sx={{
            display: "flex",
            width: "100%",
          }}> 
            <Card sx={{
              width: "100%",
              display: "flex", 
              flexDirection: "column",
              p: 5
            }}>
              <Box sx={{
                display: "flex",
                flexDirection: "row", 
                justifyContent: "space-between",
                alignItems: "center",
                mb: 3, 
              }}> 
                <Typography sx={{
                  fontWeight: "bold",
                  fontSize: "20px",
                  mb: 3,
                }}>Item Plating Mapping</Typography>
                <Box
                  onClick={() => {
                    setSelectAllItems(!selectAllItems);
                  }}
                  sx={{
                    display: "flex",
                    flexDirection: "row", 
                    alignContent: "center",
                    alignItems: "center",
                  }}>
                  <Checkbox sx={{
                    cursor: "pointer"
                  }} checked={selectAllItems} 
                  onClick={(e) => {
                    setSelectAllItems(!selectAllItems);
                  }} />

                  <Typography sx={{
                    fontSize: "12px",  
                  }}> Select All Items With Plating </Typography>

                </Box>
                <Box
                  onClick={() => {
                    setSelectAllItemsWithoutPlating(!selectAllItemsWithoutPlating);
                  }}
                  sx={{
                    display: "flex",
                    flexDirection: "row", 
                    alignContent: "center",
                    alignItems: "center",
                  }}>
                  <Checkbox sx={{
                    cursor: "pointer"
                  }} checked={selectAllItemsWithoutPlating} 
                  onClick={(e) => {
                    setSelectAllItemsWithoutPlating(!selectAllItemsWithoutPlating);
                  }} />

                  <Typography sx={{
                    fontSize: "12px",  
                  }}> Select All Items Without Plating </Typography>

                </Box>
              </Box>
              {
                itemToPlating.filter((m) => selectAllItems ? ((m.plating || "").trim() !== "" ? true : false) :
                  selectAllItemsWithoutPlating ? ((m.plating || "").trim() === "" ? true : false) :
                    true).map((i) => {
                  return <Box key={i.id} sx={{display: "flex", flexDirection: "row", justifyContent: "space-between", m:1}}> 
                    <Typography>{i.id}</Typography>
                    <Autocomplete
                      sx={{
                        width: "50%",
                        ml: 1,
                      }} 
                      value={i.plating}
                      onChange={(e, val) => {
                        setHasChanges(true);
                        const newItems = itemToPlating.map((item) => {
                          if (item.id === i.id) {
                            return {
                              ...item,
                              plating: val,
                            };
                          }
                          return item;
                        });
                        setItemToPlating(newItems);
                      }}
                      options={plating.map((p) => p.name.trim()).sort()}
                      renderInput={(params) => <TextField {...params} label="Plating" />}
                    />
                  </Box>;
                })
              }
            </Card>
  
            <Card sx={{
              width: "100%",
              display: "flex", 
              flexDirection: "column",
              p: 5
            }}> 
              <Box sx={{
                display: "flex",
                flexDirection: "row", 
                justifyContent: "space-between",
                alignItems: "center",
                mb: 3, 
              }}> 
                <Typography sx={{
                  fontWeight: "bold",
                  fontSize: "20px",
                  mb: 3,
                }}>Modifiers Plating Mapping</Typography>
                <Box
                  onClick={() => {
                    setSelectAllModifiers(!selectAllModifiers);
                  }}
                  sx={{
                    display: "flex",
                    flexDirection: "row", 
                    alignContent: "center",
                    alignItems: "center",
                  }}>
                  <Checkbox sx={{
                    cursor: "pointer"
                  }} checked={selectAllModifiers} 
                  onClick={(e) => {
                    setSelectAllModifiers(!selectAllModifiers);
                  }} />

                  <Typography sx={{
                    fontSize: "12px",  
                  }}> Select All Modifiers With Plating </Typography>

                </Box>
                <Box
                  onClick={() => {
                    setSelectAllWithouModifiersWithoutPlating(!selectAllModifiersWithoutPlating);
                  }}
                  sx={{
                    display: "flex",
                    flexDirection: "row", 
                    alignContent: "center",
                    alignItems: "center",
                  }}>
                  <Checkbox sx={{
                    cursor: "pointer"
                  }} checked={selectAllModifiersWithoutPlating} 
                  onClick={(e) => {
                    setSelectAllWithouModifiersWithoutPlating(!selectAllModifiersWithoutPlating);
                  }} />

                  <Typography sx={{
                    fontSize: "12px",  
                  }}> Select All Modifiers Without Plating </Typography>

                </Box>
              </Box>
              {
                modifierToPlating.filter((m) => selectAllModifiers ? (m.plating !== "" ? true : false) :
                  selectAllModifiersWithoutPlating ? (m.plating === "" ? true : false) :
                    true).map((i) => {
                  return <Box key={i.id} sx={{display: "flex", flexDirection: "row", justifyContent: "space-between", m:1}}> 
                    <Typography>{i.id}</Typography>
                    <Autocomplete
                      sx={{
                        width: "50%",
                        ml: 1,
                      }} 
                      value={i.plating}
                      onChange={(e, val) => {
                        setHasChanges(true);
                        const newItems = modifierToPlating.map((item) => {
                          if (item.id === i.id) {
                            return {
                              ...item,
                              plating: val,
                            };
                          }
                          return item;
                        });
                        setModifierToPlating(newItems);
                      }}
                      options={plating.map((p) => p.name.trim()).sort()}
                      renderInput={(params) => <TextField {...params} label="Plating" />}
                    /> 
                  </Box>;
                })
              }
            </Card>
          </Box>

        </>
      } 
    </Box>
  );
};

export default ItemToPlating;