import React, { useEffect, useState } from "react";

const API_URL = process.env.REACT_APP_API_URL;

export default function useCharts() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [location, setLocation] = useState("All");

  useEffect(() => {
    fetchData();
  }, [location]);

  const fetchData = async () => {
    setLoading(true);
    const res = await fetch(`${API_URL}/dailySales?storeId=${location}`);
    let result = await res.json();
    setData(result);
    setLoading(false);
  };

  return {
    data,
    fetchData,
    loading,
    location,
    setLocation,
  };
}

export const useAddNote = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [apiResults, setApiResults] = React.useState();
  const [serverError, setServerError] = React.useState(null);

  const addNoteToSales = async (note, storeAbbreviation) => {
    setIsLoading(true);
    try {
      const res = await fetch(`${API_URL}/addNoteToDate`, {
        method: "POST",
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({storeAbbreviation, note})
      });

      const data = await res.json();
      setApiResults(data);
      setIsLoading(false);
      return data;
    } catch (error) {
      setServerError(error);
      setIsLoading(false);
    }
  };

 

  return { isLoading, apiResults, serverError, addNoteToSales };

};