import BrandChartsDesktop from "./pages/desktop/BrandCharts";
import SettingsDesktop from "./pages/desktop/settings";

import BrandChartsMobile from "./pages/mobile/BrandCharts";
import SettingsMobile from "./pages/mobile/settings";

export const brandDesktopPages = [
  {
    path: "/charts",
    to: "/charts",
    title: "Charts",
    component: (brand) => <BrandChartsDesktop brand={brand} />,
  },
  {
    path: "/settings",
    to: "/settings",
    title: "Settings",
    component: () => <SettingsDesktop />,
  },
];

export const brandMobilePages = [
  {
    path: "/charts",
    to: "/charts",
    title: "Charts",
    component: (brand) => <BrandChartsDesktop brand={brand} />,
  },
  {
    path: "/settings",
    to: "/settings",
    title: "Settings",
    component: () => <SettingsMobile />,
  },
];


