import { ResponsiveBar } from "@nivo/bar";

const ResponsiveBarGraph = ({ data, keys }) => (
  <ResponsiveBar
    data={data} 
    keys={["Sales"]}
    margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
    padding={0.35} 
    valueScale={{ type: "linear" }}
    indexScale={{ type: "band", round: true }}
    colors={{ scheme: "nivo" }} 
    indexBy={"day"}
    valueFormat=" >-,.1f"
    defs={[
      {
        id: "dots",
        type: "patternDots",
        background: "inherit",
        color: "#38bcb2",
        size: 4,
        padding: 1,
        stagger: true
      },
      {
        id: "lines",
        type: "patternLines",
        background: "inherit",
        color: "#eed312",
        rotation: -45, 
        lineWidth: 6,
        spacing: 10
      }
    ]}
    fill={[
      { 
        id: "dots"
      }, 
    ]}
    label={e=>e.formattedValue}
    borderColor={{
      from: "color",
      modifiers: [
        [
          "darker",
          1.6
        ]
      ]
    }}
    axisTop={null}
    axisRight={null}
    axisBottom={{
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: "Day of Week",
      legendPosition: "middle",
      legendOffset: 32
    }}
    axisLeft={{
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: "Sales",
      legendPosition: "middle",
      legendOffset: -40
    }}
    labelSkipWidth={12}
    labelSkipHeight={12}
    labelTextColor={{
      from: "color",
      modifiers: [
        [
          "darker",
          1.6
        ]
      ]
    }}
    legends={[
      {
        dataFrom: "keys",
        anchor: "bottom-right",
        direction: "column",
        justify: false,
        translateX: 120,
        translateY: 0,
        itemsSpacing: 2,
        itemWidth: 100,
        itemHeight: 20,
        itemDirection: "left-to-right",
        itemOpacity: 0.85,
        symbolSize: 20,
        effects: [
          {
            on: "hover",
            style: {
              itemOpacity: 1
            }
          }
        ]
      }
    ]}
    role="application" 
  />
);

export default ResponsiveBarGraph;