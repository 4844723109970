import React, {useEffect} from "react";
import { Flex, Box, Text } from "noshery-ui";
import moment from "moment"; 
import useCharts from "../../hooks/useBrandChart";
import styled from "styled-components";
import PageLoader from "../../components/PageLoader"; 
import { isDataEmpty } from "../../utils/validators";
import NoData from "../../components/NoData";
import Breakdown from "../../components/Breakdown"; 
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useLocationCharts } from "../../hooks/useLocation";

const restaurants = {
  "Humphry Slocombe Ice Cream": "Humphry Slocombe Ice Cream",
  "The Pastry Cupboard": "The Pastry Cupboard",
  "Kasa": "Kasa", 
  "The Little Chihuahua": "The Little Chihuahua",
  "East Side Banh Mi": "East Side Banh Mi",
  "Bourbon Street West": "Bourbon Street West",
  "Road Food": "Road Food",
  "C&C Curry House": "C&C Curry House",
  "Oren's Hummus": "Oren's Hummus",
  "The Melt": "The Melt", 
  "Asian Box": "Asian Box",
  "Lazy Susan": "Lazy Susan",
  "Sumo Dog": "Sumo Dog",
  "Sushirrito": "Sushirrito",
  "Rooster and Rice": "Rooster and Rice",
};


const dateRangeOption = {
  today: "Today",
  yesterday: "Yesterday",
  thisWeek: "This week",
  lastWeek: "Last week",
  thisMonth: "This month",
  lastMonth: "Last month",
  thisYear: "This year",
  allTime: "All time",
  custom: "Custom",
};

const periods = {
  period10: "Period 10",
  period11: "Period 11",
  period12: "Period 12",
  period13: "Period 1",
  period14: "Period 2",
  period15:  "Period 3",
  period16: "Period 4",
  period17: "Period 5",
  period18: "Period 6",
  period19: "Period 7",
  period20: "Period 8",
  period21: "Period 9",
  period22: "Period 10",
  period23: "Period 11",
  period24: "Period 12",
  
  period25: "Period 1",
  period26: "Period 2",
  period27: "Period 3",
  period28: "Period 4",
  period29: "Period 5",
  period30: "Period 6",
  period31: "Period 7",
  period32: "Period 8",
  period33: "Period 9",
  period34: "Period 10",
};


const years = {
  year2022: "2022",
  year2023: "2023",
  year2024: "2024",
};

const StyledSelect = styled.select`
  border-radius: 10px;
  box-shadow: 0 0 3px 0 rgb(0, 0, 0, 0.2);
  border: none;
  background-color: white;
  font-size: ${(props) => (props.fontSize ? props.fontSize : "20px")}
  ${(props) =>
    props.position
      ? "position: absolute; right: 8px; top: 8px; z-index: 1;"
      : ""}
  padding: 12px;
  font-weight: 600;
  &:hover,
  &:focus {
    cursor: pointer;
  }
  :focus-visible {
    outline: solid orange 2px;
  }
`;
 

const Charts = ({brand: userBrand}) => { 
  const [year, setYear] = React.useState("2023");
  const {
    chartChosen,
    setChartChosen,
    setDateRange,
    startDate,
    endDate,
    chartData,
    loading,
    onSelectStartDate,
    onSelectEndDate,
    setLocation,
    dateRange,
    setBrand,
    brand,
    setLoading
  } = useCharts();

  const {locations: locationsOption} = useLocationCharts();

  useEffect(() => {
    if (Object.values(locationsOption).length > 0) {
      setLocation(Object.values(locationsOption)[0]);
    }
  }, [locationsOption]); 

  useEffect(() => {
    setBrand(userBrand);
  }, [userBrand]);
 
  return (
    <Box> 
      <Flex pt={"100px"} pb={4} px="100px" flexDirection="column" bg="#f2f2f2">
        <Flex justifyContent="space-between" mb={4}>
          <Flex w="100%" flexDirection="column" justifyContent="space-between">
            <Flex w="100%" paddingBottom="2%">
              <Box paddingRight="10px">
                <StyledSelect
                  onChange={(e) => setLocation(locationsOption[e.target.value])}
                >
                  {Object.keys(locationsOption).map((d) => (
                    <option value={d} key={d}>
                      {d}
                    </option>
                  ))}
                </StyledSelect>
              </Box>
              <Box paddingRight="10px">
                <StyledSelect onChange={(e) => {setDateRange(e.target.value);}}>
                  {Object.keys(periods).filter((d) => {
                    if(year === "2023" && 13 <= parseInt(d.match(/([0-9]+)/))) {
                      return d;
                    }  
                    else if(year === "2022" && 13 > parseInt(d.match(/([0-9]+)/))) {
                      return d;
                    }
                    else return; 
                  }).map((d) => (
                    <option value={d} key={d}>
                      {periods[d]}
                    </option>
                  ))}
                  <option disabled selected value> -- select a period -- </option>
                </StyledSelect>
              </Box>
              <Box paddingRight="10px">
                <StyledSelect
                  value={year}
                  onChange={(e) => {
                    setYear(e.target.value);
                  }}
                >
                  {Object.keys(years).map((d) => (
                    <option value={years[d]} key={d}>
                      {years[d]}
                    </option>
                  ))}
                </StyledSelect>
              </Box>

              <Box paddingRight="10px">
                <StyledSelect
                  value={dateRange.includes("period") ? "Period" : dateRange}
                  onChange={(e) => {
                    setDateRange(e.target.value);
                  }}
                >
                  {Object.keys(dateRangeOption).map((d) => (
                    <option value={d} key={d}>
                      {dateRangeOption[d]}
                    </option>
                  ))}
                  <option hidden value={"Period"} key={"Period"}>
                    Period
                  </option>
                </StyledSelect>
              </Box>

              <Box paddingRight="10px">
                <StyledSelect
                  disabled={userBrand ? true : false}
                  value={brand}
                  onChange={(e) => {
                    setBrand(e.target.value);
                    setLoading(true);
                  }}
                >
                  {Object.keys(restaurants).map((d) => (
                    <option value={d} key={d}>
                      {restaurants[d]}
                    </option>
                  ))}
                </StyledSelect>
              </Box>
            </Flex>
            <Box>
              <Flex
                style={{ width: "80%", borderRadius: "10px" }}
                py="20px"
                px="10px"
                bg="white"
              >
                <Flex
                  alignItems="center"
                  mr={3}
                  ml={2}
                  style={{width: "100%"}}
                  onClick={() => setDateRange("custom")}
                >
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DesktopDatePicker
                      label="Start Date"
                      inputFormat="YYYY-MM-DD"
                      minDate={moment("2021-11-12")}
                      maxDate={moment()}
                      value={moment.isMoment(startDate) ? startDate : moment(startDate)}
                      onChange={onSelectStartDate}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </Flex>
                <Flex
                  alignItems="center"
                  mr={2}
                  style={{width: "100%"}}
                  onClick={() => setDateRange("custom")}
                >
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DesktopDatePicker
                      label="End Date"
                      inputFormat="YYYY-MM-DD"
                      maxDate={moment()}
                      minDate={ moment.isMoment(startDate) ? startDate : moment(startDate)}
                      value={
                        moment.isMoment(endDate) ? endDate : moment(endDate)}
                      onChange={onSelectEndDate}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </Flex>
              </Flex>
            </Box>
          </Flex>
        </Flex>
      </Flex>

      {loading ? (
        <PageLoader />
      ) : isDataEmpty(chartData) ? (
        <NoData />
      ) : (
        
        chartData && !loading && chartData["items"] && chartData["items"]["daily"] && 
        <Flex px="100px" flexDirection="column" minHeight="100vh" bg="#f2f2f2">
          {chartData["items"] && chartData["items"]["daily"] && (
            <Breakdown
              data={chartData["items"]["daily"]} 
              globalData={chartData}
              brand={brand}
              range={dateRange}
              downloads={!userBrand ? true : false}
            />
          )} 
        </Flex>
      )}
    </Box>
  );
};
  
 
export default Charts;
