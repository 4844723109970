import React, { useState } from "react";
import usePlating from "../hooks/usePlating";
import useItemToPlating from "../hooks/useItemToPlating";
import {Autocomplete, Box, Typography, TextField, Button, Modal} from "@mui/material";
import Loading from "./Loading";
import GeneralTable from "./Table";
import moment from "moment-timezone";
import { CSVLink } from "react-csv";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails"; 
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  height: "60%",
  bgcolor: "background.paper", 
  boxShadow: 24,
  display: "flex",
  flexDirection: "column",
  p: 4,
  overflow: "auto",
  borderRadius: "10px",
};
 
const IngredientsTable = ({ items, modifiers, startDate, endDate }) => {
  const [isOpen, setIsOpen] = useState(false);  
  const { plating, loading: platingLoading } = usePlating();
  const { itemToPlating, modifierToPlating, loading: itemToPlatingLoading } = useItemToPlating();
  const [fitlerIngredients, setFilterIngredients] = useState("");
  const [filterUnscopedItems, setFilterUnscopedItems] = useState("");
  const [chosenItemHistory, setChosenItemHistory] = useState({});
  
  const item = (items.daily.orderCount || {}); 
  const modifier = (modifiers.daily || {});
  
  const convertItemsAndModifiersToIngredients = (items, modifiers) => {
    const ingredients = [];
    
    const itemCounts = {}; 
    const modifierCounts = {};

    const itemsAndModsNotCaptured = {};
    const itemsHistory = {};

    Object.keys(items).forEach((itemName) => {
      let totalCount = 0; 

      item[itemName].forEach((item) => {
        totalCount += item.y;
      });

      itemCounts[itemName] = totalCount;

    });

    Object.keys(modifiers).forEach((item) => { 
      if(!modifier[item]) return;
      Object.keys(modifier[item]).forEach((modifierName) => {
        let totalCount = 0; 

        if(!modifier[item][modifierName]) return;

        totalCount += Object.values(modifier[item][modifierName]).reduce((acc, cur) => {
          acc += cur || 1;
          return acc;
        }, 0);

        if(modifierCounts[modifierName]) {
          modifierCounts[modifierName] += totalCount;
        } else {
          modifierCounts[modifierName] = totalCount;
        }
      });

    });

    const ingredientsMap = {}; 
 
    Object.keys(itemCounts).forEach((itemName) => {
      const name = itemName.replaceAll("/", "");   
      const count = itemCounts[name];
      const findItem = itemToPlating.find((item) => (item.id || "").toLowerCase().trim() === (name || "").toLowerCase().trim());
      if(!findItem) {
        // console.log("Item not found", name);
        itemsAndModsNotCaptured[name] = count + (itemsAndModsNotCaptured[name] || 0);
        return;
      }
      if(findItem.plating === "" || !findItem.plating) {
        // console.log("Item has no plating assigned", name);
        itemsAndModsNotCaptured[name] = count + (itemsAndModsNotCaptured[name] || 0);
        return; 
      }
      const findPlating = plating.find((plating) => (plating.name || "").toLowerCase().trim() === (findItem.plating || "").toLowerCase().trim());
      if(!findPlating) {
        // console.log("Plating not found", findItem.plating);
        itemsAndModsNotCaptured[name] = count + (itemsAndModsNotCaptured[name] || 0);
        return;
      }

      const platingIngredients = findPlating.ingredients;

      platingIngredients.forEach((ingredient) => {
        const id = ingredient.name + " || " + ingredient.unit;
        if(ingredientsMap[id]) {
          ingredientsMap[id] += (parseFloat(ingredient.quantity) * count); 
        } else {
          ingredientsMap[id] = (parseFloat(ingredient.quantity) * count);
        }

        if(itemsHistory[id]) { 
          if(itemsHistory[id][name]) {
            itemsHistory[id][name] += count;
          } else {
            itemsHistory[id][name] = count;
          }
        } else {
          itemsHistory[id] = {};
          itemsHistory[id][name] = count;
        }
      });

    });

    Object.keys(modifierCounts).forEach((modifierName) => {
      const count = modifierCounts[modifierName];
      const findModifier = modifierToPlating.find((item) => (item.id || "").toLowerCase().trim() === (modifierName || "").toLowerCase().trim());
      if(!findModifier) {
        // console.log("Modifier not found", modifierName);
        itemsAndModsNotCaptured[modifierName] = count + (itemsAndModsNotCaptured[modifierName] || 0);
        return;
      }
      if(findModifier.plating === "" || !findModifier.plating) {
        // console.log("Modifier has no plating assigned", modifierName);
        itemsAndModsNotCaptured[modifierName] = count + (itemsAndModsNotCaptured[modifierName] || 0);
        return; 
      }
      const findPlating = plating.find((plating) => (plating.name || "").toLowerCase().trim() === (findModifier.plating || "").toLowerCase().trim());
      if(!findPlating) {
        // console.log("Plating not found", findModifier.plating);
        itemsAndModsNotCaptured[modifierName] = count + (itemsAndModsNotCaptured[modifierName] || 0);
        return;
      }

      const platingIngredients = findPlating.ingredients;

      platingIngredients.forEach((ingredient) => {
        const id = ingredient.name + " || " + ingredient.unit;
        if(ingredientsMap[id]) {
          ingredientsMap[id] += (parseFloat(ingredient.quantity) * count);
        } else {
          ingredientsMap[id] = (parseFloat(ingredient.quantity) * count);
        }

        if(itemsHistory[id]) { 
          if(itemsHistory[id][modifierName]) {
            itemsHistory[id][modifierName] += count;
          } else {
            itemsHistory[id][modifierName] = count;
          }
        } else {
          itemsHistory[id] = {};
          itemsHistory[id][modifierName] = count;
        }

      });

    }); 

    Object.keys(ingredientsMap).forEach((ingredient) => {
      const split = ingredient.split(" || ");
      const name = split[0];
      const unit = split[1];
      const quantity = ingredientsMap[ingredient];
      ingredients.push({
        name,
        unit,
        quantity,
        id: ingredient,
      });
    });

    return {
      ingredients: ingredients.sort((a, b) => a.name - b.name),
      itemsAndModsNotCaptured: Object.keys(itemsAndModsNotCaptured).map((a) => {
        return {
          name: a,  
          count: itemsAndModsNotCaptured[a],
        };
      }).sort((a, b) => a.name - b.name),
      history: itemsHistory,
    };

  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <Box sx={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
    }}>
      {
        (platingLoading || itemToPlatingLoading) ? <Loading /> : 
          ( item && modifier ) && <Box sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: 900,
            mb: 10
          }}>
            <Typography sx={{
              fontWeight: "bold",
              fontSize: "20px",
              mb: 3, 
            }}>
            Ingredients
            </Typography>
            <CSVLink style={{ alignSelf: "end", display: "flex", width: "100vw !important", alignContent: "center", justifyContent: "end", alignItems: "center"}}
              data={convertItemsAndModifiersToIngredients(item, modifier).ingredients.map((i) => {
                return {
                  name: i.name,
                  quantity: i.quantity,
                  unit: i.unit,
                };
              })}
              filename={`ingredients-data-${moment(startDate).format("YYYY-MM-DD")}_${moment(endDate).format("YYYY-MM-DD")}.csv`}
            >
              <Button disabled={(platingLoading || itemToPlatingLoading)}> Download Data  </Button>
            </CSVLink> 
            <Autocomplete 
              options={convertItemsAndModifiersToIngredients(item, modifier).ingredients.map((ingredient) => ingredient.name + " -- " + ingredient.unit).sort()}
              value={fitlerIngredients}
              sx={{
                backgroundColor: "white",
                width: "100%",
              }}
              onChange={(e, val) => {
                setFilterIngredients(val.split(" -- ")[0].trim());
              }}
              onInputChange={(e, val) => {
                setFilterIngredients(val.split(" -- ")[0].trim());
              }}
              renderInput={(params) => <TextField {...params} label="Search" />}
            />
            <GeneralTable 
              anyResult={convertItemsAndModifiersToIngredients(item, modifier)
                .ingredients
                .filter((ingredient) => ((ingredient.name || "").toLowerCase().trim())
                  .includes((fitlerIngredients || "").toLowerCase().trim()))
              }  
              onRowClick={(row) => {
                const itemHistory = convertItemsAndModifiersToIngredients(item, modifier).history;
                const allIngredients = convertItemsAndModifiersToIngredients(item, modifier).ingredients;

                const searchIngredient = allIngredients.find((ingredient) => ingredient.id === row);

                const history = itemHistory[row];

                console.log("history", history);

                const chosenItemHistory = {
                  history: history,
                  name: row.split(" || ")[0],
                  unit: row.split(" || ")[1],
                  quantity: searchIngredient.quantity,
                };

                setChosenItemHistory(chosenItemHistory);

                setIsOpen(true);
              }}
              skipHeaders={["id"]}
              itemName={"name"}
              itemId={"id"}
            />

            <Typography sx={{
              fontWeight: "bold",
              fontSize: "20px",
              m : 3, 
            }}>
            Items and modifiers not captured
            </Typography>
            <CSVLink style={{ alignSelf: "end", display: "flex", width: "100vw !important", alignContent: "center", justifyContent: "end", alignItems: "center"}}
              data={convertItemsAndModifiersToIngredients(item, modifier).itemsAndModsNotCaptured.map((i) => {
                return {
                  name: i.name, 
                  count: i.count,
                };
              })}
              filename={`uncaptured-items-data-${moment(startDate).format("YYYY-MM-DD")}_${moment(endDate).format("YYYY-MM-DD")}.csv`}
            >
              <Button disabled={(platingLoading || itemToPlatingLoading)}> Download Data  </Button>
            </CSVLink>
            <Autocomplete 
              options={convertItemsAndModifiersToIngredients(item, modifier).itemsAndModsNotCaptured.map((ingredient) => ingredient.name).sort()}
              value={filterUnscopedItems}
              sx={{
                backgroundColor: "white",
                width: "100%",
              }}
              onChange={(e, val) => {
                setFilterUnscopedItems(val);
              }}
              onInputChange={(e, val) => {
                setFilterUnscopedItems(val);
              }}
              renderInput={(params) => <TextField {...params} label="Search" />}
            /> 
            <GeneralTable 
              anyResult={convertItemsAndModifiersToIngredients(item, modifier).itemsAndModsNotCaptured.filter((ingredient) => ((ingredient.name || "").toLowerCase().trim()).includes((filterUnscopedItems || "").toLowerCase().trim()) )} 
              itemName={"name"}
              itemId={"name"}
            />

          </Box>
      }

      <Modal
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            height: "100%",
            overflow: "auto",
          }}>

            <Box sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              overflow: "auto",
            }}> 

              {
                chosenItemHistory && <Box> 
                  <Typography sx={{
                    fontSize: "32px",
                    fontWeight: "bold",
                    mb: 3,
                  }}> 
                    {chosenItemHistory.name}: {chosenItemHistory.quantity} {chosenItemHistory.unit}
                  </Typography>

                  <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    height: "100%",
                    overflow: "auto",
                  }}> 
                    <Typography sx={{
                      fontWeight: "bold",
                      fontSize: "30px",
                      mb: 3,
                    }}> 
                      Ingredient history
                    </Typography>
                    {
                      chosenItemHistory.history && Object.keys(chosenItemHistory.history).map((key) => {
                        const itemRecipe = itemToPlating.find((item) => item.id === key);
                        const modifierRecipe = modifierToPlating.find((modifier) => modifier.id === key);
                        const recipe = itemRecipe || modifierRecipe;
                        const curPlating = recipe ? recipe.plating : "";
                        const findPlating = curPlating ? plating.find((p) => p.name === curPlating) : {};
                        const ingredients = findPlating ? findPlating.ingredients : [];

                        
                        return <Accordion sx={{
                          backgroundColor: "whitesmoke"
                        }} key={key}>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Typography sx={{
                              fontSize: "20px", 
                            }}>  
                              {key}: {chosenItemHistory.history[key]}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            {
                              ingredients ? ingredients.map((ingredient) => {
                                return <Typography key={ingredient + key} sx={{
                                  fontSize: "20px",
                                  ml: 4
                                }}> 
                                  •  { chosenItemHistory.name === ingredient.name ? <b> {ingredient.name} {ingredient.quantity} {ingredient.unit} </b> : ingredient.name + " " + ingredient.quantity + " " + ingredient.unit} 
                                </Typography>;
                              }): <Typography sx={{
                                fontSize: "20px",
                              }}> 
                                No plating assigned
                              </Typography>
                            }
                          </AccordionDetails>
                        </Accordion>; 
                      })
                    }
                  </Box> 

                </Box>
              }
            </Box> 
          </Box>

          <Box sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            mt: "auto",            
          }}> 
            <Button variant="contained" color="error" sx={{
              mr: 2,
            }}
            onClick={handleClose}
            > 
                Close
            </Button> 
          </Box>

        </Box>
      </Modal>


    </Box>
  );
};
 
export default IngredientsTable;
