import  React, { useEffect } from "react"; 

const API_URL = process.env.REACT_APP_API_URL;

export const useUnits = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [units, setUnits] = React.useState([]);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const res = await fetch(`${API_URL}/getUnits`, {
        method: "GET",
        mode: "cors",
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
        }, 
      });
   
      const data = await res.json();
      console.log(data);
      setUnits(data.data);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return { isLoading, units, fetchData };

};
