import { useState, useEffect } from "react";
import Cookies from "js-cookie";

const API_URL = process.env.REACT_APP_API_URL;

const getAccessToken = () => Cookies.get("accessToken");

export default function useUsers() {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingUsers, setLoadingUsers] = useState(false);

  useEffect(() => {
    setLoading(true);
    const makeReq = async () => {
      const req = await fetch(`${API_URL}/users`, {
        method: "GET",
        mode: "cors",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${getAccessToken()}`,
        },
      });

      const { data } = await req.json();

      setUsers(data);
    };
    makeReq();
    setLoading(false);
  }, []);

  const refetchUsers = async () => {
    setLoadingUsers(true);
    const req = await fetch(`${API_URL}/users`, {
      method: "GET",
      mode: "cors",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getAccessToken()}`,
      },
    });

    const { data } = await req.json();

    setUsers(data);
    setLoadingUsers(false);
  };

  const setUsersClaim = async (
    uid,
    brand,
    admin = null,
    dev = null,
    accounting = null
  ) => {
    await fetch(`${API_URL}/setUserPermissions`, {
      method: "POST",
      mode: "cors",
      body: JSON.stringify({ uid, brand, admin, dev, accounting }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getAccessToken()}`,
      },
    });
    await refetchUsers();
  };

  return { users, setUsersClaim, loading, loadingUsers };
}
