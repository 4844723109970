import React, { useEffect } from "react";
import { Flex, Box, Text,} from "noshery-ui";
import moment from "moment";
import useCharts from "../../hooks/useCharts";
import styled from "styled-components";
import PageLoader from "../../components/PageLoader";
import { isDataEmpty } from "../../utils/validators";
import NoData from "../../components/NoData";
import ResponsiveBarGraph from "../../components/ResponsiveBarGraphCompare";
import Card from "@mui/material/Card";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { SalesTable, ComparisonsTable, ItemsTableComparisons } from "../../components/Tables";
import { MenuItem, Select, InputLabel, FormControl } from "@mui/material"; 
import { useLocationCharts, useLocationsStoreVsStore } from "../../hooks/useLocation";

const comparisonsOptions = {
  "Day over Day": "dod",
  "Same Day Last Week": "sdlw",
  "Week over Week": "wow",
  "Month over Month": "mom",
};

const comparisonTypes = {
  "Single Store": "Single Store",
  "Store vs Store": "Store vs Store",
};

const dateRangeOption = {
  today: "Today",
  yesterday: "Yesterday",
  thisWeek: "This week",
  lastWeek: "Last week",
  thisMonth: "This month",
  lastMonth: "Last month",
  thisYear: "This year",
  allTime: "All time",
  custom: "Custom",
};

const getDates = {
  dod: () => {
    return {
      startDate: moment().subtract(1, "days"),
      endDate: moment().subtract(1, "days"),
      startDate2: moment(),
      endDate2: moment(),
    };
  },
  wow: () => {
    return {
      startDate: moment().subtract(2, "weeks").startOf("isoWeek"),
      endDate: moment().subtract(2, "weeks").endOf("isoWeek"),
      startDate2: moment().subtract(1, "weeks").startOf("isoWeek"),
      endDate2: moment().subtract(1, "weeks").endOf("isoWeek"),
    };
  },
  mom: () => {
    return {
      startDate: moment().subtract(2, "months").startOf("month"),
      endDate: moment().subtract(2, "months").endOf("month"),
      startDate2: moment().subtract(1, "months").startOf("month"),
      endDate2: moment().subtract(1, "months").endOf("month"),
    };
  },
  sdlw: () => {
    return {
      startDate: moment().subtract(1, "weeks"),
      endDate: moment().subtract(1, "weeks"),
      startDate2: moment(),
      endDate2: moment(),
    };
  }
};

const StyledSelect = styled.select`
  border-radius: 10px;
  box-shadow: 0 0 3px 0 rgb(0, 0, 0, 0.2);
  border: none;
  background-color: white;
  font-size: ${(props) => (props.fontSize ? props.fontSize : "20px")}
  ${(props) =>
    props.position
      ? "position: absolute; right: 8px; top: 8px; z-index: 1;"
      : ""}
  padding: 12px;
  font-weight: 600;
  &:hover,
  &:focus {
    cursor: pointer;
  }
  :focus-visible {
    outline: solid orange 2px;
  }
`;
const Charts = () => {
  
  const [chosenComparison, setChosenComparison] = React.useState("dod");

  const [comparisonType, setComparisonType] = React.useState("Single Store");

  const [filter, setFilter] = React.useState("Total");

  const {locations: storeVsStore} = useLocationsStoreVsStore();

  const [store1, setStore1] = React.useState({id: "93895000000000000", name: "San Mateo"});

  const [store2, setStore2] = React.useState({id: "114032000000000000", name: "San Carlos"});

  const {
    setDateRange,
    dateRange,
    startDate,
    endDate,
    chartData,
    loading,
    location,
    onSelectStartDate,
    onSelectEndDate,
    setLocation,
  } = useCharts();

  const {
    startDate: startDate2,
    setDateRange: setDateRange2,
    dateRange: dateRange2,
    endDate: endDate2,
    chartData: chartData2,
    loading: loading2,
    location: location2,
    onSelectStartDate: onSelectStartDate2,
    onSelectEndDate: onSelectEndDate2,
    setLocation: setLocation2,
  } = useCharts();

  useEffect(() => { 
    if(chosenComparison && comparisonType !== "Store vs Store") {
      const { startDate, endDate, startDate2, endDate2 } = getDates[chosenComparison]();
      onSelectStartDate(startDate);
      onSelectEndDate(endDate);
      onSelectStartDate2(startDate2);
      onSelectEndDate2(endDate2);
    }
  }, [chosenComparison]);


  useEffect(() => {
    if (comparisonType === "Store vs Store") {
      setLocation(store1.id);
      setLocation2(store2.id);
      setDateRange2(dateRange);
      onSelectStartDate2(startDate);
      onSelectEndDate2(endDate);
    } 
    else {
      setLocation("All");
      setLocation2("All");
    }
  }, [comparisonType]);


  const {locations: locationsOption} = useLocationCharts();

  useEffect(() => { 
    if (comparisonType === "Store vs Store") {
      setLocation(store1.id);
      setLocation2(store2.id); 
    }
  }, []);

  useEffect(() => {
    if(comparisonType === "Store vs Store") {
      setDateRange2(dateRange);
      onSelectStartDate2(startDate2);
      onSelectEndDate2(endDate2);
    }
  }, [startDate, endDate]);
  
  useEffect(() => {
    if (comparisonType === "Store vs Store") {
      setLocation(store1.id);
    }
  }, [store1]);

  useEffect(() => {
    if (comparisonType === "Store vs Store") {
      setLocation2(store2.id);
    }
  }, [store2]);

  const categories = [
    { title: "Brand split", type: "brand", name: "Brand" },
    { title: "Channel split", type: "channel", name: "Channel" },
    {
      title: "1st vs 3rd Party",
      type: "FirstVsThird",
      name: "First Vs Third Party",
    },
  ];

  let keysSalesSummary = [ 
    "Net Sales",
    "Adjusted AOV", 
    "Non-Catering",
    "Catering",
    "Tips", 
    "Total Orders"
  ];
  let keySalesSummaryMap = { 
    "Net Sales": "netSales",
    "Adjusted AOV":  "nonCateringAov", 
    "Non-Catering": "nonCateringTotal",
    "Catering": "cateringTotal",
    Tips: "totalTip",
    "Total Orders": "totalOrders",
  };

  let keysOrders = ["Employee Orders", "AOV", "Employee Order Amount"];
  let keyOrdersMap = {
    "Employee Orders": "testOrders", 
    "Employee Order Amount": "testSales",
    "AOV": "aov",
  };

  return (
    <Box>
      <Flex pt={"100px"} pb={4} px="100px" pr="30px" flexDirection="column" bg="#f2f2f2">
        <Flex w="100%" mb={4}>
          <Flex w="100%" mr="1%" justifyContent="space-between">
            
            { 
              comparisonType === "Single Store" && <>
                <Flex w="100%" justifyContent="space-between">
                  <Box paddingRight="10px">            
                    <StyledSelect
                      onChange={(e) => {
                        setLocation(locationsOption[e.target.value]);
                        setLocation2(locationsOption[e.target.value]);
                      }}
                    >
                      {Object.keys(locationsOption).map((d) => (
                        <option value={d} key={d}>
                          {d}
                        </option>
                      ))}
                    </StyledSelect>
                  </Box>
                </Flex>
                <Flex w="100%" justifyContent="space-between">
                  <Box paddingRight="10px">
                    <StyledSelect
                      onChange={(e) => {
                        setChosenComparison(comparisonsOptions[e.target.value]);
                      }}
                    >
                      {Object.keys(comparisonsOptions).map((d) => (
                        <option value={d} key={d}>
                          {d}
                        </option>
                      ))}
                    </StyledSelect>
                  </Box>
                </Flex>
              </>
            }
            {
              comparisonType === "Store vs Store" &&
              <Flex w="100%" justifyContent="space-between">
                <Box paddingRight="10px">
                  <StyledSelect
                    value={dateRange}
                    onChange={(e) => setDateRange(e.target.value)}
                  >
                    {Object.keys(dateRangeOption).map((d) => (
                      <option value={d} key={d}>
                        {dateRangeOption[d]}
                      </option>
                    ))}
                  </StyledSelect>
                </Box>

                <Box paddingRight="10px">
                  <StyledSelect
                    value={store1.name}
                    onChange={(e) => setStore1(storeVsStore[e.target.value])}
                  >
                    {Object.keys(storeVsStore).map((d) => (
                      <option value={d} key={d}>
                        {d}
                      </option>
                    ))}
                  </StyledSelect>
                </Box>

                <Box paddingRight="10px">
                  <StyledSelect
                    value={store2.name}
                    onChange={(e) => setStore2(storeVsStore[e.target.value])}
                  >
                    {Object.keys(storeVsStore).map((d) => (
                      <option value={d} key={d}>
                        {d}
                      </option>
                    ))}
                  </StyledSelect>
                </Box>
              </Flex>
            }
            <Flex w="100%" justifyContent="space-between">
              <Box paddingRight="10px">
                <StyledSelect
                  onChange={(e) => {
                    setComparisonType(comparisonTypes[e.target.value]);
                  }}
                >
                  {Object.keys(comparisonTypes).map((d) => (
                    <option value={d} key={d}>
                      {d}
                    </option>
                  ))}
                </StyledSelect>
              </Box>
            </Flex>
          </Flex>
        </Flex>
        <Flex>         
          <Flex
            style={{ width: "20%", borderRadius: "10px", justifyContent: "center", alignItems: "center", alignContent: "center",  flexDirection: "column" }}
            py="20px"
            px="10px"
            bg="white"
            mr="5%"
          >
            <Flex
              alignItems="center"
              mr={2}
              ml={2}
              mb={3}
              onClick={() => setDateRange("custom")}
            >
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DesktopDatePicker
                  label="Start Date"
                  inputFormat="YYYY-MM-DD"
                  minDate={moment("2021-11-12")}
                  maxDate={moment()}
                  value={moment.isMoment(startDate) ? startDate : moment(startDate)}
                  onChange={onSelectStartDate}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Flex>
            <Flex
              alignItems="center"
              mr={2}
              ml={2}
              onClick={() => setDateRange("custom")}
            >
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DesktopDatePicker
                  label="End Date"
                  inputFormat="YYYY-MM-DD"
                  maxDate={moment()}
                  minDate={ moment.isMoment(startDate) ? startDate : moment(startDate)}
                  value={
                    moment.isMoment(endDate) ? endDate : moment(endDate)}
                  onChange={onSelectEndDate}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Flex>
          </Flex>
          
          {
            <Flex
              style={{ width: "20%", borderRadius: "10px", justifyContent: "center", alignItems: "center", alignContent: "center", flexDirection: "column" }}
              py="20px"
              px="10px"
              bg="white"
              mr="15%"
            >
              <Flex
                alignItems="center"
                mr={2}
                ml={2}
                mb={3}
                onClick={() => setDateRange("custom")}
              >
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DesktopDatePicker
                    label="Start Date"
                    inputFormat="YYYY-MM-DD"
                    minDate={moment("2021-11-12")}
                    maxDate={moment()}
                    value={moment.isMoment(startDate2) ? startDate2 : moment(startDate2)}
                    onChange={onSelectStartDate2}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </Flex>
              <Flex
                alignItems="center"
                mr={2}
                ml={2}
                onClick={() => setDateRange("custom")}
              >
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DesktopDatePicker
                    label="End Date"
                    inputFormat="YYYY-MM-DD"
                    maxDate={moment()}
                    minDate={ moment.isMoment(startDate2) ? startDate2 : moment(startDate2)}
                    value={
                      moment.isMoment(endDate2) ? endDate2 : moment(endDate2)}
                    onChange={onSelectEndDate2}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </Flex>
            </Flex>
          }
        </Flex>
      </Flex>

      {(loading || loading2) ? (
        <PageLoader />
      ) : isDataEmpty(chartData) ? (
        <NoData />
      ) : (
        <Flex
          pl="100px"
          pr="30px"
          flexDirection="column"
          minHeight="100vh"
          bg="#f2f2f2"
        >
          <Flex justifyContent="space-between" flexDirection="column">
            <Text fontWeight="600" fontSize={4} mt={4} mb={4}>
                Sales Summary
            </Text>
            {
              chartData &&
              chartData.total &&
              chartData.total.netSales >= 0 && 
              chartData.total.totalTip >= 0 && 
              chartData.total.grossTotal >= 0 &&
              chartData.total.totalOrders >= 0 &&
              chartData.total.nonCateringTotal >= 0 &&
              chartData.total.cateringTotal >= 0 &&
              // chartData.total.nonCateringAov >= 0 && 
              chartData2 &&
              chartData2.total &&
              chartData2.total.netSales >= 0 && 
              chartData2.total.totalTip >= 0 && 
              chartData2.total.grossTotal >= 0 && 
              chartData2.total.totalOrders >= 0 &&
              chartData2.total.nonCateringTotal >= 0 &&
              chartData2.total.cateringTotal >= 0 &&
              // chartData2.total.nonCateringAov >= 0 && 
              startDate &&
              endDate &&
              startDate2 &&
              endDate2 &&
              <Card sx={{height: "500px", padding: "16px", width: "100%", flexDirection: "row", display: "flex"}}>
                <Box width="100%" style={{height: "450px"}}>
                  <ResponsiveBarGraph 
                    data={
                      [
                        { 
                          "Net Sales": chartData.total.netSales,
                          "Adjusted AOV": chartData.total.nonCateringAov || 0, 
                          "Non-Catering": chartData.total.nonCateringTotal,
                          "Catering": chartData.total.cateringTotal,
                          Tips: chartData.total.totalTip,
                          "Total Orders": chartData.total.totalOrders,
                          id: comparisonType === "Single Store" ? `${moment(startDate).format("MM-DD-YYYY")} - ${moment(new Date(endDate)).format("MM-DD-YYYY")}` : store1.name
                        },
                        { 
                          "Net Sales": chartData2.total.netSales,
                          "Adjusted AOV": chartData2.total.nonCateringAov || 0, 
                          "Non-Catering": chartData2.total.nonCateringTotal,
                          "Catering": chartData2.total.cateringTotal,
                          Tips: chartData2.total.totalTip,
                          "Total Orders": chartData2.total.totalOrders,
                          id: comparisonType === "Single Store" ? `${moment(startDate2).format("MM-DD-YYYY")} - ${moment(new Date(endDate2)).format("MM-DD-YYYY")}` : store2.name
                        }
                      ]
                    }
                    keys={keysSalesSummary}
                  /> 
                </Box>
                <Card sx={{height: "450px",  width: "100%" }}>
                  <ComparisonsTable 
                    title="Sales"
                    keysOrders={keysSalesSummary}
                    chartData={chartData}
                    chartData2={chartData2}
                    keyOrdersMap={keySalesSummaryMap}
                    startDate={comparisonType === "Single Store" ? `${moment(startDate).format("MM-DD-YYYY")} - ${moment(new Date(endDate)).format("MM-DD-YYYY")}` : store1.name}
                    endDate={comparisonType === "Single Store" ? `${moment(startDate2).format("MM-DD-YYYY")} - ${moment(new Date(endDate2)).format("MM-DD-YYYY")}` : store2.name}
                  />
                </Card>
              </Card>
            }
          </Flex>
          <Flex justifyContent="space-between" flexDirection="column">
            <Text fontWeight="600" fontSize={4} mt={4} mb={4}>
                  Catering Split Net Sales
            </Text>
            {
              chartData &&
              chartData["catering"] &&
              chartData["catering"]["split"] &&
              chartData["catering"]["split"].length !== 0 &&
              chartData["catering"]["netSalesPieChart"] &&
              chartData["total"] && (              
                <Card sx={{height: "500px", padding: "16px", width: "100%", flexDirection: "row", display: "flex"}}>
                  <Box width="100%" height="450px"> 
                    {
                      chartData["catering"]["split"] && <Box width="100%" height="450px">
                        <ResponsiveBarGraph 
                          data={
                            [
                              {
                                ...chartData["catering"]["split"].reduce((acc, cur) => ({...acc, [cur.channel]: cur.netSales }), {}),
                               
                                id: comparisonType === "Single Store" ?  `${moment(startDate).format("MM-DD-YYYY")} - ${moment(new Date(endDate)).format("MM-DD-YYYY")}` : store1.name
                              },
                              {
                                ...chartData2["catering"]["split"].reduce((acc, cur) => ({...acc, [cur.channel]: cur.netSales }), {}),
                                  
                                id: comparisonType === "Single Store" ? `${moment(startDate2).format("MM-DD-YYYY")} - ${moment(new Date(endDate2)).format("MM-DD-YYYY")}` : store2.name
                              }
                            ]
                          }
                          keys={
                            [...new Set([...chartData["catering"]["split"].map((d) => d.channel), ...chartData2["catering"]["split"].map((d) => d.channel)])]
                          }
                        /> 
                      </Box>
                    }
                  </Box>
                  <Card sx={{height: "450px",  width: "80%" }}>
                    {
                      (() => {
                        let keys = {}; 
                        chartData["catering"]["split"].forEach((d) => keys[d.channel] = d.channel);
                        chartData2["catering"]["split"].forEach((d) => keys[d.channel] = d.channel);
                        let chart = chartData["catering"]["split"].reduce((acc, cur) => ({...acc, [cur.channel]: cur.netSales }), {});
                        let chart2 = chartData2["catering"]["split"].reduce((acc, cur) => ({...acc, [cur.channel]: cur.netSales }), {});
                        Object.keys(keys).forEach((d) => {
                          if (!chart[d]) chart[d] = 0;
                          if (!chart2[d]) chart2[d] = 0;
                        });
                        return <SalesTable 
                          title="Brand"
                          keysOrders={Object.keys(keys)}
                          chartData={chart} 
                          chartData2={chart2}
                          startDate={comparisonType === "Single Store" ? `${moment(new Date(startDate)).format("MM-DD-YYYY")} - ${moment(new Date(endDate)).format("MM-DD-YYYY")}` : store1.name}
                          endDate={comparisonType === "Single Store" ? `${moment(new Date(startDate2)).format("MM-DD-YYYY")} - ${moment(new Date(endDate2)).format("MM-DD-YYYY")}` : store2.name}
                        /> ;
                      })()
                    }
                  </Card>
                </Card>
              )}
          </Flex>
          <>
            {categories.map((cat, index) => {
              return (
                <Box key={index}>
                  <Text fontWeight="600" fontSize={4} mt={4}>
                    {cat.title}
                  </Text>
                  <Flex justifyContent="space-between" flexDirection="column" pt={4}>
                    {            
                      chartData &&
                      chartData[`${cat.type}s`] &&
                      chartData[`${cat.type}s`]["split"] && <>
                        <Card sx={{height: "500px", padding: "16px",  width: "100%", flexDirection: "row", display: "flex", marginBottom: "3%"}}>
                          <Box width="100%" height="450px"> 
                            <Flex flexDirection="column" width="100%">
                              <Flex style={{justifyContent: "space-between"}}> 
                                <Text fontWeight="800" mt={2} fontSize={"24px"} ml={15} style={{width: "200px"}}>
                                Net Sales
                                </Text>
                                {
                                  cat.type === "brand" &&
                                  <FormControl sx={{width: "30%", }}>
                                    <InputLabel id="demo-simple-select-label">Filter Catering</InputLabel>
                                    <Select
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      value={filter}
                                      label="Catering"
                                      onChange={(e) => setFilter(e.target.value)}
                                    >
                                      <MenuItem value={"Catering"}>Catering</MenuItem>
                                      <MenuItem value={"Non Catering"}>Non Catering</MenuItem> 
                                      <MenuItem value={"Total"}>Total </MenuItem>  
                                    </Select>
                                  </FormControl>
                                }

                              </Flex>
                              <Box width="100%" height="450px">
                                <ResponsiveBarGraph 
                                  data={
                                    [
                                      {
                                        ...chartData[`${cat.type}s`]["split"].reduce((acc, cur) => {
                                          let fitleredResults = {}; 
                                          if(filter === "Catering") {
                                            fitleredResults = (chartData["catering"]["split"].reduce((acc, catering) => {
                                              if(cur.name === catering.channel) acc += catering.netSales;
                                              return acc;
                                            }, 0)); 
                                          }
                                          else if(filter === "Non Catering") {
                                            fitleredResults = (cur.netSales -
                                              chartData["catering"]["split"].reduce((acc, catering) => {
                                                if(cur.name === catering.channel) acc += catering.netSales;
                                                return acc;
                                              }, 0)); 
                                          }
                                          else if (filter === "Total") {
                                            fitleredResults = cur.netSales;
                                          }
                                          return {...acc, [cur.name]: fitleredResults};
                                        }, 
                                        {}),
                                   
                                        id: comparisonType === "Single Store" ? `${moment(startDate).format("MM-DD-YYYY")} - ${moment(new Date(endDate)).format("MM-DD-YYYY")}` : store1.name
                                      },
                                      {
                                        ...chartData2[`${cat.type}s`]["split"].reduce((acc, cur) => {
                                          let fitleredResults = {}; 
                                          if(filter === "Catering") {
                                            fitleredResults = (
                                              chartData2["catering"]["split"].reduce((acc, catering) => {
                                                if(cur.name === catering.channel) acc += catering.netSales;
                                                return acc;
                                              }, 0)); 
                                          }
                                          else if(filter === "Non Catering") {
                                            fitleredResults = (cur.netSales -
                                              chartData2["catering"]["split"].reduce((acc, catering) => {
                                                if(cur.name === catering.channel) acc += catering.netSales;
                                                return acc;
                                              }, 0)); 
                                          }
                                          else if (filter === "Total") {
                                            fitleredResults = cur.netSales;
                                          }
                                          return {...acc, [cur.name]: fitleredResults};
                                        }, 
                                        {}),
                                        
                                        id: comparisonType === "Single Store" ? `${moment(startDate2).format("MM-DD-YYYY")} - ${moment(new Date(endDate2)).format("MM-DD-YYYY")}` : store2.name
                                      }
                                    ]
                                  }
                                  keys={
                                    [...new Set([...chartData[`${cat.type}s`]["split"].map((d) => d.name), ...chartData2[`${cat.type}s`]["split"].map((d) => d.name)])]
                                  }
                                /> 
                              </Box>
                            </Flex>
                          </Box>
                          <Card sx={{height: "450px",  width: "100%", overflow: "scroll", }}>
                            {
                              (() => {
                                let keys = {}; 
                                chartData[`${cat.type}s`]["split"].forEach((d) => keys[d.name] = d.name);
                                chartData2[`${cat.type}s`]["split"].forEach((d) => keys[d.name] = d.name);
                                let chart = chartData[`${cat.type}s`]["split"].reduce((acc, cur) => {
                                  let fitleredResults = 0; 

                                  if(cat.type === "brand" && filter === "Total") fitleredResults = cur.netSales;
                                  if(cat.type === "brand" && filter === "Catering") fitleredResults = chartData["catering"]["split"].reduce((acc, catering) => {
                                    if(cur.name === catering.channel) acc += catering.netSales;
                                    return acc;
                                  }, 0);
                                  if(cat.type === "brand" && filter === "Non Catering") fitleredResults = cur.netSales - chartData["catering"]["split"].reduce((acc, catering) => {
                                    if(cur.name === catering.channel) acc += catering.netSales;
                                    return acc;
                                  }, 0);
                                  else if(cat.type !== "brand") {
                                    fitleredResults = cur.netSales;
                                  }

                                  acc[cur.name] = fitleredResults;
                                  return acc;
                                }, {});

                                let chart2 = chartData2[`${cat.type}s`]["split"].reduce((acc, cur) => {
                                  let fitleredResults = 0; 

                                  if(cat.type === "brand" && filter === "Total") fitleredResults = cur.netSales;
                                  if(cat.type === "brand" && filter === "Catering") fitleredResults = chartData2["catering"]["split"].reduce((acc, catering) => {
                                    if(cur.name === catering.channel) acc += catering.netSales;
                                    return acc;
                                  }, 0);
                                  if(cat.type === "brand" && filter === "Non Catering") fitleredResults = cur.netSales - chartData2["catering"]["split"].reduce((acc, catering) => {
                                    if(cur.name === catering.channel) acc += catering.netSales;
                                    return acc;
                                  }, 0);
                                  else if(cat.type !== "brand") {
                                    fitleredResults = cur.netSales;
                                  }

                                  acc[cur.name] = fitleredResults;
                                  return acc;
                                }, {});

                                Object.keys(keys).forEach((d) => {
                                  if (!chart[d]) chart[d] = 0;
                                  if (!chart2[d]) chart2[d] = 0;
                                });
                                return <SalesTable
                                  title={cat.type}
                                  keysOrders={Object.keys(keys)}
                                  chartData={chart}
                                  chartData2={chart2}
                                  startDate={comparisonType === "Single Store" ? `${moment(new Date(startDate)).format("MM-DD-YYYY")} - ${moment(new Date(endDate)).format("MM-DD-YYYY")}` : store1.name}
                                  endDate={comparisonType === "Single Store" ? `${moment(new Date(startDate2)).format("MM-DD-YYYY")} - ${moment(new Date(endDate2)).format("MM-DD-YYYY")}` : store2.name} 
                                /> ;
                              })()
                            }
                          </Card>
                        </Card>
                        <Card sx={{height: "500px", padding: "16px",  width: "100%", flexDirection: "row", display: "flex"}}>  
                          <Box width="100%" height="450px">
                            <Flex flexDirection="column" width="100%">
                              <Flex style={{justifyContent: "space-between"}}> 
                                <Text fontWeight="800" mt={2} fontSize={"24px"} ml={15} style={{width: "200px"}}>
                                  Order Count 
                                </Text>  
                                {cat.type === "brand" &&
                                  <FormControl sx={{width: "30%", }}>
                                    <InputLabel id="demo-simple-select-label">Filter Catering</InputLabel>
                                    <Select
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      value={filter}
                                      label="Catering"
                                      onChange={(e) => setFilter(e.target.value)}
                                    >
                                      <MenuItem value={"Catering"}>Catering</MenuItem>
                                      <MenuItem value={"Non Catering"}>Non Catering</MenuItem> 
                                      <MenuItem value={"Total"}>Total </MenuItem> 
                                    </Select>
                                  </FormControl>
                                }
                              </Flex>
                              <Box width="100%" height="450px">
                                <ResponsiveBarGraph 
                                  data={
                                    [
                                      {
                                        ...chartData[`${cat.type}s`]["split"].reduce((acc, cur) => {
                                          let fitleredResults = {}; 
                                          if(filter === "Catering") {
                                            fitleredResults = (chartData["catering"]["split"].reduce((acc, catering) => {
                                              if(cur.name === catering.channel) acc += catering.orderCount;
                                              return acc;
                                            }, 0)); 
                                          }
                                          else if(filter === "Non Catering") {
                                            fitleredResults = (cur.orderCount -
                                                chartData["catering"]["split"].reduce((acc, catering) => {
                                                  if(cur.name === catering.channel) acc += catering.orderCount;
                                                  return acc;
                                                }, 0)); 
                                          }
                                          else if (filter === "Total") {
                                            fitleredResults = cur.orderCount;
                                          }
                                          return {...acc, [cur.name]: fitleredResults};
                                        }, 
                                        {}),
                                     
                                        id: comparisonType === "Single Store" ? `${moment(startDate).format("MM-DD-YYYY")} - ${moment(new Date(endDate)).format("MM-DD-YYYY")}` : store1.name
                                      },
                                      {
                                        ...chartData2[`${cat.type}s`]["split"].reduce((acc, cur) => {
                                          let fitleredResults = {}; 
                                          if(filter === "Catering") {
                                            fitleredResults = (cur.orderCount -
                                                chartData2["catering"]["split"].reduce((acc, catering) => {
                                                  if(cur.name === catering.channel) acc += catering.orderCount;
                                                  return acc;
                                                }, 0)); 
                                          }
                                          else if(filter === "Non Catering") {
                                            fitleredResults = (cur.orderCount -
                                                chartData2["catering"]["split"].reduce((acc, catering) => {
                                                  if(cur.name === catering.channel) acc += catering.orderCount;
                                                  return acc;
                                                }, 0)); 
                                          }
                                          else if (filter === "Total") {
                                            fitleredResults = cur.orderCount;
                                          }
                                          return {...acc, [cur.name]: fitleredResults};
                                        }, 
                                        {}),
                                          
                                        id: comparisonType === "Single Store" ? `${moment(startDate2).format("MM-DD-YYYY")} - ${moment(new Date(endDate2)).format("MM-DD-YYYY")}` : store2.name
                                      }
                                    ]
                                  }
                                  keys={
                                    [...new Set([...chartData[`${cat.type}s`]["split"].map((d) => d.name), ...chartData2[`${cat.type}s`]["split"].map((d) => d.name)])]
                                  }
                                /> 
                              </Box>
                            </Flex>
                          </Box>
                          <Card sx={{height: "450px",  width: "100%", overflow: "scroll", }}>
                            {
                              (() => {
                                let keys = {}; 
                                chartData[`${cat.type}s`]["split"].forEach((d) => keys[d.name] = d.name);
                                chartData2[`${cat.type}s`]["split"].forEach((d) => keys[d.name] = d.name);
                                let chart = chartData[`${cat.type}s`]["split"].reduce((acc, cur) => {
                                  let fitleredResults = 0; 

                                  if(cat.type === "brand" && filter === "Total") fitleredResults = cur.orderCount;
                                  if(cat.type === "brand" && filter === "Catering") fitleredResults = chartData["catering"]["split"].reduce((acc, catering) => {
                                    if(cur.name === catering.channel) acc += catering.orderCount;
                                    return acc;
                                  }, 0);
                                  if(cat.type === "brand" && filter === "Non Catering") fitleredResults = cur.orderCount - chartData["catering"]["split"].reduce((acc, catering) => {
                                    if(cur.name === catering.channel) acc += catering.orderCount;
                                    return acc;
                                  }, 0);
                                  else if(cat.type !== "brand") {
                                    fitleredResults = cur.orderCount;
                                  }

                                  acc[cur.name] = fitleredResults;
                                  return acc;
                                }, {});

                                let chart2 = chartData2[`${cat.type}s`]["split"].reduce((acc, cur) => {
                                  let fitleredResults = 0; 

                                  if(cat.type === "brand" && filter === "Total") fitleredResults = cur.orderCount;
                                  if(cat.type === "brand" && filter === "Catering") fitleredResults = chartData2["catering"]["split"].reduce((acc, catering) => {
                                    if(cur.name === catering.channel) acc += catering.orderCount;
                                    return acc;
                                  }, 0);
                                  if(cat.type === "brand" && filter === "Non Catering") fitleredResults = cur.orderCount - chartData2["catering"]["split"].reduce((acc, catering) => {
                                    if(cur.name === catering.channel) acc += catering.orderCount;
                                    return acc;
                                  }, 0);
                                  else if(cat.type !== "brand") {
                                    fitleredResults = cur.orderCount;
                                  }

                                  acc[cur.name] = fitleredResults;
                                  return acc;
                                }, {});
                                Object.keys(keys).forEach((d) => {
                                  if (!chart[d]) chart[d] = 0;
                                  if (!chart2[d]) chart2[d] = 0;
                                }); 
                                return <SalesTable
                                  title={cat.type}
                                  keysOrders={Object.keys(keys)}
                                  chartData={chart}
                                  chartData2={chart2}
                                  startDate={comparisonType === "Single Store" ? `${moment(new Date(startDate)).format("MM-DD-YYYY")} - ${moment(new Date(endDate)).format("MM-DD-YYYY")}` : store1.name}
                                  endDate={comparisonType === "Single Store" ? `${moment(new Date(startDate2)).format("MM-DD-YYYY")} - ${moment(new Date(endDate2)).format("MM-DD-YYYY")}` : store2.name}
                                /> ;
                              })()
                            }
                          </Card>
                        </Card></> 
                    }
                  </Flex>
                </Box>
              );
            })}
          </>
          <Flex justifyContent="space-between" flexDirection="column">
            <Text fontWeight="600" fontSize={4} mt={4} mb={4}>
              Items Net Sales
            </Text>
            {
              chartData &&
              chartData["items"] &&
              chartData["items"]["daily"] &&
              chartData["itemsBrandNames"] &&
              chartData["items"]["daily"]["netSales"] &&
              chartData2 &&
              chartData2["items"] &&
              chartData2["items"]["daily"] && 
              chartData2["itemsBrandNames"] &&
              chartData2["items"]["daily"]["netSales"] && (              
                <Card sx={{height: "500px", padding: "16px", width: "100%", flexDirection: "row", display: "flex"}}>
                  <Card sx={{height: "450px",  width: "100%", overflow: "scroll" }}>
                    {
                      (() => {
                        let keys = {}; 
                        const itemBrandMaps = {};
                        
                        Object.keys(chartData["itemsBrandNames"]).forEach((d) => {
                          itemBrandMaps[d] = chartData["itemsBrandNames"][d];
                        });

                        Object.keys(chartData2["itemsBrandNames"]).forEach((d) => {
                          itemBrandMaps[d] = chartData2["itemsBrandNames"][d];
                        });
                        
                        Object.keys(chartData["items"]["daily"]["netSales"]).forEach((d) => keys[d] = d);
                        Object.keys(chartData2["items"]["daily"]["netSales"]).forEach((d) => keys[d] = d);

                        const chart = Object.keys(keys).reduce((acc, cur) => {
                          if (!chartData["items"]["daily"]["netSales"][cur]) {
                            return {
                              ...acc,
                              [cur]: 0
                            };
                          }

                          if(acc[cur]) {
                            const total = chartData["items"]["daily"]["netSales"][cur].reduce((acc, cur) => acc + cur.y, 0) + acc[cur];
                            return {
                              ...acc,
                              [cur]: total
                            };
                          }

                          const total = chartData["items"]["daily"]["netSales"][cur].reduce((acc, cur) => acc + cur.y, 0);
                          return {
                            ...acc,
                            [cur]: total
                          };
                        }, {});

                        const chart2 = Object.keys(keys).reduce((acc, cur) => {
                          if (!chartData2["items"]["daily"]["netSales"][cur]) {
                            return {
                              ...acc,
                              [cur]: 0
                            };
                          }
                          
                          if(acc[cur]) {
                            const total = chartData2["items"]["daily"]["netSales"][cur].reduce((acc, cur) => acc + cur.y, 0) + acc[cur];
                            return {
                              ...acc,
                              [cur]: total
                            };
                          }

                          const total = chartData2["items"]["daily"]["netSales"][cur].reduce((acc, cur) => acc + cur.y, 0);
                          return {
                            ...acc,
                            [cur]: total
                          };
                          
           
                        }, {});

                        Object.keys(keys).forEach((d) => {
                          if (!chart[d]) chart[d] = 0;
                          if (!chart2[d]) chart2[d] = 0;
                        });

                        return <ItemsTableComparisons 
                          title="Items"
                          keysOrders={Object.keys(keys)}
                          chartData={chart} 
                          chartData2={chart2}
                          itemBrandMaps={itemBrandMaps}
                          startDate={comparisonType === "Single Store" ? `${moment(new Date(startDate)).format("MM-DD-YYYY")} - ${moment(new Date(endDate)).format("MM-DD-YYYY")}` : store1.name}
                          endDate={comparisonType === "Single Store" ? `${moment(new Date(startDate2)).format("MM-DD-YYYY")} - ${moment(new Date(endDate2)).format("MM-DD-YYYY")}` : store2.name}
                        /> ;
                      })()
                    }
                  </Card>
                </Card>
              )}
          </Flex>
          <Flex justifyContent="space-between" flexDirection="column">
            <Text fontWeight="600" fontSize={4} mt={4} mb={4}>
              Items Order Count
            </Text>
            {
              chartData &&
              chartData["itemsBrandNames"] &&
              chartData["items"] &&
              chartData["items"]["daily"] &&
              chartData["items"]["daily"]["orderCount"] &&
              chartData2 &&
              chartData["itemsBrandNames"] &&
              chartData2["items"] &&
              chartData2["items"]["daily"] &&
              chartData2["items"]["daily"]["orderCount"] && (              
                <Card sx={{height: "500px", padding: "16px", width: "100%", flexDirection: "row", display: "flex"}}>
                  <Card sx={{height: "450px",  width: "100%", overflow: "scroll" }}>
                    {
                      (() => {
                        let keys = {}; 
                        const itemBrandMaps = {};
                        
                        Object.keys(chartData["itemsBrandNames"]).forEach((d) => {
                          itemBrandMaps[d] = chartData["itemsBrandNames"][d];
                        });

                        Object.keys(chartData2["itemsBrandNames"]).forEach((d) => {
                          itemBrandMaps[d] = chartData2["itemsBrandNames"][d];
                        });
                        Object.keys(chartData["items"]["daily"]["orderCount"]).forEach((d) => keys[d] = d);
                        Object.keys(chartData2["items"]["daily"]["orderCount"]).forEach((d) => keys[d] = d);

                        const chart = Object.keys(keys).reduce((acc, cur) => {
                          if (!chartData["items"]["daily"]["orderCount"][cur]) {
                            return {
                              ...acc,
                              [cur]: 0
                            };
                          }

                          if(acc[cur]) {
                            const total = chartData["items"]["daily"]["orderCount"][cur].reduce((acc, cur) => acc + cur.y, 0) + acc[cur];
                            return {
                              ...acc,
                              [cur]: total
                            };
                          }

                          const total = chartData["items"]["daily"]["orderCount"][cur].reduce((acc, cur) => acc + cur.y, 0);
                          return {
                            ...acc,
                            [cur]: total
                          };
                        }, {});

                        const chart2 = Object.keys(keys).reduce((acc, cur) => {
                          if (!chartData2["items"]["daily"]["orderCount"][cur]) {
                            return {
                              ...acc,
                              [cur]: 0
                            };
                          }
                          
                          if(acc[cur]) {
                            const total = chartData2["items"]["daily"]["orderCount"][cur].reduce((acc, cur) => acc + cur.y, 0) + acc[cur];
                            return {
                              ...acc,
                              [cur]: total
                            };
                          }

                          const total = chartData2["items"]["daily"]["orderCount"][cur].reduce((acc, cur) => acc + cur.y, 0);
                          return {
                            ...acc,
                            [cur]: total
                          };
                          
           
                        }, {});

                        Object.keys(keys).forEach((d) => {
                          if (!chart[d]) chart[d] = 0;
                          if (!chart2[d]) chart2[d] = 0;
                        });

                        return <ItemsTableComparisons 
                          title="Items"
                          keysOrders={Object.keys(keys)}
                          chartData={chart} 
                          chartData2={chart2}
                          itemBrandMaps={itemBrandMaps}
                          startDate={comparisonType === "Single Store" ? `${moment(new Date(startDate)).format("MM-DD-YYYY")} - ${moment(new Date(endDate)).format("MM-DD-YYYY")}` : store1.name}
                          endDate={comparisonType === "Single Store" ? `${moment(new Date(startDate2)).format("MM-DD-YYYY")} - ${moment(new Date(endDate2)).format("MM-DD-YYYY")}` : store2.name}
                        /> ;
                      })()
                    }
                  </Card>
                </Card>
              )}
          </Flex>
          <Flex justifyContent="space-between" flexDirection="column"> 
            <Text fontWeight="600" fontSize={4} mt={4} mb={4}>
              AOV/Adjusted Aov/Employee Orders
            </Text>
            {
              chartData &&
              chartData.total &&
              chartData.total.testOrders >= 0 &&
              chartData.total.aov >= 0 &&
              chartData.total.testSales >= 0 && 
              chartData2 &&
              chartData2.total &&
              chartData2.total.testOrders >= 0 && 
              chartData2.total.aov >= 0 &&
              chartData2.total.testSales >= 0 &&  
              startDate &&
              endDate &&
              startDate2 &&
              endDate2 &&
                <Card sx={{height: "500px", overflow: "scroll", padding: "16px", width: "100%", flexDirection: "row", display: "flex"}}>
                  <Box width="100%" height="450px">
                    <ResponsiveBarGraph 
                      data={
                        [
                          {
                            "Employee Orders": chartData.total.testOrders || 0,
                            "AOV": chartData.total.aov || 0, 
                            "Employee Order Amount": chartData.total.testSales || 0,
                            id: comparisonType === "Single Store" ? `${moment(startDate).format("MM-DD-YYYY")} - ${moment(new Date(endDate)).format("MM-DD-YYYY")}` : store1.name
                          },
                          {
                            "Employee Orders": chartData2.total.testOrders || 0,
                            "AOV": chartData2.total.aov || 0, 
                            "Employee Order Amount": chartData2.total.testSales || 0,
                            id: comparisonType === "Single Store" ? `${moment(startDate2).format("MM-DD-YYYY")} - ${moment(new Date(endDate2)).format("MM-DD-YYYY")}` : store2.name
                          }
                        ]
                      }
                      keys={keysOrders}
                    /> 
                  </Box>
                  <Card sx={{height: "450px",  width: "100%",  overflow: "scroll", }}>
                    <ComparisonsTable 
                      title="Orders"
                      keysOrders={keysOrders}
                      chartData={chartData}
                      chartData2={chartData2}
                      keyOrdersMap={keyOrdersMap}
                      startDate={comparisonType === "Single Store" ? `${moment(new Date(startDate)).format("MM-DD-YYYY")} - ${moment(new Date(endDate)).format("MM-DD-YYYY")}` : store1.name}
                      endDate={comparisonType === "Single Store" ? `${moment(new Date(startDate2)).format("MM-DD-YYYY")} - ${moment(new Date(endDate2)).format("MM-DD-YYYY")}` : store2.name}
                    />
                  </Card>
                </Card>
            }
          </Flex>
        </Flex>
      )}
    </Box>
  );
};

export default Charts;
