import React, { useState, useEffect } from "react";
import { Box, Flex, Text, Button } from "noshery-ui";
import Dropdown from "../components/Dropdown";
import { restaurantFilterOptions } from "../constants";
import { Select, MenuItem } from "@mui/material";

const restaurants = {
  "Humphry Slocombe Ice Cream": "Humphry Slocombe Ice Cream",
  "The Pastry Cupboard": "The Pastry Cupboard",
  "Kasa": "Kasa", 
  "The Little Chihuahua": "The Little Chihuahua",
  "East Side Banh Mi": "East Side Banh Mi",
  "Bourbon Street West": "Bourbon Street West",
  "Road Food": "Road Food",
  "C&C Curry House": "C&C Curry House",
  "Oren's Hummus": "Oren's Hummus",
  "The Melt": "The Melt", 
  "Asian Box": "Asian Box",
  "Lazy Susan": "Lazy Susan",
  "Sumo Dog": "Sumo Dog",
  "Sushirrito": "Sushirrito",
  "Rooster and Rice": "Rooster and Rice",
};


const SingleUser = ({ user, setUsersClaim }) => {
  const [selectedBrand, setSelectedBrand] = useState({
    label: "",
    value: "", 
  });
  const [isByteUser, setIsByteUser] = useState(false);

  useEffect(() => {
    if (user.email && user.email.indexOf("@bytekitchen.io") > -1) {
      setIsByteUser(true);
    }

    if (user.customClaims && user.customClaims.brand) {
      console.log("set the selected brand??", user.customClaims.brand);
      setSelectedBrand({
        label: user.customClaims.brand,
        value: user.customClaims.brand,
      });
    }
  }, [user]); 

  return (
    <Flex key={user.uid}>
      <Flex flexDirection="column"  width="100%"> 
        <Button ml={4} mr={10} style={{marginRight: "5%", marginBottom: "5%"}} onClick={() => setUsersClaim(user.uid, null, true)}>
          Make Admin
        </Button>
      
        <Button ml={4} style={{marginRight: "5%", marginBottom: "5%", backgroundColor: "red"}} onClick={() => setUsersClaim(user.uid, null, false)}>
          Remove Admin
        </Button>
      </Flex>

      <Flex flexDirection="column" width="100%">
        <Button ml={4} style={{marginRight: "5%", marginBottom: "5%"}} onClick={() => setUsersClaim(user.uid, null, null, true)}>
            Make Dev
        </Button>
        <Button ml={4} style={{marginRight: "5%", backgroundColor: "red"}} onClick={() => setUsersClaim(user.uid, null, null, false)}>
            Remove Dev
        </Button>
      
      </Flex>

      <Flex flexDirection="column"  width="100%">
        <Button ml={4} style={{marginRight: "5%", marginBottom: "5%"}}  onClick={() => setUsersClaim(user.uid, null, null, null, true)}>
        Make Accounting
        </Button>
        <Button ml={4} style={{marginRight: "5%", backgroundColor: "red"}} onClick={() => setUsersClaim(user.uid, null, null, null, false)}>
        Remove Accounting
        </Button>
      </Flex> 

      <Flex flexDirection="column"  width="100%">
        <Select value={selectedBrand.value} onChange={(e) => {
          setSelectedBrand({
            label: e.target.value,
            value: e.target.value,
          });
        }}>
          {
            Object.keys(restaurants).map((i) => {
              return <MenuItem key={i} value={i}> {i} </MenuItem>;
            })
          }
        </Select>
        <Button ml={4} style={{marginRight: "5%", marginBottom: "5%"}}  onClick={() => setUsersClaim(user.uid, selectedBrand, null, null, null, null)}>
          Set Brand
        </Button>
      </Flex>

    </Flex>
  );
};

export default SingleUser;
