/* eslint-disable no-undef */ 
import  React, { useEffect } from "react"; 
import { locations } from "../utils";

const API_URL = process.env.REACT_APP_API_URL;

export const useBbotCustomer = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [customers, setCustomers] = React.useState([]);
  const [averages, setAverages] = React.useState({});
  const [countCategories, setCountCategories] = React.useState([]);
  const [countSalesCategories, setCountSalesCategories] = React.useState([]);
  const [totals, setTotals] = React.useState({});
  const [retention, setRetention] = React.useState([]);
  const [firstBrandSplit, setFirstBrandSplit] = React.useState({});
  const [brandMix, setBrandMix] = React.useState({});
  const [serverError, setServerError] = React.useState(null);
  const [storeId, setStoreId] = React.useState("All Stores");

  const fetchCustomers = async () => {
    setIsLoading(true);
    try {
      const res = await fetch(`${API_URL}/bbotCustomerMetrics`, {
        method: "POST",
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({storeId: locations[storeId]})
      });
      const data = await res.json();
      setCustomers(data.data);
      setAverages(data.averages);
      setCountCategories(data.countCategories);
      setCountSalesCategories(data.countSalesCategories);
      setRetention(data.customersRetention);
      setTotals(data.totals);
      setBrandMix(data.brandMixCount);
      setFirstBrandSplit(data.firstOrderBrandName);   
      setIsLoading(false);

      return data;
    } catch (error) {
      setServerError(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchCustomers();
  }, [storeId]);

  return { 
    isLoading, 
    customers, 
    averages,
    countCategories,
    countSalesCategories,
    retention,
    totals,
    firstBrandSplit,
    brandMix,
    serverError,
    setStoreId,
    fetchCustomers, 
  };

};