import { useEffect, useState, useRef } from "react";
import moment from "moment";
import Cookies from "js-cookie";

const API_URL = process.env.REACT_APP_API_URL;

const dateRangeToChartChoosen = {
  today: "Hourly",
  yesterday: "Hourly",
  thisWeek: "Daily",
  lastWeek: "Daily",
  thisMonth: "Daily",
  lastMonth: "Daily",
  thisYear: "Weekly",
  allTime: "Weekly",
  periods: "period",
  custom: "Daily",
};
let periods = {
  period1: { startDate: "01-03", endDate: "01-30", year: "2022" },
  period2: { startDate: "01-31", endDate: "02-27", year: "2022" },
  period3: { startDate: "02-28", endDate: "04-03", year: "2022" },
  period4: { startDate: "04-04", endDate: "05-01", year: "2022" },
  period5: { startDate: "05-02", endDate: "05-29", year: "2022" },
  period6: { startDate: "05-30", endDate: "07-03", year: "2022" },
  period7: { startDate: "07-04", endDate: "07-31", year: "2022" },
  period8: { startDate: "08-01", endDate: "08-28", year: "2022" },
  period9: { startDate: "08-29", endDate: "10-02", year: "2022" },
  period10: { startDate: "10-03", endDate: "10-30", year: "2022" },
  period11: { startDate: "10-31", endDate: "11-27", year: "2022" },
  period12: { startDate: "11-28", endDate: "01-01", year: "2022-2023" },

  period13: { startDate: "01-02", endDate: "01-29", year: "2023" },
  period14: { startDate: "01-30", endDate: "02-26", year: "2023" },
  period15: { startDate: "02-27", endDate: "04-02", year: "2023" },
  period16: { startDate: "04-03", endDate: "04-30", year: "2023" },
  period17: { startDate: "05-01", endDate: "05-28", year: "2023" },
  period18: { startDate: "05-29", endDate: "07-02", year: "2023" },
  period19: { startDate: "07-03", endDate: "07-30", year: "2023" },
  period20: { startDate: "07-31", endDate: "08-27", year: "2023" },
  period21: { startDate: "08-28", endDate: "10-01", year: "2023" },
  period22: { startDate: "10-02", endDate: "10-29", year: "2023" },
  period23: { startDate: "10-30", endDate: "11-26", year: "2023" },
  period24: { startDate: "11-27", endDate: "12-31", year: "2023-2024" },

  period25: { startDate: "01-01", endDate: "01-28", year: "2024" },
  period26: { startDate: "01-29", endDate: "02-25", year: "2024" },
  period27: { startDate: "02-26", endDate: "03-31", year: "2024" },
  period28: { startDate: "04-01", endDate: "04-28", year: "2024" },
  period29: { startDate: "04-29", endDate: "05-26", year: "2024" },
  period30: { startDate: "05-27", endDate: "06-30", year: "2024" },
  period31: { startDate: "07-01", endDate: "07-28", year: "2024" },
  period32: { startDate: "07-29", endDate: "08-25", year: "2024" },
  period33: { startDate: "08-26", endDate: "09-29", year: "2024" },
  period34: { startDate: "09-30", endDate: "10-27", year: "2024" },
  period35: { startDate: "10-28", endDate: "11-24", year: "2024" },
  period36: { startDate: "11-25", endDate: "12-29", year: "2024" }, 
};

const getInitialDate = (type = "start") => {
  const time = type === "start" ? "11:00" : "20:00";
  return moment(moment(new Date()).format("YYYY/MM/DD") + " " + time);
};

const getAccessToken = () => Cookies.get("accessToken");

export default function useCharts() {
  const abortController = useRef();

  const [endDate, setEndDate] = useState(getInitialDate("end"));
  const [startDate, setStartDate] = useState(getInitialDate("start"));
  const [loading, setLoading] = useState(false);
  const [chartData, setChartData] = useState({});
  const [chartChosen, setChartChosen] = useState("Hourly");
  const [dateRange, setDateRange] = useState("today");
  const [location, setLocation] = useState("All");
  const [brand, setBrand] = useState("The Melt");

  useEffect(() => {
    fetchData();
  }, [startDate, endDate, location, brand]);

  const fetchData = async () => {
    if (abortController.current) {
      abortController.current.abort();
    }
    abortController.current = new AbortController();
    const { signal } = abortController.current;

    setLoading(true);
    const res = await fetch(
      `${API_URL}/chartDataByBrand?startDate=${moment(startDate).format(
        "YYYY-MM-DD"
      )}&endDate=${moment(endDate).format(
        "YYYY-MM-DD"
      )}&storeId=${location}&brand=${brand}`,
      {
        method: "GET",
        mode: "cors",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${getAccessToken()}`,
        },
        signal: signal,
      }
    );
    let result = await res.json();
    setChartData(result);
    console.log(
      "dateRangeToChartChoosen[dateRange]",
      dateRangeToChartChoosen[dateRange],
      dateRange
    );
    if (dateRange.includes("period")) {
      setChartChosen("Daily");
    } else {
      setChartChosen(dateRangeToChartChoosen[dateRange]);
    }
    setLoading(false);
  };

  useEffect(() => {
    console.log("dateRange here", dateRange);
    if (dateRange === "custom") {
      return;
    }
    if (dateRange === "today") {
      setStartDate(moment(new Date()).format("YYYY-MM-DD"));
      setEndDate(moment(new Date()).format("YYYY-MM-DD"));
    } else if (dateRange === "yesterday") {
      setStartDate(moment(new Date()).subtract(1, "days").format("YYYY-MM-DD"));
      setEndDate(moment(new Date()).subtract(1, "days").format("YYYY-MM-DD"));
    } else if (dateRange === "thisWeek") {
      let date = moment(new Date());
      let dayNumber = date.day() === 0 ? 6 : date.day() - 1;
      setStartDate(
        moment(new Date()).subtract(dayNumber, "days").format("YYYY-MM-DD")
      );
      setEndDate(moment(new Date()).format("YYYY-MM-DD"));
    } else if (dateRange === "lastWeek") {
      let date = moment(new Date());
      let dayNumber = date.day() === 0 ? 7 : date.day();
      setEndDate(
        moment(new Date()).subtract(dayNumber, "days").format("YYYY-MM-DD")
      );
      setStartDate(
        moment(new Date())
          .subtract(dayNumber + 6, "days")
          .format("YYYY-MM-DD")
      );
    } else if (dateRange === "thisMonth") {
      let date = moment(new Date());
      setEndDate(date.format("YYYY-MM-DD"));
      setStartDate(
        moment(new Date())
          .subtract(date.date() - 1, "days")
          .format("YYYY-MM-DD")
      );
    } else if (dateRange === "lastMonth") {
      let date = moment(new Date());
      let monthEndingDate = moment(new Date()).subtract(date.date(), "days");
      let monthStartingDate = moment(new Date()).subtract(
        monthEndingDate.date() + date.date() - 1,
        "days"
      );
      setEndDate(monthEndingDate.format("YYYY-MM-DD"));
      setStartDate(monthStartingDate.format("YYYY-MM-DD"));
    } else if (dateRange === "thisYear") {
      let year = moment(new Date()).year();
      setEndDate(moment(new Date()).format("YYYY-MM-DD"));
      setStartDate(moment(`${year}-01-01`, "YYYY-MM-DD").format("YYYY-MM-DD"));
    } else if (dateRange === "allTime") {
      setEndDate(moment(new Date()).format("YYYY-MM-DD"));
      setStartDate(moment("2021-11-16", "YYYY-MM-DD").format("YYYY-MM-DD"));
    } else if (dateRange.includes("period")) {
      const { startDate, endDate, year } = periods[dateRange];
      let startYear = year,
        endYear = year;
      if (year.includes("-")) {
        startYear = year.split("-")[0];
        endYear = year.split("-")[1];
      }
      setStartDate(moment(`${startYear}-${startDate}`).format("YYYY-MM-DD"));
      setEndDate(moment(`${endYear}-${endDate}`).format("YYYY-MM-DD"));
    }
  }, [dateRange]);

  const onSelectStartDate = (date) => {
    return setStartDate(date || null);
  };

  const onSelectEndDate = (date) => {
    return setEndDate(date || null);
  };

  return {
    dateRange,
    setDateRange,
    chartChosen,
    setChartChosen,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    chartData,
    fetchData,
    loading,
    onSelectStartDate,
    onSelectEndDate,
    setLocation,
    setBrand,
    brand,
    setLoading
  };
}
