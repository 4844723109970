import React, { useState } from "react";
import moment from "moment";
import { Box, Flex, Text, DatePicker, Button, Spinner } from "noshery-ui";
import Dropdown from "../../components/Dropdown";
import OrdersList from "../../components/OrdersList";
import useOrders from "../../hooks/useOrders";
import { sourceFilterOptions, restaurantFilterOptions } from "../../constants";
import { useLocationOrders } from "../../hooks/useLocation";
import { CSVLink } from "react-csv"; 
import MuiButton from "@mui/material/Button"; 

const convertArrayOfObjectToCSV = (arr) => {
  if (!arr || !arr.length) {
    return null;
  }
  let str = Object.keys(arr[0]).join(",") + "\n";
  str = str + arr.map((a) => Object.values(a).join(",")).join("\n");
  return str;
};

const API_URL = process.env.REACT_APP_API_URL;

function filterOrders(orders, selectedFilter, restaurantFilter, locationFilter) {
  let filteredOrders = orders;
  if (selectedFilter && restaurantFilter) {
    filteredOrders = orders.filter(
      o =>
        o.source === selectedFilter.value &&
        o.selections[0] &&
        o.selections[0].restaurantName === restaurantFilter.value
    );
  } else if (selectedFilter) {
    filteredOrders = orders.filter(o => o.source === selectedFilter.value);
  } else if (restaurantFilter) {
    filteredOrders = orders.filter(
      o => o.selections[0].restaurantName === restaurantFilter.value
    );
  } else if (locationFilter) {
    if(locationFilter.value === "All") return filteredOrders;
    filteredOrders = orders.filter(o => o.storeId === locationFilter.value);
  }
  return filteredOrders;
}

const Orders = () => {
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [restaurantFilter, setRestaurantFilter] = useState(null);
  const [seederLoading, setSeederLoading] = useState(false);
  const [locationFilter, setLocationFilter] = useState(null);
  const {
    orders,
    getOrders,
    loading,
    startDate,
    endDate,
    onSelectStartDate,
    onSelectEndDate,
  } = useOrders();
  console.log("{ sourceFilterOptions, restaurantFilterOptions }", {
    sourceFilterOptions,
    restaurantFilterOptions,
  });

  const {locations: locationsOption} = useLocationOrders();

  React.useEffect(() => {
    if (Object.values(locationsOption).length > 0) {
      setLocationFilter(Object.values(locationsOption)[0]);
    }
  }, [locationsOption]);

  let _orders = filterOrders(orders, selectedFilter, restaurantFilter, locationFilter);

  let grossSales = 0;
  let netSales = 0;
  let totalTaxes = 0;
  let totalTips = 0;
  // let popMenuCharges = 0;

  _orders.forEach(order => {
    const { amount, totalTax, totalTip, isPopMenu, totalAmount } = order;
    netSales += amount;
    if (isPopMenu) {
      netSales -= 1;
      // popMenuCharges += 1;
      totalTaxes += 0.09;
    }
    totalTaxes += totalTax;
    totalTips += totalTip;
    grossSales += totalAmount;
  });

  const headerData = [grossSales, netSales, totalTaxes, totalTips];

  const seedMenuData = async () => {
    setSeederLoading(true);
    const res = await fetch(`${API_URL}/menuSeeder`);
    const { error } = await res.json();
    if (error) {
      console.error("Error happened while updating the menu");
    }
    setSeederLoading(false);
  };

  return (
    <Box paddingTop="120px">
      <Flex px="130px" mt={3} flexWrap={"wrap"}>
        <Box mr={3} mb={2}>
          <Text mr={2}>Start Date</Text>
          <DatePicker
            showTimeSelect
            minDate={moment(new Date("2021-11-12"))}
            maxDate={moment().add(5)}
            selectedDate={startDate}
            onChooseDate={onSelectStartDate}
            dateFormat="YYYY-MM-D HH:mm"
          />
        </Box>
        <Box mr={3} mb={2}>
          <Text mr={2}>End Date</Text>
          <DatePicker
            showTimeSelect
            minDate={startDate}
            maxDate={moment().add(5)}
            selectedDate={endDate}
            onChooseDate={onSelectEndDate}
            dateFormat="YYYY-MM-D HH:mm"
          />
        </Box>
        <Box mr={3} mb={2}>
          <Text mr={2}>Source </Text>
          <Box width={"320px"}>
            <Dropdown
              options={sourceFilterOptions}
              selected={selectedFilter}
              onSelect={setSelectedFilter}
              isClearable
              isSearchable
            />
          </Box>
        </Box>
        <Box mr={3} mb={2}>
          <Text mr={2}>Restaurant</Text>
          <Box width="320px">
            <Dropdown
              options={restaurantFilterOptions}
              selected={restaurantFilter}
              onSelect={setRestaurantFilter}
              isClearable
              isSearchable
            />
          </Box>
        </Box>
        <Box mr={3} mb={2}>
          <Text mr={2}>Location</Text>
          <Box width="320px">
            <Dropdown
              options={locationsOption}
              selected={locationFilter}
              onSelect={setLocationFilter}
              isClearable
              isSearchable
            />
          </Box>
        </Box>
      </Flex>

      {startDate && endDate && (
        <React.Fragment>
          <Flex px="130px" my={3}>
            <Button style={{marginRight: 50}} onClick={getOrders}>Click to see Orders</Button>
            <CSVLink style={{ alignSelf: "end", display: "flex", width: "100vw !important", alignContent: "center", justifyContent: "end", alignItems: "center"}}
              data={_orders}
              filename={`orders-${moment().format("YYYY-MM-DD:HH:MM")}.csv`}
            >
              <Button disabled={(loading)}> Download Data  </Button>
            </CSVLink>
          </Flex>
          <Flex
            border="1px solid #ececec"
            borderRadius={4}
            px="130px"
            py={3}
            my={2}
          >
            <Box pr={3}>
              <Text>
                Total {selectedFilter && selectedFilter.label} Orders:{" "}
                {_orders.length}
              </Text>
            </Box>
            {headerData.map((data, i) => (
              <DataHeader
                data={data}
                key={i}
                ordersLength={_orders.length}
                type={String(data)}
              />
            ))}
            <Box pr={3}>
              <Text>
                Total Days: {moment.duration(endDate.diff(startDate)).asDays()}
              </Text>
            </Box>
          </Flex>
        </React.Fragment>
      )}
      {!loading ? (
        <Box px="120px">
          <OrdersList orders={_orders} />
        </Box>
      ) : (
        <Flex
          my={3}
          justifyContent="center"
          height="35vh"
          alignItems="flex-end"
        >
          <Spinner variant="large" color="orange" />
        </Flex>
      )}
    </Box>
  );
};

function DataHeader({ data, ordersLength, type }) {
  let title;
  if (type === "taxes") {
    title = "Total taxes";
  } else if (type === "tips") {
    title = "Total tips";
  } else if (type === "grossSales") {
    title = "Gross sales";
  } else if (type === "netSales") {
    title = "Net sales";
  }
  return (
    <Box pr={3}>
      <Text>
        {title} $
        {data.toLocaleString(undefined, {
          minimumFractionDigits: 0,
        })}{" "}
        (avg. $
        {data &&
          ordersLength &&
          (data / ordersLength > 0 ? data / ordersLength : 0).toFixed(2)}
        )
      </Text>
    </Box>
  );
}

export default Orders;
