import React, { useEffect } from "react";
import { Box, Flex, Text, Button } from "noshery-ui";
import styled from "styled-components";
import { sortData } from "../../utils/sort";
import moment from "moment";
import { CSVLink } from "react-csv";

const StyledCard = styled(Flex)`
  flex-direction: ${props =>
    props.flexDirection ? props.flexDirection : "row"}
  border-radius: 10px;
  box-shadow: 0 0 3px 0 rgb(0, 0, 0, 0.2);
  margin-bottom: 20px;
  ${props =>
    props.fullwidth
      ? "width: auto; justify-content: space-around;"
      : "width: 100% !important;"}
  background-color: white;
  max-height: 800px;
  margin-right: 20px;
`;

const SpacedBox = styled(Box)`
  display: grid;
  height: 100% !important;
  grid-template-columns: 14% 14% 14% 14% 14% 14% 14%;
  text-align: center;
  font-weight: ${p => (p.isTitle ? "bolder" : "normal")};
  padding: 10px;
  border-bottom: ${p => (p.isTitle ? "1px solid black" : "0px")};
  font-size: ${p => (p.isTitle ? "20px" : "15px")};
`;

const getTotals = data => {
  let total = 0;
  data.forEach(element => {
    total = element["y"] + total;
  });
  return total;
};

const getArrayDataWithMods = (data, sortType, globalData, revenue, split) => {
  if (!data) return;
  let obj = {};
  Object.keys(data["netSales"]).forEach(menuItem => {
    if(menuItem.includes("Noshery") && split) return; 
    if(!menuItem.includes("Noshery") && !split) return; 
    if(obj[menuItem.split("|&|")[0]]) {
      obj[menuItem.split("|&|")[0]].netSales += getTotals(data["netSales"][menuItem]);
      obj[menuItem.split("|&|")[0]].orderCount += getTotals(data["orderCount"][menuItem]);
      obj[menuItem.split("|&|")[0]].discount += getTotals(globalData["discounts"]["daily"][menuItem]);
      obj[menuItem.split("|&|")[0]].grossSales += getTotals(globalData["grossSales"]["daily"][menuItem]);
      obj[menuItem.split("|&|")[0]].avgPrice = obj[menuItem.split("|&|")[0]].netSales / obj[menuItem.split("|&|")[0]].orderCount;
      obj[menuItem.split("|&|")[0]].share = (obj[menuItem.split("|&|")[0]].netSales * revenue);
    }
    else {
      obj[menuItem.split("|&|")[0]] = {};
      obj[menuItem.split("|&|")[0]].name = menuItem.split("|&|")[0];  
      obj[menuItem.split("|&|")[0]].reference = menuItem;
      obj[menuItem.split("|&|")[0]].netSales = getTotals(data["netSales"][menuItem]);
      obj[menuItem.split("|&|")[0]].orderCount = getTotals(data["orderCount"][menuItem]);
      obj[menuItem.split("|&|")[0]].discount = getTotals(globalData["discounts"]["daily"][menuItem]);
      obj[menuItem.split("|&|")[0]].grossSales = getTotals(globalData["grossSales"]["daily"][menuItem]);
      obj[menuItem.split("|&|")[0]].avgPrice = obj[menuItem.split("|&|")[0]].netSales / obj[menuItem.split("|&|")[0]].orderCount;
      obj[menuItem.split("|&|")[0]].share = (obj[menuItem.split("|&|")[0]].netSales * revenue);
    }
  });
  let items = Object.keys(obj).map((key) => obj[key]);
  let res = sortData(items, sortType);

  let withMods = res.map((item) => {
    const { reference: menuItem } = item;
    let temp = [];
    temp.push(item);
    globalData["modifiers"]["daily"][menuItem] ? Object.keys(globalData["modifiers"]["daily"][menuItem]).map((modName) => {
      let modObj = {};
      modObj.name = menuItem.split("|&|")[0];
      modObj.modifier = modName;
      modObj.orderCount = Object.keys(globalData["modifiers"]["daily"][menuItem][modName]).reduce((acc, cur) => acc += globalData["modifiers"]["daily"][menuItem][modName][cur], 0);
      modObj.netSales = globalData["modifiersPrice"]["daily"][menuItem][modName][Object.keys(globalData["modifiersPrice"]["daily"][menuItem][modName])[0]] * modObj.orderCount;
      temp.push(modObj);
    }) : "";
    return temp;
  });

  return withMods.flat();
};

const getArrayDataWithModsAll = (data, sortType, globalData, revenue) => {
  if (!data) return;
  let obj = {};
  Object.keys(data["netSales"]).forEach(menuItem => {
    if(obj[menuItem.split("|&|")[0]]) {
      obj[menuItem.split("|&|")[0]].netSales += getTotals(data["netSales"][menuItem]);
      obj[menuItem.split("|&|")[0]].orderCount += getTotals(data["orderCount"][menuItem]);
      obj[menuItem.split("|&|")[0]].discount += getTotals(globalData["discounts"]["daily"][menuItem]);
      obj[menuItem.split("|&|")[0]].grossSales += getTotals(globalData["grossSales"]["daily"][menuItem]);
      obj[menuItem.split("|&|")[0]].avgPrice = obj[menuItem.split("|&|")[0]].netSales / obj[menuItem.split("|&|")[0]].orderCount;
      obj[menuItem.split("|&|")[0]].share = (obj[menuItem.split("|&|")[0]].netSales * revenue);
    }
    else {
      obj[menuItem.split("|&|")[0]] = {};
      obj[menuItem.split("|&|")[0]].name = menuItem.split("|&|")[0];  
      obj[menuItem.split("|&|")[0]].reference = menuItem;
      obj[menuItem.split("|&|")[0]].netSales = getTotals(data["netSales"][menuItem]);
      obj[menuItem.split("|&|")[0]].orderCount = getTotals(data["orderCount"][menuItem]);
      obj[menuItem.split("|&|")[0]].discount = getTotals(globalData["discounts"]["daily"][menuItem]);
      obj[menuItem.split("|&|")[0]].grossSales = getTotals(globalData["grossSales"]["daily"][menuItem]);
      obj[menuItem.split("|&|")[0]].avgPrice = obj[menuItem.split("|&|")[0]].netSales / obj[menuItem.split("|&|")[0]].orderCount;
      obj[menuItem.split("|&|")[0]].share = (obj[menuItem.split("|&|")[0]].netSales * revenue);
    }
  });
  let items = Object.keys(obj).map((key) => obj[key]);
  let res = sortData(items, sortType);

  let withMods = res.map((item) => {
    const { reference: menuItem } = item;
    let temp = [];
    temp.push(item);
    globalData["modifiers"]["daily"][menuItem] ? Object.keys(globalData["modifiers"]["daily"][menuItem]).map((modName) => {
      let modObj = {};
      modObj.name = menuItem.split("|&|")[0];
      modObj.modifier = modName;
      modObj.orderCount = Object.keys(globalData["modifiers"]["daily"][menuItem][modName]).reduce((acc, cur) => acc += globalData["modifiers"]["daily"][menuItem][modName][cur], 0);
      modObj.netSales = globalData["modifiersPrice"]["daily"][menuItem][modName][Object.keys(globalData["modifiersPrice"]["daily"][menuItem][modName])[0]] * modObj.orderCount;
      temp.push(modObj);
    }) : "";
    return temp;
  });

  return withMods.flat();
};


const getArrayData = (data, sortType, globalData, revenue) => {
  if (!data) return;
  let items = [];
  Object.keys(data["netSales"]).forEach(menuItem => {
    let obj = {};
    obj.name = menuItem;
    obj.netSales = getTotals(data["netSales"][menuItem]);
    obj.orderCount = getTotals(data["orderCount"][menuItem]);
    obj.discount = getTotals(globalData["discounts"]["daily"][menuItem]);
    obj.grossSales = getTotals(globalData["grossSales"]["daily"][menuItem]);
    obj.avgPrice = obj.netSales / obj.orderCount;
    obj.share = (obj.netSales * revenue);
    items.push(obj);
  });

  let res = sortData(items, sortType);

  return res;
};

const getTotalsItems = (data, globalData, revenue) => {
  let obj = {
    name: "Total",
    netSales: 0,
    orderCount: 0,
    discount: 0,
    grossSales: 0,
    avgPrice: 0,
    share: 0
  };
  Object.keys(data["netSales"]).forEach(menuItem => {
    obj.netSales += getTotals(data["netSales"][menuItem]);
    obj.orderCount += getTotals(data["orderCount"][menuItem]);
    obj.discount += getTotals(globalData["discounts"]["daily"][menuItem]);
    obj.grossSales += getTotals(globalData["grossSales"]["daily"][menuItem]);
    obj.avgPrice += obj.netSales / obj.orderCount;
    obj.share = (obj.netSales * revenue);
  });
  return obj;
};

const BestSellerTable = ({ data, revenue, globalData }) => {
  const [sortBy, setSortBy] = React.useState("netSales");

  let itemArray = getArrayData(data, sortBy, globalData, revenue);

  let itemWithModsArrayNoshery = globalData ? getArrayDataWithMods(data, sortBy, globalData, revenue, false) : "";

  let itemWithModsArrayStorefront = globalData ? getArrayDataWithMods(data, sortBy, globalData, revenue, true) : "";

  let itemsWithModsArray = globalData ? getArrayDataWithModsAll(data, sortBy, globalData, revenue, true) : "";

  let totalsItem = getTotalsItems(data, globalData, revenue);

  useEffect(() => {}, []);

  const header = {
    fontSize: "10px",
  };

  let headersMods = [
    { label: "Item Name", key: "name" },
    { label: "Modifier", key: "modifier" },
    { label: "Avg Price", key: "avgPrice" },
    { label: "Quantity", key: "orderCount" },
    { label: "Gross Sales", key: "grossSales" },
    { label: "Discount", key: "discount" },
    { label: "Net Sales", key: "netSales" },
    { label: "Share", key: "share" },
  ];

  let headers = [
    { label: "Item Name", key: "name" },
    { label: "Avg Price", key: "avgPrice" },
    { label: "Quantity", key: "orderCount" },
    { label: "Gross Sales", key: "grossSales" },
    { label: "Discount", key: "discount" },
    { label: "Net Sales", key: "netSales" },
    { label: "Share", key: "share" },
  ];

  let headers2 = [
    { label: "Item Name", key: "name" },
    { label: "Modifier", key: "modifier" },
    { label: "Avg Price", key: "avgPrice" },
    { label: "Quantity", key: "orderCount" },
    { label: "Gross Sales", key: "grossSales" },
    { label: "Discount", key: "discount" },
    { label: "Net Sales", key: "netSales" },
  ];

  return (
    <Flex py={5} height="100%"width="100%" flexDirection="column" bg="#f2f2f2">
      <Flex justifyContent="flex-end" mb={3}>
        <CSVLink
          headers={headers}
          data={[...itemArray, totalsItem]}
          filename={`brand-totals-${moment().format("YYYY-MM-DD:HH:MM")}.csv`}
        >
          <Button style={{width: "85%", fontSize: "70%"}}>Download table</Button>
        </CSVLink>
        <CSVLink
          headers={headers2}
          data={[...itemsWithModsArray, totalsItem]}
          filename={`brand-totals-${moment().format("YYYY-MM-DD:HH:MM")}.csv`}
        >
          <Button style={{width: "85%", fontSize: "70%"}}>Download table with mods</Button>
        </CSVLink>

        <CSVLink
          headers={headersMods}
          data={[{name: "Noshery Storefront"}, ...itemWithModsArrayNoshery, {name: "Brand Storefront"}, ...itemWithModsArrayStorefront, totalsItem]}
          filename={`brand-totals-${moment().format("YYYY-MM-DD:HH:MM")}.csv`}
        >
          <Button style={{width: "85%", fontSize: "70%"}}>Download storefront split table with mods </Button>
        </CSVLink>
      </Flex>
      <StyledCard flexDirection="column">
        <SpacedBox isTitle={1}>
          <Text style={{...header}}> Item </Text>
          <Text style={{...header}}> Avg Price </Text>
          <Text
            style={{ cursor: "pointer", ...header }}
            onClick={() => setSortBy("orderCount")}
          >
            {" "}
            No of items{" "}
          </Text>
          <Text
            style={{ cursor: "pointer", ...header }}
            onClick={() => setSortBy("netSales")}
          >
            {" "}
            Net Sales{" "}
          </Text>
          
          <Text             
            style={{ cursor: "pointer", ...header }}
            onClick={() => setSortBy("discount")}
          > Discount </Text>
          
          <Text
            style={{ cursor: "pointer", ...header }}
            onClick={() => setSortBy("grossSales")}
          > GrossSales </Text>
          
          <Text style={{...header}}> Share </Text>
        </SpacedBox>
        <div style={{ maxHeight: "360px", overflowX: "scroll" }}>
          {data &&
            data["netSales"] &&
            itemArray &&
            itemArray.map((menuItem, i) => {
              return (
                <MenuItem
                  key={`#bestSeller${menuItem}${i}`}
                  i={i}
                  menuItem={menuItem}
                />
              );
            })}
          <MenuItem
            i={itemArray.length + 1}
            menuItem={totalsItem}
            isTotal={true}
            revenue={revenue}
          />
        </div>
      </StyledCard>
    </Flex>
  );
};

const MenuItem = ({ menuItem, i, isTotal }) => {
  const header = {
    fontSize: "12px",
  };
  return (
    <SpacedBox id={menuItem} style={{ borderBottom: "1px solid", backgroundColor: isTotal ? "#F1A638" : "none" }} isTitle={0}>
      <Text style={{ paddingRight: "5px", textAlign: "left", ...header }}>
        {isTotal ? "" : `${i + 1}.`} {menuItem["name"]}{" "}
      </Text>
      <Text style={{...header}}>
        {" "}
        ${(menuItem["avgPrice"]).toFixed(2)}{" "}
      </Text>
      <Text style={{...header}}> #{menuItem["orderCount"]} </Text>
      <Text style={{...header}}> ${menuItem["netSales"].toFixed(2)} </Text>
      <Text style={{...header}}> ${(menuItem["discount"]).toFixed(2)} </Text>
      <Text style={{...header}}> ${menuItem["grossSales"].toFixed(2)} </Text>
      {isTotal && <Text style={{...header}}> ${(menuItem["share"]).toFixed(2)} </Text>} 
    </SpacedBox>
  );
};

export default BestSellerTable;
