import React, { useCallback } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Box } from "noshery-ui";
import useDailySales from "../../hooks/useDailySales";
import PageLoader from "../../components/PageLoader";
import styled from "styled-components";

const StyledSelect = styled.select`
  border-radius: 10px;
  box-shadow: 0 0 3px 0 rgb(0, 0, 0, 0.2);
  border: none;
  background-color: white;
  font-size: ${props => (props.fontSize ? props.fontSize : "20px")}
  ${props =>
    props.position
      ? "position: absolute; right: 8px; top: 8px; z-index: 1;"
      : ""}
  padding: 12px;
  font-weight: 600;
  &:hover,
  &:focus {
    cursor: pointer;
  }
  :focus-visible {
    outline: solid orange 2px;
  }
`;

const locationsOption = {
  "All Stores": "All",
  "San Mateo": "93895000000000000",
  "San Carlos": "114032000000000000",
};

const localizer = momentLocalizer(moment);

const SalesCalendar = () => {
  const { loading, data, setLocation } = useDailySales();
  const [dailyData, setDailyData] = React.useState([]);
  // console.log("data", );
  console.log("data", data);

  React.useEffect(() => {
    if(!data["daily"]) return;
    let mobileData = (data.daily).map((item) => {
      item.title = item.title.split(":")[1];
      return item;  
    });
    setDailyData(mobileData);
  }, [data]);
  return (
    <Box py={4} flexDirection="column" bg="#f2f2f2">
      <Box paddingBottom="10px">
        <StyledSelect
          onChange={e => setLocation(locationsOption[e.target.value])}
        >
          {Object.keys(locationsOption).map(d => (
            <option value={d} key={d}>
              {d}
            </option>
          ))}
        </StyledSelect>
      </Box>
      {loading ? <PageLoader /> : data && <CalendarView events={dailyData} />}
    </Box>
  );
};

const CalendarView = ({ events }) => {
  const eventPropGetter = useCallback(
    event => ({
      ...{
        style: {
          backgroundColor: "rgb(0,0,0,0)",
          color: event.title.includes("$") ? "blue" : "red",
          borderRadius: "1px",
          textDecoration: "underline",
          padding: "10px",
          fontWeight: "bold",
          fontSize: "8px",
        },
      },
    }),
    []
  );
  return (
    <div>
      <Calendar
        localizer={localizer}
        eventPropGetter={eventPropGetter}
        step={60}
        messages={{ today: "Today", previous: "Previous", next: "Next" }}
        culture="fr"
        startAccessor="start"
        events={events}
        defaultDate={new Date()}
        views={["month"]}
        endAccessor="end"
        style={{ height: 500, width: "100%" }}
      />
    </div>
  );
};
export default SalesCalendar;
