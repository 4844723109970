import React from "react";
import { Box, Flex, Text } from "noshery-ui";
import moment from "moment";

const SingleOrder = ({
  mixedOrder,
  items,
  selections,
  guid,
  totalAmount,
  restaurantName,
  openedDate,
  closedDate,
  isKiosk,
  isUbereats,
  isDoordash,
  isGrubhub,
  isPopmenu,
  isGoogle,
  isPostmates,
  index,
  source,
  amount
}) => {
  const getSource = () =>
    (isKiosk && "Kiosk") ||
    (isDoordash && "Doordash") ||
    (isGrubhub && "Grubhub") ||
    (isUbereats && "Ubereats") ||
    (isPopmenu && "Popmenu") ||
    (isGoogle && "Google") ||
    (isPostmates && "Postmates") ||
    source ||
    "Empty";
 
  const getDate = date => {
    const utcDate = moment.utc(date).toDate();
    return moment(utcDate).local().format("lll");
  };
  return (
    <Flex
      px={2}
      py={3}
      borderBottom="1px solid #ececec"
      borderRadius={4}
      my={2}
      width="100%"
    >
      <Box width={1.1 / 15}>
        <Text> {index+1} {restaurantName}</Text>
      </Box>
      <Box width={1.5 / 15}>
        <Text>{getSource()}</Text>
      </Box>
      <Box width={2 / 15}>
        <Text>{getDate(openedDate)}</Text>
      </Box>
      <Box width={2 / 15}>
        <Text>{getDate(closedDate)}</Text>
      </Box>
      <Box width={1 / 15}>
        <Text>{mixedOrder ? "Yes" : "No"}</Text>
      </Box>
      <Box width={1 / 15}>
        <Text>${amount}</Text>
      </Box>
      <Box width={7 / 15}>
        <Items items={selections} />
      </Box>
      <Box width={7 / 15}>
        <Text>{guid}</Text>
      </Box>

    </Flex>
  );
};

export default SingleOrder;

const Items = ({ items }) => {
  return (
    <Flex style={{flexDirection: "column", marginLeft: "10%"}}>
      {items &&
        (items).map((item, index) => { 
          console.log(item);
          return (
            <Flex key={index} style={{flexDirection: "column"}} >
              <Text mr={3} fontSize={2} fontWeight={500} color="orange">
                {item.brandName}
              </Text>
              <Flex alignItems="start" key={index}>
                <Text>
                  {item.quantity} x {item.displayName} = ${item.price}
                </Text>
              </Flex>
            </Flex>
          );
        })}
      <Box mb={3}></Box>
    </Flex>
  );
};
