import React, { useEffect } from "react";
import { Box, Flex, Text, Button } from "noshery-ui";
import styled from "styled-components";
import { sortData } from "../utils/sort";
import { CSVLink } from "react-csv";

const itemsBrandsSorting = {
  "Oren's Hummus": {
    "Hummus Beets": 1,	
    "Hummus Chicken": 2,	
    "Hummus Classic": 3,	
    "Hummus Eggplant": 4,	
    "Hummus with Moroccan Beef": 5,	
    "Hummus  with Moroccan Beef": 5,	
    "Hummus Moroccan Beef": 5,	
    "Beef Kebab Rice Bowl": 6,	
    "Orens Beef Kebab Rice Bowl": 6,
    "Orens Chicken Rice Bowl": 7,	
    "Chicken Rice Bowl": 7,	
    "Pita Beef Kebab": 8,	
    "Pita Chicken": 9,	
    "Pita Falafel": 10,
    "Beef Kebab a la carte": 11,
    "Beef Kebab Skewer": 12,
    "Chicken Skewer": 13,
    "Chicken Skewer a la carte": 14,
    "Babaganoush Eggplant | 8oz (Dips & Sides)": 15,
    "Chunky Eggplant | 8oz (Dips & Sides)": 16,
    "Classic Hummus | 8oz (Dips & Sides)": 17,
    "Small Hummus | 8oz (Dips & Sides)": 17,
    "Marinated Beets | 8oz (Dips & Sides)": 18,
    "Marinated Bees | 8oz (Dips & Sides)": 18,
    "Marinated Beets | 8oz (Dips & sides)": 18,
    "Marinated Beets | 8oz (Dips and sides)": 18,
    "Labane | 8oz (Dips & Sauces)": 19,
    "Labane | 8oz (Dips & Sides)": 19,
    "French Fries with Ketchup": 20,
    "Side of Falafel (5 pieces)": 21,
    "Green Herb Falafel (5 pieces)": 21,    
    "Sweet potato fries with red pepper mayo": 22,
    "Beet & Goat Cheese Salad": 23,
    "Fattoush Salad": 24,
    "Large Israeli Salad": 25,
    "Extra White Pita": 26,
    "Extra Gluten-Free Pita": 27,
  },
  "The Little Chihuahua": { 
    "Classic Burritos": 1,
    "Classic Burrito": 1,
    "Garlic Shrimp Burrito": 2,
    "Fried Plantain & Black Bean Burrito": 3,
    "Fried Plantain Burrito": 3,
    "Classic Fajita Quesadillas":	4,
    "Classic Quesadillas":	4,
    "Fajita Cheese Quesadilla":	5,
    "Cheese Quesadilla":	5,
    "Garlic Shrimp Rice Bowl": 6,
    "Classic Rice Bowls": 7,
    "Fried Plantain & Black Bean Rice Bowl": 8,
    "Fried Plantain Rice Bowl": 8,
    "Garlic Shrimp Tacos (2)": 9,
    "Classic Tacos (2)": 10,
    "Fried Plantain & Black Bean Tacos (2)": 11,
    "Fried Plantain Tacos (2)": 11,
    "Guac & Chips": 12, 
    "Salted Corn Tortilla Chips": 13,
  },
  "Asian Box": {
    "Chicken Curry": 1,
    "Vietnamese Hot Chicken": 2,
    "The Workout": 3,
    "Garden Box": 4,
    "Chef's Salad": 5,
  },
  "C&C Curry House": {
    "Yellow Tofu Curry": 1,
    "Tofu Yellow Curry": 1,
    "Green Chicken Curry": 2,
    "Green Tofu Curry": 3,
    "Vegetable Green Curry": 4,
    "Green Vegetable Curry": 4,
    "Vegetable Yellow Curry": 5,
    "Yellow Vegetable Curry": 5,
    "Yellow Chicken Curry": 6,
  },
  "Kasa": {
    "Naan Roll": 1,
    "Thali Plate": 2,
    "Roti Kati Roll": 3,
    "Samosa Cravings (3)": 4,
    "Samosa (1)": 5,
    "Naan & Masala Sauce": 6,
    "Lychee Lemonade": 7,
    "Mango Lassi": 8,
  },
  "The Melt": {
    "MeltBurger": 1,
    "Double MeltBurger": 2,
    "BBQ Bacon MeltBurger": 3,
    "Double BBQ Bacon MeltBurger": 4,
    "Impossible MeltBurger": 5,
    "Three Cheese Classic": 6,
    "Kid's Grilled Cheese": 7,
    "Patty Melt": 8,
    "Melty Mac": 9,
    "Mini Mac": 10,
    "Mac Daddy": 11,
    "Fries": 12,
    "Garlic Parmesan Fries": 13,
    "Cheddar Bacon Fries": 14,
    "Tomato Soup": 15,
  },
  "The Pastry Cupboard": {
    "1 Cookie": 1,
    "4 Cookies": 2,
    "6 Cookies": 3,
    "12 Cookies": 4,
  },
  "East Side Banh Mi": {
    "Pork Banh Mi": 1,
    "Pork Deluxe Banh Mi": 2,
    "Chicken Banh Mi": 3,
    "Chicken Deluxe Banh Mi": 4,
    "Vegan Banh Mi": 5,
    "Roasted Caramel Pork Rice Bowl": 6,
    "Caramel Pork Rice Bowl": 6,
    "Lemongrass Chicken Rice Bowl": 7,
    "Leomongrass Chicken Rice Bowl": 7,
    "Chili Crisp Tofu & Eggplant Rice Bowl": 8,
    "Chili Crisp & Tofu Eggplant Rice Bowl": 8,
    "Tofu & Eggplant Rice Bowl": 8, 
    "Citrus Soy Rice Bowl": 9,
    "Roasted Caramel Pork Noodle Bowl": 10,
    "Caramel Pork Noodle Bowl": 10,
    "Lemongrass Chicken Noodle Bowl": 11,
    "Lemongrass Chicken Noddle Bowl": 11,
    "Chicken Noodle Bowl": 11,
    "Chili Crisp Tofu & Eggplant Noodle Bowl": 12,
    "Chili Crips & Tofu Egglplant Noddle Bowl": 12,
    "Chili Crisp & Tofu Eggplant Noodle Bowl": 12,
    "Tofu & Eggplant Noodle Bowl": 12,
    "Citrus Soy Noodle Bowl": 13,
    "No Protein Noodle Bowl": 14,
    "Roasted Caramel Pork Salad": 15,
    "Caramel Pork Salad": 15,
    "Lemongrass Chicken Salad":	16,
    "Chili Crisp Tofu & Eggplant Salad": 17,
    "Chili Crisp & Tofu Eggplant Salad": 17,
    "Tofu & Eggplant Salad": 17,
    "Citrus Soy Salad": 18,
    "No Protein Salad": 19,
  }, 
  "Lazy Susan": {
    
  },
  "Sumo Dog": {
    "Sumo Dog": 1,
    "Chili & Cheese": 2,
    "Tater Tots with Spicy Mayo & Furikake": 3,
    "Bacon Banh Mi": 4,
    "Sumo Tots": 5,
    "Tater Tots": 6,
    "Plain Hot Dog w/ Bacon": 7,
  },
  "Sushirrito": {}
};

const itemsAlias = {
  "Hummus Beets": "Hummus Beet",	
  "Hummus Chicken": "Hummus Chicken",	
  "Hummus Classic": "Hummus Classic",	
  "Hummus Eggplant": "Hummus Eggplant",	

  "Hummus with Moroccan Beef": "Hummus with Moroccan Beef",	
  "Hummus  with Moroccan Beef": "Hummus with Moroccan Beef",	
  "Hummus Moroccan Beef": "Hummus with Moroccan Beef",	

  "Beef Kebab Rice Bowl": "Beef Kebab Rice Bowl",	
  "Orens Beef Kebab Rice Bowl": "Beef Kebab Rice Bowl",

  "Orens Chicken Rice Bowl": "Chicken Rice Bowl",	
  "Chicken Rice Bowl": "Chicken Rice Bowl",	
  
  "Pita Beef Kebab": "Pita Beef Kebab",	
  "Pita Chicken": "Pita Chicken",	
  "Pita Falafel": "Pita Falafel",

  "Beef Kebab a la carte": "Beef Kebab a la carte",
  "Beef Kebab Skewer": "Beef Kebab Skewer",
  "Chicken Skewer": "Chicken Skewer",
  "Chicken Skewer a la carte": "Chicken Skewer a la carte",

  "Babaganoush Eggplant | 8oz (Dips & Sides)": "Babaganoush Eggplant | 8oz (Dips & Sides)",
  "Chunky Eggplant | 8oz (Dips & Sides)": "Chunky Eggplant | 8oz (Dips & Sides)",
  "Classic Hummus | 8oz (Dips & Sides)": "Classic Hummus | 8oz (Dips & Sides)",
  "Small Hummus | 8oz (Dips & Sides)": "Classic Hummus | 8oz (Dips & Sides)",

  "Marinated Beets | 8oz (Dips & Sides)": "Marinated Beets | 8oz (Dips & Sides)",
  "Marinated Bees | 8oz (Dips & Sides)": "Marinated Beets | 8oz (Dips & Sides)",
  "Marinated Beets | 8oz (Dips & sides)": "Marinated Beets | 8oz (Dips & Sides)",
  "Marinated Beets | 8oz (Dips and sides)": "Marinated Beets | 8oz (Dips & Sides)",

  "Labane | 8oz (Dips & Sauces)": "Labane | 8oz (Dips & Sauces)",
  "Labane | 8oz (Dips & Sides)": "Labane | 8oz (Dips & Sauces)",

  "French Fries with Ketchup": "French Fries with Ketchup",
  "Side of Falafel (5 pieces)": "Side of Falafel (5 pieces)",
  "Green Herb Falafel (5 pieces)": "Green Herb Falafel (5 pieces)",    
  "Sweet potato fries with red pepper mayo": "Sweet potato fries with red pepper mayo",
  "Beet & Goat Cheese Salad": "Beet & Goat Cheese Salad",
  "Fattoush Salad": "Fattoush Salad",
  "Large Israeli Salad": "Large Israeli Salad",
  "Extra White Pita": "Extra White Pita",
  "Extra Gluten-Free Pita": "Extra Gluten-Free Pita",

  "Classic Burritos": "Classic Burritos",
  "Classic Burrito": "Classic Burritos",
  "Garlic Shrimp Burrito": "Garlic Shrimp Burrito",

  "Fried Plantain & Black Bean Burrito": "Fried Plantain & Black Bean Burrito",
  "Fried Plantain Burrito": "Fried Plantain & Black Bean Burrito",

  "Classic Fajita Quesadillas":	"Classic Fajita Quesadillas",
  "Classic Quesadillas":	"Classic Fajita Quesadillas",
  "Fajita Cheese Quesadilla":	"Fajita Cheese Quesadilla",
  "Cheese Quesadilla":	"Fajita Cheese Quesadilla",
  "Garlic Shrimp Rice Bowl": "Garlic Shrimp Rice Bowl",
  "Classic Rice Bowls": "Classic Rice Bowls",
  "Fried Plantain & Black Bean Rice Bowl": "Fried Plantain & Black Bean Rice Bowl",
  "Fried Plantain Rice Bowl": "Fried Plantain Rice Bowl",
  "Garlic Shrimp Tacos (2)": "Garlic Shrimp Tacos (2)",
  "Classic Tacos (2)": "Classic Tacos (2)",
  "Fried Plantain & Black Bean Tacos (2)": "Fried Plantain & Black Bean Tacos (2)",
  "Fried Plantain Tacos (2)": "Fried Plantain & Black Bean Tacos (2)",
  "Guac & Chips": "Guac & Chips", 
  "Salted Corn Tortilla Chips": "Salted Corn Tortilla Chips",

  "Chicken Curry": "Chicken Curry",
  "Vietnamese Hot Chicken": "Vietnamese Hot Chicken",
  "The Workout": "The Workout",
  "Garden Box": "Garden Box",
  "Chef's Salad": "Chef's Salad",

  "Yellow Tofu Curry": "Yellow Tofu Curry",
  "Tofu Yellow Curry": "Yellow Tofu Curry",
  "Green Chicken Curry": "Green Chicken Curry",
  "Green Tofu Curry": "Green Tofu Curry",
  "Vegetable Green Curry": "Vegetable Green Curry",
  "Green Vegetable Curry": "Vegetable Green Curry",
  "Vegetable Yellow Curry": "Vegetable Yellow Curry",
  "Yellow Vegetable Curry": "Vegetable Yellow Curry",
  "Yellow Chicken Curry": "Yellow Chicken Curry",

  "Naan Roll": "Naan Roll",
  "Thali Plate": "Thali Plate",
  "Roti Kati Roll": "Roti Kati Roll",
  "Samosa Cravings (3)": "Samosa Cravings (3)",
  "Samosa (1)": "Samosa (1)",
  "Naan & Masala Sauce": "Naan & Masala Sauce",
  "Lychee Lemonade": "Lychee Lemonade",
  "Mango Lassi": "Mango Lassi",

  "MeltBurger": "MeltBurger",
  "Double MeltBurger": "Double MeltBurger",
  "BBQ Bacon MeltBurger": "BBQ Bacon MeltBurger",
  "Double BBQ Bacon MeltBurger": "Double BBQ Bacon MeltBurger",
  "Impossible MeltBurger": "Impossible MeltBurger",
  "Three Cheese Classic": "Three Cheese Classic",
  "Kid's Grilled Cheese": "Kid's Grilled Cheese",
  "Patty Melt": "Patty Melt",
  "Melty Mac": "Melty Mac",
  "Mini Mac": "Mini Mac",
  "Mac Daddy": "Mac Daddy",
  "Fries": "Fries",
  "Garlic Parmesan Fries": "Garlic Parmesan Fries",
  "Cheddar Bacon Fries": "Cheddar Bacon Fries",
  "Tomato Soup": "Tomato Soup",

  "1 Cookie": "1 Cookie",
  "4 Cookies": "4 Cookies",
  "6 Cookies": "6 Cookies",
  "12 Cookies": "12 Cookies",

  "Pork Deluxe Banh Mi": "Pork Deluxe Banh Mi",
  "Chicken Banh Mi": "Chicken Banh Mi",
  "Chicken Deluxe Banh Mi": "Chicken Deluxe Banh Mi",
  "Vegan Banh Mi": "Vegan Banh Mi",
  "Roasted Caramel Pork Rice Bowl": "Roasted Caramel Pork Rice Bowl",
  "Caramel Pork Rice Bowl": "Roasted Caramel Pork Rice Bowl",
  "Lemongrass Chicken Rice Bowl": "Lemongrass Chicken Rice Bowl",
  "Leomongrass Chicken Rice Bowl": "Lemongrass Chicken Rice Bowl",
  "Chili Crisp Tofu & Eggplant Rice Bowl": "Chili Crisp Tofu & Eggplant Rice Bowl",
  "Chili Crisp & Tofu Eggplant Rice Bowl": "Chili Crisp Tofu & Eggplant Rice Bowl",
  "Tofu & Eggplant Rice Bowl": "Tofu & Eggplant Rice Bowl", 
  "Citrus Soy Rice Bowl": "Citrus Soy Rice Bowl",
  "Roasted Caramel Pork Noodle Bowl": "Roasted Caramel Pork Noodle Bowl",
  "Caramel Pork Noodle Bowl": "Roasted Caramel Pork Noodle Bowl",
  "Lemongrass Chicken Noodle Bowl": "Lemongrass Chicken Noodle Bowl",
  "Lemongrass Chicken Noddle Bowl": "Lemongrass Chicken Noodle Bowl",
  "Chicken Noodle Bowl": "Lemongrass Chicken Noodle Bowl",
  "Chili Crisp Tofu & Eggplant Noodle Bowl": "Chili Crisp Tofu & Eggplant Noodle Bowl",
  "Chili Crips & Tofu Egglplant Noddle Bowl": "Chili Crisp Tofu & Eggplant Noodle Bowl",
  "Chili Crisp & Tofu Eggplant Noodle Bowl": "Chili Crisp Tofu & Eggplant Noodle Bowl",
  "Tofu & Eggplant Noodle Bowl": "Chili Crisp Tofu & Eggplant Noodle Bowl",
  "Citrus Soy Noodle Bowl": "Citrus Soy Noodle Bowl",
  "No Protein Noodle Bowl": "Citrus Soy Noodle Bowl",
  "Roasted Caramel Pork Salad": "Roasted Caramel Pork Salad",
  "Caramel Pork Salad": "Roasted Caramel Pork Salad",
  "Lemongrass Chicken Salad":	"Lemongrass Chicken Salad",
  "Chili Crisp Tofu & Eggplant Salad": "Chili Crisp Tofu & Eggplant Salad",
  "Chili Crisp & Tofu Eggplant Salad": "Chili Crisp Tofu & Eggplant Salad",
  "Tofu & Eggplant Salad": "Chili Crisp Tofu & Eggplant Salad",
  "Citrus Soy Salad": "Citrus Soy Salad",
  "No Protein Salad": "Citrus Soy Salad",

  "Sumo Dog": "Sumo Dog",
  "Chili & Cheese": "Chili & Cheese",
  "Tater Tots with Spicy Mayo & Furikake": "Tater Tots with Spicy Mayo & Furikake",
  "Bacon Banh Mi": "Bacon Banh Mi",
  "Sumo Tots": "Sumo Tots",
  "Tater Tots": "Sumo Tots",
  "Plain Hot Dog w/ Bacon": "Plain Hot Dog w/ Bacon",

};

const StyledCard = styled(Flex)`
  flex-direction: ${props =>
    props.flexDirection ? props.flexDirection : "row"}
  border-radius: 10px;
  box-shadow: 0 0 3px 0 rgb(0, 0, 0, 0.2);
  margin-bottom: 20px;
  ${props =>
    props.fullwidth
      ? "width: auto; justify-content: space-around;"
      : "width: 100% !important;"}
  background-color: white;
  max-height: 800px;
  margin-right: 20px;
`;

const SpacedBox = styled(Box)`
  display: grid;
  height: 100% !important;
  grid-template-columns: 14% 14% 14% 14% 14% 14% 14%;
  text-align: center;
  font-weight: ${p => (p.isTitle ? "bolder" : "normal")};
  padding: 10px;
  border-bottom: ${p => (p.isTitle ? "1px solid black" : "0px")};
  font-size: ${p => (p.isTitle ? "20px" : "15px")};
`;

const getTotals = data => {
  let total = 0;
  data.forEach(element => {
    total = element["y"] + total;
  });
  return total;
};

const getArrayData = (data, sortType, globalData) => {
  if (!data) return;
  let obj = {};
  Object.keys(data["netSales"]).forEach(menuItem => {
    if(obj[menuItem.split("|&|")[0]]) {
      obj[menuItem.split("|&|")[0]].netSales += getTotals(data["netSales"][menuItem]);
      obj[menuItem.split("|&|")[0]].orderCount += getTotals(data["orderCount"][menuItem]);
      obj[menuItem.split("|&|")[0]].discount += getTotals(globalData["discounts"]["daily"][menuItem]);
      obj[menuItem.split("|&|")[0]].grossSales += getTotals(globalData["grossSales"]["daily"][menuItem]);
      obj[menuItem.split("|&|")[0]].avgPrice = obj[menuItem.split("|&|")[0]].netSales / obj[menuItem.split("|&|")[0]].orderCount;
    }
    else {
      obj[menuItem.split("|&|")[0]] = {};
      obj[menuItem.split("|&|")[0]].name = menuItem.split("|&|")[0];  
      obj[menuItem.split("|&|")[0]].netSales = getTotals(data["netSales"][menuItem]);
      obj[menuItem.split("|&|")[0]].orderCount = getTotals(data["orderCount"][menuItem]);
      obj[menuItem.split("|&|")[0]].discount = getTotals(globalData["discounts"]["daily"][menuItem]);
      obj[menuItem.split("|&|")[0]].grossSales = getTotals(globalData["grossSales"]["daily"][menuItem]);
      obj[menuItem.split("|&|")[0]].avgPrice = obj[menuItem.split("|&|")[0]].netSales / obj[menuItem.split("|&|")[0]].orderCount;
    }
  });
  let items = Object.keys(obj).map((key) => obj[key]);
  let res = sortData(items, sortType);
  return res;
};

const getArrayDataWithMods = (data, sortType, globalData, split, brand) => {
  if (!data) return;
  let obj = {};
  Object.keys(data["netSales"]).forEach(menuItem => {
    if(menuItem.includes("Noshery") && split) return; 
    if(!menuItem.includes("Noshery") && !split) return; 
    if(obj[menuItem.split("|&|")[0]]) {
      obj[menuItem.split("|&|")[0]].netSales += getTotals(data["netSales"][menuItem]);
      obj[menuItem.split("|&|")[0]].orderCount += getTotals(data["orderCount"][menuItem]);
      obj[menuItem.split("|&|")[0]].discount += getTotals(globalData["discounts"]["daily"][menuItem]);
      obj[menuItem.split("|&|")[0]].grossSales += getTotals(globalData["grossSales"]["daily"][menuItem]);
      obj[menuItem.split("|&|")[0]].avgPrice = obj[menuItem.split("|&|")[0]].netSales / obj[menuItem.split("|&|")[0]].orderCount;
    
    }
    else {
      obj[menuItem.split("|&|")[0]] = {};
      obj[menuItem.split("|&|")[0]].name = menuItem.split("|&|")[0];  
      obj[menuItem.split("|&|")[0]].reference = menuItem;
      obj[menuItem.split("|&|")[0]].netSales = getTotals(data["netSales"][menuItem]);
      obj[menuItem.split("|&|")[0]].orderCount = getTotals(data["orderCount"][menuItem]);
      obj[menuItem.split("|&|")[0]].discount = getTotals(globalData["discounts"]["daily"][menuItem]);
      obj[menuItem.split("|&|")[0]].grossSales = getTotals(globalData["grossSales"]["daily"][menuItem]);
      obj[menuItem.split("|&|")[0]].avgPrice = obj[menuItem.split("|&|")[0]].netSales / obj[menuItem.split("|&|")[0]].orderCount;
    }
  });
  let items = Object.keys(obj).map((key) => obj[key]);
  let res = sortData(items, sortType);

  let withMods = res.map((item) => {
    const { reference: menuItem } = item;
    let temp = [];
    temp.push(item);
    globalData["modifiers"]["daily"][menuItem] ? Object.keys(globalData["modifiers"]["daily"][menuItem]).map((modName) => {
      let modObj = {};
      modObj.name = menuItem.split("|&|")[0];
      modObj.modifier = modName;
      modObj.orderCount = Object.keys(globalData["modifiers"]["daily"][menuItem][modName]).reduce((acc, cur) => acc += globalData["modifiers"]["daily"][menuItem][modName][cur], 0);
      modObj.netSales = globalData["modifiersPrice"]["daily"][menuItem][modName][Object.keys(globalData["modifiersPrice"]["daily"][menuItem][modName])[0]] * modObj.orderCount;
      temp.push(modObj);
    }) : "";
    return temp;
  });

  let withModsFlat = withMods.flat();

  withModsFlat.forEach((item) => {
    item["name"] = itemsAlias[item["name"]]; 
  }); 

  let withModsMergeSameName = [];
  let temp = {};
  withModsFlat.forEach((item) => {
    let uniqueId = item.name + item.modifier;
    if(temp[uniqueId]) {
      temp[uniqueId].orderCount += item.orderCount;
      temp[uniqueId].netSales += item.netSales;
    }
    else {
      temp[uniqueId] = item;
    }
  });

  Object.keys(temp).forEach((key) => {
    withModsMergeSameName.push(temp[key]);
  });

  withModsMergeSameName = withModsMergeSameName.sort((a, b) => {
    if(!itemsBrandsSorting[brand][a["name"]]) console.log("missing name a", a["name"]);
    if(!itemsBrandsSorting[brand][b["name"]]) console.log("missing name b", b["name"]);
    const nameA = itemsBrandsSorting[brand][a["name"]];
    const nameB = itemsBrandsSorting[brand][b["name"]];
    return nameA - nameB;
  });

  return withModsMergeSameName;
};

const getArrayDataWithModsAll = (data, sortType, globalData, split, brand) => {
  if (!data) return;
  let obj = {};
  Object.keys(data["netSales"]).forEach(menuItem => {
    if(obj[menuItem.split("|&|")[0]]) {
      obj[menuItem.split("|&|")[0]].netSales += getTotals(data["netSales"][menuItem]);
      obj[menuItem.split("|&|")[0]].orderCount += getTotals(data["orderCount"][menuItem]);
      obj[menuItem.split("|&|")[0]].discount += getTotals(globalData["discounts"]["daily"][menuItem]);
      obj[menuItem.split("|&|")[0]].grossSales += getTotals(globalData["grossSales"]["daily"][menuItem]);
      obj[menuItem.split("|&|")[0]].avgPrice = obj[menuItem.split("|&|")[0]].netSales / obj[menuItem.split("|&|")[0]].orderCount;
    }
    else {
      obj[menuItem.split("|&|")[0]] = {};
      obj[menuItem.split("|&|")[0]].name = menuItem.split("|&|")[0];  
      obj[menuItem.split("|&|")[0]].reference = menuItem;
      obj[menuItem.split("|&|")[0]].netSales = getTotals(data["netSales"][menuItem]);
      obj[menuItem.split("|&|")[0]].orderCount = getTotals(data["orderCount"][menuItem]);
      obj[menuItem.split("|&|")[0]].discount = getTotals(globalData["discounts"]["daily"][menuItem]);
      obj[menuItem.split("|&|")[0]].grossSales = getTotals(globalData["grossSales"]["daily"][menuItem]);
      obj[menuItem.split("|&|")[0]].avgPrice = obj[menuItem.split("|&|")[0]].netSales / obj[menuItem.split("|&|")[0]].orderCount;
    }
  });

  console.log("obj", obj);

  let items = Object.keys(obj).map((key) => obj[key]);
  let res = sortData(items, sortType);

  let withMods = res.map((item) => {
    const { reference: menuItem } = item;
    let temp = [];
    temp.push(item); 
    globalData["modifiers"]["daily"][menuItem] ? Object.keys(globalData["modifiers"]["daily"][menuItem]).map((modName) => {
      let modObj = {};
      modObj.name = menuItem.split("|&|")[0];
      modObj.modifier = modName;
      modObj.orderCount = Object.keys(globalData["modifiers"]["daily"][menuItem][modName]).reduce((acc, cur) => acc += globalData["modifiers"]["daily"][menuItem][modName][cur], 0);
      modObj.netSales = globalData["modifiersPrice"]["daily"][menuItem][modName][Object.keys(globalData["modifiersPrice"]["daily"][menuItem][modName])[0]] * modObj.orderCount;
      temp.push(modObj);
    }) : globalData["modifiers"]["daily"][menuItem.split("|&|")[0] + "|&|Noshery"] ? Object.keys(globalData["modifiers"]["daily"][menuItem.split("|&|")[0] + "|&|Noshery"]).map((modName) => {
      let modObj = {};
      modObj.name = menuItem.split("|&|")[0];
      modObj.modifier = modName;
      modObj.orderCount = Object.keys(globalData["modifiers"]["daily"][menuItem.split("|&|")[0] + "|&|Noshery"][modName]).reduce((acc, cur) => acc += globalData["modifiers"]["daily"][menuItem.split("|&|")[0] + "|&|Noshery"][modName][cur], 0);
      modObj.netSales = globalData["modifiersPrice"]["daily"][menuItem.split("|&|")[0] + "|&|Noshery"][modName][Object.keys(globalData["modifiersPrice"]["daily"][menuItem.split("|&|")[0] + "|&|Noshery"][modName])[0]] * modObj.orderCount;
      temp.push(modObj);
    }) : "";
    return temp;
  });

  let withModsFlat = withMods.flat();

  withModsFlat.forEach((item) => {
    item["name"] = itemsAlias[item["name"]]; 
  }); 

  let withModsMergeSameName = [];
  let temp = {};
  withModsFlat.forEach((item) => {
    let uniqueId = item.name + item.modifier;
    if(temp[uniqueId]) {
      temp[uniqueId].orderCount += item.orderCount;
      temp[uniqueId].netSales += item.netSales;
    }
    else {
      temp[uniqueId] = item;
    }
  });

  Object.keys(temp).forEach((key) => {
    withModsMergeSameName.push(temp[key]);
  });

  withModsMergeSameName = withModsMergeSameName.sort((a, b) => {
    if(!itemsBrandsSorting[brand][a["name"]]) console.log("missing name a", a["name"]);
    if(!itemsBrandsSorting[brand][b["name"]]) console.log("missing name b", b["name"]);
    const nameA = itemsBrandsSorting[brand][a["name"]];
    const nameB = itemsBrandsSorting[brand][b["name"]];
    return nameA - nameB;
  });

  return withModsMergeSameName;
};

const getTotalsItems = (data, globalData) => {
  let obj = {
    name: "Total",
    netSales: 0,
    orderCount: 0,
    discount: 0,
    grossSales: 0,
    avgPrice: 0,
    share: 0
  };
  Object.keys(data["netSales"]).forEach(menuItem => {
    obj.netSales += getTotals(data["netSales"][menuItem]);
    obj.orderCount += getTotals(data["orderCount"][menuItem]);
    obj.discount += getTotals(globalData["discounts"]["daily"][menuItem]);
    obj.grossSales += getTotals(globalData["grossSales"]["daily"][menuItem]);
    obj.avgPrice = (obj.netSales / obj.orderCount);
  });
  return obj;
};

const Breakdown = ({ data, globalData, brand, range, downloads }) => {

  if(!data) return <> </>;
  if(!globalData) return <> </>;

  const [sortBy, setSortBy] = React.useState("netSales");

  let itemArray = getArrayData(data, sortBy, globalData,);

  let itemWithModsArrayNoshery = globalData ? getArrayDataWithMods(data, sortBy, globalData, false, brand) : "";

  let itemWithModsArrayStorefront = globalData ? getArrayDataWithMods(data, sortBy, globalData, true, brand) : "";

  let itemsWithModsArray = globalData ? getArrayDataWithModsAll(data, sortBy, globalData, true, brand) : "";

  let totalsItem = getTotalsItems(data, globalData,);

  useEffect(() => {}, []);

  let headersMods = [
    { label: "Item Name", key: "name" },
    { label: "Modifier", key: "modifier" },
    { label: "Avg Price", key: "avgPrice" },
    { label: "Quantity", key: "orderCount" },
    { label: "Gross Sales", key: "grossSales" },
    { label: "Discount", key: "discount" },
    { label: "Net Sales", key: "netSales" },
  ];

  let headers = [
    { label: "Item Name", key: "name" },
    { label: "Avg Price", key: "avgPrice" },
    { label: "Quantity", key: "orderCount" },
    { label: "Gross Sales", key: "grossSales" },
    { label: "Discount", key: "discount" },
    { label: "Net Sales", key: "netSales" },
    { label: "Share", key: "share" },
  ];

  let headers2 = [
    { label: "Item Name", key: "name" },
    { label: "Modifier", key: "modifier" },
    { label: "Avg Price", key: "avgPrice" },
    { label: "Quantity", key: "orderCount" },
    { label: "Gross Sales", key: "grossSales" },
    { label: "Discount", key: "discount" },
    { label: "Net Sales", key: "netSales" },
  ]; 
  return (
    <Flex py={6} height="100%" px="130px" flexDirection="column" bg="#f2f2f2">
      {downloads && <Flex justifyContent="flex-end" mb={3}>     
        <CSVLink
          style={{marginRight: "5%"}}
          headers={headers}
          data={[...itemArray, totalsItem]}
          filename={`${brand}-${range}.csv`}
        >
          <Button>Download table </Button>
        </CSVLink>  
        <CSVLink
          style={{marginRight: "5%"}}
          headers={headers2}
          data={[...itemsWithModsArray, totalsItem]}
          filename={`${brand}-${range}.csv`}
        >
          <Button>Download table with mods </Button>
        </CSVLink> 
        <CSVLink
          headers={headersMods}
          data={[{name: "Noshery Storefront"}, ...itemWithModsArrayNoshery, {name: "Brand Storefront"}, ...itemWithModsArrayStorefront, totalsItem]}
          filename={`${brand}-${range}.csv`}
        >
          <Button>Download storefront split table with mods</Button>
        </CSVLink>
      </Flex>}
      <StyledCard flexDirection="column">
        <SpacedBox isTitle={1}>
          <Text> Item </Text>
          
          <Text> Avg Price </Text>

          <Text
            style={{ cursor: "pointer" }}
            onClick={() => setSortBy("orderCount")}
          >
            {" "}
            No. Sold{" "}
          </Text>

          <Text
            style={{ cursor: "pointer" }}
            onClick={() => setSortBy("grossSales")}
          > Gross Sales </Text>

          <Text             
            style={{ cursor: "pointer" }}
            onClick={() => setSortBy("discount")}
          > Discount Amount </Text>

          <Text
            style={{ cursor: "pointer" }}
            onClick={() => setSortBy("netSales")}
          >
            {" "}
            Net Sales
          </Text>

        </SpacedBox>
        <div style={{ maxHeight: "600px", overflowX: "scroll" }}>
          {data &&
            data["netSales"] &&
            itemArray &&
            itemArray.map((menuItem, i) => {
              return (
                <MenuItem
                  key={`#bestSeller${menuItem}${i}`}
                  i={i}
                  menuItem={menuItem}
                />
              );
            })}
          <MenuItem
            i={itemArray.length + 1}
            menuItem={totalsItem}
            isTotal={true} 
            
          />
        </div>
      </StyledCard>
    </Flex>
  );
};

const MenuItem = ({ menuItem, i, isTotal }) => {
  return (
    <SpacedBox id={menuItem} style={{ borderBottom: "1px solid", backgroundColor: isTotal ? "#F1A638" : "none" }} isTitle={0}>
      <Text style={{ paddingRight: "5px", textAlign: "left" }}>
        {isTotal ? "" : `${i + 1}.`} {menuItem["name"]}{" "}
      </Text>     
      <Text>
        {" "}
        ${(menuItem["avgPrice"]).toFixed(2)}{" "}
      </Text>
   
      <Text> #{menuItem["orderCount"]} </Text>
      
      <Text> ${menuItem["grossSales"].toFixed(2)} </Text> 
      <Text> ${(menuItem["discount"]).toFixed(2)} </Text>
      <Text> ${menuItem["netSales"].toFixed(2)} </Text>

      {/* {isTotal && <Text> ${(menuItem["share"]).toFixed(2)} </Text>}  */}
    </SpacedBox>
  );
};

export default Breakdown;
