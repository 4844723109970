import React from "react";
import { Box, Flex, Text, Button } from "noshery-ui";
import { getAuth, signOut, sendPasswordResetEmail } from "firebase/auth";
import useWindowSize from "../../hooks/useWindowSize";
import Alert from "@mui/material/Alert";
import { Snackbar} from "@mui/material";

const Users = () => {
  const [user, setUser] = React.useState();
  const { width } = useWindowSize();
  const [error, setError] = React.useState();
  const [successPopup, setSucessPopup] = React.useState(false);

  React.useEffect(() => {
    setUser(getAuth().currentUser);
  }, []);

  const handleLogout = () => {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        console.log("Signing out.....");
      })
      .catch(() => {
        console.log("Could not sign out.....");
      });
  };

  const handleResetPassword = () => {
    const auth = getAuth();
    sendPasswordResetEmail(auth, user.email)
      .then(() => {
        setSucessPopup(true);
      })
      .catch((error) => {
        setError(error.message);
        // ..
      });
  };

  return (
    <Box pt={"100px"} pb={4} px="100px" mt={3}>
      <Text fontSize={3}>Settings:</Text>

      {user && (
        <Flex
          width={`${(width * 20) / 100}px`}
          borderBottom="1px solid #ececec"
          p={2}
        >
          <Box mr={3}>
            <Text fontSize={3}>
              Signed in as: {user.email.split("@")[0]} -----
            </Text>
          </Box>
        </Flex>
      )}
      <Flex flexDirection="column" mt={3}>
        <Button style={{marginBottom: "5%"}} p={5} width={`${(width * 20) / 100}px`} onClick={handleLogout}>
          {" "}
          Sign Out{" "}
        </Button>
        <Button p={5} width={`${(width * 20) / 100}px`} onClick={handleResetPassword}>
          {" "}
          Reset Pasword{" "}
        </Button>
        {error && <Text color="red">{error}</Text>}
      </Flex>
      <Snackbar open={successPopup} autoHideDuration={5000} onClose={() => setSucessPopup(false)}>
        <Alert onClose={() => setSucessPopup(false)} severity="success" sx={{ width: "100%" }}>
          Reset password link sent to your email!
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Users;
