import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import useWindowSize from "../../hooks/useWindowSize";

const StyledLink = styled(NavLink)`
  text-decoration: none;
  display: flex;
  align-items: center;
  width: 100%;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: white;
`;

const drawerWidth = 240;

function DrawerAppBar(props) {
  const { window, pages } = props;
  const {width} = useWindowSize();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center", color: "white", height: "100%", backgroundColor: "rgb(26 35 26)" }}>
      <Typography textAlign={"start"} sx={{ color: "#F1A637", marginLeft: `${(width*6)/100}px !important`,fontSize: `${(width*8)/100}px !important`,  my: 2 }}>
        Noshery
      </Typography>
      <Divider />
      <List>
        {pages.map((item) => (
          <ListItem key={item.title} disablePadding>
            <StyledLink to={item.to} >
              <Typography sx={{ textAlign: "center", marginLeft: `${(width*6)/100}px !important`, fontSize: `${(width*8)/100}px !important` }}> {item.title} </Typography> 
            </StyledLink>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex", zIndex: 1000, }} position="sticky">
      <AppBar component="nav" sx={{backgroundColor: "rgb(26 35 26)"}}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            sx={{color: "#F1A637", fontWeight: "bold", fontSize: "1.5rem"}}
          >
            Noshery
          </Typography>
          <Box sx={{ display: "flex", }}>
            {pages.map((item) => (
              <StyledLink key={item.title} to={item.to} >
                <Typography sx={{ textAlign: "center", marginLeft: `${(width*3)/100}px !important`, fontSize: `${(width*2)/100}px !important` }}> {item.title} </Typography> 
              </StyledLink>
            ))}
          </Box>
        </Toolbar>
      </AppBar>
      <Box component="nav" sx={{backgroundColor: "rgb(26 35 26)"}}>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
      <Box component="main">
        <Toolbar />
      </Box>
    </Box>
  );
}


export default DrawerAppBar;