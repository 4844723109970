import React, { useEffect } from "react";
import { Box, Flex, Text, Button } from "noshery-ui";
import styled from "styled-components";
import moment from "moment";
import { CSVLink } from "react-csv";
import Popup from "./MobilePopup";
import { sortData } from "../../utils/sort";

const StyledCard = styled(Flex)`
  flex-direction: ${props =>
    props.flexDirection ? props.flexDirection : "row"}
  border-radius: 10px;
  box-shadow: 0 0 3px 0 rgb(0, 0, 0, 0.2);
  margin-bottom: 20px;
  ${props =>
    props.fullwidth
      ? "width: auto; justify-content: space-around;"
      : "width: 100% !important;"}
  background-color: white;
  max-height: 800px;
  margin-right: 20px;
`;

const SpacedBox = styled(Box)`
  display: grid;
  height: 100% !important;
  grid-template-columns: 20% 20% 20% 20% 20%;
  text-align: center;
  font-weight: ${p => (p.isTitle ? "bolder" : "normal")};
  padding: 10px;
  border-bottom: ${p => (p.isTitle ? "1px solid black" : "0px")};
  font-size: ${p => (p.isTitle ? "20px" : "15px")};
`;

const getTotals = data => {
  let total = 0;
  data.forEach(element => {
    total = element["y"] + total;
  });
  return total;
};

const getArrayData = (data, sortType, globalData) => {
  if (!data) return;
  let items = [];
  Object.keys(data["netSales"]).forEach(menuItem => {
    let obj = {};
    obj.name = menuItem;
    obj.netSales = getTotals(data["netSales"][menuItem]);
    obj.orderCount = getTotals(data["orderCount"][menuItem]);
    obj.discount = getTotals(globalData["discounts"]["daily"][menuItem]);
    obj.grossSales = getTotals(globalData["grossSales"]["daily"][menuItem]);
    items.push(obj);
  });

  let res = sortData(items, sortType);

  return res;
};

const getTotalsItems = (data, globalData) => {
  let obj = {
    name: "Total",
    netSales: 0,
    orderCount: 0,
    discount: 0,
    grossSales: 0,
  };
  Object.keys(data["netSales"]).forEach(menuItem => {
    obj.netSales += getTotals(data["netSales"][menuItem]);
    obj.orderCount += getTotals(data["orderCount"][menuItem]);
    obj.discount += getTotals(globalData["discounts"]["daily"][menuItem]);
    obj.grossSales += getTotals(globalData["grossSales"]["daily"][menuItem]);
  });
  return obj;
};

const BestSellerTable = ({
  data,
  chartComp,
  globalData,
  setTopSellers,
  time,
}) => {
  const [popupOpen, setPopupOpen] = React.useState(0);
  const [itemChosen, setItemChose] = React.useState(null);
  const [sortBy, setSortBy] = React.useState("netSales");

  const handleItemClick = menuItem => {
    setPopupOpen(true);
    setItemChose(menuItem);
  };

  let itemArray = getArrayData(data, sortBy, globalData);

  let totalsItem = getTotalsItems(data, globalData);

  useEffect(() => {
    setTopSellers(itemArray.slice(0, 5));
  }, [data, globalData]);

  return (
    <Flex py={6} height="100%" width="100%" flexDirection="column" bg="#f2f2f2">
      <Flex justifyContent="flex-end" mb={3}>
        <CSVLink
          data={itemArray}
          filename={`bestsellers-${moment().format("YYYY-MM-DD:HH:MM")}.csv`}
        >
          <Button>Download me</Button>
        </CSVLink>
      </Flex>
      <StyledCard flexDirection="column">
        <SpacedBox isTitle={1}>
          <Text> Best Sellers </Text>
          <Text
            style={{ cursor: "pointer" }}
            onClick={() => setSortBy("netSales")}
          >
            {" "}
            Revenue{" "}
          </Text>
          <Text
            style={{ cursor: "pointer" }}
            onClick={() => setSortBy("orderCount")}
          >
            {" "}
            Count{" "}
          </Text>
          <Text
            style={{ cursor: "pointer" }}
            onClick={() => setSortBy("discount")}
          >
            {" "}
            Discount{" "}
          </Text>
          <Text
            style={{ cursor: "pointer" }}
            onClick={() => setSortBy("grossSales")}
          >
            {" "}
            Gross Sales{" "}
          </Text>
        </SpacedBox>
        <div style={{ maxHeight: "350px", overflowX: "scroll" }}>
          {data &&
            data["netSales"] &&
            data["orderCount"] &&
            globalData["discounts"]["daily"] &&
            globalData["discounts"]["daily"] &&
            itemArray &&
            itemArray.map((menuItem, i) => {
              return (
                <MenuItem
                  key={`#bestSeller${menuItem}${i}`}
                  i={i}
                  menuItem={menuItem}
                  data={data}
                  setPopupOpen={handleItemClick}
                />
              );
            })}
          <MenuItem
            i={itemArray.length + 1}
            menuItem={totalsItem}
            total={true}
            data={data}
          />
        </div>
        {popupOpen ? (
          <Popup
            chartComp={chartComp}
            time={time}
            globalData={globalData}
            setPopupOpen={setPopupOpen}
            data={data}
            itemChosen={itemChosen}
          />
        ) : null}
      </StyledCard>
    </Flex>
  );
};

const MenuItem = ({ menuItem, setPopupOpen, i, total}) => {
  return (
    <SpacedBox
      id={menuItem}
      style={{ cursor: "pointer", borderBottom: "1px solid", backgroundColor: total ? "#F1A638" : "none" }}
      onClick={() => setPopupOpen(menuItem)}
      isTitle={0}
    >
      <Text style={{ paddingRight: "5px", textAlign: "left" }}>
        {" "}
        {total ? "" : i + 1 + "."} {menuItem["name"]}{" "}
      </Text>
      <Text> ${menuItem["netSales"].toFixed(2)} </Text>
      <Text> #{menuItem["orderCount"]} </Text>
      <Text> ${(menuItem["discount"]).toFixed(2)} </Text>
      <Text> ${menuItem["grossSales"].toFixed(2)} </Text>
    </SpacedBox>
  );
};

export default BestSellerTable;
