import React, { useState } from "react";
import { Button, Input, Text, Box, Logo, Flex, Spinner } from "noshery-ui";
import useWindowSize from "./hooks/useWindowSize";
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  signInWithEmailAndPassword,
} from "firebase/auth";
import useDeviceType from "./hooks/useDeviceType";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);
  const { width, height } = useWindowSize();
  const [loading, setLoading] = useState(false);
  const {isDesktop} = useDeviceType(); 

  const onClickGoogle = () => {
    const auth = getAuth();
    const provider = new GoogleAuthProvider();
    provider.addScope("profile");
    provider.addScope("email");
    provider.setCustomParameters({
      hd: "bytekitchen.io",
    });
    signInWithPopup(auth, provider)
      .then(() => {
        console.info("User successfully logged in");
      })
      .catch(error => {
        console.error("Error happened while logging in", error.message);
      });
  };

  const onClickEmailAndPassword = () => {
    setLoading(true);
    const auth = getAuth();
    signInWithEmailAndPassword(auth, email, password)
      .then(() => {
        setError(false);
        setLoading(false);
      })
      .catch(() => {
        setError(true);
        setLoading(false);
      });
  };

  const container = {
    bg: "#344734",
    color: "white",
    padding: 6,
    width: isDesktop ? "435" : "90%",
    border: 1,
    paddingY: `${(width * 4) / 100}px`,
    borderColor: "#344734",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    borderRadius: `${(width * (isDesktop ? 1 : 2)) / 100}px`,
  };

  const textParams = {
    padding: `${(width * 1) / 100}px`,
  };

  return (
    <Flex
      height={height}
      bg="#F1A638"
      justifyContent="center"
      alignItems="center"
      flexDirection="row"
    >
      <Flex {...container}>
        <Logo />
        <Text
          bold
          fontSize={`${(width * (isDesktop ? 2 : 8)) / 100}px`}
          paddingTop={`${(width * 1.8) / 100}px`}
        >
          {" "}
          Sales Dashboard{" "}
        </Text>
        <Text bold fontSize={`${(width * (isDesktop ? 1.8 : 8)) / 100}px`} {...textParams}>
          {" "}
          Log In{" "}
        </Text>
        <Flex
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          paddingBottom={error ? 0 : `${(width * 1.8) / 100}px`}
          width={"100%"}
        >
          <Box
            paddingBottom={`${(width * 1) / 100}px`}
            width={`${(width * (isDesktop ? 20 : 60)) / 100}px`}
          >
            <Input
              placeholder="Email"
              type="email"
              value={email}
              setValue={setEmail}
            />
          </Box>
          <Box width={`${(width * (isDesktop ? 20 : 60)) / 100}px`}>
            <Input
              placeholder="Password"
              type="password"
              value={password}
              setValue={setPassword}
            />
          </Box>
        </Flex>
        {error && (
          <Flex paddingBottom={`${(width * 1) / 100}px`}>
            <Text color="red" bold>
              {" "}
              There was an error while signing in. Please try again.{" "}
            </Text>
          </Flex>
        )}
        {loading ? (
          <Spinner variant="large" />
        ) : (
          <Flex width={`${(width * (isDesktop ? 20 : 60)) / 100}px`} borderRadius={8}>
            <Button
              width={"100%"}
              variant="orange"
              onClick={onClickEmailAndPassword}
            >
              Login
            </Button>
          </Flex>
        )}
        <Text fontSize={`${(width * (isDesktop ? 1 : 6)) / 100}px`} {...textParams} bold>
          {" "}
          or{" "}
        </Text>
        <Flex width={`${(width * (isDesktop ? 20 : 60)) / 100}px`} borderRadius={8}>
          <Button
            backgroundColor="#4285F4 !important"
            width={"100%"}
            variant="orange"
            onClick={onClickGoogle}
          >
            Sign in with Google
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Login;
