import React, {useEffect} from "react";
import { Flex, Box, Text } from "noshery-ui";
import moment from "moment";
import Dropdown from "../../components/Dropdown";
import useCharts from "../../hooks/useBrandChart";
import styled from "styled-components";
import PageLoader from "../../components/PageLoader";
import { restaurantColors } from "../../constants";
import { isDataEmpty } from "../../utils/validators";
import NoData from "../../components/NoData";
import Breakdown from "../../components/Breakdown";
import ResponsiveLineChart from "../../components/ResponsiveLineChart";
import ResponsivePieChart from "../../components/ResponsivePieChart";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useLocationCharts } from "../../hooks/useLocation";

const restaurants = {
  "Humphry Slocombe Ice Cream": "Humphry Slocombe Ice Cream",
  "The Pastry Cupboard": "The Pastry Cupboard",
  "Kasa": "Kasa", 
  "The Little Chihuahua": "The Little Chihuahua",
  "East Side Banh Mi": "East Side Banh Mi",
  "Bourbon Street West": "Bourbon Street West",
  "Road Food": "Road Food",
  "C&C Curry House": "C&C Curry House",
  "Oren's Hummus": "Oren's Hummus",
  "The Melt": "The Melt", 
  "Asian Box": "Asian Box",
  "Lazy Susan": "Lazy Susan",
  "Sumo Dog": "Sumo Dog",
  "Sushirrito": "Sushirrito",
  "Rooster and Rice": "Rooster and Rice",
};


const dateRangeOption = {
  today: "Today",
  yesterday: "Yesterday",
  thisWeek: "This week",
  lastWeek: "Last week",
  thisMonth: "This month",
  lastMonth: "Last month",
  thisYear: "This year",
  allTime: "All time",
  custom: "Custom",
};

const periods = {
  period10: "Period 10",
  period11: "Period 11",
  period12: "Period 12",
  period13: "Period 1",
  period14: "Period 2",
  period15:  "Period 3",
  period16: "Period 4",
  period17: "Period 5",
  period18: "Period 6",
  period19: "Period 7",
  period20: "Period 8",
  period21: "Period 9",
  period22: "Period 10",
  period23: "Period 11",
  period24: "Period 12",
  
  period25: "Period 1",
  period26: "Period 2",
  period27: "Period 3",
  period28: "Period 4",
  period29: "Period 5",
  period30: "Period 6",
  period31: "Period 7",
  period32: "Period 8",
  period33: "Period 9",
  period34: "Period 10",
};


const years = {
  year2022: "2022",
  year2023: "2023",
  year2024: "2024",
};

const StyledSelect = styled.select`
  border-radius: 10px;
  box-shadow: 0 0 3px 0 rgb(0, 0, 0, 0.2);
  border: none;
  background-color: white;
  font-size: ${(props) => (props.fontSize ? props.fontSize : "20px")}
  ${(props) =>
    props.position
      ? "position: absolute; right: 8px; top: 8px; z-index: 1;"
      : ""}
  padding: 12px;
  font-weight: 600;
  &:hover,
  &:focus {
    cursor: pointer;
  }
  :focus-visible {
    outline: solid orange 2px;
  }
`;

const ChartContainer = styled(Box)`
  margin-bottom: 20px;
  margin-left: ${({ category }) => (category ? "0" : "16px")};
  box-shadow: 0 0 3px 0 rgb(0, 0, 0, 0.2);
  width: 100%;
  height: fit-content;
  position: relative;
  background-color: white;
  border-radius: 10px;
  padding: 10px;
`;

const colors = ["orange", "#FF8C69", "black", "darkblue", "teal"];
 
const createLegendData = (data, multiple, filter, topSellers, items) => {
  if (!multiple) {
    return Object.keys(data).map((key, i) => {
      return {
        name: key,
        symbol: { fill: items ? colors[i] : restaurantColors[key] },
      };
    });
  }
  if (topSellers !== null) {
    return topSellers.map((key, i) => {
      return {
        name: key.name,
        symbol: { fill: items ? colors[i] : restaurantColors[key] },
      };
    });
  } else {
    return filter
      ? Object.keys(data["netSales"][`${filter.value}`]).map((key, i) => {
        return {
          name: key,
          symbol: { fill: items ? colors[i] : restaurantColors[key] },
        };
      })
      : Object.keys(data["netSales"]).map((key, i) => {
        return {
          name: key,
          symbol: { fill: items ? colors[i] : restaurantColors[key] },
        };
      });
  }
};
 

const Charts = () => {
  const [year, setYear] = React.useState("2023");
  const {
    chartChosen,
    setChartChosen,
    setDateRange,
    startDate,
    endDate,
    chartData,
    loading,
    onSelectStartDate,
    onSelectEndDate,
    setLocation,
    dateRange,
    setBrand,
    brand,
    setLoading
  } = useCharts();

  const {locations: locationsOption} = useLocationCharts();

  useEffect(() => {
    if (Object.values(locationsOption).length > 0) {
      setLocation(Object.values(locationsOption)[0]);
    }
  }, [locationsOption]); 

  return (
    <Box>
      <Flex pt={"100px"} pb={4} px="100px" flexDirection="column" bg="#f2f2f2">
        <Flex justifyContent="space-between" mb={4}>
          <Flex w="100%" flexDirection="column" justifyContent="space-between">
            <Flex w="100%" paddingBottom="2%">
              <Box paddingRight="10px">
                <StyledSelect
                  onChange={(e) => setLocation(locationsOption[e.target.value])}
                >
                  {Object.keys(locationsOption).map((d) => (
                    <option value={d} key={d}>
                      {d}
                    </option>
                  ))}
                </StyledSelect>
              </Box>
              <Box paddingRight="10px">
                <StyledSelect onChange={(e) => {setDateRange(e.target.value);}}>
                  {Object.keys(periods).filter((d) => {
                    console.log(d);
                    console.log(parseInt(d.match(/([0-9]+)/)));
                    if(year === "2023" 
                        &&  parseInt(d.match(/([0-9]+)/)) >= 13
                        &&  parseInt(d.match(/([0-9]+)/)) < 25
                    ) {
                      return d;
                    }  
                    else if(year === "2022" 
                        && parseInt(d.match(/([0-9]+)/)) < 13
                    ) { 
                      return d;
                    }
                    else if(year === "2024" 
                        && parseInt(d.match(/([0-9]+)/)) >= 25
                    ) {
                      return d;
                    } 
                    else return; 
                  }).map((d) => (
                    <option value={d} key={d}>
                      {periods[d]}
                    </option>
                  ))}
                  <option disabled selected value> -- select a period -- </option>
                </StyledSelect>
              </Box>
              <Box paddingRight="10px">
                <StyledSelect
                  value={year}
                  onChange={(e) => {
                    setYear(e.target.value);
                  }}
                >
                  {Object.keys(years).map((d) => (
                    <option value={years[d]} key={d}>
                      {years[d]}
                    </option>
                  ))}
                </StyledSelect>
              </Box>

              <Box paddingRight="10px">
                <StyledSelect
                  value={dateRange.includes("period") ? "Period" : dateRange}
                  onChange={(e) => {
                    setDateRange(e.target.value);
                  }}
                >
                  {Object.keys(dateRangeOption).map((d) => (
                    <option value={d} key={d}>
                      {dateRangeOption[d]}
                    </option>
                  ))}
                  <option hidden value={"Period"} key={"Period"}>
                    Period
                  </option>
                </StyledSelect>
              </Box>

              <Box paddingRight="10px">
                <StyledSelect
                  value={brand}
                  onChange={(e) => {
                    setBrand(e.target.value);
                    setLoading(true);
                  }}
                >
                  {Object.keys(restaurants).map((d) => (
                    <option value={d} key={d}>
                      {restaurants[d]}
                    </option>
                  ))}
                </StyledSelect>
              </Box>
            </Flex>
            <Box>
              <Flex
                style={{ width: "80%", borderRadius: "10px" }}
                py="20px"
                px="10px"
                bg="white"
              >
                <Flex
                  alignItems="center"
                  mr={3}
                  ml={2}
                  style={{width: "100%"}}
                  onClick={() => setDateRange("custom")}
                >
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DesktopDatePicker
                      label="Start Date"
                      inputFormat="YYYY-MM-DD"
                      minDate={moment("2021-11-12")}
                      maxDate={moment()}
                      value={moment.isMoment(startDate) ? startDate : moment(startDate)}
                      onChange={onSelectStartDate}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </Flex>
                <Flex
                  alignItems="center"
                  mr={2}
                  style={{width: "100%"}}
                  onClick={() => setDateRange("custom")}
                >
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DesktopDatePicker
                      label="End Date"
                      inputFormat="YYYY-MM-DD"
                      maxDate={moment()}
                      minDate={ moment.isMoment(startDate) ? startDate : moment(startDate)}
                      value={
                        moment.isMoment(endDate) ? endDate : moment(endDate)}
                      onChange={onSelectEndDate}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </Flex>
              </Flex>
            </Box>
          </Flex>
        </Flex>
      </Flex>

      {loading ? (
        <PageLoader />
      ) : isDataEmpty(chartData) ? (
        <NoData />
      ) : (
        
        chartData && !loading &&
        <Flex px="100px" flexDirection="column" minHeight="100vh" bg="#f2f2f2">
          {chartData["items"] && chartData["items"]["daily"] && (
            <Breakdown
              data={chartData["items"]["daily"]}
              revenue={chartData["revenue"]}
              globalData={chartData}
              brand={brand}
              range={dateRange}
            />
          )}
          {/* <StyledCard>
            <Text fontSize={4} p={4}>
              Net Sales:{" "}
              <NumberSpan>
                $
                {chartData &&
                  chartData.items &&
                  chartData.items.daily && 
                  chartData.items.daily.netSales && 
                  Object.keys(chartData.items.daily.netSales).reduce((acc, cur) => {
                    const total = chartData.items.daily.netSales[cur].reduce((acc2, cur2) => {
                      acc2 += cur2.y;
                      return acc2;
                    }, 0);
                    acc += total;
                    return acc;
                  }, 0).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                  })
                }
              </NumberSpan>
            </Text>
            <Text fontSize={4} p={4}>
              Total Orders:{" "}
              <NumberSpan>
                {chartData &&
                  chartData.total &&
                  chartData.total.totalOrders.toLocaleString(undefined, {})}
              </NumberSpan>
            </Text>
            <Text fontSize={4} p={4}>
              AOV:{" "}
              <NumberSpan>
                $
                {chartData && chartData.total && chartData.total.aov
                  ? chartData.total.aov.toFixed(2)
                  : 0}
              </NumberSpan>
            </Text>
          </StyledCard>
          <Flex justifyContent="space-between">
            <StyledCard flexDirection="column" p="16px">
              <Flex justifyContent="space-between">
                <Text fontSize={5} p={2} mr="60px">
                  Total
                </Text>
                <Text fontSize={5} p={2} fontWeight={600}>
                  $
                  {chartData &&
                  chartData.items &&
                  chartData.items.daily && 
                  chartData.items.daily.netSales && 
                  Object.keys(chartData.items.daily.netSales).reduce((acc, cur) => {
                    const total = chartData.items.daily.netSales[cur].reduce((acc2, cur2) => {
                      acc2 += cur2.y;
                      return acc2;
                    }, 0);
                    acc += total;
                    return acc;
                  }, 0).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                  })
                  }
                </Text>
              </Flex>
              <Divider />
              <Flex justifyContent="space-between">
                <Text fontSize={5} p={2} mr="60px">
                  # number of days:{" "}
                </Text>
                <Text fontSize={5} p={2} fontWeight={600}>
                  {chartData && chartData.total && chartData.total.days}
                </Text>
              </Flex>
              <Flex justifyContent="space-between">
                <Text fontSize={5} p={2} mr="60px">
                  Avg. daily sales:{" "}
                </Text>
                <Text fontSize={5} p={2} fontWeight={600}>
                  $
                  {chartData && chartData.total && chartData.total.adv
                    ? chartData.total.adv.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                    })
                    : 0}
                </Text>
              </Flex>
              <Flex justifyContent="space-between">
                <Text fontSize={5} p={2} mr="60px">
                  Avg. daily orders:{" "}
                </Text>
                <Text fontSize={5} p={2} fontWeight={600}>
                  {" "}
                  {chartData && chartData.total && chartData.total.ado
                    ? chartData.total.ado.toFixed(2)
                    : 0}
                </Text>
              </Flex>
              <Flex paddingTop={100}>
                <Text fontSize={2} p={4} fontWeight={600} color={gray}>
                  Sales Updated at:
                  {chartData &&
                    chartData.timeStamp &&
                    moment
                      .tz(chartData.timeStamp.writeDate, "America/Los_Angeles")
                      .format("MM/DD/YYYY h:mm a")}
                </Text>
              </Flex>
            </StyledCard>
            {
              chartData &&
              chartData.total &&
              chartData.total.hourly && (
                <NetSalesLineChart
                  chartChosen={chartChosen}
                  setChartChosen={setChartChosen}
                  chartData={chartData.total}
                  total={chartData.total}
                  dataType="netSales"
                  name="totalBoth"
                />
              )}
          </Flex>
          {(
            <>
              {categories.map((cat, index) => {
                return (
                  <Box key={index}>
                    <Text fontWeight="600" fontSize={4} mt={4}>
                      {cat.title}
                    </Text>
                    {cat.type === "storefront" &&
                    chartData &&
                    chartData[`${cat.type}s`] &&
                    chartData[`${cat.type}s`]["hourly"] ? (
                        <Dropdown
                          options={
                            chartData[`${cat.type}s`]["hourly"] &&
                          createStorefronts(chartData[`${cat.type}s`])
                          }
                          selected={splitFilter}
                          onSelect={setSplitFilter}
                          isClearable={false}
                          isSearchable
                        />
                      ) : null}
                    <Flex justifyContent="space-between" pt={4}>
                      <StyledCard>
                        <CategoryRankingBox>
                          <Text fontWeight="600" fontSize={4}>
                            {cat.name}
                          </Text>
                          <Text fontWeight="600" fontSize={4}>
                            {cat.type === "storefront"
                              ? "Orders part of"
                              : "Orders"}
                          </Text>
                          <Text fontWeight="600" fontSize={4}>
                            Net sales
                          </Text>
                          <GridBox>
                            <Flex flexDirection="column">
                              {chartData &&
                                chartData[`${cat.type}s`] &&
                                chartData[`${cat.type}s`].split.map((brand) => {
                                  if (
                                    cat.type === "storefront" &&
                                    brand.name !== splitFilter.value
                                  )
                                    return false;
                                  return (
                                    <SpacedBox
                                      justifyContent="space-around"
                                      key={`${brand.name}&${brand.channel}`}
                                    >
                                      <Box my={2}>
                                        <Text fontWeight={600}>
                                          {cat.type === "storefront"
                                            ? brand.channel
                                            : brand.name}
                                        </Text>
                                      </Box>
                                      <Box my={2}>
                                        <Text>
                                          {`${brand.orderCount} `}
                                          <SmallSpan>{`(${(
                                            (brand.orderCount /
                                              chartData.total.totalOrders) *
                                            100
                                          ).toLocaleString(undefined, {
                                            maximumFractionDigits: 1,
                                          })}%)`}</SmallSpan>
                                        </Text>
                                      </Box>
                                      <Box my={2}>
                                        <Text>
                                          $
                                          {`${brand.netSales.toLocaleString(
                                            undefined,
                                            {
                                              maximumFractionDigits: 0,
                                            }
                                          )} `}
                                          <SmallSpan>{`(${(
                                            (brand.netSales /
                                              chartData.total.netSales) *
                                            100
                                          ).toLocaleString(undefined, {
                                            maximumFractionDigits: 1,
                                          })}%)`}</SmallSpan>
                                        </Text>
                                      </Box>
                                    </SpacedBox>
                                  );
                                })}
                            </Flex>
                          </GridBox>
                        </CategoryRankingBox>
                      </StyledCard>
                      <StyledCard flexDirection="column">
                        <Text
                          textAlign="center"
                          p={2}
                          fontWeight={600}
                          fontSize={4}
                        >
                          Net sales breakdown
                        </Text>
                        {cat.type === "storefront" ? (
                          <ResponsivePieChart
                            data={
                              chartData &&
                              chartData[`${cat.type}s`] &&
                              chartData[`${cat.type}s`].netSalesPieChart
                                .filter((d) =>
                                  d.name.includes(splitFilter.value)
                                )
                                .map((d) => ({
                                  id: d.label,
                                  value: d.y.toFixed(2),
                                }))
                            }
                          />
                        ) : (
                          <ResponsivePieChart
                            data={
                              chartData &&
                              chartData[`${cat.type}s`] &&
                              chartData[`${cat.type}s`].netSalesPieChart.map(
                                (d) => ({ id: d.label, value: d.y.toFixed(2) })
                              )
                            }
                          />
                        )}
                      </StyledCard>
                    </Flex>
                    {chartData &&
                      chartData[`${cat.type}s`] &&
                      chartData[`${cat.type}s`].hourly && (
                      <>
                        <NetSalesandOrderChart
                          chartChosen={chartChosen}
                          setChartChosen={setChartChosen}
                          chartData={chartData[`${cat.type}s`]}
                          total={chartData.total}
                          category
                          topSellers={null}
                          filter={
                            cat.type === "storefront" ? splitFilter : null
                          }
                          dataType="netSales"
                          name={`${cat.type}sNetSales`}
                          chartName={"Net Sales"}
                        />
                        <NetSalesandOrderChart
                          chartChosen={chartChosen}
                          setChartChosen={setChartChosen}
                          chartData={chartData[`${cat.type}s`]}
                          total={chartData.total}
                          category
                          topSellers={null}
                          filter={
                            cat.type === "storefront" ? splitFilter : null
                          }
                          dataType="orderCount"
                          name={`${cat.type}sOrderCount`}
                          chartName={"Order Count"}
                        />
                      </>
                    )}
                  </Box>
                );
              })}
            </>
          )} */}
        </Flex>
      )}
    </Box>
  );
};

const ChartTypeButton = ({ days, chartChosen, setChartChosen }) => {
  const chartOptions = createChartOptions(days);

  return (
    <StyledSelect
      onChange={(e) => {
        setChartChosen(e.target.value);
      }}
      fontSize="16px"
      position="absolute"
    >
      {chartOptions &&
        chartOptions.map((option) => (
          <option
            selected={chartChosen === option ? option : false}
            value={option}
            key={option}
          >
            {option}
          </option>
        ))}
    </StyledSelect>
  );
};

const createChartOptions = (days) => {
  if (days === 1) return ["Hourly"];
  if (days > 1 && days < 14) return ["Hourly", "Daily"];
  if (days >= 14 && days < 60) return ["Hourly", "Daily", "Weekly"];
  if (days >= 60) return ["Hourly", "Daily", "Weekly", "Monthly"];
  return ["None"];
};

const sort = (chartChosen, data) => {
  if (chartChosen.toLowerCase() === "hourly") {
    return data.sort((a, b) => parseInt(a.x) - parseInt(b.x));
  }

  return data.sort();
};

const NetSalesandOrderChart = ({
  chartChosen,
  setChartChosen,
  chartData,
  total,
  category,
  topSellers,
  filter,
  dataType,
  chartName,
}) => {
  let legendData = createLegendData(
    chartData[chartChosen.toLowerCase()],
    category,
    filter,
    topSellers,
    null
  );

  let data = legendData.map((l) => {
    return {
      id: l.name,
      color: l.symbol.fill,
      data: filter
        ? sort(
          chartChosen,
          chartData[chartChosen.toLowerCase()][dataType][filter.value][l.name]
        )
        : sort(
          chartChosen,
          chartData[chartChosen.toLowerCase()][dataType][l.name]
        ),
    };
  });
  if (chartChosen.toLowerCase() === "hourly") {
    data.sort((a, b) => parseInt(a.data[0].x) - parseInt(b.data[0].x));

    for (var i = 0; i < data.length; i++) {
      const intVersion = data[i].data.map((d) => parseInt(d.x));
      let smallest = intVersion[0];
      for (var a = 1; a < intVersion.length; a++) {
        let dd = intVersion[a];
        smallest += 1;
        if (smallest != dd) {
          data[i].data.splice(a, 0, { x: smallest.toString(), y: 0 });
          a--;
        } else {
          smallest = dd;
        }
      }
    }
    data.forEach((d) => d.data.sort((a, b) => parseInt(a.x) - parseInt(b.x)));
  } else {
    data.sort((a, b) => b.data.length - a.data.length);
  }

  return (
    <ChartContainer category={category}>
      <ChartTypeButton
        days={total.days}
        chartChosen={chartChosen}
        setChartChosen={setChartChosen}
      ></ChartTypeButton>
      <Box width="100%" height="500px">
        <ResponsiveLineChart
          data={data}
          legend={"Time"}
          axisLeftLegend={chartName === "Net Sales" ? "Sales" : "Count"}
          tickSize={chartChosen.toLowerCase() !== "hourly" ? 3 : 5}
          tickRotation={chartChosen.toLowerCase() !== "hourly" ? -50 : 0}
        />
      </Box>
    </ChartContainer>
  );
};

const NetSalesLineChart = ({
  chartChosen,
  setChartChosen,
  chartData,
  total,
}) => {
  let legendData = createLegendData(chartData[chartChosen.toLowerCase()]);
  let data = legendData.map((l) => {
    return {
      id: l.name,
      color: l.symbol.fill,
      data: chartData[chartChosen.toLowerCase()][l.name].sort(),
    };
  });
  if (chartChosen.toLowerCase() === "hourly") {
    data.sort((a, b) => parseInt(a.data[0].x) - parseInt(b.data[0].x));

    for (var i = 0; i < data.length; i++) {
      const intVersion = data[i].data.map((d) => parseInt(d.x));
      let smallest = intVersion[0];
      for (var a = 1; a < intVersion.length; a++) {
        let dd = intVersion[a];
        smallest += 1;
        if (smallest != dd) {
          data[i].data.splice(a, 0, { x: smallest.toString(), y: 0 });
          a--;
        } else {
          smallest = dd;
        }
      }
    }
    data.forEach((d) => d.data.sort((a, b) => parseInt(a.x) - parseInt(b.x)));
  } else {
    data.sort((a, b) => b.data.length - a.data.length);
  }
  if (chartChosen.toLowerCase() === "hourly") {
    data.sort((a, b) => parseInt(a.data[0].x) - parseInt(b.data[0].x));
  } else {
    data.sort((a, b) => b.data.length - a.data.length);
  }

  return (
    <ChartContainer>
      <ChartTypeButton
        days={total.days}
        chartChosen={chartChosen}
        setChartChosen={setChartChosen}
      ></ChartTypeButton>
      <Box width="100%" height="500px">
        <ResponsiveLineChart
          data={data}
          legend="Net Sales vs Order Count"
          axisLeftLegend="price"
          tickSize={chartChosen.toLowerCase() !== "hourly" ? 3 : 5}
          tickRotation={chartChosen.toLowerCase() !== "hourly" ? -50 : 0}
        />
      </Box>
    </ChartContainer>
  );
};

export default Charts;
