import React, {useState} from "react"; 
import { Box, Flex, Text } from "noshery-ui";
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  TypingIndicator,
} from "@chatscope/chat-ui-kit-react"; 
import useGpt from "../../hooks/useGpt";

const Bot = () => {
  const [currentMessage, setCurrentMessage] = useState({message: "", sentTime: "", sender: "User", position: "single", direction: "outgoing"}); 
  const [messages, setMessages] = useState([{
    message: "Hello! I have sales/weather data from all three stores for the last 30 days. What would you like to know?",
    sentTime: "just now",
    sender: "Ayla",
    position: "single"
  }]);

  const {fetchGpt, loading} = useGpt(); 

  return (
    <Box>
      <Flex pt={"100px"} pb={4} px="100px" flexDirection="column" bg="white">
        <Flex justifyContent="center" flexDirection="column" bg="white" p={20} mb={4}>
          <Text fontWeight="600" fontSize={5} mb={4}>
             Bot
          </Text>
        </Flex>
        
        <div style={{ position: "relative", height: "500px" }}>
          <MainContainer>
            <ChatContainer>
              <MessageList 
                typingIndicator={loading && <TypingIndicator content="Ayla is typing" />}
              > 
                {
                  messages && messages.map((message, index) => (
                    <Message
                      key={index+message.message}
                      model={{
                        message: message.message,
                        direction: message.sender === "Ayla" ? "incoming" : "outgoing",
                        sentTime: message.sentTime,
                        sender: message.sender,
                        position: message.position,
                      }}
                    />
                  ))
                }
              </MessageList> 
              <MessageInput placeholder="Type message here"
                value={currentMessage.message}

                onChange={(e, text) => {  
                  setCurrentMessage({
                    ...currentMessage,
                    message: text,
                  });
                }}  
                
                onSend={async () => {
                  setMessages([...messages, currentMessage]);
                  setCurrentMessage({message: "", sentTime: "", sender: "User", position: "single", direction: "outgoing"});
                  const result = await fetchGpt(currentMessage.message, null, null);
                  setMessages([...[...messages, currentMessage], {
                    message: result,
                    sentTime: "just now",
                    sender: "Ayla",
                    position: "single"
                  }]);

                }} />
            </ChatContainer>
          </MainContainer>
        </div>
      </Flex>
    </Box>
  );
};

export default Bot; 