import  React, { useEffect } from "react"; 

const API_URL = process.env.REACT_APP_API_URL;

export const useMenu = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [apiResults, setApiResults] = React.useState({ok: "none"});
  const [serverError, setServerError] = React.useState(null);

  const fetchMenu = async (location) => {
    setIsLoading(true);
    try {
      const res = await fetch(`${API_URL}/menuFiller?restaurantId=${location}`, {
        method: "GET",
        mode: "cors",
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
        },
      });
      const data = await res.json();
      setApiResults(data["allMenus"]);
      if(data.detail) setServerError(data); 
      setIsLoading(false);
      return data["allMenus"]; 
    } catch (error) {
      setServerError(error);
      setIsLoading(false);
    }
  };

  return { isLoading, menu: apiResults, serverError, fetchMenu};

};