import * as React from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";

const columns = [
  {
    field: "name",
    headerName: "Name",
    width: 200,
    editable: true,
  },
  {
    field: "Life Time Spend",
    headerName: "Life Time Spend",
    valueFormatter: params => `$${Math.abs(params.value).toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })}`,
    width: 200,
    editable: true,
  },
  {
    field: "Order Frequency",
    headerName: "Order Frequency",
    valueFormatter: params => `${Math.abs(params.value).toLocaleString(undefined, {
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    })}`,
    type: "number",
    width: 125,
    editable: true,
  },
  {
    field: "AOV",
    headerName: "AOV", 
    valueFormatter: params => `$${Math.abs(params.value).toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })}`,
    sortable: true,
    width: 200,
  },
  {
    field: "Total Orders",
    headerName: "Total Orders", 
    sortable: true,
    width: 200, 
  },
  {
    field: "Total Discount",
    headerName: "Total Discount", 
    valueFormatter: params => `$${Math.abs(params.value).toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })}`,
    sortable: true,
    width: 200,
  },
  { 
    field: "firstOrderDate",
    headerName: "First Order Date", 
    sortable: true,
    width: 200,
  },
  { 
    field: "lastOrderDate",
    headerName: "Last Order Date", 
    sortable: true,
    width: 200,
  }
];

export default function CustomerTable({data}) {
  return (
    <Box sx={{ height: 800, width: "100%" }}>
      <DataGrid 
        rows={data}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 20,
            },
          },
        }}
        pageSizeOptions={[20]}  
      />
    </Box>
  );
}