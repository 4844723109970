import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import styled from "styled-components";
import { Text } from "noshery-ui";
const SmallSpan = styled.span`
  font-size: 12px;
`;


export const DesktopTable = ({rows, cat, chartData, splitFilter, otherTotal}) => {
  const [curRows, setCurRows] = React.useState(rows);
  const [change, setChange] = React.useState(false);
  const [countTotal, setCountTotal] = React.useState(0);
  const [salesTotal, setSalesTotal] = React.useState(0);

  React.useEffect(() => {
    setCurRows(rows);
  }, [rows]);

  React.useEffect(() => {
    setCountTotal(0);
    setSalesTotal(0);
    rows.forEach((row) => {
      if(!row.orderCount) return;
      if(!row.netSales) return;
      setCountTotal((prev) => prev + row.orderCount);
      setSalesTotal((prev) => prev + row.netSales);
    });
  }, [rows, otherTotal]);


  React.useEffect(() => {
    setCurRows(cat.type === "storefront" ? rows.filter((row) => row.name === splitFilter.value) : rows);
  }, [splitFilter]);

  return (
    <TableContainer>
      <Table sx={{ minWidth: 550, width: "100%" }} aria-label="data table">
        <TableHead sx={{backgroundColor: "rgb(0, 0, 0, 0.03) !important", zIndex: 1}}>
          <TableRow>
            <TableCell sx={{fontSize: "16px", fontWeight: "900"}}>Name</TableCell>
            <TableCell sx={{fontSize: "16px", fontWeight: "900"}}>Count</TableCell>
            <TableCell sx={{fontSize: "16px", fontWeight: "900"}}>Sales</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {curRows.map((brand, index) => {
            if(brand.name !== splitFilter.value && cat.type === "storefront") {
              return null;
            }
            return <TableRow
              key={index}
              hover
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell sx={{fontSize: "15px",}} component="th" scope="row">
                <Text style={{fontWeight: "600"}}>{cat.type === "storefront" || cat.type === "catering" 
                  ? brand.channel
                  : brand.name}   
                </Text>
              </TableCell>
              <TableCell  sx={{fontSize: "15px",}}>
                <Text style={{fontWeight: "600"}}>{`${brand.orderCount} `} </Text>
                <SmallSpan>{`(${(
                  (brand.orderCount /
                  (otherTotal && otherTotal.orderCount ? otherTotal.orderCount :
                    chartData.total.totalOrders)) * 100
                ).toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                })}%)`}</SmallSpan>
              </TableCell>
              <TableCell  sx={{fontSize: "15px",}}>
                <Text style={{fontWeight: "600"}}>$
                  {`${brand.netSales.toLocaleString(
                    undefined,
                    {
                      maximumFractionDigits: 2,
                    }
                  )} `}</Text>
                <SmallSpan>{`(${(
                  (brand.netSales /
                  (otherTotal && otherTotal.netSales ? otherTotal.netSales : chartData.total.netSales)) *
                 100
                ).toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                })}%)`}</SmallSpan>
              </TableCell>
            </TableRow>;
          })}
          <TableRow
            key={"total"}
            sx={{ "&:last-child td, &:last-child th": { border: 0 }, backgroundColor: "#F1A638" }}
          >
            <TableCell sx={{fontSize: "15px",}} component="th" scope="row">
              <Text style={{fontWeight: "600"}}>Total</Text>
            </TableCell>
            <TableCell  sx={{fontSize: "15px",}}>
              <Text style={{fontWeight: "600"}}>{`${countTotal}`} </Text>
            </TableCell>
            <TableCell  sx={{fontSize: "15px",}}>
              <Text style={{fontWeight: "600"}}>$
                {`${(salesTotal).toLocaleString(
                  undefined,
                  {
                    maximumFractionDigits: 2,
                  }
                )} `}</Text>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export const DesktopTableCategories = ({rows, cat, chartData, otherTotal}) => {
  const [curRows, setCurRows] = React.useState(rows); 
  const [countTotal, setCountTotal] = React.useState(0);
  const [salesTotal, setSalesTotal] = React.useState(0);

  React.useEffect(() => {
    setCountTotal(0);
    setSalesTotal(0);
    rows.forEach((row) => {
      if(!row.orderCount) return;
      if(!row.netSales) return;
      setCountTotal((prev) => prev + row.orderCount);
      setSalesTotal((prev) => prev + row.netSales);
    });
  }, [rows]);
 
  return (
    <TableContainer>
      <Table sx={{ minWidth: 550, width: "100%" }} aria-label="data table">
        <TableHead sx={{backgroundColor: "rgb(0, 0, 0, 0.03) !important", zIndex: 1}}>
          <TableRow>
            <TableCell sx={{fontSize: "16px", fontWeight: "900"}}>Name</TableCell>
            <TableCell sx={{fontSize: "16px", fontWeight: "900"}}>Count</TableCell>
            <TableCell sx={{fontSize: "16px", fontWeight: "900"}}>Sales</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {curRows.map((brand, index) => { 
            return <TableRow
              key={index}
              hover
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell sx={{fontSize: "15px",}} component="th" scope="row">
                <Text style={{fontWeight: "600"}}>{ brand.category }   
                </Text>
              </TableCell>
              <TableCell  sx={{fontSize: "15px",}}>
                <Text style={{fontWeight: "600"}}>{`${brand.orderCount} `} </Text>
                <SmallSpan>{`(${(
                  (brand.orderCount /
                  (otherTotal ? otherTotal.orderCount :
                    Object.keys(chartData.items.daily.orderCount).reduce((acc, cur) => {
                      const curTotal = chartData.items.daily.orderCount[cur].reduce((acc, cur) => {
                        return acc += cur.y;
                      }, 0);
                      return acc += curTotal;
                    }, 0))) * 100
                ).toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                })}%)`}</SmallSpan>
              </TableCell>
              <TableCell  sx={{fontSize: "15px",}}>
                <Text style={{fontWeight: "600"}}>$
                  {`${brand.netSales.toLocaleString(
                    undefined,
                    {
                      maximumFractionDigits: 2,
                    }
                  )} `}</Text>
                <SmallSpan>{`(${(
                  (brand.netSales /
                   otherTotal ? otherTotal.netSales : chartData.total.netSales) *
                 100
                ).toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                })}%)`}</SmallSpan>
              </TableCell>
            </TableRow>;
          })}
          <TableRow
            key={"total"}
            sx={{ "&:last-child td, &:last-child th": { border: 0 }, backgroundColor: "#F1A638" }}
          >
            <TableCell sx={{fontSize: "15px",}} component="th" scope="row">
              <Text style={{fontWeight: "600"}}>Total</Text>
            </TableCell>
            <TableCell  sx={{fontSize: "15px",}}>
              <Text style={{fontWeight: "600"}}>{`${countTotal}`} </Text>
            </TableCell>
            <TableCell  sx={{fontSize: "15px",}}>
              <Text style={{fontWeight: "600"}}>$
                {`${(salesTotal).toLocaleString(
                  undefined,
                  {
                    maximumFractionDigits: 2,
                  }
                )} `}</Text>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export const ComparisonsTable = ({keysOrders, chartData, chartData2, keyOrdersMap, startDate, endDate, title}) => {
  return <TableContainer>
    <Table sx={{ minWidth: 550, width: "100%" }} aria-label="data table">
      <TableHead sx={{backgroundColor: "rgb(0, 0, 0, 0.03) !important", zIndex: 1}}>
        <TableRow>
          <TableCell sx={{fontSize: "16px", fontWeight: "900"}}> {title} </TableCell>
          <TableCell sx={{fontSize: "16px", fontWeight: "900"}}> {startDate} </TableCell>
          <TableCell sx={{fontSize: "16px", fontWeight: "900"}}>  {endDate} </TableCell>
          <TableCell sx={{fontSize: "16px", fontWeight: "900"}}>  Percent Difference </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {keysOrders.map((key, i) => {
          return <TableRow
            key={key+i}
            hover
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
          >
            <TableCell sx={{fontSize: "15px",}} component="th" scope="row">
              <Text style={{fontWeight: "600"}}>{key}   
              </Text>
            </TableCell>
            <TableCell sx={{fontSize: "15px",}} component="th" scope="row">
              <Text fontSize={3} mb={2}> 
                {(chartData.total[keyOrdersMap[key]] || 0).toFixed(1).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </Text>
            </TableCell>
            <TableCell  sx={{fontSize: "15px",}}>
              <Text fontSize={3} mb={2}> 
                {(chartData2.total[keyOrdersMap[key]] || 0).toFixed(1).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </Text>
            </TableCell>
            <TableCell  sx={{fontSize: "15px", }}>
              <Text fontSize={3} mb={2} fontWeight="600" color={
                ((chartData2.total[keyOrdersMap[key]]-chartData.total[keyOrdersMap[key]])/chartData.total[keyOrdersMap[key]] * 100).toFixed(1).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") === "0.0" ? "black" :
                  ((chartData2.total[keyOrdersMap[key]]-chartData.total[keyOrdersMap[key]])/chartData.total[keyOrdersMap[key]]) === Infinity 
                    ? 100 : 
                    ((chartData2.total[keyOrdersMap[key]]-chartData.total[keyOrdersMap[key]])/chartData.total[keyOrdersMap[key]]) < 0 ? "red" : "#4C9A2A"
              }> 
                {(((chartData2.total[keyOrdersMap[key]]-chartData.total[keyOrdersMap[key]])/chartData.total[keyOrdersMap[key]]) === Infinity 
                  ? 100 : 
                  ((chartData2.total[keyOrdersMap[key]]-chartData.total[keyOrdersMap[key]])/chartData.total[keyOrdersMap[key]]) * 100).toFixed(1).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}% 
              </Text>
            </TableCell>
          </TableRow>;
        })}
      </TableBody>
    </Table>
  </TableContainer>;
};

export const SalesTable = ({keysOrders, chartData, chartData2, startDate, endDate, title}) => {
  return <TableContainer>
    <Table sx={{ minWidth: 550, width: "100%" }} aria-label="data table">
      <TableHead sx={{backgroundColor: "rgb(0, 0, 0, 0.03) !important", zIndex: 1}}>
        <TableRow>
          <TableCell sx={{fontSize: "16px", fontWeight: "900"}}> {title.charAt(0).toUpperCase() + title.slice(1)} </TableCell>
          <TableCell sx={{fontSize: "16px", fontWeight: "900"}}> {startDate} </TableCell>
          <TableCell sx={{fontSize: "16px", fontWeight: "900"}}>  {endDate} </TableCell>
          <TableCell sx={{fontSize: "16px", fontWeight: "900"}}>  Percent Difference </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {keysOrders.map((key) => {
          return <TableRow
            key={key}
            hover
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
          >
            <TableCell sx={{fontSize: "15px",}} component="th" scope="row">
              <Text style={{fontWeight: "600"}}>{key}   
              </Text>
    
            </TableCell>
            <TableCell sx={{fontSize: "15px",}} component="th" scope="row">
              <Text fontSize={3} mb={2}> 
                {chartData[key].toFixed(1).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </Text>
            </TableCell>
            <TableCell  sx={{fontSize: "15px",}}>
              <Text fontSize={3} mb={2}> 
                {chartData2[key].toFixed(1).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </Text>
            </TableCell>
            <TableCell  sx={{fontSize: "15px", }}>
              <Text fontSize={3} mb={2} fontWeight="600" color={
                ((chartData2[key]-chartData[key])/chartData[key] * 100).toFixed(1).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") === "0.0" ? "black" :
                  ((chartData2[key]-chartData[key])/chartData[key]) === Infinity 
                    ? 100 : 
                    ((chartData2[key]-chartData[key])/chartData[key]) < 0 ? "red" : "#4C9A2A"
              }> 
                {(((chartData2[key]-chartData[key])/chartData[key]) === Infinity 
                  ? 100 : 
                  ((chartData2[key]-chartData[key])/chartData[key]) * 100).toFixed(1).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}%
              </Text>
            </TableCell>
          </TableRow>;
        })}
      </TableBody>
    </Table>
  </TableContainer>;
};

export const ItemsTableComparisons = ({keysOrders, chartData, chartData2, startDate, endDate, title, itemBrandMaps}) => {
  const [sort, setSort] = React.useState("asc"); 
  const [chosen, setChosen] = React.useState("chartData");

 

  return <TableContainer>
    <Table sx={{ minWidth: 550, width: "100%", overflow: "scroll" }} aria-label="data table">
      <TableHead sx={{backgroundColor: "rgb(0, 0, 0, 0.03) !important", zIndex: 1}}>
        <TableRow>
          <TableCell sx={{fontSize: "16px", fontWeight: "900"}}> {title.charAt(0).toUpperCase() + title.slice(1)} </TableCell>
          <TableCell
            sx={{fontSize: "16px", fontWeight: "900", "&:hover": {
              cursor: "pointer",
              backgroundColor: "#c4c4c4"
            }}} 
            onClick={() => {
              setSort(sort === "asc" ? "desc" : "asc");
              setChosen("chartData");
            }}
          > {startDate} </TableCell>
          <TableCell 
            sx={{fontSize: "16px", fontWeight: "900", "&:hover": {
              cursor: "pointer",
              backgroundColor: "#c4c4c4"
            }}} 
            onClick={() => {
              setSort(sort === "asc" ? "desc" : "asc");
              setChosen("chartData2");
            }}
          >  {endDate} </TableCell>
          <TableCell 
            sx={{fontSize: "16px", fontWeight: "900", "&:hover": {
              cursor: "pointer",
              backgroundColor: "#c4c4c4"
            }}} 
            onClick={() => {
              setSort(sort === "asc" ? "desc" : "asc");
              setChosen("difference");
            }}
          >  Difference </TableCell>
          <TableCell sx={{fontSize: "16px", fontWeight: "900"}}> Percent Difference </TableCell>
          <TableCell 
            sx={{fontSize: "16px", fontWeight: "900", "&:hover": {
              cursor: "pointer",
              backgroundColor: "#c4c4c4"
            }}} 
            onClick={() => {
              setSort(sort === "asc" ? "desc" : "asc");
              setChosen("brand");
            }} > Brand </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {keysOrders.sort((a, b) => {
          if(chosen === "brand") {  
            if(sort === "asc") {
              return itemBrandMaps[a] > itemBrandMaps[b] ? 1 : -1;
            }
            return itemBrandMaps[b] < itemBrandMaps[a] ? -1 : 1;
          }
          else if(chosen === "chartData") {
            if(sort === "asc") {
              return chartData[a] - chartData[b];
            }
            return chartData[b] - chartData[a]; 
          } 
          else if(chosen === "difference") {  
            if(sort === "asc") {
              return (chartData[a] - chartData[b]) ? -1 : 1;
            }
            return (chartData[b] - chartData[a]) ? 1 : -1; 
          }
          else {
            if(sort === "asc") {
              return chartData2[a] - chartData2[b];
            }
            return chartData2[b] - chartData2[a]; 
          }
        }).map((key) => {
          return <TableRow
            key={key}
            hover
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
          >
            <TableCell sx={{fontSize: "15px",}} component="th" scope="row">
              <Text style={{fontWeight: "600"}}>{key}   
              </Text>
  
            </TableCell>
            <TableCell sx={{fontSize: "15px",}} component="th" scope="row">
              <Text fontSize={3} mb={2}> 
                {chartData[key].toFixed(1).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </Text>
            </TableCell>
            <TableCell  sx={{fontSize: "15px",}}>
              <Text fontSize={3} mb={2}> 
                {chartData2[key].toFixed(1).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </Text>
            </TableCell>
            <TableCell  sx={{fontSize: "15px",}}>
              <Text fontSize={3} mb={2}> 
                {(chartData2[key] - chartData[key]).toFixed(1).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </Text>
            </TableCell>
            <TableCell  sx={{fontSize: "15px", }}>
              <Text fontSize={3} mb={2} fontWeight="600" color={
                ((chartData2[key]-chartData[key])/chartData[key] * 100).toFixed(1).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") === "0.0" ? "black" :
                  ((chartData2[key]-chartData[key])/chartData[key]) === Infinity 
                    ? 100 : 
                    ((chartData2[key]-chartData[key])/chartData[key]) < 0 ? "red" : "#4C9A2A"
              }> 
                {(((chartData2[key]-chartData[key])/chartData[key]) === Infinity 
                  ? 100 : 
                  ((chartData2[key]-chartData[key])/chartData[key]) * 100).toFixed(1).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}%
              </Text>
            </TableCell>
            <TableCell  sx={{fontSize: "15px",}}>
              <Text fontSize={3} mb={2}> 
                {itemBrandMaps && itemBrandMaps[key] ? itemBrandMaps[key] : "N/A"}
              </Text>
            </TableCell>
          </TableRow>;
        })}
      </TableBody>
    </Table>
  </TableContainer>;
};