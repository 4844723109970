import * as React from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";

const columns = [
  { field: "month", headerName: "Month", width: 250, },
  {
    field: "totalCustomers",
    headerName: "Total Customers",
    width: 250,
    editable: true,
  },
  {
    field: "newCustomers",
    headerName: "New Customers",
    width: 250,
    editable: true,
  },
  {
    field: "customerRetention",
    headerName: "MoM Retention",
    type: "number",
    valueFormatter: params => `${params.value}%`,
    width: 250,
    editable: true,
  },
];


export default function RetentionTable({data}) {
  return (
    <Box sx={{ height: 650, width: "100%" }}>
      <DataGrid 
        rows={data}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        pageSizeOptions={[10]}  
      />
    </Box>
  );
}