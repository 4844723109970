import React from "react"; 

const API_URL = process.env.REACT_APP_API_URL;

const useGpt = () => {
  const [results, setResults] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);

  const fetchGpt = async (prompt, history, memory) => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetch(`${API_URL}/gpt`, {
        method: "POST", 
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({prompt, history, memory}),
      });
      const data = await response.json();
      setResults(data.data);
      setLoading(false);
      return data.data;
    } catch (error) {
      console.log(error);
      setError(error);
      setResults("");
      setLoading(false);
      return "Could not query gpt.";
    }
  }; 

  return { results, loading, error, fetchGpt };

};

export default useGpt;