import React, { useEffect } from "react";
import { Flex, Box, Text, Button } from "noshery-ui";
import moment from "moment";
import Dropdown from "../../components/Dropdown";
import useCharts from "../../hooks/useCharts";
import styled from "styled-components";
import PageLoader from "../../components/PageLoader";
import { restaurantColors } from "../../constants";
import BestSellerTable from "../../components/BestSellerTable";
import IngredientsTable from "../../components/Ingredients";
import { isDataEmpty } from "../../utils/validators";
import NoData from "../../components/NoData";
import LaborCost from "../../components/charts/LaborChart";
import ResponsiveLineChart from "../../components/ResponsiveLineChart";
import ResponsivePieChart from "../../components/ResponsivePieChart";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import {DesktopTable, DesktopTableCategories} from "../../components/Tables";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { Typography } from "@mui/material"; 
import { useLocationCharts } from "../../hooks/useLocation";
import { CSVLink } from "react-csv"; 
import { MenuItem, Select, InputLabel, FormControl } from "@mui/material"; 

import { styled as MUIStyle } from "@mui/material/styles"; 
import ResponsiveBarGraph from "../../components/ResponsiveBarGraph";

const dateRangeOption = {
  today: "Today",
  yesterday: "Yesterday",
  thisWeek: "This week",
  lastWeek: "Last week",
  thisMonth: "This month",
  lastMonth: "Last month",
  thisYear: "This year",
  allTime: "All time",
  custom: "Custom",
};


const StyledSelect = styled.select`
  border-radius: 10px;
  box-shadow: 0 0 3px 0 rgb(0, 0, 0, 0.2);
  border: none;
  background-color: white;
  font-size: ${(props) => (props.fontSize ? props.fontSize : "20px")}
  ${(props) =>
    props.position
      ? "position: absolute; right: 8px; top: 8px; z-index: 1;"
      : ""}
  padding: 12px;
  font-weight: 600;
  &:hover,
  &:focus {
    cursor: pointer;
  }
  :focus-visible {
    outline: solid orange 2px;
  }
`;

const ChartContainer = styled(Box)`
  margin-bottom: 20px;
  margin-left: ${({ category }) => (category ? "0" : "16px")};
  box-shadow: 0 0 3px 0 rgb(0, 0, 0, 0.2);
  width: 100%;
  height: fit-content;
  position: relative;
  background-color: white;
  border-radius: 10px;
  padding: 10px;
`;

const NumberSpan = styled.span`
  font-weight: 700;
`;

const StyledCard = styled(Flex)`
  flex-direction: ${(props) =>
    props.flexDirection ? props.flexDirection : "row"}
  border-radius: 10px;
  box-shadow: 0 0 3px 0 rgb(0, 0, 0, 0.2);
  margin-bottom: 20px;
  ${(props) =>
    props.fullwidth
      ? "width: auto; justify-content: space-around;"
      : props.datePicker
        ? "width: 50%; justify-content: space-around;"
        : "width: 100% !important;"}
  background-color: white;
  max-height: 500px;
  margin-right: 20px;
`;

const Divider = styled(Box)`
  height: 2px;
  background-color: black;
  margin: 0 8px;
`;
 
const Accordion = MUIStyle((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = MUIStyle((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, 0)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = MUIStyle(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const colors = ["orange", "#FF8C69", "black", "darkblue", "teal"];

const gray = "#585858";
const createLegendData = (data, multiple, filter, topSellers, items) => {
  if (!multiple) {
    return Object.keys(data).map((key, i) => {
      return {
        name: key,
        symbol: { fill: items ? colors[i] : restaurantColors[key] },
      };
    });
  }
  if (topSellers !== null) {
    return topSellers.map((key, i) => {
      return {
        name: key.name,
        symbol: { fill: items ? colors[i] : restaurantColors[key] },
      };
    });
  } else {
    return filter && data["netSales"][`${filter.value}`] 
      ? Object.keys(data["netSales"][`${filter.value}`]).map((key, i) => {
        return {
          name: key,
          symbol: { fill: items ? colors[i] : restaurantColors[key] },
        };
      })
      : Object.keys(data["netSales"]).map((key, i) => {
        return {
          name: key,
          symbol: { fill: items ? colors[i] : restaurantColors[key] },
        };
      });
  }
};

const getMinMax = (data, type) => {
  let min = 5000;
  let max = 0;
  Object.keys(data).forEach((time) => {
    if (data[time].y < min) {
      min = data[time].y;
    }
    if (data[time].y > max) {
      max = data[time].y;
    }
  });
  if (min === 5000) min = 0;
  if (type === "Max") return max.toFixed(1).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  if (type === "Min") return min.toFixed(1).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const getPeakandLows = (chartData, range, type) => {
  if (range === "today" || range === "yesterday") {
    return getMinMax(chartData.total.hourly.netSales, type);
  }
  if (range === "lastWeek" || range === "thisWeek") {
    return getMinMax(chartData.total.daily.netSales, type);
  }
  if (range === "thisMonth" || range === "lastMonth") {
    return getMinMax(chartData.total.daily.netSales, type);
  }
  if (range === "thisYear" || range === "allTime") {
    return getMinMax(chartData.total.weekly.netSales, type);
  } else {
    return getMinMax(chartData.total.hourly.netSales, type);
  }
};

const createStorefronts = (data) => {
  if (!data["hourly"]) return { label: "", value: "" };
  let filters = Object.keys(data["hourly"]["netSales"]).map((name) => {
    return {
      label: name,
      value: name,
    };
  });
  return filters;
};

const Charts = () => {
  const {
    chartChosen,
    setChartChosen,
    setDateRange,
    startDate,
    endDate,
    chartData,
    loading,
    onSelectStartDate,
    onSelectEndDate,
    setLocation,
    dateRange,
  } = useCharts();

  const [filter, setFilter] = React.useState("Total");

  const {locations: locationsOption} = useLocationCharts();

  useEffect(() => {
    if (Object.values(locationsOption).length > 0) {
      setLocation(Object.values(locationsOption)[0]);
    }
  }, [locationsOption]);

  const categories = [
    { title: "Brand split", type: "brand", name: "Brand" },
    { title: "Channel split", type: "channel", name: "Channel" },
    { title: "Storefront split", type: "storefront", name: "Storefront" },
    {
      title: "1st vs 3rd Party",
      type: "FirstVsThird",
      name: "First Vs Third Party",
    },
  ];

  const [splitFilter, setSplitFilter] = React.useState({
    label: "Noshery",
    value: "Noshery",
  });
  const [category, setCategory] = React.useState("Sales");
  const [topSellers, setTopSellers] = React.useState([
    { name: "", netSales: "", orderCount: "" },
  ]);  
  const [expanded, setExpanded] = React.useState("panel");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  useEffect(() => {
    if(chartData && chartData.total && chartData.total.days && chartData.total.days > 31) {
      setChartChosen("Monthly");
    }
  }, [chartChosen]);

  return (
    <Box>
      <Flex pt={"100px"} pb={4} pl="100px" pr="30px" flexDirection="column" bg="#f2f2f2">
        <Flex w="100%" mb={4}>
          <Flex w="100%" mr="1%" justifyContent="space-between">
            <Flex w="100%" justifyContent="space-between">
              <Box paddingRight="10px">
                <StyledSelect
                  onChange={(e) => setLocation(locationsOption[e.target.value])}
                >
                  {Object.keys(locationsOption).map((d) => (
                    <option value={d} key={d}>
                      {d}
                    </option>
                  ))}
                </StyledSelect>
              </Box>
              <Box>
                <StyledSelect
                  value={dateRange}
                  onChange={(e) => setDateRange(e.target.value)}
                >
                  {Object.keys(dateRangeOption).map((d) => (
                    <option value={d} key={d}>
                      {dateRangeOption[d]}
                    </option>
                  ))}
                </StyledSelect>
              </Box>
            </Flex>
          </Flex>
          <Box w="100%">
            <StyledSelect onChange={(e) => setCategory(e.target.value)}>
              <option value="Sales">Sales</option>
              <option value="Menu">Menu</option>
              <option value="Ingredients">Ingredients</option>
              <option value="Labor">Labor</option>
            </StyledSelect>
          </Box>
        </Flex>
        
        <Flex
          style={{ width: "40%", borderRadius: "10px" }}
          py="20px"
          px="10px"
          bg="white"
        >
          <Flex
            alignItems="center"
            mr={3}
            ml={2}
            style={{width: "100%"}}
            onClick={() => setDateRange("custom")}
          >
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DesktopDatePicker
                label="Start Date"
                inputFormat="YYYY-MM-DD"
                minDate={moment("2021-11-12")}
                maxDate={moment()}
                value={moment.isMoment(startDate) ? startDate : moment(startDate)}
                onChange={onSelectStartDate}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </Flex>
          <Flex
            alignItems="center"
            mr={2}
            style={{width: "100%"}}
            onClick={() => setDateRange("custom")}
          >
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DesktopDatePicker
                label="End Date"
                inputFormat="YYYY-MM-DD"
                maxDate={moment()}
                minDate={ moment.isMoment(startDate) ? startDate : moment(startDate)}
                value={
                  moment.isMoment(endDate) ? endDate : moment(endDate)}
                onChange={onSelectEndDate}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </Flex>
        </Flex>

        <CSVLink style={{ alignSelf: "end", display: "flex", width: "100vw !important", alignContent: "center", justifyContent: "end", alignItems: "center"}}
          data={(() => {
            const data = {}; 
            const channels = {};
            const brands = {};
            const labor = {};

            if(!isDataEmpty(chartData) && !loading && chartData && chartData.total && chartData.total.daily && chartData.total.daily.netSales) {
              chartData.total.daily.netSales.forEach((d) => {
                const monthDate = moment(d.x).format("MM-YYYY");
                if(!data[monthDate]) data[monthDate] = 0;
                data[monthDate] += d.y;
              });
            }

            if(!isDataEmpty(chartData) && !loading && chartData && chartData.channels && chartData.channels.daily && chartData.channels.daily.netSales) {
              Object.keys(chartData.channels.daily.netSales).forEach((d) => {
                const channel = d; 
                chartData.channels.daily.netSales[channel].forEach((d) => {
                  const monthDate = moment(d.x).format("MM-YYYY");
                  if(!channels[channel]) channels[channel] = {};
                  if(!channels[channel][monthDate]) channels[channel][monthDate] = 0;
                  channels[channel][monthDate] += d.y;
                });
              });
              
            }

            if(!isDataEmpty(chartData) && !loading && chartData && chartData.brands && chartData.brands.daily && chartData.brands.daily.netSales) {
              Object.keys(chartData.brands.daily.netSales).forEach((d) => {
                const brand = d; 
                chartData.brands.daily.netSales[brand].forEach((d) => {
                  const monthDate = moment(d.x).format("MM-YYYY");
                  if(!brands[brand]) brands[brand] = {};
                  if(!brands[brand][monthDate]) brands[brand][monthDate] = 0;
                  brands[brand][monthDate] += d.y;
                });
              });
              
            }

            if(!isDataEmpty(chartData) && !loading && chartData && chartData.laborDaily) {
              Object.keys(chartData.laborDaily).forEach((d) => {
                const monthDate = moment(d).format("MM-YYYY");
                if(!labor[monthDate]) labor[monthDate] = 0;
                labor[monthDate] += chartData.laborDaily[d];
              });
            }

            const allDataByMonths = Object.keys(data).map((d) => ({ date: d, netSales: data[d] }));
            const allDataByChannels = Object.keys(channels).map((d) => {
              const channel = d;
              return Object.keys(channels[channel]).map((d) => ({ channel, date: d, netSales: channels[channel][d] }));
            });
            const allDataByBrands = Object.keys(brands).map((d) => {
              const brand = d;
              return Object.keys(brands[brand]).map((d) => ({ brand, date: d, netSales: brands[brand][d] }));
            });
            const allDataByLabor = Object.keys(labor).map((d) => ({ date: d, labor: labor[d] }));
            const allData = [...allDataByMonths, ...allDataByChannels.flat(), ...allDataByBrands.flat(), ...allDataByLabor];

            return allData;
          })()}
          filename={`sd-data-${moment().format("YYYY-MM-DD:HH:MM")}.csv`}
        >
          <Button disabled={(loading && !isDataEmpty(chartData))}> Download Data  </Button>
        </CSVLink>
      </Flex>

 
      {loading ? (
        <PageLoader />
      ) : isDataEmpty(chartData) ? (
        <NoData />
      ) : (
        <Flex
          pl="100px"
          pr="30px"
          flexDirection="column"
          minHeight="100vh"
          bg="#f2f2f2"
        >
          <StyledCard>
            <Text fontSize={4} p={4}>
              Net Sales:{" "}
              <NumberSpan>
                $
                {chartData &&
                  chartData.total &&
                  chartData.total.netSales &&
                  chartData.total.netSales.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                  })}
              </NumberSpan>
            </Text>
            <Text fontSize={4} p={4}>
              Total Orders:{" "}
              <NumberSpan>
                {chartData &&
                  chartData.total &&
                  chartData.total.totalOrders.toLocaleString(undefined, {})}
              </NumberSpan>
            </Text>
            <Text fontSize={4} p={4}>
            Test/Employee Orders:{" "}
              <NumberSpan>
                {chartData &&
                  chartData.total &&
                  chartData.total.testOrders.toLocaleString(undefined, {})}
              </NumberSpan>
            </Text>
            <Text fontSize={4} p={4}>
            Test/Employee Total:{" "}
              <NumberSpan>
                ${chartData &&
                  chartData.total &&
                  chartData.total.testSales.toLocaleString(undefined, {})}
              </NumberSpan>
            </Text>
            <Text fontSize={4} p={4}>
              AOV:{" "}
              <NumberSpan>
                $
                {chartData && chartData.total && chartData.total.aov
                  ? chartData.total.aov.toFixed(2)
                  : 0}
              </NumberSpan>
            </Text>
            <Text fontSize={4} p={4}>
              AOV {"(Orders > $5 & No Catering)"}:{" "}
              <NumberSpan>
                $
                {chartData && chartData.total && chartData.total.nonCateringAov > 0
                  ? chartData.total.nonCateringAov.toFixed(2)
                  : 0}
              </NumberSpan>
            </Text>
            <Text fontSize={4} p={4}>
              Tips per hour:
              <NumberSpan>
                $
                {chartData &&
                    chartData.total &&
                    chartData.total.totalTip &&
                    chartData.total.hourly && 
                    chartData.total.hourly.netSales && 
                    (chartData.total.totalTip/chartData.total.hourly.netSales.length).toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
              </NumberSpan>
            </Text>
          </StyledCard>
          <Flex justifyContent="space-between">
            <StyledCard flexDirection="column" p="18px">
              <Flex justifyContent="space-between">
                <Text fontSize={5} p={2}  mr="60px" >
                  Net sales
                </Text>
                <Text fontSize={5} p={2} fontWeight={600}>
                  $
                  {chartData &&
                    chartData.total &&
                    chartData.total.netSales &&
                    chartData.total.netSales.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                    })}
                </Text>
              </Flex>
              <Flex justifyContent="space-between">
                <Text fontSize={4} p={2} mr="60px" color={gray}>
                  Non catering net sales
                </Text>
                <Text fontSize={4} p={2} fontWeight={600} color={gray}>
                  $
                  {chartData &&
                    chartData.total &&
                    chartData.total.netSales &&
                    chartData.channels &&
                    chartData.channels.split &&
                    (
                      (chartData.total.netSales - (chartData["channels"]["split"].reduce((acc, channel) => {
                        if (channel.name === "Forkable" || channel.name === "Relish" || channel.name === "Cater2me" || channel.name === "Noshery Catering" || channel.name === "ezcater" || channel.name === "Sharebite" || channel.name === "Doordash Catering" || channel.name === "Fooda" || channel.name === "Hungry" ) {
                          return acc += channel.netSales;
                        } else return acc;
                        
                      }, 0))).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }) 
                    )
                  }
                </Text>
              </Flex>
              <Flex justifyContent="space-between">
                <Text fontSize={4} p={2} mr="60px" color={gray}>
                  Catering net sales
                </Text>
                <Text fontSize={4} p={2} fontWeight={600} color={gray}>
                  $
                  {chartData &&
                    chartData.channels &&
                    chartData.channels.split &&
                    (chartData["channels"]["split"].reduce((acc, channel) => {
                      if (channel.name === "Forkable" || channel.name === "Relish" || channel.name === "Cater2me" || channel.name === "Noshery Catering" || channel.name === "ezcater" || channel.name === "Sharebite" || channel.name === "Doordash Catering" || channel.name === "Fooda" || channel.name === "Hungry" ) {
                        return acc += channel.netSales;
                      } else return acc;
                    }, 0)).toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  }
                </Text>
              </Flex>
              <Flex justifyContent="space-between">
                <Text fontSize={4} p={2} mr="60px" color={gray}>
                  Taxes
                </Text>
                <Text fontSize={4} p={2} fontWeight={600} color={gray}>
                  $
                  {chartData &&
                    chartData.total &&
                    chartData.total.totalTax &&
                    chartData.total.totalTax.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                    })}
                </Text>
              </Flex>
              <Flex justifyContent="space-between">
                <Text fontSize={4} p={2} mr="60px" color={gray}>
                  Tips
                </Text>
                <Text fontSize={4} p={2} fontWeight={600} color={gray}>
                  $
                  {chartData &&
                    chartData.total &&
                    chartData.total.totalTip &&
                    chartData.total.totalTip.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                    })}
                </Text>
              </Flex>
              {chartData &&
                    typeof(chartData.totalGiftCardLoad) === "number" && 
                    chartData.totalGiftCardLoad > 0 &&
              <Flex justifyContent="space-between">
                <Text fontSize={4} p={2} mr="60px" color={gray}>
                  Gift Card Loaded
                </Text>
                <Text fontSize={4} p={2} fontWeight={600} color={gray}>
                  $
                  {
                    chartData.totalGiftCardLoad.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                    })}
                </Text>
              </Flex>
              }
              {chartData &&
                    typeof(chartData.totalGiftCardTransactions) === "number" &&
                    chartData.totalGiftCardTransactions > 0 &&
                <Flex justifyContent="space-between">
                  <Text fontSize={4} p={2} mr="60px" color={gray}>
                  Gift Card Transactions 
                  </Text>
                  <Text fontSize={4} p={2} fontWeight={600} color={gray}>
                  $
                    {
                      chartData.totalGiftCardTransactions.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                      })}
                  </Text>
                </Flex>
              }
              <Divider />
              <Flex justifyContent="space-between">
                <Text fontSize={5} p={2} mr="60px">
                  Total
                </Text>
                <Text fontSize={5} p={2} fontWeight={600}>
                  $
                  {chartData &&
                    chartData.total &&
                    chartData.total.netSales &&
                    chartData.total.totalTax &&
                    chartData.total.totalTip >= 0 &&
                    (
                      chartData.total.netSales +
                      chartData.total.totalTax + 
                      chartData.total.totalTip
                    ).toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                    })}
                  <Text as="span" alignText="right" paddingLeft={"10%"}>
                    {" "}
                    {chartData &&
                      chartData.percentDifferenceSales !== 0 &&
                      (chartData.percentDifferenceSales > 0 ? (
                        <Text color="#228C22" fontSize={3} w="100%" alignText="right">
                          DoD: +{(chartData.percentDifferenceSales || 0).toFixed(0)}%
                        </Text>
                      ) : (
                        <Text color="red" fontSize={3} w="100%" alignText="right">
                          DoD: {(chartData.percentDifferenceSales || 0).toFixed(0)}%
                        </Text>
                      ))}
                    {" "}
                    {chartData &&
                      chartData.sdlw !== 0 &&
                      (chartData.sdlw > 0 ? (
                        <Text color="#228C22" fontSize={3} w="100%" alignText="right">
                          SDLW: +{(chartData.sdlw || 0).toFixed(0)}%
                        </Text>
                      ) : (
                        <Text color="red" fontSize={3} w="100%" alignText="right">
                          SDLW: {(chartData.sdlw || 0).toFixed(0)}%
                        </Text>
                      ))}
                  </Text>
                </Text>
              </Flex>
              <Text fontSize={2} p={2} fontWeight={600} color={gray}>
                Sales Updated at:
                {chartData &&
                  chartData.timeStamp &&
                  moment
                    .tz(chartData.timeStamp.writeDate, "America/Los_Angeles")
                    .format("MM/DD/YYYY h:mm a")}
              </Text>
            </StyledCard>
            {category === "Sales" &&
              chartData &&
              chartData.total &&
              chartData.total.hourly && (
              < NetSalesLineChart
                chartChosen={chartChosen}
                setChartChosen={setChartChosen}
                chartData={chartData.total}
                total={chartData.total}
                dataType="netSales"
                name="totalBoth"
                dateRange={dateRange}
              />
            )}
          </Flex>
          <StyledCard fullwidth>
            <Text fontSize={3} p={4}>
              # number of days:{" "}
              <NumberSpan>
                {chartData && chartData.total && chartData.total.days}
              </NumberSpan>
            </Text>
            <Text fontSize={3} p={4}>
              Avg. daily sales:{" "}
              <NumberSpan>
                {" "}
                $
                {chartData && chartData.total && chartData.total.adv
                  ? chartData.total.adv.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                  })
                  : 0}
              </NumberSpan>
            </Text>
            <Text fontSize={3} p={4}>
              Avg. daily orders:{" "}
              <NumberSpan>
                {" "}
                {chartData && chartData.total && chartData.total.ado
                  ? chartData.total.ado.toFixed(2)
                  : 0}
              </NumberSpan>
            </Text>
            <Text fontSize={3} p={4}>
              Peak Sales:
              <NumberSpan>
                $
                {chartData &&
                  chartData.total &&
                  (getPeakandLows(chartData, dateRange, "Max"))}
              </NumberSpan>
            </Text>
            <Text fontSize={3} p={4}>
              Low Sales:
              <NumberSpan>
                $
                {chartData &&
                  chartData.total &&
                  getPeakandLows(chartData, dateRange, "Min")}
              </NumberSpan>
            </Text>
          </StyledCard>
          <StyledCard style={{
            height: "400px",
            width: "400px",
          }}>   
            <Box style={{
              height: "350px",
              width: "95%",
            }}>
              {
                chartData && chartData.total && chartData.total &&
                <ResponsiveBarGraph  
                  data={
                    (() => {
                      if(!chartData || !chartData.total || !chartData.total) return [];
                      const days = {
                        "monday": {
                          count: 0, 
                          sales: 0,
                        },
                        "tuesday": {
                          count: 0, 
                          sales: 0,
                        },
                        "wednesday": {
                          count: 0, 
                          sales: 0,
                        },
                        "thursday": {
                          count: 0, 
                          sales: 0,
                        },
                        "friday": {
                          count: 0, 
                          sales: 0,
                        },
                        "saturday": {
                          count: 0, 
                          sales: 0,
                        },
                        "sunday": {
                          count: 0, 
                          sales: 0,
                        },
                      };
                      chartData.total.daily.netSales.map((d) => {
                        const day = moment(d.x).format("dddd").toLowerCase();
                        days[day].count += 1;
                        days[day].sales += d.y;
                      });

                      const averagesDaily = Object.keys(days).map((d) => {

                        let avr = (days[d].sales / days[d].count).toFixed(2);

                        avr = parseFloat(avr) || 0;

                        return {
                          day: d.toUpperCase(),  
                          Sales: avr,
                        };
              
                      });

                      return averagesDaily;
                    })()
                  }
                />
              }
            </Box>
          </StyledCard>
          {category === "Sales" &&
            chartData &&
            chartData["catering"] &&
            chartData["catering"]["split"] &&
            chartData["catering"]["split"].length !== 0 &&
            chartData["catering"]["netSalesPieChart"] &&
            chartData["total"] && (
            <Box flexDirection="column">
              <Text fontWeight="600" fontSize={4} mt={4}>
                  Catering Split
              </Text>
              <Flex justifyContent="space-between" pt={4}>
                <StyledCard>
                  <DesktopTable chartData={chartData} rows={chartData["catering"]["split"]} cat={{type: "catering"}} splitFilter={""} />
                </StyledCard>
                <StyledCard>
                  <ResponsivePieChart
                    data={chartData["catering"]["netSalesPieChart"].map(
                      (d) => ({ id: d.channel, value: d.y.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                      }) })
                    )}
                  />
                </StyledCard>
              </Flex>
            </Box>
          )}
          {(category === "Sales" && (
            <Box mb={100}>
              {categories.map((cat, index) => {
                return (
                  <Box key={index} mb={10} >
                    <Text fontWeight="600" fontSize={4} mt={4}>
                      {cat.title} {cat.title === "1st vs 3rd Party" ? "(Non-Catering)" : ""}
                    </Text>
                    {cat.type === "storefront" &&
                    chartData &&
                    chartData[`${cat.type}s`] &&
                    chartData[`${cat.type}s`]["hourly"] ? (
                        <Dropdown
                          options={
                            chartData[`${cat.type}s`]["hourly"] &&
                          createStorefronts(chartData[`${cat.type}s`])
                          }
                          selected={splitFilter}
                          onSelect={setSplitFilter}
                          isClearable={false}
                          isSearchable
                        />
                      ) : null}
                    <Flex justifyContent="space-between" pt={4}>
                      <StyledCard >
                        {
                          chartData &&
                            chartData[`${cat.type}s`] &&
                            chartData[`${cat.type}s`]["split"]  &&
                            (cat.type === "brand" ? 
                              (
                                (() => {
                                  const keys = {}; 

                                  let sales = chartData[`${cat.type}s`]["split"].reduce((acc, cur) => {
                                    let fitleredResults = 0; 

                                    if(cat.type === "brand" && filter === "Total") fitleredResults = cur.netSales;
                                    if(cat.type === "brand" && filter === "Catering") fitleredResults = chartData["catering"]["split"].reduce((acc, catering) => {
                                      if(cur.name === catering.channel) acc += catering.netSales;
                                      return acc;
                                    }, 0);
                                    if(cat.type === "brand" && filter === "Non Catering") fitleredResults = cur.netSales - chartData["catering"]["split"].reduce((acc, catering) => {
                                      if(cur.name === catering.channel) acc += catering.netSales;
                                      return acc;
                                    }, 0);

                                    acc[cur.name] = fitleredResults;
                                    return acc;
                                  }, {});

                                  const orderCount = chartData[`${cat.type}s`]["split"].reduce((acc, cur) => {
                                    let fitleredResults = 0;
                                    if(cat.type === "brand" && filter === "Total") fitleredResults = cur.orderCount;
                                    if(cat.type === "brand" && filter === "Catering") fitleredResults = chartData["catering"]["split"].reduce((acc, catering) => {
                                      if(cur.name === catering.channel) acc += catering.orderCount;
                                      return acc;
                                    }, 0);
                                    if(cat.type === "brand" && filter === "Non Catering") fitleredResults = cur.orderCount - chartData["catering"]["split"].reduce((acc, catering) => {
                                      if(cur.name === catering.channel) acc += catering.orderCount;
                                      return acc;
                                    }, 0);

                                    acc[cur.name] = fitleredResults;
                                    return acc;
                                  }, {}); 

                                  Object.keys(sales).forEach((key) => {
                                    keys[key] = key; 
                                  });

                                  Object.keys(orderCount).forEach((key) => {
                                    keys[key] = key; 
                                  });

                                
                                  Object.keys(keys).forEach((d) => {
                                    if (!sales[d]) sales[d] = 0;
                                    if (!orderCount[d]) orderCount[d] = 0;
                                  });

                                  let rows = Object.keys(sales).map((d) => {
                                    return {
                                      name: d,
                                      netSales: sales[d],
                                      orderCount: orderCount[d],
                                    };
                                  });

                                  rows = rows.filter((a) => a.netSales !== 0).sort((a, b) => b.netSales - a.netSales);

                                  const totals = rows.reduce((acc, cur) => {
                                    acc.netSales += parseFloat(cur.netSales);
                                    acc.orderCount += parseFloat(cur.orderCount);
                                    return acc;
                                  }, {
                                    netSales: 0,
                                    orderCount: 0,
                                  });

                                  return <DesktopTable 
                                    rows={rows} 
                                    chartData={chartData} 
                                    cat={cat} 
                                    splitFilter={splitFilter} 
                                    otherTotal={totals}
                                  />; 
                                })()
                              ) 
                              : 
                              (
                                <DesktopTable 
                                  rows={chartData[`${cat.type}s`].split} 
                                  chartData={chartData} 
                                  cat={cat} 
                                  splitFilter={splitFilter} 
                                  otherTotal={undefined}
                                /> 
                              ))
                        }
                      </StyledCard>
                      <StyledCard flexDirection="column">
                        {
                          cat.type === "brand" &&
                                  <FormControl sx={{width: "30%", mt:1, ml: 1}}>
                                    <InputLabel id="demo-simple-select-label">Filter Catering</InputLabel>
                                    <Select
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      value={filter}
                                      label="Filter Catering"
                                      onChange={(e) => setFilter(e.target.value)}
                                    >
                                      <MenuItem value={"Catering"}>Catering</MenuItem>
                                      <MenuItem value={"Non Catering"}>Non Catering</MenuItem> 
                                      <MenuItem value={"Total"}>Total </MenuItem>  
                                    </Select>
                                  </FormControl>
                        }
                        <Text
                          textAlign="center"
                          p={2}
                          fontWeight={600}
                          fontSize={4}
                        >
                          Net sales breakdown
                        </Text>

                        {cat.type === "storefront" ? (
                          <ResponsivePieChart
                            data={
                              chartData &&
                              chartData[`${cat.type}s`] &&
                              chartData[`${cat.type}s`].netSalesPieChart
                                .filter((d) =>
                                  d.name.includes(splitFilter.value)
                                )
                                .map((d) => ({
                                  id: d.label,
                                  value: d.y.toFixed(2),
                                }))
                            }
                          />
                        ) : 
                          cat.type === "brand" ? (
                            <ResponsivePieChart
                              data={
                                chartData &&
                                chartData[`${cat.type}s`] && 
                                chartData["catering"] &&  
                                chartData[`${cat.type}s`]["split"] && 
                                chartData["catering"]["split"] &&  
                                (() => {
                                  let consolidatedData = chartData[`${cat.type}s`]["split"].reduce((accBrand, cur) => {
                                    let fitleredResults = {}; 
                                    if(filter === "Catering") {
                                      fitleredResults = (
                                        chartData["catering"]["split"].reduce((acc, catering) => {
                                          if(cur.name === catering.channel) acc += catering.netSales;
                                          return acc;
                                        }, 0)); 
                                    }
                                    else if(filter === "Non Catering") {
                                      fitleredResults = (cur.netSales -
                                          chartData["catering"]["split"].reduce((acc, catering) => {
                                            if(cur.name === catering.channel) acc += catering.netSales;
                                            return acc;
                                          }, 0)); 
                                    }
                                    else if (filter === "Total") {
                                      fitleredResults = cur.netSales;
                                    }

                                    return {...accBrand, [cur.name]: fitleredResults};
                                  }, {});

                                  const currentTotal = Object.keys(consolidatedData).reduce((acc, cur) => acc += consolidatedData[cur], 0);

                                  return Object.keys(consolidatedData).filter((key) => consolidatedData[key] !== 0).map((key) => ({
                                    id: `${key} ${((consolidatedData[key]/currentTotal) * 100).toFixed(0)}%`,
                                    value: consolidatedData[key]
                                  }));

                                })()                   
                              }
                            />
                          ) :
                            (
                              <ResponsivePieChart
                                data={
                                  chartData &&
                                  chartData[`${cat.type}s`] &&
                                  chartData[`${cat.type}s`].netSalesPieChart.map(
                                    (d) => ({ id: d.label, value: d.y.toFixed(2) })
                                  )
                                }
                              />
                            )}
                      </StyledCard>
                    </Flex>
                    {chartData &&
                      chartData[`${cat.type}s`] &&
                      chartData[`${cat.type}s`].hourly && (
                      <>
                        <Accordion expanded={expanded === `panel1&${cat.type}`} onChange={handleChange(`panel1&${cat.type}`)}>
                          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                            <Typography> Net Sales Graph </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <NetSalesandOrderChart
                              chartChosen={chartChosen}
                              setChartChosen={setChartChosen}
                              chartData={chartData[`${cat.type}s`]}
                              total={chartData.total}
                              category
                              topSellers={null}
                              filter={
                                cat.type === "storefront" ? splitFilter : null
                              }
                              dataType="netSales"
                              name={`${cat.type}sNetSales`}
                              chartName={"Net Sales"}
                              dateRange={dateRange}
                            />
                          </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === `panel2&${cat.type}`} onChange={handleChange(`panel2&${cat.type}`)}>
                          <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                            <Typography>Order count Graph  </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <NetSalesandOrderChart
                              chartChosen={chartChosen}
                              setChartChosen={setChartChosen}
                              chartData={chartData[`${cat.type}s`]}
                              total={chartData.total}
                              category
                              topSellers={null}
                              filter={
                                cat.type === "storefront" ? splitFilter : null
                              }
                              dataType="orderCount"
                              name={`${cat.type}sOrderCount`}
                              chartName={"Order Count"}
                              dateRange={dateRange}
                            />
                          </AccordionDetails>
                        </Accordion>
                      </>
                    )}
                  </Box>
                );
              })}
            </Box>
          )) ||
            (category === "Menu" && (
              <>
                {chartData &&
                  chartData["items"] &&
                  chartData["discounts"] &&
                  chartData["discounts"]["daily"] && 
                  chartData["grossSales"] && 
                  chartData["grossSales"]["daily"] && 
                  chartData["items"].hourly &&
                  chartData["items"][`${chartChosen.toLowerCase()}`] && (
                  <>
                    <BestSellerTable
                      setTopSellers={setTopSellers}
                      globalData={chartData}
                      data={chartData["items"][chartChosen.toLowerCase()]}
                      time={chartChosen.toLowerCase()}
                    />

                    {/* <Text
                      style={{
                        fontWeight: "bold",
                        fontSize: "20px",
                        paddingBottom: "10px",
                      }}
                    >
                        Top 5 Best Sellers
                    </Text>
                    {topSellers !== null &&
                        topSellers !== undefined &&
                        chartData["items"] && 
                        chartData["items"][chartChosen.toLowerCase()] &&
                        chartData["items"][chartChosen.toLowerCase()]["netSales"] &&
                        topSellers[0] &&
                        topSellers[1] &&
                        topSellers[2] &&
                        topSellers[3] &&
                        topSellers[4] &&
                        chartData["items"][chartChosen.toLowerCase()]["netSales"][topSellers[0].name] && 
                        chartData["items"][chartChosen.toLowerCase()]["netSales"][topSellers[1].name] &&
                        chartData["items"][chartChosen.toLowerCase()]["netSales"][topSellers[2].name] &&
                        chartData["items"][chartChosen.toLowerCase()]["netSales"][topSellers[3].name] &&
                        chartData["items"][chartChosen.toLowerCase()]["netSales"][topSellers[4].name] && 
                        topSellers.filter((t) => t.name).length && (
                      <BestSellersChart
                        chartChosen={chartChosen}
                        setChartChosen={setChartChosen}
                        chartData={chartData["items"]}
                        total={chartData.total}
                        category
                        items={true}
                        topSellers={topSellers.filter((t) => t.name)}
                        filter={null}
                        dataType="orderCount"
                        name={"itemsOrderCount"}
                      />
                    )} */}
                  </>
                )}
              </>
            )) 
            ||
            (category === "Ingredients" && chartData && chartData.modifiers && chartData["items"] && (
              <IngredientsTable
                modifiers={chartData.modifiers}
                items={chartData["items"]}
                startDate={startDate}
                endDate={endDate}
              />
            )) ||
            (category === "Labor" &&
              chartData["hourlyLaborCost"] &&
              chartData["hourlyLaborCost"].length !== 0 && (
              <Box paddingBottom={"15%"}>
                <StyledCard justifyContent="center" flexDirection="row">
                  <Text
                    textAlign="center"
                    p={2}
                    fontWeight={600}
                    fontSize={4}
                  >
                      Net sales: ${" "}
                    {chartData &&
                        chartData.total &&
                        chartData.total.netSales &&
                        chartData.total.netSales.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                        })}
                  </Text>
                  <Text
                    textAlign="center"
                    p={2}
                    fontWeight={600}
                    fontSize={4}
                  >
                      Labor Cost:{" "}
                    ${(chartData["totalLaborCost"] || 0).toFixed(1).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </Text>

                  <Text
                    textAlign="center"
                    p={2}
                    fontWeight={600}
                    fontSize={4}
                  >
                      Labor Cost %:{" "}
                    {(
                      ((chartData["totalLaborCost"] || 0)  /
                          (chartData &&
                            chartData.total &&
                            chartData.total.netSales)) *
                        100
                    ).toFixed(2)}
                  </Text> 
                  <Text
                    textAlign="center"
                    p={2}
                    fontWeight={600}
                    fontSize={4}
                  >
                      Regular Hours Cost:{" "}
                    {
                      ((chartData["totalRegularHourCost"] || 0) 
                      ).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    }
                  </Text>        

                  <Text
                    textAlign="center"
                    p={2}
                    fontWeight={600}
                    fontSize={4}
                  >
                       Overt Time Hours Cost :{" "}
                    {
                      ((chartData["totalOvertimeHourCost"] || 0) 
                      ).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    }
                  </Text>

                </StyledCard>
 
                <LaborCost data={chartData} category="Labor" />
              </Box>
            ))
          }
          {category === "Sales" &&
            chartData &&
            chartData["categoriesSales"] && 
            chartData["categoriesSales"]["daily"] &&   
            chartData["categoriesOrderCount"] && 
            chartData["categoriesOrderCount"]["daily"] &&   
            (
              <Box flexDirection="column" mb={10}>
                <Text fontWeight="600" fontSize={4} >
                  Categories Split
                </Text>
                <Flex justifyContent="space-between" pt={4}>
                  <StyledCard>
                    <DesktopTableCategories cat="Categories" chartData={chartData} rows={(() => {
                      const rows = [];
                      Object.keys(chartData["categoriesSales"]["daily"]).forEach((d) => {
                        rows.push({
                          category: d,
                          netSales: chartData["categoriesSales"]["daily"][d],
                          orderCount: chartData["categoriesOrderCount"]["daily"][d],
                        });
                      });
                      return rows;
                    })()} />
                  </StyledCard>
                  <StyledCard>
                    <ResponsivePieChart
                      data={
                        (() => {
                          const data = [];
                          Object.keys(chartData["categoriesSales"]["daily"]).forEach((d) => {
                            data.push({
                              id: d,
                              label: d,
                              value: chartData["categoriesSales"]["daily"][d]
                            });
                          });
                          return data;
                        })()
                      } 
                    />
                  </StyledCard>
                </Flex>
              </Box>
            )}
        </Flex>
      )}
    </Box>
  );
};

const ChartTypeButton = ({ days, chartChosen, setChartChosen }) => {
  const chartOptions = createChartOptions(days);

  return (
    <StyledSelect
      onChange={(e) => {
        setChartChosen(e.target.value);
      }}
      fontSize="16px"
      position="absolute"
    >
      {chartOptions &&
        chartOptions.map((option) => (
          <option
            selected={chartChosen === option ? option : false}
            value={option}
            key={option}
          >
            {option}
          </option>
        ))}
    </StyledSelect>
  );
};

const createChartOptions = (days) => {
  if (days === 1) return ["Hourly"];
  if (days > 1 && days < 14) return ["Hourly", "Daily"];
  if (days >= 14 && days < 60) return ["Hourly", "Daily", "Weekly"];
  if (days >= 60) return ["Hourly", "Daily", "Weekly", "Monthly"];
  return ["None"];
};

const sort = (chartChosen, data) => {
  if (chartChosen.toLowerCase() === "hourly") {
    return data.sort((a, b) => parseInt(a.x) - parseInt(b.x));
  }
  return data.sort();
};

const NetSalesandOrderChart = ({
  chartChosen,
  setChartChosen,
  chartData,
  total,
  category,
  topSellers,
  filter,
  dataType,
  chartName,
}) => {
  
  let legendData = createLegendData(
    chartData[chartChosen.toLowerCase()],
    category,
    filter,
    topSellers,
    null
  );

  let data = legendData.map((l) => {
    return {
      id: l.name,
      color: l.symbol.fill,
      data: filter
        ? sort(
          chartChosen,
          chartData[chartChosen.toLowerCase()][dataType][filter.value][l.name]
        )
        : sort(
          chartChosen,
          chartData[chartChosen.toLowerCase()][dataType][l.name]
        ),
    };
  });

  if (chartChosen.toLowerCase() === "hourly") {
    data.sort((a, b) => parseInt(a.data[0].x) - parseInt(b.data[0].x));

    for (var i = 0; i < data.length; i++) {
      const intVersion = data[i].data.map((d) => parseInt(d.x));
      let smallest = intVersion[0];
      for (var a = 1; a < intVersion.length; a++) {
        let dd = intVersion[a];
        smallest += 1;
        if (smallest != dd) {
          data[i].data.splice(a, 0, { x: smallest.toString(), y: 0 });
          a--;
        } else {
          smallest = dd;
        }
      }
    }
    data.forEach((d) => d.data.sort((a, b) => parseInt(a.x) - parseInt(b.x)));
  } else {
    data.sort((a, b) => b.data.length - a.data.length);
  }

  return (
    <ChartContainer category={category}>
      <ChartTypeButton
        days={total.days}
        chartChosen={chartChosen}
        setChartChosen={setChartChosen}
      ></ChartTypeButton>
      <Box width="100%" height="500px">
        <ResponsiveLineChart
          data={data}
          legend={"Time"}
          axisLeftLegend={chartName === "Net Sales" ? "Sales" : "Count"}
          tickSize={chartChosen.toLowerCase() !== "hourly" ? 3 : 5}
          tickRotation={chartChosen.toLowerCase() !== "hourly" ? -50 : 0}
        />
      </Box>
    </ChartContainer>
  );
};

const NetSalesLineChart = ({
  chartChosen,
  setChartChosen,
  chartData,
  total,
}) => {
  let legendData = createLegendData(chartData[chartChosen.toLowerCase()]);
  let data = legendData.map((l) => {
    return {
      id: l.name,
      color: l.symbol.fill,
      data: chartData[chartChosen.toLowerCase()][l.name].sort(),
    };
  });
  if (chartChosen.toLowerCase() === "hourly") {
    data.sort((a, b) => parseInt(a.data[0].x) - parseInt(b.data[0].x));

    for (var i = 0; i < data.length; i++) {
      const intVersion = data[i].data.map((d) => parseInt(d.x));
      let smallest = intVersion[0];
      for (var a = 1; a < intVersion.length; a++) {
        let dd = intVersion[a];
        smallest += 1;
        if (smallest != dd) {
          data[i].data.splice(a, 0, { x: smallest.toString(), y: 0 });
          a--;
        } else {
          smallest = dd;
        }
      }
    }
    data.forEach((d) => d.data.sort((a, b) => parseInt(a.x) - parseInt(b.x)));
  } else {
    data.sort((a, b) => b.data.length - a.data.length);
  }
  if (chartChosen.toLowerCase() === "hourly") {
    data.sort((a, b) => parseInt(a.data[0].x) - parseInt(b.data[0].x));
  } else {
    data.sort((a, b) => b.data.length - a.data.length);
  }

  console.log(chartChosen);

  return (
    <ChartContainer>
      <ChartTypeButton
        days={total.days}
        chartChosen={chartChosen}
        setChartChosen={setChartChosen}
      ></ChartTypeButton>
      <Box width="100%" height="500px">
        <ResponsiveLineChart
          data={data}
          legend="Net Sales vs Order Count"
          axisLeftLegend="price"
          tickSize={chartChosen.toLowerCase() !== "hourly" ? 3 : 5}
          tickRotation={chartChosen.toLowerCase() !== "hourly" ? -50 : 0}
        />
      </Box>
    </ChartContainer>
  );
};

const BestSellersChart = ({
  chartChosen,
  setChartChosen,
  chartData,
  total,
  category,
  dataType,
  items,
  filter,
  topSellers,
}) => {
  let legendData = createLegendData(
    chartData[chartChosen.toLowerCase()],
    category,
    filter,
    topSellers,
    items
  );


  let data = legendData.map((l) => {
    
    console.log(chartChosen, dataType, l.name);
    return {
      id: l.name,
      color: l.symbol.fill,
      data: chartData[chartChosen.toLowerCase()][dataType][l.name].sort(),
    };
  });
  console.log("--------------------");
  if (chartChosen.toLowerCase() === "hourly") {
    data.sort((a, b) => parseInt(a.data[0].x) - parseInt(b.data[0].x));

    for (var i = 0; i < data.length; i++) {
      const intVersion = data[i].data.map((d) => parseInt(d.x));
      let smallest = intVersion[0];
      for (var a = 1; a < intVersion.length; a++) {
        let dd = intVersion[a];
        smallest += 1;
        if (smallest != dd) {
          data[i].data.splice(a, 0, { x: smallest.toString(), y: 0 });
          a--;
        } else {
          smallest = dd;
        }
      }
    }
    data.forEach((d) => d.data.sort((a, b) => parseInt(a.x) - parseInt(b.x)));
  } else {
    data.sort((a, b) => b.data.length - a.data.length);
  }
  if (chartChosen.toLowerCase() === "hourly") {
    data.sort((a, b) => parseInt(a.data[0].x) - parseInt(b.data[0].x));
  } else {
    data.sort((a, b) => b.data.length - a.data.length);
  }

  return (
    <ChartContainer category={category}>
      <ChartTypeButton
        days={total.days}
        chartChosen={chartChosen}
        setChartChosen={setChartChosen}
      ></ChartTypeButton>
      <Box width="100%" height="500px">
        <ResponsiveLineChart
          data={data}
          legend="Best Sellers Order Count"
          tickSize={chartChosen.toLowerCase() !== "hourly" ? 3 : 5}
          tickRotation={chartChosen.toLowerCase() !== "hourly" ? -50 : 0}
        />
      </Box>
    </ChartContainer>
  );
};

export default Charts;
