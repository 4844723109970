import  React from "react"; 
import { useEffect } from "react";

const API_URL = process.env.REACT_APP_API_URL;

export const useItemsSelect = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [updateLoading, setUpdateLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [brands, setBrands] = React.useState([]);
  const [items, setItems] = React.useState([]);

  const fetchData = async () => {
    setIsLoading(true);
    const res = await fetch(`${API_URL}/getBrandsAndItems`, {
      method: "GET",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      }, 
    });

    const { brands, items } = await res.json();
    setBrands(brands);
    setItems(items);

    setIsLoading(false);

  };

  const updateChosenItemsAndBrands = async () => {
    setUpdateLoading(true);

    const chosenBrands = brands.filter((b) => b.chosen).map((b) => b.name);
    const chosenItems = items.filter((b) => b.chosen).map((b) => b.name);

    const result = await fetch(`${API_URL}/updateChosenItemsAndBrands`, {
      method: "POST",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ chosenBrands, chosenItems })
    });

    await fetchData();

    setSuccess(true);

    setUpdateLoading(false);
  };

  useEffect(() => { 
    fetchData(); 
  }, []);

  return { isLoading, refetch: fetchData, brands, items, setBrands, setItems, updateChosenItemsAndBrands, updateLoading, success,};

};
