// import OrdersDesktop from "./pages/desktop/Orders";
import ChartsDesktop from "./pages/desktop/Charts";
import CalendarDesktop from "./pages/desktop/Calendar";
import SettingsDesktop from "./pages/desktop/settings";

// import OrdersMobile from "./pages/mobile/Orders";
import ChartsMobile from "./pages/mobile/Charts";
import CalendarMobile from "./pages/mobile/Calendar";
import SettingsMobile from "./pages/mobile/settings";

export const accountingDesktopPages = [
  { path: "/charts", to: "/charts", title: "Charts", component: <ChartsDesktop /> },
  {
    path: "/Calendar",
    to: "/Calendar",
    title: "Calendar",
    component: <CalendarDesktop />,
  },
  {
    path: "/settings",
    to: "/settings",
    title: "Settings",
    component: <SettingsDesktop />,
  },
];

export const accountingMobilePages = [
  { path: "/charts", to: "/charts", title: "Charts", component: <ChartsMobile /> },
  {
    path: "/Calendar",
    to: "/Calendar",
    title: "Calendar",
    component: <CalendarMobile />,
  },
  {
    path: "/settings",
    to: "/settings",
    title: "Settings",
    component: <SettingsMobile />,
  },
];

