import React, { useEffect } from "react";
import { Flex, Box, } from "noshery-ui";
import moment from "moment"; 
import useInvoices from "../../../hooks/useInvoice";
import styled from "styled-components"; 
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment"; 
import { useLocationCharts } from "../../../hooks/useLocation"; 
import Loading from "../../../components/Loading"; 
import GeneralTable from "../../../components/Table";
import useCharts from "../../../hooks/useCharts";
import useCalculateInventory from "../../../hooks/useCalculateInventory";

const dateRangeOption = {
  today: "Today",
  yesterday: "Yesterday",
  thisWeek: "This week",
  lastWeek: "Last week",
  thisMonth: "This month",
  lastMonth: "Last month",
  thisYear: "This year",
  allTime: "All time",
  custom: "Custom",
};

const StyledSelect = styled.select`
  border-radius: 10px;
  box-shadow: 0 0 3px 0 rgb(0, 0, 0, 0.2);
  border: none;
  background-color: white;
  font-size: ${(props) => (props.fontSize ? props.fontSize : "20px")}
  ${(props) =>
    props.position
      ? "position: absolute; right: 8px; top: 8px; z-index: 1;"
      : ""}
  padding: 12px;
  font-weight: 600;
  &:hover,
  &:focus {
    cursor: pointer;
  }
  :focus-visible {
    outline: solid orange 2px;
  }
`;

const LiveInventory = () => {
  const {
    location,
    loading,
    setLocation,
    calculations
  } = useCalculateInventory();

  const {locations: locationsOption} = useLocationCharts();

  useEffect(() => {
    if (Object.values(locationsOption).length > 0) {
      setLocation(Object.values(locationsOption)[0]);
    }
  }, [locationsOption]); 
 
  return (
    <Box>
      <Flex  flexDirection="column"  >
        <Flex w="100%" mb={4}>
          <Flex w="100%" mr="1%" justifyContent="space-between">
            <Flex w="100%" justifyContent="space-between">
              <Box paddingRight="10px">
                <StyledSelect
                  onChange={(e) => {
                    setLocation(locationsOption[e.target.value]); 
                  }} 
                >
                  {Object.keys(locationsOption).filter((l) => l !== "All Stores").map((d) => (
                    <option value={d} key={d}>
                      {d}
                    </option>
                  ))}
                </StyledSelect>
              </Box> 
            </Flex>
          </Flex> 
        </Flex> 
      </Flex>

 
      {loading ? <Loading />   : (
        <Flex 
          flexDirection="column"
          minHeight="100vh" 
        >
          { 
            (calculations && calculations.inventory && Object.keys(calculations.inventory).length !== 0) ? 
              <GeneralTable 
                anyResult={Object.keys(calculations.inventory).map((i) => {
                  const totalDeduction = calculations.inventory[i].deductions.quantity + " " + calculations.inventory[i].deductions.unit;
                  const totalAddition = calculations.inventory[i].additions.quantity + " " + calculations.inventory[i].additions.unit;
                  if((calculations.inventory[i].additions.unit === calculations.inventory[i].deductions.unit)
                    || ((calculations.inventory[i].additions.unit === "oz" || calculations.inventory[i].deductions.unit === "oz") && 
                    (calculations.inventory[i].additions.unit === "ea" || calculations.inventory[i].deductions.unit === "ea"))  
                  ) {
                    return {
                      name: i,
                      totalDeduction,
                      totalAddition,
                      current: (calculations.inventory[i].additions.quantity - calculations.inventory[i].deductions.quantity) + " " + calculations.inventory[i].additions.unit,
                      qts: ((calculations.inventory[i].additions.quantity - calculations.inventory[i].deductions.quantity) / 32).toFixed(2) + " qts",
                      lbs: ((calculations.inventory[i].additions.quantity - calculations.inventory[i].deductions.quantity) / 16).toFixed(2) + " lbs",
                    };
                  }
                  return {
                    name: i,
                    totalDeduction,
                    totalAddition, 
                  };
                })}
                itemId={"name"}
                itemName={"name"}
              /> 
              :
              <p> 
                No data found
              </p>
          }
        </Flex>
      )}
    </Box>
  );
}; 

export default LiveInventory;
