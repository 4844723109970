import  React from "react"; 

const API_URL = process.env.REACT_APP_API_URL;

export const useReadRelish = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [apiResults, setApiResults] = React.useState({ok: "none"});
  const [serverError, setServerError] = React.useState(null);

  const readJson = async (dataUri) => {
    setIsLoading(true);
    try {
      const res = await fetch(`${API_URL}/pdfToJson`, {
        method: "POST",
        mode: "cors",
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({dataUri: dataUri})
      });

      const data = await res.json();
      setApiResults(data);
      if(data.detail) setServerError(data); 
      setIsLoading(false);
      return data; 
    } catch (error) {
      setServerError(error);
      setIsLoading(false);
    }
  };

  return { isLoading, apiResults, serverError, readJson };

};