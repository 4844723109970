import React from "react";
// import styled from "styled-components";
import { Box } from "noshery-ui";
import Modal from "react-modal";
import ResponsiveLineChart from "../ResponsiveLineChart";
import useWindowSize from "../../hooks/useWindowSize";

const unitMap = {
  Hummus: { unit: "oz", conversion: 8, toQts: true },
  Naan: { unit: "ea", conversion: 1 },
  "White Pita": { unit: "ea", conversion: 1 },
  "Gluten Free Pita": { unit: "ea", conversion: 1 },
  Roti: { unit: "ea", conversion: 1 },
  Samosa: { unit: "ea", conversion: 1 },
  "Gluten Free Tortilla": { unit: "ea", conversion: 1 },
  "Flour Tortilla": { unit: "ea", conversion: 1 },
  "Burger Pattys": { unit: "ea", conversion: 1 },
  Shrimp: { unit: "oz", conversion: 3, toLbs: true }, // lbs
  "ESBM Chicken": { unit: "oz", conversion: 4, toLbs: true }, // lbs
  "ESBM Pork": { unit: "oz", conversion: 4, toLbs: true }, // lbs
  "Pollo Asado": { unit: "oz", conversion: 3, toLbs: true }, // lbs
  "Carne Asada": { unit: "oz", conversion: 3, toLbs: true }, // lbs
  "Chicken Tikka": { unit: "oz", conversion: 6, toLbs: true }, // lbs
  "Orens Chicken": { unit: "oz", conversion: 4, toLbs: true }, // lbs
  "Orens Beef": { unit: "oz", conversion: 4, toLbs: true }, // lbs
};

const setUnit = (count, ingName) => {
  if (unitMap[ingName].toLbs) {
    return `${(count * unitMap[ingName].conversion * 0.0625).toFixed(2)}`;
  }
  if (unitMap[ingName].toQts) {
    return `${(count * unitMap[ingName].conversion * 0.03125).toFixed(2)}`;
  }
  return `${(count * unitMap[ingName].conversion).toFixed(2)} ${
    unitMap[ingName].unit
  }`;
};

const IngredientsByHourModal = ({
  isOpen,
  onClose,
  ingredient,
  chartData = [],
}) => {
  console.log("data", chartData);

  const {width} = useWindowSize();

  const data = [
    {
      id: `${ingredient} By Hour`,
      color: "hsl(177, 70%, 50%)",
      data: chartData.map((c) => {
        return { x: c.x, y: setUnit(c.y, ingredient) };
      }),
    },
  ];

  console.log("D", data);

  return (
    <Modal style={{content: {
      height: "30%",
      top: "35%"
    }}} isOpen={isOpen} onRequestClose={onClose}>
      <Box style={{height: `${(width * 40)/100}px !important`}}>
        <Box>{ingredient}</Box>
        <Box width="100%" height="200px">
          <ResponsiveLineChart
            yFormat=","
            xAxisFormat=""
            data={data}
            legend="Ingredient By Hour"
            tickSize={5}
            tickRotation={0}
          />
        </Box>
      </Box>
    </Modal>
  );
};

export default IngredientsByHourModal;
