import React, { useCallback, useEffect } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Box, Flex, Button } from "noshery-ui";
import useDailySales, { useAddNote } from "../../hooks/useDailySales";
import PageLoader from "../../components/PageLoader";
import styled from "styled-components";
import { CSVLink } from "react-csv";
import LoadingButton from "@mui/lab/LoadingButton";

import MUIBox from "@mui/material/Box"; 
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import { useLocationCharts } from "../../hooks/useLocation";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  display: "flex",
  flexDirection: "column",
  height: "40%",
};

const StyledSelect = styled.select`
  border-radius: 10px;
  box-shadow: 0 0 3px 0 rgb(0, 0, 0, 0.2);
  border: none;
  background-color: white;
  font-size: ${props => (props.fontSize ? props.fontSize : "20px")}
  ${props =>
    props.position
      ? "position: absolute; right: 8px; top: 8px; z-index: 1;"
      : ""}
  padding: 12px;
  font-weight: 600;
  &:hover,
  &:focus {
    cursor: pointer;
  }
  :focus-visible {
    outline: solid orange 2px;
  }
`;

const locationsOption = {
  "All Stores": "All",
  "San Mateo": "93895000000000000",
  "San Carlos": "114032000000000000",
  "San Ramon": "146868000000000000" ,
};

const locationsAbbv = {
  "All": "All",
  "93895000000000000": "sm",
  "114032000000000000": "sc",
  "146868000000000000": "sr",
};

const localizer = momentLocalizer(moment);

const SalesCalendar = () => {
  const { loading, data, location, setLocation, fetchData } = useDailySales();
  const {locations: locationsOption} = useLocationCharts();

  useEffect(() => {
    if (Object.values(locationsOption).length > 0) {
      setLocation(Object.values(locationsOption)[0]);
    }
  }, [locationsOption]);

  const { addNoteToSales, isLoading, apiResults } = useAddNote(); 
  const [open, setOpen] = React.useState(false);
  const [note, setNote] = React.useState({
    note: "",
    date: "",
  });
  const handleOpen = (event) => {
    const date = moment(event.start).format("YYYY-MM-DD");

    setNote({
      ...note,
      date: date,
    });

    setOpen(true);
  };

  const handleClose = () => {
    setNote({
      date: "",
      note: "",
    });
    setOpen(false);
  };

  const handleSaveNote = async () => {
    const results = await addNoteToSales(note, locationsAbbv[location]);
    if(results.success) {
      await fetchData();
      handleClose();
    }
    else {
      alert("Could not save note. Please try again.");
    }
  };

  const buttonStyle = {
    borderRadius: "10px",
    mx: "5%",
    color: "white",
    transition: "all 0.3s ease",
    "&:hover": {
      backgroundColor: "#f3b457",
      color: "white",
    },
    boxShadow: "0 0 3px 0 rgb(0, 0, 0, 0.2)",
    backgroundColor: "#F1A637",
    mb: "1%",
  };
  

  return (
    <Box pt={"100px"} pb={4} px="100px" flexDirection="column" bg="#f2f2f2">
      <Box paddingBottom="10px">
        <StyledSelect
          onChange={e => setLocation(locationsOption[e.target.value])}
        >
          {Object.keys(locationsOption).map(d => (
            <option value={d} key={d}>
              {d}
            </option>
          ))}
        </StyledSelect>
      </Box>

      {loading ? <PageLoader /> : data &&
        <>
          <Flex justifyContent="flex-end"> 
            <CSVLink
              style={{alignSelf: "flex-end"}}
              headers={["Date", "Order Count", "Net Sales"]}
              data={data.daily ? (() => {
                let obj = {}; 
                data.daily.forEach(d => {
                  if(!obj[d.start]) obj[d.start] = {};
                  if(d.title.includes("Count")) {
                    obj[d.start]["Order Count"] = d.title.split(":")[1];
                  }
                  else if (d.title.includes("Sales")) {
                    obj[d.start]["Net Sales"] = d.title.split(":")[1];
                  }
                  obj[d.start]["Date"] = d.start;
                });
                return Object.values(obj);
              })() : []}
              filename={`sales-ordercount-${moment().format("YYYY-MM-DD")}.csv`}
            >
              <Button>Download Calendar</Button>
            </CSVLink>
          </Flex>
          <CalendarView events={data.daily} onClickEventHandler={handleOpen} />
          <div> 
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <MUIBox sx={style}>
                <Typography id="modal-modal-title" variant="h6" fontWeight={"600"} mb="10%" component="h2">
                  Add Note for {moment(note.date).format("DD/MM/YYYY")}
                </Typography>
                <TextField id="outlined-basic" label="Note" sx={{marginBottom: "5%"}} variant="outlined" multiline rows={3} value={note.note} onChange={(e) => {
                  setNote({
                    ...note,
                    note: e.target.value,
                  });
                }} />
                <LoadingButton sx={buttonStyle} loading={isLoading} onClick={handleSaveNote}>Save</LoadingButton>
                <LoadingButton sx={buttonStyle} loading={isLoading} onClick={handleClose}>Cancel</LoadingButton>
              </MUIBox>
            </Modal>
          </div>
        </>
      }
    </Box>
  );
};

const CalendarView = ({ events, onClickEventHandler }) => {
  const eventPropGetter = useCallback(
    event => ({
      ...{
        style: {
          backgroundColor: "rgb(0,0,0,0)",
          color: event.title.includes("Count") ? "blue" : event.title.includes("Note") ? "black" :  event.title.includes("Labor") ? "orange" : "red",
          borderRadius: "1px",
          textDecoration: "underline",
          padding: "10px",
          fontWeight: "bold",
          fontSize: "16px",
        },
      },
    }),
    []
  );
  console.log(events);
  return (
    <div>
      <Calendar
        localizer={localizer}
        eventPropGetter={eventPropGetter}
        step={60}
        messages={{ today: "Today", previous: "Previous", next: "Next" }}
        culture="fr"
        startAccessor="start"
        events={events}
        defaultDate={new Date()}
        views={["month"]}
        onSelectEvent={event => event.title.includes("Note") ? onClickEventHandler(event) : null}
        endAccessor="end" 
        style={{ height: 1200, width: 1600, backgroundColor: "white" }}
      />
    </div>
  );
};
export default SalesCalendar;
