import React from "react";
import { Box, Flex, Text } from "noshery-ui";
import styled from "styled-components";
import moment from "moment";

const StyledFlex = styled(Flex)`
  height: 100vh;
  width: 100wh;
  align-items: center;
  justify-content: center;
`;

const PageLoader = () => {
  const now = moment(moment().format("YYYY-MM-DDTHH:mm:SS.000-0000"));
  const openHours = moment(moment().format("YYYY-MM-DDT12:03:00.000-0000"));

  let secs = openHours.diff(now, "seconds");

  const [seconds, setSeconds] = React.useState(secs);

  React.useEffect(() => {
    if (seconds > 0) {
      setTimeout(() => setSeconds(seconds - 1), 1000);
    } else {
      setSeconds(0);
    }
  }, [seconds]);

  return (
    <StyledFlex bg="#f2f2f2">
      <Box mb={"20%"} color="black">
        {seconds === 0 ? (
          <Text fontWeight="200" fontSize={5} mb={4}>
            {" "}
            Data has been updated, please refresh page.{" "}
          </Text>
        ) : (
          <Text fontWeight="200" fontSize={5} mb={4}>
            {" "}
            No Data exist for today yet, data should be available in: {
              seconds
            }{" "}
            seconds{" "}
          </Text>
        )}
      </Box>
    </StyledFlex>
  );
};

export default PageLoader;
