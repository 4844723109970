import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";
import { Box } from "noshery-ui";
const API_URL = process.env.REACT_APP_API_URL;

const ApiDocs = ({ isDesktop }) => {
  return (
    <Box pt={isDesktop ? 5 : 0} px="100px">
      <SwaggerUI url={`${API_URL}/swagger`} />
    </Box>
  );
};

export default ApiDocs;
