/* eslint-disable no-undef */
import moment from "moment";
import  React from "react"; 

const API_URL = process.env.REACT_APP_API_URL;

export const useLabels = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [apiResults, setApiResults] = React.useState({ok: "none"});
  const [serverError, setServerError] = React.useState(null);

  const generateLabels = async (orders, channel, location, discount, date, company) => {
    setIsLoading(true);
    try {
      const res = await fetch(`${API_URL}/generateLabels`, {
        method: "POST",
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({orders: orders, channel: channel, location: location, date: date, Company: company })
      });

      const data = await res.blob();
      setApiResults(data);
      setIsLoading(false);

      return data;
    } catch (error) {
      setServerError(error);
      setIsLoading(false);
    }
  };


  return { 
    isLoading, 
    apiResults, 
    serverError,
    generateLabels, 
  };

};