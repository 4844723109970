const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const E164_PHONE_NUMBER = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

export function validateEmail(email) {
  return EMAIL_REGEX.test(String(email).toLowerCase());
}

export function validatePhone(phone) {
  //TODO: for V1 we decided to validate only E18 format (Auth0 required format).
  //Possible moving to Twillio API
  return E164_PHONE_NUMBER.test(phone);
}

export function isDataEmpty(data) {
  let check = false;
  Object.keys(data).forEach(category => {
    if (
      category !== "items" &&
      category !== "timeStamp" &&
      category !== "total" &&
      category !== "catering" &&
      category !== "modifiers" &&
      category !== "ingredients" &&
      category !== "FirstVsThirds" &&
      category !== "laborCost" &&
      category !== "revenue" &&
      category !== "percentDifferenceSales" &&
      category !== "discounts" &&
      category !== "grossSales" &&
      category !== "totalLaborCost" &&
      (data[category] && data[category]["split"] && data[category]["split"].length === 0 )
    ) {
      check = true;
      return;
    }
  });
  return check;
}
