// import OrdersDesktop from "./pages/desktop/Orders";
import ChartsDesktop from "./pages/desktop/Charts";
import CalendarDesktop from "./pages/desktop/Calendar";
import UsersDesktop from "./pages/desktop/Users";
import SettingsDesktop from "./pages/desktop/settings";
import CateringUploadDesktop from "./pages/desktop/UploadCatering";
import DbEditorsDesktop from "./pages/desktop/Editor";
import CustomerMetricsViewDesktop from "./pages/desktop/CustomerMetricsView";
import Milestones from "./pages/desktop/Milestones";
import Comparisons from "./pages/desktop/Comparisons"; 
import ApiDocs from "./pages/desktop/ApiDocs"; 
import Orders from "./pages/desktop/Orders"; 

// import OrdersMobile from "./pages/mobile/Orders";
import ChartsMobile from "./pages/mobile/Charts";
import CalendarMobile from "./pages/mobile/Calendar";
import UsersMobile from "./pages/mobile/Users";
import SettingsMobile from "./pages/mobile/settings";  
import BrandChartView from "./pages/desktop/BrandChartView";
import BrandChartViewMobile from "./pages/mobile/BrandChartView";
import MilestonesMobile from "./pages/mobile/Milestones";
import Bot from "./pages/desktop/Bot";
import ItemSelectForEmail from "./pages/desktop/ItemSelect";
import PlatingRecipes from "./pages/desktop/PlatingRecipes";
import LiveInventory from "./pages/desktop/LiveInventory";

export const adminDesktopPages = [
  {
    path: "/charts",
    to: "/charts",
    title: "Charts",
    component: <ChartsDesktop />,
  },
  {
    path: "/comparisons",
    to: "/comparisons",
    title: "Comparisons",
    component: <Comparisons />,
  },
  // {
  //   path: "/checklist-view",
  //   to: "/checklist-view",
  //   title: "Checklist",
  //   component: <ChecklistView />,
  // },
  {
    path: "/customerMetrics",
    to: "/customerMetrics",
    title: "Customers",
    component: <CustomerMetricsViewDesktop />,
  },
  // {
  //   path: "/schedules",
  //   to: "/schedules",
  //   title: "Schedules",
  //   component: <Schedules />,
  // },
  {
    path: "/Calendar",
    to: "/Calendar",
    title: "Calendar",
    component: <CalendarDesktop />,
  },
  {
    path: "/brandView",
    to: "/brandView",
    title: "Brand View",
    component: <BrandChartView />,
  },
  {
    path: "/milestones",
    to: "/milestones",
    title: "Milestones",
    component: <Milestones />,
  },
  {
    path: "/cateringUpload",
    to: "/cateringUpload",
    title: "Add Catering",
    component: <CateringUploadDesktop />,
  },
  // {
  //   path: "/invoices-upload",
  //   to: "/invoices-upload",
  //   title: "Add Invoice",
  //   component: <InvoiceUpload />,
  // },
  {
    path: "/admin",
    to: "/admin",
    title: "Admin",
    component: <DbEditorsDesktop />,
  },
  { path: "/users", to: "/users", title: "Users", component: <UsersDesktop /> },
  { path: "/orders", to: "/orders", title: "Orders", component: <Orders />},
  { path: "/emailContent", to: "/emailContent", title: "Email Content", component: <ItemSelectForEmail />},
  { path: "/platingRecipes", to: "/platingRecipes", title: "Plating Recipes", component: <PlatingRecipes />},
  // { path: "/labels", to: "/labels", title: "Labels", component: <Labels />},
  {
    path: "/settings",
    to: "/settings",
    title: "Settings",
    component: <SettingsDesktop />,
  },
  {
    path: "/liveInventory",
    to: "/liveInventory",
    title: "Live Inventory",
    component: <LiveInventory />,
  },
  {
    path: "/bot",
    to: "/bot",
    title: "Bot",
    component: <Bot />,
  },
  // {
  //   path: "/api-docs",
  //   to: "/api-docs",
  //   title: "Api Docs",
  //   component: <ApiDocs isDesktop />,
  // },
];
export const adminMobilePages = [
  // { path: "/orders", to: "/orders", title: "Orders", component: <OrdersMobile /> },
  {
    path: "/charts",
    to: "/charts",
    title: "Charts",
    component: <ChartsMobile />,
  },
  {
    path: "/bot",
    to: "/bot",
    title: "Bot",
    component: <Bot />,
  },
  { path: "/users", to: "/users", title: "Users", component: <UsersMobile /> },
  {
    path: "/Calendar",
    to: "/Calendar",
    title: "Calendar",
    component: <CalendarMobile />,
  },
  {
    path: "/cateringUpload",
    to: "/cateringUpload",
    title: "Add Catering",
    component: <CateringUploadDesktop />,
  },
  {
    path: "/admin",
    to: "/admin",
    title: "Admin",
    component: <DbEditorsDesktop />,
  },
  {
    path: "/brandView",
    to: "/brandView",
    title: "Brand View",
    component: <BrandChartViewMobile />,
  },
  {
    path: "/settings",
    to: "/settings",
    title: "Settings",
    component: <SettingsMobile />,
  },
  {
    path: "/milestones",
    to: "/milestones",
    title: "Milestones",
    component: <MilestonesMobile />,
  },
  {
    path: "/customerMetrics",
    to: "/customerMetrics",
    title: "Customer",
    component: <CustomerMetricsViewDesktop />,
  },
  {
    path: "/api-docs",
    to: "/api-docs",
    title: "Api Docs",
    component: <ApiDocs />,
  },
];
