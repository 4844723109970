import React from "react";
import { Box, Typography, Button, Modal, TextField, IconButton, Autocomplete } from "@mui/material";
import usePlating from "../../../hooks/usePlating"; 
import { LoadingButton } from "@mui/lab"; 
import DeleteIcon from "@mui/icons-material/Delete";
import {v4 as uuidv4} from "uuid";
import GeneralTable from "../../../components/Table";
import Loading from "../../../components/Loading";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  height: "80%",
  bgcolor: "background.paper", 
  boxShadow: 24,
  display: "flex",
  flexDirection: "column",
  p: 4,
  overflow: "auto",
};

const Plating = () => {
  const { loading, plating, gptGenerateLoading, gptGeneratePlating, setPlatingMutation, updatePlatingLoading } = usePlating(); 
  const [open, setOpen] = React.useState(false);
  const [queryToGeneratePlating, setQueryToGeneratePlating] = React.useState(""); 
  const [filterPlating, setFilterPlating] = React.useState("");
  const [newPlating, setNewPLating] = React.useState({
    id: uuidv4(),
    name: "",
    ingredients: [],
  });

  const handleClose = () => {
    setOpen(false);
    setNewPLating({
      id: uuidv4(),
      name: "",
      ingredients: [],
    });
    setQueryToGeneratePlating("");
  };

  return  (
    <Box sx={{
      display: "flex",
      flexDirection: "column", 
      width: "100%",
        
    }}>
      {
        loading ? <Loading /> : <>
          <Button
            onClick={() => setOpen(true)}
            variant="contained"
            sx={{
              display: "flex",
              width: "fit-content",
              alignSelf: "flex-end",
            }}
          >Create Plating</Button>

          <Autocomplete 
            sx={{
              width: 300,
              backgroundColor: "white"
            }}
            options={plating.map((p) => p.name.trim()).sort()}
            value={filterPlating}
            onChange={(e, value) => setFilterPlating(value)}
            onInputChange={(e, value) => setFilterPlating(value)}
            renderInput={(params) => <TextField {...params} sx={{
              backgroundColor: "white"
            }} label="Search Plating" />}
          />  

          <GeneralTable 
            anyResult={(plating || []).filter((p) => filterPlating ? p.name.trim().toLowerCase().includes(filterPlating.trim().toLowerCase()) : true)}
            onRowClick={(id) => {
              const newPlating = plating.find((p) => p.id === id);
              setNewPLating(newPlating);
              setOpen(true);
            }}
            itemName="name"
            itemId="id"
            skipHeaders={["ingredients"]}
          />

        </>
      }

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography sx={{
            fontWeight: "bold",
            fontSize: "30px",
            mb: 3,
          }}> 
            Create Plating
          </Typography>

          <TextField sx={{width: "45%", mb: 3}} label="Plating Name" value={newPlating.name} onChange={(e) => setNewPLating({
            ...newPlating,
            name: e.target.value,
          })} />

          <Box sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            height: "70%",
          }}>

            <Box sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}> 
              <Typography sx={{
                fontWeight: "bold",
                fontSize: "18px", 
                mb: 1,
              }}> 
            Auto Generate Plating
              </Typography>
              <TextField 
                multiline
                rows={8}
                placeholder="Query to generate plating"
                sx={{
                  width: "90%",
                  mb: 3,
                }}
                value={queryToGeneratePlating}
                onChange={(e) => setQueryToGeneratePlating(e.target.value)}
              /> 
              <LoadingButton 
                onClick={async () => {
                  const newIngredients = await gptGeneratePlating(queryToGeneratePlating);
                  setNewPLating({
                    ...newPlating,
                    ingredients: [...newPlating.ingredients ,...newIngredients.ingredients],
                  });
                }}    
                loading={gptGenerateLoading}     
                sx={{
                  width: "90%",
                }} variant="contained">
            Generate Plating
              </LoadingButton>
                
              <Button
                sx={{
                  width: "90%",
                  mt: 3,
                }} 
                variant="contained" onClick={() => {
                  const newIngredient = {
                    name: "",
                    quantity: "",
                    unit: "",
                  };
                  const newIngredients = [...newPlating.ingredients, newIngredient];
                  setNewPLating({
                    ...newPlating,
                    ingredients: newIngredients,
                  });
                }}> 
                Add Ingredient
              </Button>
            </Box>

            <Box sx={{
              width: "100%",
              height: "100%",
              overflow: "scroll",
            }}>
              <Typography sx={{
                fontWeight: "bold",
                fontSize: "18px",
                mb: 1, 
              }}> 
            Plating ingredients
              </Typography>

              {
                newPlating.ingredients.map((ingredient, index) => {
                  return (
                    <Box key={index} sx={{
                      display: "flex",
                      flexDirection: "row",
                      my: 1
                    }} > 
                      <TextField
                        variant="outlined"
                        sx={{
                          mx: 1
                        }}
                        onChange={(e) => {
                          const newIngredients = newPlating.ingredients.map((ing, i) => {
                            if (i === index) {
                              return {
                                ...ing,
                                name: e.target.value,
                              };
                            }
                            return ing;
                          });
                          setNewPLating({
                            ...newPlating,
                            ingredients: newIngredients,
                          });
                        }}
                        label="Name"
                        value={ingredient.name} /> 
                      <TextField
                        variant="outlined"
                        sx={{
                          mx: 1
                        }}
                        onChange={(e) => {
                          const newIngredients = newPlating.ingredients.map((ing, i) => {
                            if (i === index) {
                              return {
                                ...ing,
                                quantity: e.target.value,
                              };
                            }
                            return ing;
                          });
                          setNewPLating({
                            ...newPlating,
                            ingredients: newIngredients,
                          });
                        }}
                        label="Quantity"
                        value={ingredient.quantity} /> 
                      <TextField
                        variant="outlined"
                        sx={{
                          mx: 1
                        }}
                        onChange={(e) => {
                          const newIngredients = newPlating.ingredients.map((ing, i) => {
                            if (i === index) {
                              return {
                                ...ing,
                                unit: e.target.value,
                              };
                            }
                            return ing;
                          });
                          setNewPLating({
                            ...newPlating,
                            ingredients: newIngredients,
                          });
                        }}
                        label="Unit"
                        value={ingredient.unit} /> 
                      <IconButton onClick={() => {
                        const newIngredients = newPlating.ingredients.filter((ing, i) => i !== index);
                        setNewPLating({
                          ...newPlating,
                          ingredients: newIngredients,
                        });
                      }}>
                        <DeleteIcon color="error" />
                      </IconButton>
                    </Box>
                  );
                })  
              }
            </Box>
          </Box>

          <Box sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            mt: "auto",            
          }}> 
            <Button variant="contained" color="error" sx={{
              mr: 2,
            }}
            onClick={handleClose}
            > 
                Cancel
            </Button>
            <Box> 
              <LoadingButton
                onClick={async () => {
                  await setPlatingMutation(newPlating);
                  handleClose();
                }}
                loading={updatePlatingLoading} 
                variant="contained">
                    Save Plating
              </LoadingButton>
            </Box>
          </Box>

        </Box>
      </Modal>

    </Box>
  );
};

export default Plating;