import React from "react";
import StyledDataGrid from "./StlyedDataGrid";
import { Box } from "@mui/material"; 

const width = 100;

const GeneralTable = ({ anyResult, onRowClick, itemName, itemId, skipHeaders, headerWithExtraWidth, sortWeights }) => {
  const columns = anyResult[0] ? Object.keys(anyResult[0]).map((key) => ({
    field: key,
    headerName: key,
    editable: false,
    width: key === itemName ? width + 300 : width ,
  }))
    .map((c) => {
      if (headerWithExtraWidth && headerWithExtraWidth.includes(c.field)) {
        return {
          ...c,
          width: c.width + 300,
        };
      }
      return c;
    })
    .filter((a) => !(skipHeaders || []).includes(a.field))
    .sort((a, b) => {
      if(sortWeights) {
        const x = sortWeights[a.field] || 0;
        const y = sortWeights[b.field] || 0;
        return x < y ? -1 : x > y ? 1 : 0;
      } else {
        const x = a.field.toLowerCase();
        const y = b.field.toLowerCase();
        return x < y ? -1 : x > y ? 1 : 0;
      }
    }) : [];


  return (
    <Box 
      sx={{width: "100%", height: "80vh", overflow: "auto", my: "2%" }}>
      <StyledDataGrid
        sx={{ backgroundColor: "white" }}
        rows={anyResult}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 20,
            },
          },
        }} 
        onRowClick={(row) => onRowClick(row.row.id)}
        getRowId={(row) => row[itemId] + (Math.random() *100)}
        pageSizeOptions={[20]}
        disableRowSelectionOnClick
      />
    </Box>
  );
};

export default GeneralTable;