import React from "react";
import PropTypes from "prop-types";
import { Box, Flex } from "noshery-ui";

import SingleOrder from "./SingleOrder";

const Orders = ({ orders }) => {
  return (
    <Box border="1px solid #ececec" borderRadius="4px" mt={4} p={2}>
      <OrdersHeader />
      {orders.map((order, index) => (
        <SingleOrder {...order} index={index} key={index} />
      ))}
    </Box>
  );
};

Orders.propTypes = {
  orders: PropTypes.array,
};

export default Orders;

const OrdersHeader = () => {
  const headers1 = ["Rest.", "Source"];
  const headers2 = ["Opened Date", "Closed Date"];
  const headers3 = ["Mixed Order", "Amount" ];

  return (
    <Flex
      alignItems="center"
      width="100%"
      borderBottom="1px solid #ececec"
      pb={2}
    >
      {headers1.map(h => (
        <Box key={h} width={1 / 15}>
          {h}
        </Box>
      ))}
      {headers2.map(h => (
        <Box key={h} width={2 / 15}>
          {h}
        </Box>
      ))}
      {headers3.map(h => (
        <Box key={h} width={1 / 15}>
          {h}
        </Box>
      ))}
      <Box width={7 / 15}>Details</Box>
      <Box width={7 / 15}>GUID</Box>
    </Flex>
  );
};
