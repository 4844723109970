import React, {useEffect, useState} from "react";
import { Flex, Text } from "noshery-ui";
import {InputLabel, MenuItem, FormControl, Box, Select, Typography, Snackbar, CircularProgress, TextField, Switch, FormControlLabel, Checkbox} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import AlertTitle from "@mui/material/AlertTitle";
import useReadDb from "../../hooks/useReadDb";
import useWriteDb from "../../hooks/useWriteDb"; 
import Autocomplete from "@mui/material/Autocomplete";
import Modal from "@mui/material/Modal";
import useWindowSize from "../../hooks/useWindowSize";
import Cookies from "js-cookie";
import { LoadingButton } from "@mui/lab";

const API_URL = process.env.REACT_APP_API_URL;

const dboptions = {
  "employees": "Employees",
  "Checklist": "Checklist",
  "Checklist/locationsFilter": "Line Check Areas",
  "menuItemsCategories": "Menu Items Categories",
  "menuItemsMapping": "Menu Items Brand Mappings",
};

const checklistOptions = {
  "OPENING": "Opening",
  "CLOSING": "Closing",
  "HOST_OPENING": "Host Opening",
  "HOST_CLOSING": "Host Closing",
  "LINE_CHECK_AM": "Line Check AM",
  "LINE_CHECK_PM": "Line Check PM",
  "COUNT_OPENING": "Count AM",
  "COUNT_CLOSING": "Count PM",
};

const locations = {
  "San Mateo": "sm",
  "San Carlos": "sc",
  "San Ramon": "sr",
  "All": "all"
};

const ListAreas = {
  "Melt/TLC Coldline": "Melt/TLC Coldline",
  "Orens/TLC Coldline": "Orens/TLC Coldline",
  "Hot Line": "Hot Line",
  "Coldline A": "Coldline A",
  "Coldline B": "Coldline B",
  "ESBM": "ESBM",
  "TLC": "TLC",
  "Orens": "Orens",
  "Asian Box": "Asian Box",
  "Lazy Susan": "Lazy Susan",
};

const countTypes = ["Soda", "Ice Cream"];

const Editor = () => {
  const [db, setDb] = useState("");
  const [ListAreas, setListAreas] = useState({});
  const [checklist, setChecklist] = useState("");
  const [filterArea, setFilterArea] = useState("");
  const [results, setResults] = useState([]);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(false);
  const [successPopup, setSucessPopup] = useState(false);
  const [input, setInput] = useState({});
  const {width} = useWindowSize();

  const [newEmployeeOpen, setNewEmployeeOpen] = React.useState(false);
  const handleOpen = () => setNewEmployeeOpen(true);
  const handleClose = () => setNewEmployeeOpen(false);
  const [newAreaOpen, setNewAreaOpen] = React.useState(false);
  const handleAreaOpen = () => setNewAreaOpen(true);
  const handleAreaClose = () => setNewAreaOpen(false);

  const [name, setName] = useState("");
  const [guid, setGuid] = useState("");
  const [firstname, setFirstName] = useState("");
  const [admin, setAdmin] = useState(false);
  const [passcode, setPasscode] = useState("");
  const [assignedList, setAssignedList] = useState(""); 
  const [location, setLocation] = useState(""); 
  const [gustoId, setGustoId] = useState("");
  const [newarea, setNewArea] = useState("");
  const [sync, setSync] = useState(false);

  const {readCollection, isLoading: readLoading, readDocument} = useReadDb(); 
  const {writeDocument} = useWriteDb(); 
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [brands, setBrands] = useState([]);
  const [changedItems , setChangedItems] = useState([]);

  React.useEffect(() => {
    if(db === "Checklist") return;
    (async () =>{
      if(db === "Checklist/locationsFilter") {
        const data = await readDocument("Checklist", "locationsFilters");
        setResults(data.data);
        return;
      }
      let {data} = await readCollection(db);
      setResults(data);
    })();  
    setChecklist("");
  }, [db]);
  
  React.useEffect(() => {
    (async () =>{
      if(checklist==="") return;
      let {data} = await readDocument(db, checklist);
     
      setResults(data);
    })();  
  }, [checklist]);

  React.useEffect(() => {
    (async () => { 
      let data = await readDocument("Checklist", "filters");
      setListAreas(data.data); 
    })(); 
  }, []);

  React.useEffect(() => {
    (async () => { 
      let data = await readCollection("categories");
      setCategories(data.data); 
    })(); 
    (async () => { 
      let data = await readCollection("brands");
      setBrands(data.data); 
    })(); 
  }, []);

  const addnewArea = async () => {
    let temp = JSON.parse(JSON.stringify(ListAreas));
    temp[newarea] = newarea;
    await writeDocument("Checklist", "filters", temp);
    setListAreas(temp);
    setNewArea("");
    handleAreaClose();
    (async () => { 
      let data = await readDocument("Checklist", "filters");
      setListAreas(data.data);
    })(); 
  };

  const handleUpload = async () => {
    if(db === "Checklist/locationsFilter"){
      setLoading(true);
      let temp = JSON.parse(JSON.stringify(results));
      Object.keys(input).map((key) => {
        console.log(key, input[key]);
        temp[key] = input[key];
      });
      let dbRes = await writeDocument("Checklist", "locationsFilters", temp);
      if(!dbRes) setError(true);
      setLoading(false);
      setResults([]);
      setDb("");
      setInput({});
      setSucessPopup(true);
    }
    else if(db === "Checklist"){
      setLoading(true);
      let temp = JSON.parse(JSON.stringify(results));
      Object.keys(input).map((key, i) => {
        temp[key] = input[key];
      });
      let dbRes = await writeDocument(db, checklist, temp);
      if(!dbRes) setError(true);
      setLoading(false);
      setResults([]);
      setDb("");
      setInput({});
      setSucessPopup(true);
    }
    else if(db === "employees"){
      setLoading(true);
      let temp = JSON.parse(JSON.stringify(results));
      let obj = {};
      temp.map((employee) => {
        if(input[employee["name"]] ) {
          obj[employee["guid"]] = employee;
        }
      });
      
      let dbRes = await writeDocument(db, "", obj);
      if(!dbRes) setError(true);
      setLoading(false);
      setResults([]);
      setDb("");
      setInput({});
      setSucessPopup(true);
    } else if (db === "menuItemsCategories") {
      setLoading(true);
      await Promise.all(changedItems.map(async (item) => {
        let dbRes = await writeDocument(db, item["id"], item);
        if(!dbRes) setError(true);
      }));
      setLoading(false);
      setResults([]);
      setDb("");
      setInput({});
      setSucessPopup(true);
    } else if (db === "menuItemsMapping") {
      setLoading(true);
      await Promise.all(changedItems.map(async (item) => {
        let dbRes = await writeDocument(db, item["id"], item);
        if(!dbRes) setError(true);
      }));
      setLoading(false);
      setResults([]);
      setDb("");
      setInput({});
      setSucessPopup(true);
    }
  };

  const handleCancel = () => {
    setResults([]);
    setName("");
    setAdmin(false);
    setPasscode("");
    setAssignedList([]);
    setGuid("");
    setDb("");
  };

  const handleSave = () => {
    let temp = JSON.parse(JSON.stringify(results));    
    Object.keys(input).forEach((key) => { 
      temp.forEach((employee) => {
        if(employee["guid"] === input[key]["guid"]){
          console.log("employee", "true");
          employee["admin"] = input[key]["admin"];
          employee["passcode"] = input[key]["passcode"];
          employee["location"] = input[key]["location"];
          employee["gusto_id"] = input[key]["gusto_id"];
          employee["firstName"] = input[key]["firstName"];
        }
      });
    });
    setResults(temp);
  };

  const addEmployee = () => {
    let temp = JSON.parse(JSON.stringify(results));
    temp.push({
      "name": name.trim(),
      "admin": admin,
      "passcode": passcode.trim(),
      guid: guid.trim(),
      alertSent: false,
      AssignedList: assignedList,
      gusto_id: gustoId,
      firstName: firstname,
      location: location,
    });
    setResults(temp);
    setInput(values => ({...values, [name]: {      
      "name": name.trim(),
      "admin": admin,
      "passcode": passcode.trim(),
      guid: guid.trim(),
      alertSent: false,
      AssignedList: assignedList,
      gusto_id: gustoId,
      firstName: firstname,
      location: location,
    }}));
    handleClose();
  };

  const hoverStyle = {
    "&:hover": {
      backgroundColor: "#d8870f",
    },
  };

  const style = {
    width: "45%",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    overflow: "hidden",
    height: `calc(100% - ${(width * 2) / 100}px)`,
    background: "white",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    overflowY: "scroll",
  };
  
  const styleArea = {
    width: "45%",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    overflow: "hidden",
    height: "45%",
    background: "white",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    overflowY: "scroll",
    borderRadius: "10px",
  };

  const deleteFromChecklist = (taskNumber) => {
    let temp = JSON.parse(JSON.stringify(results));
    delete temp[taskNumber];
    setResults(temp);
  };

  const addTask = ({taskName, taskNumber, area, location, type}) => {
    if(checklist === "LINE_CHECK_AM" || checklist === "LINE_CHECK_PM"){
      let task = {
        "taskName": taskName,
        "by": "",
        "completed": false,
        "state": "",
        "time": "",
        "imageUrl": "",
        "comments": "",
        "issues": "",
        area: area,
        location: location,
      };
      setResults({...results, ["TASK"+taskNumber]: task});
    }
    else if(checklist.includes("COUNT")) {
      let task = {
        "taskName": taskName,
        "by": "",
        "completed": false,
        count: 0,
        type: type,
        location: location,
      };
      setResults({...results, ["TASK"+taskNumber]: task});
    }
    else {
      let task = {
        "taskName": taskName,
        "by": "",
        "completed": false,
        location: location,
      };
      setResults({...results, ["TASK"+taskNumber]: task});
    }
  };

  const syncChecklist = async () => {
    setSync(true);
    const getAccessToken = () => Cookies.get("accessToken");
    await fetch(
      `${API_URL}/updateChecklist`,
      {
        method: "GET",
        mode: "cors",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${getAccessToken()}`,
        }, 
      }
    );
    setSync(false);
    (async () =>{
      if(checklist==="") return;
      let {data} = await readDocument(db, checklist);
     
      setResults(data);
    })();  
  };

  return (
    <Box>
      <Flex pt={"100px"} pb={4} px="100px" flexDirection="column" bg="white">
        <Flex justifyContent="center" flexDirection="column" bg="white" p={20} mb={4}>
          <Text fontWeight="600" fontSize={5} mb={4}>
            Editor
          </Text>
          <Flex flexDirection="column" justifyContent="center">
            <Flex justifyContent="center"> 
              <FormControl sx={{paddingBottom: "2%", width: "40%"}}>
                <InputLabel id="demo-simple-select-label"> Database</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={db}
                  label="Database"
                  onChange={(e) => setDb(e.target.value)}
                >
                  {Object.keys(dboptions).map((option) => {
                    return <MenuItem key={option} value={option}>{dboptions[option]}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            </Flex>
            {
              db === "Checklist" && (
                <>
                  <Flex justifyContent="end">
                    <Button onClick={handleAreaOpen} sx={{marginTop: "5%", mr: "1%", background: "#F1A637", ...hoverStyle, width: "200px"}} disabled={loading || db === "invoices"} variant="contained" component="label">
                      Add New Area
                    </Button>
                    <LoadingButton onClick={syncChecklist} loading={sync} sx={{marginTop: "5%", background: "#F1A637", ...hoverStyle, width: "200px"}} disabled={loading || db === "invoices"} variant="contained" component="label">
                      Sync Line Checks 
                    </LoadingButton>
                  </Flex>
                  <Flex justifyContent="center">
                    <FormControl sx={{paddingBottom: "2%", width: "40%"}}>
                      <InputLabel id="demo-simple-select-label"> Checklist Name </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={checklist}
                        label="Database"
                        onChange={(e) => setChecklist(e.target.value)}
                      >
                        {Object.keys(checklistOptions).map((option) => {
                          return <MenuItem key={option} value={option}>{checklistOptions[option]}</MenuItem>;
                        })}
                      </Select>
                    </FormControl>
                  </Flex>
                  { checklist.includes("LINE_CHECK") && <Flex justifyContent="center">
                    <FormControl sx={{paddingBottom: "2%", width: "40%"}}>
                      <InputLabel id="demo-simple-select-label"> Line Check Area </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={filterArea}
                        label="Database"
                        onChange={(e) => setFilterArea(e.target.value)}
                      >
                        {
                          Object.keys(ListAreas).map((option) => {
                            return <MenuItem key={option} value={option}>{ListAreas[option]}</MenuItem>;
                          })
                        } 
                      </Select>
                    </FormControl>
                  </Flex>}
                </>
              )
            }         
            {readLoading && <CircularProgress sx={{alignSelf: "center"}} />}
            {(Object.keys(results).length !== 0 || results.length !== 0) && !readLoading && (
              (db === "Checklist/locationsFilter" && <LineCheckAreas rows={results} input={input} setInput={setInput} areas={(ListAreas)} /> ) || 
                (db === "employees" && <EmployeesTable rows={results} setInput={setInput} input={input} handleSave={handleSave} openModal={handleOpen} />) ||
                (db === "Checklist" && 
                  <ChecklistTable 
                    rows={results}
                    filterArea={filterArea} 
                    setResults={setResults} 
                    input={input} 
                    setInput={setInput} 
                    deleteFromChecklist={deleteFromChecklist} 
                    addTask={addTask} 
                    checklist={checklist} /> 
                  ||
                db === "menuItemsCategories" && <MenuCategories rows={results} setRows={setChangedItems} categories={categories} /> || 
                
                db === "menuItemsMapping" && <MenuMappings rows={results} setRows={setChangedItems} brands={brands} />
                )
            )} 
            {results.length === 0 || error && <AlertErrors setOpen={setOpen} open={open} errorMessage={"Could not read db."} /> }
            {error === true && <AlertErrors setOpen={setOpen} open={open} errorMessage={"Please Select A Database"} /> }
            {results.length !== 0 &&
                <Flex justifyContent="center"> 
                  <Button sx={{marginTop: "5%", background: "#F1A637", ...hoverStyle, marginRight: "5%", width: "30%"}} disabled={loading}  variant="contained" component="label" onClick={handleCancel}>
                    Cancel
                  </Button> 
                  <Button sx={{marginTop: "5%", background: "#F1A637", ...hoverStyle, width: "30%"}} disabled={loading || db === "invoices"} variant="contained" component="label" onClick={handleUpload}>
                    {db !== "invoices" ? "Submit" : "*VIEW ONLY*"}
                  </Button> 
                </Flex>
            }
          </Flex>
          <Snackbar open={successPopup} autoHideDuration={5000} onClose={() => setSucessPopup(false)}>
            <Alert onClose={() => setSucessPopup(false)} severity="success" sx={{ width: "100%" }}>
              Databse Successfully Updated!
            </Alert>
          </Snackbar>
        </Flex>
      </Flex>
      <Modal
        open={newAreaOpen}
        onClose={handleAreaClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleArea}>
          <Box sx={{display: "flex", width: "70%", flexDirection: "column", justifyContent: "center",alignContent: "center", alignItems: "center" }}> 
            <Typography id="modal-modal-title" variant="h6" component="h2"> Add Area </Typography>
            <TextField sx={{width: "100%", marginTop: "5%"}} id="outlined-basic" label="Area" variant="outlined" value={newarea} onChange={(e) => setNewArea(e.target.value)} /> 
          </Box>
          <Box sx={{width: "70%", justifyContent: "center", display: "flex", flexDirection: "column", alignContent: "center", alignItems: "center"}} >
            <Button onClick={() => {
              addnewArea(); 
            }}  sx={{margin: "3%", background: "#F1A637", ...hoverStyle, width: "100%", color: "white"}} > Add Area </Button>
            <Button onClick={handleClose} sx={{margin: "3%", background: "#F1A637", ...hoverStyle, width: "100%", color: "white"}} > Cancel </Button>
          </Box>
        </Box>
      </Modal>
      <Modal
        open={newEmployeeOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{display: "flex", width: "70%", flexDirection: "column", justifyContent: "center",alignContent: "center", alignItems: "center" }}> 
            <Typography id="modal-modal-title" variant="h6" component="h2"> Add Employee </Typography>
            <TextField sx={{width: "100%", marginTop: "5%"}} id="outlined-basic" label="Guid" variant="outlined" onChange={(e) => setGuid(e.target.value)} />
            <Flex width="100%">
              <TextField sx={{width: "100%", marginTop: "5%"}} id="outlined-basic" label="Name" variant="outlined" onChange={(e) => setName(e.target.value)} />
              <TextField sx={{width: "100%", marginTop: "5%"}} id="outlined-basic" label="Passcode" variant="outlined" onChange={(e) => setPasscode(e.target.value)} />
            </Flex>
            <Flex width="100%">
              <TextField sx={{width: "100%", marginTop: "5%"}} id="outlined-basic" label="First Name" variant="outlined" onChange={(e) => setFirstName(e.target.value)} />
              <TextField sx={{width: "100%", marginTop: "5%"}} id="outlined-basic" label="Gusto Id" variant="outlined" onChange={(e) => setGustoId(e.target.value)} />
            </Flex>
            <Select 
              select id={"inputs-location"} sx={{width: "100%", margin: "3%"}} name="location" 
              value={location} onChange={e => setLocation(e.target.value)}  label="Location">
              {Object.keys(locations).map((option) => (
                <MenuItem key={option} value={locations[option]}>
                  {option}
                </MenuItem>
              ))}
              <MenuItem hidden key={""} value={""}>
                {""}
              </MenuItem>
            </Select>
            <Box paddingTop={"5%"}> 
              <Text id="modal-modal-title">Assigned List: </Text> 
              <FormControlLabel sx={{marginTop: "5%"}} control={<Checkbox value="OPENING" onChange={(e) => e.target.checked ? setAssignedList([...assignedList, e.target.value]) : setAssignedList([...assignedList.filter((item) => item !== e.target.value)])} />} label="OPENING" />
              <FormControlLabel sx={{marginTop: "5%"}} control={<Checkbox value="CLOSING" onChange={(e) => e.target.checked ? setAssignedList([...assignedList, e.target.value]) : setAssignedList([...assignedList.filter((item) => item !== e.target.value)])} />} label="CLOSING" />
              <FormControlLabel sx={{marginTop: "5%"}} control={<Checkbox value="HOST_OPENING" onChange={(e) => e.target.checked ? setAssignedList([...assignedList, e.target.value]) : setAssignedList([...assignedList.filter((item) => item !== e.target.value)])} />} label="HOST_OPENING" />
              <FormControlLabel sx={{marginTop: "5%"}} control={<Checkbox value="HOST_CLOSING" onChange={(e) => e.target.checked ? setAssignedList([...assignedList, e.target.value]) : setAssignedList([...assignedList.filter((item) => item !== e.target.value)])} />} label="HOST_CLOSING" />
              <FormControlLabel sx={{marginTop: "5%"}} control={<Checkbox value="LINE_CHECK" onChange={(e) => e.target.checked ? setAssignedList([...assignedList, e.target.value]) : setAssignedList([...assignedList.filter((item) => item !== e.target.value)])} />} label="LINE_CHECK" />
            </Box>
            <FormControlLabel sx={{marginTop: "5%"}} control={<Checkbox checked={admin} onChange={(e) => setAdmin(e.target.checked)} />} label="Admin" />
          </Box>
          <Box sx={{width: "70%", justifyContent: "center", display: "flex", flexDirection: "column", alignContent: "center", alignItems: "center"}} >
            <Button onClick={() => {
              addEmployee();
              handleClose();  
            }}  sx={{margin: "3%", background: "#F1A637", ...hoverStyle, width: "100%", color: "white"}} > Save </Button>
            <Button onClick={handleClose} sx={{margin: "3%", background: "#F1A637", ...hoverStyle, width: "100%", color: "white"}} > Cancel </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

const LineCheckAreas = ({rows, input, setInput, areas}) => {
  const headers = ["Store", "Areas"];

  const handleForm = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    console.log(name, value);
    setInput(values => ({...values, [name]: value}));
  }; 

  return (
    <> 
      <TableContainer sx={{ maxWidth: 650, alignSelf: "center" }} component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow 
              sx={{ "&:last-child td, &:last-child th": { border: 0 }, display: "flex", flexDirection: "row", justifyContent: "space-between", width: 525 }}>
              {headers.map((header) => {
                return <TableCell key={header}>{header}</TableCell>;
              })}
            </TableRow>
          </TableHead>
          <TableBody>

            <FormControl>
              {Object.keys(rows).map((curRow, i) => {
                let row = curRow; 
                return <TableRow
                  key={row+ i}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 }, display: "flex", flexDirection: "row", justifyContent: "space-between", width: 600 }}
                >
                  <TableCell component="th" scope="row">
                    {row}
                  </TableCell>
                  <TableCell component="th" sx={{width: "100%"}} >       
                    <Select 
                      sx={{width: 500}}
                      multiple  
                      defaultValue={rows[row]} 
                      placeholder={rows[row]}
                      name={row} 
                      type={row} 
                      title={"Area"}
                      value={input[row] ? input[row] : rows[row]}
                      onChange={e=>handleForm(e)} label={"Area"}>
                      {Object.keys(areas).map((option) => (
                        <MenuItem key={option} value={areas[option]}>
                          {option}
                        </MenuItem>
                      ))}
                      <MenuItem hidden key={""} value={""}>
                        {""}
                      </MenuItem>
                    </Select>
                  </TableCell>
                </TableRow>;
              })}
            </FormControl>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

const MenuCategories = ({rows, setRows, categories}) => {
  const headers = [ "id", "value"];

  return (
    <> 
      <TableContainer sx={{ maxWidth: 650, alignSelf: "center" }} component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow 
              sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: 525 }}>
              {headers.map((header) => {
                return <TableCell key={header}>{header === "id" ? "Item Name" : "Category"}</TableCell>;
              })}
            </TableRow>
          </TableHead>
          <TableBody>

            <FormControl>
              {(rows).map((curRow, i) => {
                let row = curRow;  
                return <TableRow
                  key={row+ i}
                  sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: 600 }}
                >
                  <TableCell  sx={{width: "100%"}} scope="row">
                    {row["id"]}
                  </TableCell> 
      
                  <TableCell  sx={{width: "100%"}} >       
                    <Select 
                      sx={{width: 200}} 
                      value={(row["value"] && row["value"] !== "" && row["value"] !== "Uknown") ? row["value"] : "Unknown"}
                      onChange={(e) => {
                        let newRows = [...rows];
                        newRows[i]["value"] = e.target.value; 

                        const newRow = newRows[i];

                        setRows((state) => {
                          return [...state.filter((item) => item["id"] !== newRow["id"]), newRow];
                        });

                      }}
                      title={"Category"}  >
                      {(categories).map((option) => (
                        <MenuItem key={option.name} value={option.name}>
                          {option.name}
                        </MenuItem>
                      ))}
                      
                    </Select>
                  </TableCell>
                </TableRow>;
              })}
            </FormControl>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

const MenuMappings = ({rows, setRows, brands}) => {
  const headers = [ "id", "value"];

  return (
    <> 
      <TableContainer sx={{ maxWidth: 650, alignSelf: "center" }} component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow 
              sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: 525 }}>
              {headers.map((header) => {
                return <TableCell key={header}>{header === "id" ? "Item Name" : "Brand Name"}</TableCell>;
              })}
            </TableRow>
          </TableHead>
          <TableBody>

            <FormControl>
              {(rows).map((curRow, i) => {
                let row = curRow; 
                return <TableRow
                  key={row+ i}
                  sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: 600 }}
                >
                  <TableCell  sx={{width: "100%"}} scope="row">
                    {row["id"]}
                  </TableCell> 
      
                  <TableCell  sx={{width: "100%"}} >       
                    <Select 
                      sx={{width: 200}} 
                      value={row["value"] ? row["value"] : "Unknown"}
                      onChange={(e) => {
                        let newRows = [...rows];
                        newRows[i]["value"] = e.target.value; 

                        const newRow = newRows[i];

                        setRows((state) => {
                          return [...state.filter((item) => item["id"] !== newRow["id"]), newRow];
                        });

                      }}
                      title={"Brand"}  >
                      {(brands).map((option) => (
                        <MenuItem key={option.name} value={option.name}>
                          {option.name}
                        </MenuItem>
                      ))}
                      <MenuItem hidden key={""} value={""}>
                        {""}
                      </MenuItem>
                    </Select>
                  </TableCell>
                </TableRow>;
              })}
            </FormControl>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

const ChecklistTable = ({rows, setResults, deleteFromChecklist, addTask, checklist, filterArea}) => {
  const [open, setOpen] = React.useState(false);
  const [taskNumber, setTaskNumber] = React.useState((Object.keys(rows).length));
  const [task, setTask] = React.useState("");
  const [area, setArea] = React.useState("");
  const [type, setType] = useState("");
  const [tasklocations, setTaskLocations] = React.useState(["all"]);
  const {width} = useWindowSize();
  const handleOpen = () => setOpen(true);
  const handleClose = () =>{
    setOpen(false);
    setTaskNumber(Object.keys(rows).length + 1);
    setTask("");
  };
  const handleForm = (e, type) => {
    const name = e.target.name;
    const value = e.target.value;
    let temp = JSON.parse(JSON.stringify(rows));
    temp[name][type] = value;
    setResults(temp);
  }; 
  const hoverStyleDelete = {
    "&:hover": {
      backgroundColor: "red",
    },
  };
  const hoverStyle = {
    "&:hover": {
      backgroundColor: "#d8870f",
    },
  };
  const style = {
    width: "45%",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    overflow: "hidden",
    height: `calc(100% - ${(width * 10) / 100}px)`,
    background: "white",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "15px"
  };
  useEffect(() => {
    
    setTaskNumber(Object.keys(rows).map(row => parseInt(row.split("TASK")[1])).sort((a, b) => a- b).pop() + 1);
  }, [rows]);
  
  return (
    <>
      <Button onClick={handleOpen} sx={{margin: "3%", background: "#F1A637", ...hoverStyle, alignSelf: "end", color: "white"}}> Add Task </Button>
      <TableContainer sx={{ maxWidth: "70%", alignSelf: "center" }} component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 }, display: "flex", flexDirection: "row", justifyContent: "space-between", width: 600 }}>
              <TableCell component="th" key={"Task Number"}>{"Task Number"}</TableCell>
              <TableCell component="th" sx={{width: 500}} key={"Task Name"}>{"Task Name"}</TableCell>
              <TableCell component="th" sx={{width: 500}} key={"Location"}>{"Location"}</TableCell>
              {checklist.includes("COUNT") && <TableCell component="th" sx={{width: 100}} key={"Type"}>{"Type"}</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            <FormControl>
              {Object.keys(rows).sort((a, b) => parseInt(a.split("TASK")[1]) - parseInt(b.split("TASK")[1])).map((curRow, i) => {
                let row = curRow;
                if(rows[row]["area"] !== filterArea && rows[row]["area"]) return false;
                return <TableRow
                  key={row+ i}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 }, display: "flex", flexDirection: "row", justifyContent: "space-between", width: 600 }}
                >
                  <TableCell component="th" scope="row">
                    {row}
                  </TableCell>
                  {rows[row]["taskName"] && <TableCell component="th" >       
                    <TextField 
                      sx={{width: 300}}
                      multiline 
                      defaultValue={rows[row]["taskName"]} 
                      placeholder={rows[row]["taskName"]}
                      name={row} 
                      type={row} 
                      value={rows[row]["taskName"]}
                      onChange={e=>handleForm(e, "taskName")} label={"taskName"} />
                  </TableCell>}
                  {(rows[row]["location"]) && <TableCell component="th" >       
                    <Select 
                      sx={{width: 200}}
                      multiple  
                      defaultValue={rows[row]["location"]} 
                      placeholder={rows[row]["location"]}
                      name={row} 
                      type={row} 
                      title={"Location"}
                      value={rows[row]["location"]}
                      onChange={e=>handleForm(e, "location")} label={"location"}>
                      {Object.keys(locations).map((option) => (
                        <MenuItem key={option} value={locations[option]}>
                          {option}
                        </MenuItem>
                      ))}
                      <MenuItem hidden key={""} value={""}>
                        {""}
                      </MenuItem>
                    </Select>
                  </TableCell>}
                  {
                    rows[row]["area"] && <TableCell component="th" >       
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Area</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          sx={{width: 200}}
                          multiline 
                          defaultValue={rows[row]["area"]} 
                          placeholder={rows[row]["area"]}
                          name={row} 
                          type={row} 
                          value={rows[row]["area"]}
                          onChange={e=>handleForm(e, "area")} label={"area"}
                        >
                          {
                            Object.keys(ListAreas).map((option) => {
                              return <MenuItem key={option} value={option}>{ListAreas[option]}</MenuItem>;
                            })
                          } 
      
                        </Select>
                      </FormControl>
                    </TableCell>
                  }
                  {
                    rows[row]["type"] && <TableCell component="th" >
                      <Select 
                        sx={{width: 200}}
                        defaultValue={rows[row]["type"]} 
                        placeholder={rows[row]["type"]}
                        name={row} 
                        type={row} 
                        title={"type"}
                        value={rows[row]["type"]}
                        onChange={e=>handleForm(e, "type")} label={"type"}>
                        {countTypes.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    </TableCell>
                  }
                  <TableCell component="th" >
                    <Button onClick={() => deleteFromChecklist(row)} sx={{margin: "3%", background: "#F1A637", ...hoverStyleDelete, alignSelf: "end", color: "white"}}> Delete </Button>
                  </TableCell>
                </TableRow>;
              })}
            </FormControl>
          </TableBody>
        </Table>
      </TableContainer>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h3" component="h1" sx={{marginBottom: "5%"}}> Add Task </Typography>
          <Box sx={{display: "flex", width: "70%", flexDirection: "column", justifyContent: "center",alignContent: "center", alignItems: "center", paddingBottom: "3%" }}> 
            <TextField label="Task Name" fullWidth placeHolder="Task Name" value={task} onChange={e => setTask(e.target.value)} />
          </Box>
          <Box sx={{display: "flex", width: "70%", flexDirection: "column", justifyContent: "center",alignContent: "center", alignItems: "center" }}> 
            <TextField label="Task Number" fullWidth placeHolder="Task Number" value={taskNumber} onChange={e => setTaskNumber(e.target.value)} />
          </Box>
          {
            checklist.includes("COUNT") &&           
            <Box sx={{display: "flex", width: "70%", flexDirection: "column", justifyContent: "center",alignContent: "center", alignItems: "center", paddingTop: "3%"}}>     
              <Select 
                sx={{width: "100%"}}
                title={"type"}
                value={type}
                onChange={e=> setType(e.target.value)} label={"type"}>
                {countTypes.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
                <MenuItem hidden key={""} value={""}>
                  {""}
                </MenuItem>
              </Select>
            </Box>
          }
          <Box sx={{display: "flex", width: "70%", flexDirection: "column", justifyContent: "center",alignContent: "center", alignItems: "center", paddingTop: "3%"}}>     
            <Select 
              sx={{width: "100%"}}
              multiple  
              title={"Location"}
              placeholder={"Location"}
              defaultValue={["all"]}
              value={tasklocations}
              onChange={e=> setTaskLocations([...e.target.value])} label={"location"}>
              {Object.keys(locations).map((option) => (
                <MenuItem key={option} value={locations[option]}>
                  {option} {option === "All" ? "locations" : ""}
                </MenuItem>
              ))}
              <MenuItem hidden key={""} value={""}>
                {""}
              </MenuItem>
            </Select>
          </Box>
          {checklist.includes("LINE_CHECK") && <Box sx={{display: "flex", paddingTop: "3%", width: "70%", flexDirection: "column", justifyContent: "center",alignContent: "center", alignItems: "center" }}> 
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Area</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={area}
                label="area"
                onChange={e => setArea(e.target.value)}
              >
                {
                  Object.keys(ListAreas).map((option) => {
                    return <MenuItem key={option} value={option}>{ListAreas[option]}</MenuItem>;
                  })
                } 
              </Select>
            </FormControl>
          </Box>}
          <Box sx={{width: "70%", justifyContent: "center", display: "flex", flexDirection: "column", alignContent: "center", alignItems: "center"}} >
            <Button onClick={() => {
              addTask({taskNumber: taskNumber, taskName: task, area: area, location: tasklocations, type: type});
              handleClose();  
            }} sx={{margin: "3%", background: "#F1A637", ...hoverStyle, width: "100%", color: "white"}} > Add </Button>
            <Button onClick={handleClose} sx={{margin: "3%", background: "#F1A637", ...hoverStyle, width: "100%", color: "white"}} > Cancel </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

const EmployeesTable = ({rows, input, setInput, handleSave, openModal}) => {
  const headers = [ "name","admin", "passcode", "guid", "location", "gusto_id", "firstName"];
  const [chosen, setChosen] = useState({});
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const {width} = useWindowSize();
  const collator = new Intl.Collator("en");

  const handleForm = (e) => {
    if(e.target.name === "admin") {
      const name = (e.target.name).trim();
      let value = (e.target.checked);
      setInput(values => ({...values, [chosen["name"]]: {
        ...chosen,
        ...values[chosen["name"]],
        [name]: value
      }}));
    }
    else {
      const name = (e.target.name).trim();
      let value = (e.target.value).trim() || "";
      setInput(values => ({...values, [chosen["name"]]: {
        ...chosen,
        ...values[chosen["name"]],
        [name]: value
      }}));
    }
  }; 

  const style = {
    width: "45%",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    overflow: "hidden",
    height: `calc(100% - ${(width * 4) / 100}px)`,
    background: "white",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  };

  const clickEmployee = (employee) => {
    setChosen(employee);
    handleOpen();
  };

  const hoverStyle = {
    "&:hover": {
      backgroundColor: "#d8870f",
    },
  };

  return (
    <>
      <FormControl> 
        <Button onClick={openModal} sx={{margin: "3%", background: "#F1A637", ...hoverStyle, alignSelf: "end", color: "white"}}> Add Employee </Button>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                {headers.map((header) => {
                  return <TableCell key={header}>{header}</TableCell>;
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {((Object.keys(rows)).sort((a, b) => collator.compare(rows[a]["name"], rows[b]["name"]))).map((row, i) => {
                return <TableRow
                  onClick={() => clickEmployee(rows[row])}
                  key={row+ i}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  {headers.map((header) => {
                    return <TableCell key={header} component="th" scope="row">
                      {
                        header === "admin" ? (rows[row][header] === true ? "true" : "false") : rows[row][header]
                      }
                    </TableCell>;
                  })}
                </TableRow>;
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2"> {chosen["name"]} </Typography>
            <Box sx={{display: "flex", width: "70%", flexDirection: "column", justifyContent: "center",alignContent: "center", alignItems: "center" }}> 
              <TextField id={`${chosen["name"]}-inputs-passcode`} sx={{width: "100%", margin: "3%"}} defaultValue={chosen["passcode"]} name="passcode" 
                value={ (input[chosen["name"]] && (input[chosen["name"]]["passcode"]|| input[chosen["name"]]["passcode"] === "")) ? input[chosen["name"]]["passcode"] : chosen["passcode"]} onChange={e => handleForm(e)}  label="Passcode" />
              <TextField id={`${chosen["name"]}-inputs-gusto`} sx={{width: "100%", margin: "3%"}} defaultValue={chosen["gusto_id"]} name="gusto_id" 
                value={ (input[chosen["name"]] && (input[chosen["name"]]["gusto_id"]|| input[chosen["name"]]["gusto_id"] === "")) ? input[chosen["name"]]["gusto_id"] : chosen["gusto_id"]} onChange={e => handleForm(e)}  label="gusto_id" />
              <TextField id={`${chosen["name"]}-inputs-gusto`} sx={{width: "100%", margin: "3%"}} defaultValue={chosen["firstName"]} name="firstName" 
                value={ (input[chosen["name"]] && (input[chosen["name"]]["firstName"]|| input[chosen["name"]]["firstName"] === "")) ? input[chosen["name"]]["firstName"] : chosen["firstName"]} onChange={e => handleForm(e)}  label="firstName" />
       
              <Select 
                select id={`${chosen["name"]}-inputs-location`} sx={{width: "100%", margin: "3%"}} defaultValue={chosen["location"]} name="location" 
                value={ (input[chosen["name"]] && (input[chosen["name"]]["location"]|| input[chosen["name"]]["location"] === "")) ? input[chosen["name"]]["location"] : chosen["location"]} onChange={e => handleForm(e)}  label="Location">
                {Object.keys(locations).map((option) => (
                  <MenuItem key={option} value={locations[option]}>
                    {option}
                  </MenuItem>
                ))}
                <MenuItem hidden key={""} value={""}>
                  {""}
                </MenuItem>
              </Select>

              <Switch id={`${chosen["name"]}-inputs-admin`} sx={{margin: "3%"}} defaultChecked={chosen["admin"]} name="admin" 
                checked={(input[chosen["name"]] && (input[chosen["name"]]["admin"])) && input[chosen["name"]]["admin"]} onChange={e => handleForm(e)} label="Admin" />
            </Box>
            <Box sx={{width: "70%", justifyContent: "center", display: "flex", flexDirection: "column", alignContent: "center", alignItems: "center"}} >
              <Button onClick={() => {
                handleSave();
                handleClose();  
              }} sx={{margin: "3%", background: "#F1A637", ...hoverStyle, width: "100%", color: "white"}} > Save </Button>
              <Button onClick={handleClose} sx={{margin: "3%", background: "#F1A637", ...hoverStyle, width: "100%", color: "white"}} > Cancel </Button>
            </Box>
          </Box>
        </Modal>
      </FormControl>

    </>
  );
};

 
const AlertErrors = ({open, setOpen, errorMessage}) => {
  return (
    <Box style={{ width: "100%" }}>
      <Collapse in={open}>
        <Alert
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpen(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          <AlertTitle>Error</AlertTitle>
          {errorMessage}
        </Alert>
      </Collapse>
    </Box>
  );
};


export default Editor;
