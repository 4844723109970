import React from "react";
import { Box, Flex, Spinner } from "noshery-ui";
import styled from "styled-components";

const StyledFlex = styled(Flex)`
  height: 100vh;
  width: 100wh;
  align-items: center;
  justify-content: center;
`;

const PageLoader = () => {
  return (
    <StyledFlex bg="#f2f2f2">
      <Box mb={"20%"}>
        <Spinner variant="large" />
      </Box>
    </StyledFlex>
  );
};

export default PageLoader;
