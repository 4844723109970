import React from "react";
import { Flex, Box, Text } from "noshery-ui";
import moment from "moment";
import Dropdown from "../../components/Dropdown";
import useCharts from "../../hooks/useCharts";
import styled from "styled-components";
import PageLoader from "../../components/PageLoader";
import { restaurantColors } from "../../constants";
import { isDataEmpty } from "../../utils/validators";
import NoData from "../../components/NoData";
import Breakdown from "../../components/mobile/MobileBreakdown";
import ResponsiveLineChart from "../../components/ResponsiveLineChart";
import ResponsivePieChart from "../../components/ResponsivePieChart";
import useWindowSize from "../../hooks/useWindowSize";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

const dateRangeOption = {
  today: "Today",
  yesterday: "Yesterday",
  thisWeek: "This week",
  lastWeek: "Last week",
  thisMonth: "This month",
  lastMonth: "Last month",
  thisYear: "This year",
  allTime: "All time",
  custom: "Custom",
};

const periods = {
  period10: "Period 10",
  period11: "Period 11",
  period12: "Period 12",
};

const StyledSelect = styled.select`
  border-radius: 10px;
  box-shadow: 0 0 3px 0 rgb(0, 0, 0, 0.2);
  border: none;
  background-color: white;
  font-size: ${(props) => (props.fontSize ? props.fontSize : "20px")}
  ${(props) =>
    props.position
      ? "position: absolute; right: 8px; top: 8px; z-index: 1;"
      : ""}
  padding: 10px;
  font-weight: 600;
  &:hover,
  &:focus {
    cursor: pointer;
  }
  :focus-visible {
    outline: solid orange 2px;
  }
`;

const ChartContainer = styled(Box)`
  margin-bottom: 20px;
  margin-left: ${({ category }) => (category ? "0" : "16px")};
  box-shadow: 0 0 3px 0 rgb(0, 0, 0, 0.2);
  width: 100%;
  height: fit-content;
  position: relative;
  background-color: white;
  border-radius: 10px;
  padding: 10px;
`;

const NumberSpan = styled.span`
  font-weight: 700;
`;

const StyledCard = styled(Flex)`
  flex-direction: ${(props) =>
    props.flexDirection ? props.flexDirection : "row"}
  border-radius: 10px;
  box-shadow: 0 0 3px 0 rgb(0, 0, 0, 0.2);
  margin-bottom: 20px;
  ${(props) =>
    props.fullwidth
      ? "width: auto; justify-content: space-around;"
      : props.datePicker
        ? "width: 50%; justify-content: space-around;"
        : "width: 100% !important;"}
  background-color: white;
  max-height: 1000px;
  margin-right: 20px;
`;

const Divider = styled(Box)`
  height: 2px;
  background-color: black;
  margin: 0 8px;
`;

const CategoryRankingBox = styled(Box)`
  width: 100%;
  overflow-y: scroll;
  display: grid;
  grid-template-columns: 33% 33% 33%;
  padding: 24px;
  text-align: center;
`;

const SpacedBox = styled(Box)`
  display: grid;
  grid-template-columns: 33% 33% 33%;
  text-align: center;
`;

const GridBox = styled(Box)`
  grid-column: 1/4;
`;

const locationsOption = {
  "All Stores": "All",
  "San Mateo": "93895000000000000",
  "San Carlos": "114032000000000000",
};

const SmallSpan = styled.span`
  font-size: 12px;
`;

const colors = ["orange", "#FF8C69", "black", "darkblue", "teal"];

const gray = "#585858";
const createLegendData = (data, multiple, filter, topSellers, items) => {
  if (!multiple) {
    return Object.keys(data).map((key, i) => {
      return {
        name: key,
        symbol: { fill: items ? colors[i] : restaurantColors[key] },
      };
    });
  }
  if (topSellers !== null) {
    return topSellers.map((key, i) => {
      return {
        name: key.name,
        symbol: { fill: items ? colors[i] : restaurantColors[key] },
      };
    });
  } else {
    return filter
      ? Object.keys(data["netSales"][`${filter.value}`]).map((key, i) => {
        return {
          name: key,
          symbol: { fill: items ? colors[i] : restaurantColors[key] },
        };
      })
      : Object.keys(data["netSales"]).map((key, i) => {
        return {
          name: key,
          symbol: { fill: items ? colors[i] : restaurantColors[key] },
        };
      });
  }
};

const createStorefronts = (data) => {
  if (!data["hourly"]) return { label: "", value: "" };
  let filters = Object.keys(data["hourly"]["netSales"]).map((name) => {
    return {
      label: name,
      value: name,
    };
  });
  return filters;
};

const Charts = () => {
  const {
    chartChosen,
    setChartChosen,
    setDateRange,
    startDate,
    endDate,
    chartData,
    loading,
    onSelectStartDate,
    onSelectEndDate,
    setLocation,
    dateRange,
  } = useCharts();

  const {width} = useWindowSize();

  const categories = [
    { title: "Channel split", type: "channel", name: "Channel" },
    { title: "Storefront split", type: "storefront", name: "Storefront" },
  ];

  const [splitFilter, setSplitFilter] = React.useState({
    label: "Noshery",
    value: "Noshery",
  });

  return (
    <Box overflow="hidden" width="100%" >
      <Flex width="100%" flexDirection="column" bg="#f2f2f2">
        <Text ml="1%" fontWeight="600" fontSize={5} mb={4}>
          Noshery Sales Dashboard
        </Text>
        <Flex justifyContent="space-between" flexDirection="column">
          <Flex w="100%" flexDirection="column" justifyContent="space-between">
            <Flex w="100%" flexDirection="column" py="2%">
              <Flex paddingBottom="3%">
                <Box px=".5%">
                  <StyledSelect
                    onChange={(e) => setLocation(locationsOption[e.target.value])}
                  >
                    {Object.keys(locationsOption).map((d) => (
                      <option value={d} key={d}>
                        {d}
                      </option>
                    ))}
                  </StyledSelect>
                </Box>
                <Box px=".5%">
                  <StyledSelect onChange={(e) => setDateRange(e.target.value)}>
                    {Object.keys(periods).map((d) => (
                      <option value={d} key={d}>
                        {periods[d]}
                      </option>
                    ))}
                  </StyledSelect>
                </Box>
              </Flex>
              <Flex>
                <Box px=".5%">
                  <StyledSelect
                    value={dateRange.includes("period") ? "Period" : dateRange}
                    onChange={(e) => {
                      setDateRange(e.target.value);
                    }}
                  >
                    {Object.keys(dateRangeOption).map((d) => (
                      <option value={d} key={d}>
                        {dateRangeOption[d]}
                      </option>
                    ))}
                    <option hidden value={"Period"} key={"Period"}>
                    Period
                    </option>
                  </StyledSelect>
                </Box>
              </Flex>
            </Flex>
            <Flex paddingTop="5%" justifyContent="center" alignSelf="center" width="90%">
              <Flex
                style={{ borderRadius: "10px" }}
                py="5px"
                bg="white"
                px="3%"
                flexDirection="column"
              >
                <Flex
                  alignItems="center"
                  onClick={() => setDateRange("custom")}
                  flexDirection="column"
                >
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DesktopDatePicker
                      label="Start Date"
                      inputFormat="YYYY-MM-DD"
                      minDate={moment("2021-11-12")}
                      maxDate={moment()}
                      value={moment.isMoment(startDate) ? startDate : moment(startDate)}
                      onChange={onSelectStartDate}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </Flex>
                <Flex
                  alignItems="center"
                  onClick={() => setDateRange("custom")}
                  flexDirection="column"
                >
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DesktopDatePicker
                      label="End Date"
                      inputFormat="YYYY-MM-DD"
                      maxDate={moment()}
                      minDate={ moment.isMoment(endDate) ? endDate : moment(endDate)}
                      value={
                        moment.isMoment(endDate) ? endDate : moment(endDate)}
                      onChange={onSelectEndDate}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </Flex>
              </Flex>
            </Flex>
          </Flex>

        </Flex>
      </Flex>

      {loading ? (
        <PageLoader />
      ) : isDataEmpty(chartData) ? (
        <NoData />
      ) : (
        <Flex flexDirection="column" minHeight="100vh" bg="#f2f2f2">
          {chartData["items"] && (
            <Breakdown
              data={chartData["items"]["daily"]}
              revenue={chartData["revenue"]}
              globalData={chartData}
            />
          )}
          <StyledCard>
            <Text fontSize={4} p={4}>
              Net Sales:{" "}
              <NumberSpan>
                $
                {chartData &&
                  chartData.total &&
                  chartData.total.netSales &&
                  chartData.total.netSales.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                  })}
              </NumberSpan>
            </Text>
            <Text fontSize={4} p={4}>
              Total Orders:{" "}
              <NumberSpan>
                {chartData &&
                  chartData.total &&
                  chartData.total.totalOrders.toLocaleString(undefined, {})}
              </NumberSpan>
            </Text>
            <Text fontSize={4} p={4}>
              AOV:{" "}
              <NumberSpan>
                $
                {chartData && chartData.total && chartData.total.aov
                  ? chartData.total.aov.toFixed(2)
                  : 0}
              </NumberSpan>
            </Text>
          </StyledCard>
          <Flex justifyContent="space-between" flexDirection="column">
            <StyledCard flexDirection="column" p="16px">
              <Flex justifyContent="space-between">
                <Text fontSize={5} p={2} mr="60px">
                  Total
                </Text>
                <Text fontSize={5} p={2} fontWeight={600}>
                  $
                  {chartData &&
                    chartData.total &&
                    chartData.total.netSales &&
                    chartData.total.netSales.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                    })}
                </Text>
              </Flex>
              <Divider />
              <Flex justifyContent="space-between">
                <Text fontSize={5} p={2} mr="60px">
                  # number of days:{" "}
                </Text>
                <Text fontSize={5} p={2} fontWeight={600}>
                  {chartData && chartData.total && chartData.total.days}
                </Text>
              </Flex>
              <Flex justifyContent="space-between">
                <Text fontSize={5} p={2} mr="60px">
                  Avg. daily sales:{" "}
                </Text>
                <Text fontSize={5} p={2} fontWeight={600}>
                  $
                  {chartData && chartData.total && chartData.total.adv
                    ? chartData.total.adv.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                    })
                    : 0}
                </Text>
              </Flex>
              <Flex justifyContent="space-between">
                <Text fontSize={5} p={2} mr="60px">
                  Avg. daily orders:{" "}
                </Text>
                <Text fontSize={5} p={2} fontWeight={600}>
                  {" "}
                  {chartData && chartData.total && chartData.total.ado
                    ? chartData.total.ado.toFixed(2)
                    : 0}
                </Text>
              </Flex>
              <Flex paddingTop={100}>
                <Text fontSize={2} p={4} fontWeight={600} color={gray}>
                  Sales Updated at:
                  {chartData &&
                    chartData.timeStamp &&
                    moment
                      .tz(chartData.timeStamp.writeDate, "America/Los_Angeles")
                      .format("MM/DD/YYYY h:mm a")}
                </Text>
              </Flex>
            </StyledCard>
            {
              chartData &&
              chartData.total &&
              chartData.total.hourly && (
                <NetSalesLineChart
                  chartChosen={chartChosen}
                  setChartChosen={setChartChosen}
                  chartData={chartData.total}
                  total={chartData.total}
                  dataType="netSales"
                  name="totalBoth"
                />
              )}
          </Flex>
          {(
            <>
              {categories.map((cat, index) => {
                return (
                  <Box key={index}>
                    <Text ml="1%" fontWeight="600" fontSize={4} mt={4}>
                      {cat.title}
                    </Text>
                    {cat.type === "storefront" &&
                    chartData &&
                    chartData[`${cat.type}s`] &&
                    chartData[`${cat.type}s`]["hourly"] ? (
                        <Dropdown
                          options={
                            chartData[`${cat.type}s`]["hourly"] &&
                          createStorefronts(chartData[`${cat.type}s`])
                          }
                          selected={splitFilter}
                          onSelect={setSplitFilter}
                          isClearable={false}
                          isSearchable
                        />
                      ) : null}
                    <Flex justifyContent="space-between" flexDirection="column" pt={4}>
                      <StyledCard>
                        <CategoryRankingBox>
                          <Text fontWeight="600" fontSize={4}>
                            {cat.name}
                          </Text>
                          <Text fontWeight="600" fontSize={4}>
                            {cat.type === "storefront"
                              ? "Orders part of"
                              : "Orders"}
                          </Text>
                          <Text fontWeight="600" fontSize={4}>
                            Net sales
                          </Text>
                          <GridBox>
                            <Flex flexDirection="column">
                              {chartData &&
                                chartData[`${cat.type}s`] &&
                                chartData[`${cat.type}s`].split.map((brand) => {
                                  if (
                                    cat.type === "storefront" &&
                                    brand.name !== splitFilter.value
                                  )
                                    return false;
                                  return (
                                    <SpacedBox
                                      justifyContent="space-around"
                                      key={`${brand.name}&${brand.channel}`}
                                    >
                                      <Box my={2}>
                                        <Text fontWeight={600}>
                                          {cat.type === "storefront"
                                            ? brand.channel
                                            : brand.name}
                                        </Text>
                                      </Box>
                                      <Box my={2}>
                                        <Text>
                                          {`${brand.orderCount} `}
                                          <SmallSpan>{`(${(
                                            (brand.orderCount /
                                              chartData.total.totalOrders) *
                                            100
                                          ).toLocaleString(undefined, {
                                            maximumFractionDigits: 1,
                                          })}%)`}</SmallSpan>
                                        </Text>
                                      </Box>
                                      <Box my={2}>
                                        <Text>
                                          $
                                          {`${brand.netSales.toLocaleString(
                                            undefined,
                                            {
                                              maximumFractionDigits: 0,
                                            }
                                          )} `}
                                          <SmallSpan>{`(${(
                                            (brand.netSales /
                                              chartData.total.netSales) *
                                            100
                                          ).toLocaleString(undefined, {
                                            maximumFractionDigits: 1,
                                          })}%)`}</SmallSpan>
                                        </Text>
                                      </Box>
                                    </SpacedBox>
                                  );
                                })}
                            </Flex>
                          </GridBox>
                        </CategoryRankingBox>
                      </StyledCard>
                      <StyledCard height={(`${(width * 80)/100}px`)} paddingTop="5%" paddingBottom="15%" width="100%" flexDirection="column">
                        <Text
                          textAlign="center"
                          p={2}
                          fontWeight={600}
                          fontSize={4}
                        >
                          Net sales breakdown
                        </Text>
                        {cat.type === "storefront" ? (
                          <ResponsivePieChart
                            data={
                              chartData &&
                              chartData[`${cat.type}s`] &&
                              chartData[`${cat.type}s`].netSalesPieChart
                                .filter((d) =>
                                  d.name.includes(splitFilter.value)
                                )
                                .map((d) => ({
                                  id: d.label,
                                  value: d.y.toFixed(2),
                                }))
                            }
                          />
                        ) : (
                          <ResponsivePieChart
                            data={
                              chartData &&
                              chartData[`${cat.type}s`] &&
                              chartData[`${cat.type}s`].netSalesPieChart.map(
                                (d) => ({ id: d.label, value: d.y.toFixed(2) })
                              )
                            }
                          />
                        )}
                      </StyledCard>
                    </Flex>
                    {chartData &&
                      chartData[`${cat.type}s`] &&
                      chartData[`${cat.type}s`].hourly && (
                      <>
                        <NetSalesandOrderChart
                          chartChosen={chartChosen}
                          setChartChosen={setChartChosen}
                          chartData={chartData[`${cat.type}s`]}
                          total={chartData.total}
                          category
                          topSellers={null}
                          filter={
                            cat.type === "storefront" ? splitFilter : null
                          }
                          dataType="netSales"
                          name={`${cat.type}sNetSales`}
                          chartName={"Net Sales"}
                        />
                        <NetSalesandOrderChart
                          chartChosen={chartChosen}
                          setChartChosen={setChartChosen}
                          chartData={chartData[`${cat.type}s`]}
                          total={chartData.total}
                          category
                          topSellers={null}
                          filter={
                            cat.type === "storefront" ? splitFilter : null
                          }
                          dataType="orderCount"
                          name={`${cat.type}sOrderCount`}
                          chartName={"Order Count"}
                        />
                      </>
                    )}
                  </Box>
                );
              })}
            </>
          )}
        </Flex>
      )}
    </Box>
  );
};

const ChartTypeButton = ({ days, chartChosen, setChartChosen }) => {
  const chartOptions = createChartOptions(days);

  return (
    <StyledSelect
      onChange={(e) => {
        setChartChosen(e.target.value);
      }}
      fontSize="16px"
      position="absolute"
    >
      {chartOptions &&
        chartOptions.map((option) => (
          <option
            selected={chartChosen === option ? option : false}
            value={option}
            key={option}
          >
            {option}
          </option>
        ))}
    </StyledSelect>
  );
};

const createChartOptions = (days) => {
  if (days === 1) return ["Hourly"];
  if (days > 1 && days < 14) return ["Hourly", "Daily"];
  if (days >= 14 && days < 60) return ["Hourly", "Daily", "Weekly"];
  if (days >= 60) return ["Hourly", "Daily", "Weekly", "Monthly"];
  return ["None"];
};

const sort = (chartChosen, data) => {
  if (chartChosen.toLowerCase() === "hourly") {
    return data.sort((a, b) => parseInt(a.x) - parseInt(b.x));
  }

  return data.sort();
};

const NetSalesandOrderChart = ({
  chartChosen,
  setChartChosen,
  chartData,
  total,
  category,
  topSellers,
  filter,
  dataType,
  chartName,
}) => {
  let legendData = createLegendData(
    chartData[chartChosen.toLowerCase()],
    category,
    filter,
    topSellers,
    null
  );

  let data = legendData.map((l) => {
    return {
      id: l.name,
      color: l.symbol.fill,
      data: filter
        ? sort(
          chartChosen,
          chartData[chartChosen.toLowerCase()][dataType][filter.value][l.name]
        )
        : sort(
          chartChosen,
          chartData[chartChosen.toLowerCase()][dataType][l.name]
        ),
    };
  });
  if (chartChosen.toLowerCase() === "hourly") {
    data.sort((a, b) => parseInt(a.data[0].x) - parseInt(b.data[0].x));

    for (var i = 0; i < data.length; i++) {
      const intVersion = data[i].data.map((d) => parseInt(d.x));
      let smallest = intVersion[0];
      for (var a = 1; a < intVersion.length; a++) {
        let dd = intVersion[a];
        smallest += 1;
        if (smallest != dd) {
          data[i].data.splice(a, 0, { x: smallest.toString(), y: 0 });
          a--;
        } else {
          smallest = dd;
        }
      }
    }
    data.forEach((d) => d.data.sort((a, b) => parseInt(a.x) - parseInt(b.x)));
  } else {
    data.sort((a, b) => b.data.length - a.data.length);
  }

  return (
    <ChartContainer category={category}>
      <ChartTypeButton
        days={total.days}
        chartChosen={chartChosen}
        setChartChosen={setChartChosen}
      ></ChartTypeButton>
      <Box width="100%" height="300px">
        <ResponsiveLineChart
          data={data}
          legend={"Time"}
          axisLeftLegend={chartName === "Net Sales" ? "Sales" : "Count"}
          tickSize={chartChosen.toLowerCase() !== "hourly" ? 3 : 5}
          tickRotation={chartChosen.toLowerCase() !== "hourly" ? -50 : 0}
        />
      </Box>
    </ChartContainer>
  );
};

const NetSalesLineChart = ({
  chartChosen,
  setChartChosen,
  chartData,
  total,
}) => {
  let legendData = createLegendData(chartData[chartChosen.toLowerCase()]);
  let data = legendData.map((l) => {
    return {
      id: l.name,
      color: l.symbol.fill,
      data: chartData[chartChosen.toLowerCase()][l.name].sort(),
    };
  });
  if (chartChosen.toLowerCase() === "hourly") {
    data.sort((a, b) => parseInt(a.data[0].x) - parseInt(b.data[0].x));

    for (var i = 0; i < data.length; i++) {
      const intVersion = data[i].data.map((d) => parseInt(d.x));
      let smallest = intVersion[0];
      for (var a = 1; a < intVersion.length; a++) {
        let dd = intVersion[a];
        smallest += 1;
        if (smallest != dd) {
          data[i].data.splice(a, 0, { x: smallest.toString(), y: 0 });
          a--;
        } else {
          smallest = dd;
        }
      }
    }
    data.forEach((d) => d.data.sort((a, b) => parseInt(a.x) - parseInt(b.x)));
  } else {
    data.sort((a, b) => b.data.length - a.data.length);
  }
  if (chartChosen.toLowerCase() === "hourly") {
    data.sort((a, b) => parseInt(a.data[0].x) - parseInt(b.data[0].x));
  } else {
    data.sort((a, b) => b.data.length - a.data.length);
  }

  return (
    <ChartContainer>
      <ChartTypeButton
        days={total.days}
        chartChosen={chartChosen}
        setChartChosen={setChartChosen}
      ></ChartTypeButton>
      <Box width="100%" height="300px">
        <ResponsiveLineChart
          data={data}
          legend="Net Sales vs Order Count"
          axisLeftLegend="price"
          tickSize={chartChosen.toLowerCase() !== "hourly" ? 3 : 5}
          tickRotation={chartChosen.toLowerCase() !== "hourly" ? -50 : 0}
        />
      </Box>
    </ChartContainer>
  );
};

export default Charts;
