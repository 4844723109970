// eslint-disable
import React, { useEffect, useState } from "react";
import useReadDb from "../../../hooks/useReadDb";
const API_URL = process.env.REACT_APP_API_URL;
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Snackbar,
  Alert,
  Autocomplete,
} from "@mui/material";
import Loading from "../../../components/Loading";
import { LoadingButton } from "@mui/lab";
import { useIngredientSelect } from "../../../hooks/useIngredientSelect";
import { useUnits } from "../../../hooks/useUnits";

const OrderGuideItemMapping = () => {
  const { apiResults, isLoading, readCollection } = useReadDb();

  useEffect(() => {
    (async () => await readCollection("orderGuideItemMapping"))();
  }, []);

  return (
    <Box>
      {apiResults?.data && !isLoading ? (
        <EditableTable state={apiResults?.data} />
      ) : <Loading />}
    </Box>
  );
};

export default OrderGuideItemMapping;

function EditableTable({ state }) {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [saveChangesLoading, setSaveChangesLoading] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);  
  const {ingredients, isLoading: ingredientsLoading} = useIngredientSelect(); 
  const {units, isLoading: unitsLoading} = useUnits(); 

  useEffect(() => {
    if (state) {
      setRows(state);
    }
  }, [state]);

  const [rows, setRows] = useState([]);

  const handleChange = async (event, id) => {
    setHasChanges(true);
    const newRows = rows.map((row) => {
      if (row.id === id) {
        return { ...row, [event.target.name]: event.target.value };
      }
      return row;
    });
    setRows(newRows);
  };

  const saveChanges = async () => {
 
    await Promise.all(rows.map(async (row) => {  
      const updatedData = { 
        id: row.id,
        change: row
      };
      const response = await fetch(`${API_URL}/setOrderGuideItemMapping`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(updatedData),
      });
       
    }));
    setHasChanges(false);
  };  

  const headers = [ 
    { header: "Name", key: "name", type: "string", editable: false, autocomplete: false }, 
    { header: "Unit", key: "unit", type: "string", editable: true, autocomplete: true, autocompleteOptions: units, },
    { header: "Oz Value", key: "ozValue", type: "number", editable: true, autocomplete: false }, 
    { header: "Map Ingredient", key: "ingredientMap", type: "string", editable: true, autocomplete: true, autocompleteOptions: ingredients.map((i) => i.name) },
  ];

  return (
    <Box sx={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      p: 3,
    }}>
      <LoadingButton 
        sx={{ alignSelf: "flex-end" }}
        variant="contained"
        disabled={!hasChanges}
        loading={saveChangesLoading}
        onClick={async () => {
          setSaveChangesLoading(true);
          await saveChanges();
          setSaveChangesLoading(false);
          setOpenSnackbar(true);
          setSnackbarMessage("Saved Changes");
        }}
      > Save  </LoadingButton> 
      {
        ingredientsLoading || unitsLoading ? <Loading /> :      
          <TableContainer sx={{
            width: "1400px",
          }} component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  {headers.map((h) => {
                    return (
                      <TableCell align="right" key={h.header}>
                        {h.header}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.id}>
                    {headers.map((h) => {
                      return (
                        <TableCell key={h.key} align="right">
                          {
                            h.editable ? 
                              h.autocomplete ? 
                                <Autocomplete 
                                  options={h.autocompleteOptions}
                                  value={row[h.key]}
                                  onChange={(e, value) => handleChange({ target: { name: h.key, value }}, row.id)}
                                  renderInput={(params) => <TextField {...params} />}
                                />
                                :
                                <TextField
                                  value={row[h.key]}
                                  name={h.key}
                                  type={h.type}
                                  onChange={(e) => handleChange(e, row.id, h.type)}
                                />
                              : 
                              row[h.key]
                          }
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
      }


      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
      >
        <Alert
          onClose={() => setOpenSnackbar(false)}
          severity="success"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}
