import { ResponsivePie } from "@nivo/pie";
import useDeviceType from "../hooks/useDeviceType";

const ResponsivePieChart = ({ data, legend }) => {
  const {isDesktop} = useDeviceType();
  return (
    <ResponsivePie
      data={data}
      innerRadius={0.5}
      padAngle={0.7}
      cornerRadius={3}
      margin={isDesktop ? { top: 30, right: 50, bottom: 120, left: 120 } : {}}
      enableArcLinkLabels={isDesktop ? true : false}
      activeOuterRadiusOffset={8}
      borderWidth={1}
      borderColor={{
        from: "color",
        modifiers: [["darker", 0.2]],
      }}
      valueFormat=" >-,.1f"
      arcLinkLabelsSkipAngle={10}
      arcLinkLabelsTextColor="#333333"
      arcLinkLabelsThickness={2}
      arcLinkLabelsColor={{ from: "color" }}
      arcLabelsSkipAngle={10}
      arcLabelsTextColor={{
        from: "color",
        modifiers: [["darker", 2]],
      }}
      defs={[
        {
          id: "dots",
          type: "patternDots",
          background: "inherit",
          color: "rgba(255, 255, 255, 0.3)",
          size: 4,
          padding: 1,
          stagger: true,
        },
        {
          id: "lines",
          type: "patternLines",
          background: "inherit",
          color: "rgba(255, 255, 255, 0.3)",
          rotation: -45,
          lineWidth: 6,
          spacing: 10,
        },
      ]}
      legends={ legend ? [
        {
          anchor: "left",
          direction: "column",
          justify: false,
          translateX: -80,
          translateY: -10,
          itemsSpacing: 0,
          itemWidth: 10,
          itemHeight: 50,
          itemTextColor: "#999",
          itemDirection: "top-to-bottom",
          itemOpacity: 1,
          symbolSize: 18,
          symbolShape: "circle",
          effects: [
            {
              on: "hover",
              style: {
                itemTextColor: "#000",
              },
            },
          ],
        },
      ] : []}
    />
  );
};

export default ResponsivePieChart;
