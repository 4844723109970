import React from "react";
import { Box, Typography } from "@mui/material"; 
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import IngredientRunRate from "./LiveInventory/ingredientRunRate";
import InvoiceReceived from "./LiveInventory/invoiceRates";
import LiveInventorys from "./LiveInventory/liveInventory";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const LiveInventory = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        pb: 3,
        px: "100px",
        pt: "100px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 3,
        }}
      >
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: "30px",
          }}
        >
          {" "}
          Live Inventory{" "}
        </Typography>
      </Box>

      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Ingredients Run Rate" {...a11yProps(0)} />
          <Tab label="Invoice Received" {...a11yProps(1)} />
          <Tab label="Live Inventory" {...a11yProps(2)} />
          <Tab label="Inventories Resets" {...a11yProps(3)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}> 
        <IngredientRunRate />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}> 
        <InvoiceReceived />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}> 
        <LiveInventorys />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}> 
      
      </CustomTabPanel> 
    </Box>
  );
};

export default LiveInventory;
