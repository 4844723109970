const firebaseConfig = {
  apiKey: "AIzaSyAQlV_FMWACt8FHefnMo53m_VJSl_wzc80",
  authDomain: "sales-dashboard-toast.firebaseapp.com",
  projectId: "sales-dashboard-toast",
  storageBucket: "sales-dashboard-toast.appspot.com",
  messagingSenderId: "157439008336",
  appId: "1:157439008336:web:9fe848c76c835b36423b55",
  measurementId: "G-7S83DRJDCJ",
};

export default firebaseConfig;
