import React, { useState } from "react";
import { Box, Flex, Text, Button } from "noshery-ui";
import moment from "moment";
import { CSVLink } from "react-csv";
import styled from "styled-components";
import IngredientsByHourModal from "./MobileIngredientsByHour";

const unitMap = {
  Hummus: { unit: "oz", conversion: 8, toQts: true },
  Naan: { unit: "ea", conversion: 1 },
  "White Pita": { unit: "ea", conversion: 1 },
  "Gluten Free Pita": { unit: "ea", conversion: 1 },
  Roti: { unit: "ea", conversion: 1 },
  Samosa: { unit: "ea", conversion: 1 },
  "Gluten Free Tortilla": { unit: "ea", conversion: 1 },
  "Flour Tortilla": { unit: "ea", conversion: 1 },
  "Burger Pattys": { unit: "ea", conversion: 1 },
  Shrimp: { unit: "oz", conversion: 3, toLbs: true }, // lbs
  "ESBM Chicken": { unit: "oz", conversion: 4, toLbs: true }, // lbs
  "ESBM Pork": { unit: "oz", conversion: 4, toLbs: true }, // lbs
  "Pollo Asado": { unit: "oz", conversion: 3, toLbs: true }, // lbs
  "Carne Asada": { unit: "oz", conversion: 3, toLbs: true }, // lbs
  "Chicken Tikka": { unit: "oz", conversion: 6, toLbs: true }, // lbs
  "Orens Chicken": { unit: "oz", conversion: 4, toLbs: true }, // lbs
  "Orens Beef": { unit: "oz", conversion: 4, toLbs: true }, // lbs
};

const StyledCard = styled(Flex)`
  flex-direction: ${(props) =>
    props.flexDirection ? props.flexDirection : "row"}
  border-radius: 10px;
  box-shadow: 0 0 3px 0 rgb(0, 0, 0, 0.2);
  margin-bottom: 20px;
  ${(props) =>
    props.fullwidth
      ? "width: auto; justify-content: space-around;"
      : "width: 100% !important;"}
  background-color: white;
  max-height: 800px;
  margin-right: 20px;
`;

const SpacedBox = styled(Box)`
  display: grid;
  height: 100% !important;
  grid-template-columns: 50% 50%;
  text-align: center;
  font-weight: ${(p) => (p.isTitle ? "bolder" : "normal")};
  padding: 10px;
  border-bottom: ${(p) => (p.isTitle ? "1px solid black" : "0px")};
  font-size: ${(p) => (p.isTitle ? "20px" : "15px")};
`;

const setUnit = (count, ingName) => {
  if (unitMap[ingName].toLbs) {
    return `${(count * unitMap[ingName].conversion * 0.0625).toFixed(
      2
    )} ${"lbs"}`;
  }
  if (unitMap[ingName].toQts) {
    return `${(count * unitMap[ingName].conversion * 0.03125).toFixed(
      2
    )} ${"qts"}`;
  }
  return `${(count * unitMap[ingName].conversion).toFixed(2)} ${
    unitMap[ingName].unit
  }`;
};

const IngredientsTable = ({ ingredients, ingredientsByHour }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedIngredient, setSelectedIngredient] = useState(null);
  const ingredientsData = Object.keys(ingredients).map((ingredient) => {
    const count = ingredients[ingredient];
    return {
      ingredient,
      count,
      unit: ingredient.includes("Hummus") ? `${count * 8} oz` : `${count} ea`,
    };
  });
  return (
    <>
      <Flex py={2} height="200%" width="100%" flexDirection="column" bg="#f2f2f2">
        <Flex justifyContent="flex-end" mb={3}>
          <CSVLink
            data={ingredientsData}
            filename={`ingredients-${moment().format("YYYY-MM-DD:HH:MM")}.csv`}
          >
            <Button>Download me</Button>
          </CSVLink>
        </Flex>

        <StyledCard flexDirection="column" justifyContent="center">
          <SpacedBox isTitle={1}>
            <Text> Ingredients </Text>
            <Text style={{ cursor: "pointer" }}> Unit </Text>
          </SpacedBox>
          <div style={{ maxHeight: "550px", overflowX: "scroll" }}>
            {Object.keys(ingredients).map((ingredient) => {
              return (
                <Ingredient
                  key={ingredient}
                  name={ingredient}
                  count={ingredients[ingredient]}
                  onClick={() => {
                    setIsOpen(true);
                    setSelectedIngredient(ingredient);
                  }}
                />
              );
            })}
          </div>
        </StyledCard>
      </Flex>
      <IngredientsByHourModal
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
          setSelectedIngredient(null);
        }}
        ingredient={selectedIngredient}
        chartData={ingredientsByHour[selectedIngredient]}
      />
    </>
  );
};

const Ingredient = ({ name, count, onClick }) => {
  return (
    <SpacedBox
      style={{ borderBottom: "1px solid" }}
      id={name}
      isTitle={0}
      onClick={onClick}
    >
      <Text style={{ textAlign: "center" }}> {name} </Text>
      <Text> {setUnit(count, name)} </Text>
    </SpacedBox>
  );
};

export default IngredientsTable;
