import React, { useEffect } from "react";
import { Flex, Box, } from "noshery-ui";
import moment from "moment"; 
import useInvoices from "../../../hooks/useInvoice";
import styled from "styled-components"; 
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment"; 
import { useLocationCharts } from "../../../hooks/useLocation"; 
import Loading from "../../../components/Loading"; 
import GeneralTable from "../../../components/Table";

const dateRangeOption = {
  today: "Today",
  yesterday: "Yesterday",
  thisWeek: "This week",
  lastWeek: "Last week",
  thisMonth: "This month",
  lastMonth: "Last month",
  thisYear: "This year",
  allTime: "All time",
  custom: "Custom",
};

const StyledSelect = styled.select`
  border-radius: 10px;
  box-shadow: 0 0 3px 0 rgb(0, 0, 0, 0.2);
  border: none;
  background-color: white;
  font-size: ${(props) => (props.fontSize ? props.fontSize : "20px")}
  ${(props) =>
    props.position
      ? "position: absolute; right: 8px; top: 8px; z-index: 1;"
      : ""}
  padding: 12px;
  font-weight: 600;
  &:hover,
  &:focus {
    cursor: pointer;
  }
  :focus-visible {
    outline: solid orange 2px;
  }
`;

const InvoiceReceived = () => {
  const { 
    setDateRange,
    startDate,
    endDate,
    chartData,
    loading,
    onSelectStartDate,
    onSelectEndDate,
    setLocation,
    dateRange,
  } = useInvoices();

  const {locations: locationsOption} = useLocationCharts();

  useEffect(() => {
    if (Object.values(locationsOption).length > 0) {
      setLocation(Object.values(locationsOption)[0]);
    }
  }, [locationsOption]);
 
  return (
    <Box>
      <Flex  flexDirection="column"  >
        <Flex w="100%" mb={4}>
          <Flex w="100%" mr="1%" justifyContent="space-between">
            <Flex w="100%" justifyContent="space-between">
              <Box paddingRight="10px">
                <StyledSelect
                  onChange={(e) => setLocation(locationsOption[e.target.value])}
                >
                  {Object.keys(locationsOption).map((d) => (
                    <option value={d} key={d}>
                      {d}
                    </option>
                  ))}
                </StyledSelect>
              </Box>
              <Box>
                <StyledSelect
                  value={dateRange}
                  onChange={(e) => setDateRange(e.target.value)}
                >
                  {Object.keys(dateRangeOption).map((d) => (
                    <option value={d} key={d}>
                      {dateRangeOption[d]}
                    </option>
                  ))}
                </StyledSelect>
              </Box>
            </Flex>
          </Flex> 
        </Flex>
        
        <Flex
          style={{ width: "40%", borderRadius: "10px" }}
          py="20px"
          px="10px"
          bg="white"
        >
          <Flex
            alignItems="center"
            mr={3}
            ml={2}
            style={{width: "100%"}}
            onClick={() => setDateRange("custom")}
          >
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DesktopDatePicker
                label="Start Date"
                inputFormat="YYYY-MM-DD"
                minDate={moment("2021-11-12")}
                maxDate={moment()}
                value={moment.isMoment(startDate) ? startDate : moment(startDate)}
                onChange={onSelectStartDate}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </Flex>
          <Flex
            alignItems="center"
            mr={2}
            style={{width: "100%"}}
            onClick={() => setDateRange("custom")}
          >
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DesktopDatePicker
                label="End Date"
                inputFormat="YYYY-MM-DD"
                maxDate={moment()}
                minDate={ moment.isMoment(startDate) ? startDate : moment(startDate)}
                value={
                  moment.isMoment(endDate) ? endDate : moment(endDate)}
                onChange={onSelectEndDate}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </Flex>
        </Flex>
      </Flex>

 
      {loading ?   <Loading />   : (
        <Flex 
          flexDirection="column"
          minHeight="100vh" 
        >
          {
            (chartData.invoices) ?
              <GeneralTable 
                anyResult={chartData.invoices.map((i) => {
                  return {
                    ...i, 
                    quantity: (i.quantity !== "" && i.quantity) ? i.quantity : 0,
                    deliveryDates: i.deliveryDates.map((d) => moment(d).format("YYYY-MM-DD")).join(", "),
                  };
                })}
                sortWeights={{
                  ingredientName: 1,
                  productName: 2,
                  quantity: 3,
                  unit: 4,
                  oz: 5,
                  deliveryDates: 6,
                }}
                skipHeaders={["id", "ingredient", "originalUnit"]}
                itemId={"productName"}
                itemName={"productName"}
                headerWithExtraWidth={["ingredientName", "deliveryDates"]}
              />
              : <p> 
            No data found
              </p>
          }
        </Flex>
      )}
    </Box>
  );
}; 

export default InvoiceReceived;
