import React from "react"; 
import { Box, Typography, Card, Checkbox, Autocomplete, TextField } from "@mui/material";
import { useIngredientSelect } from "../../../hooks/useIngredientSelect";
import Loading from "../../../components/Loading";
import { LoadingButton } from "@mui/lab"; 

const ItemSelectForEmail = () => {
  const {ingredients, isLoading, setIngredients, updateChosenIngredients, updateLoading, success} = useIngredientSelect();
  const [hasChanges, setHasChanges] = React.useState(false);

  const [selectAllIngredients, setSelectAllIngredients] = React.useState(false); 
  
  const [fitlerIngredients, setFilterIngredients] = React.useState(""); 

  const updateIngredients = (name, chosen) => {
    setHasChanges(true); 
    const newIngredient = ingredients.map((b) => {
      if(b.name === name) {
        return {...b, chosen: chosen};
      }
      return b;
    });
    setIngredients(newIngredient);
     
  };

  const updateChosenIngredientsMutation = async () => {
    await updateChosenIngredients();
    setHasChanges(false);
  };

  return (<>
    {
      isLoading ? <Loading /> :
        <Box sx={{
          pb: 3,
          px: "100px",
          pt: "100px"
        }}>
          <Box sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 3,
          
          }}>      
            <Typography sx={{
              fontWeight: "bold",
              fontSize: "30px",
            }}> Daily Email Ingredient Select </Typography>

            <LoadingButton onClick={updateChosenIngredientsMutation} loading={updateLoading} disabled={!hasChanges} variant="contained"> 
                Save Changes
            </LoadingButton>
          </Box>


          <Box sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "top",
            mt: 3,
          }}>

            <Card sx={{
              width: "100%",
              m: 1,
              p: 3
            }}> 
              <Typography sx={{
                fontWeight: "bold", 
                fontSize: "24px"
              }}> Select Ingredients  </Typography>
              <Autocomplete 
                options={ingredients.map((i) => i.name).sort((a, b) =>  a > b? 1 : -1)}
                value = {fitlerIngredients}
                onChange={(e, value) => setFilterIngredients(value || "")}
                onInputChange={(e, value) => setFilterIngredients(value || "")}
                renderInput={(params) => <TextField {...params} label="Filter Ingredients" />}
              />
              <Box
                onClick={() => {
                  const newIngredients = ingredients.map((i) => {
                    return {...i, chosen: !selectAllIngredients};
                  });
                  setSelectAllIngredients(!selectAllIngredients);
                  setIngredients(newIngredients);
                  setHasChanges(true);
                }}
                sx={{
                  display: "flex",
                  flexDirection: "row", 
                  alignContent: "center",
                  alignItems: "center",
                }}>
                <Checkbox sx={{
                  cursor: "pointer"
                }} checked={selectAllIngredients} 
                onClick={(e) => {
                  const newIngredients = ingredients.map((i) => {
                    return {...i, chosen: !selectAllIngredients};
                  });
                  selectAllIngredients(!selectAllIngredients);
                  setIngredients(newIngredients);
                  setHasChanges(true);
                }} />

                <Typography sx={{
                  fontSize: "12px",  
                }}> Select All </Typography>

              </Box>
              {
                ingredients
                  .filter((i) => fitlerIngredients !== "" ? (i.name.toLowerCase()).includes(fitlerIngredients.toLowerCase()) : true)
                  .sort((a, b) =>  a.name > b.name ? 1 : -1)
                  .map((i) => {
                    return <Box key={i.name}
                      onClick={() => updateIngredients(i.name, !i.chosen)}
                      sx={{
                        display: "flex",
                        flexDirection: "row", 
                        alignContent: "center",
                        alignItems: "center",
                      }}>
                      <Checkbox 
                        onClick={() => updateIngredients(i.name, !i.chosen)} sx={{ 
                        }} checked={i.chosen} />
                      <Typography sx={{
                        fontSize: "24px", 
                        cursor: "pointer"
                      }}> {i.name} </Typography>
                  
                    </Box>; 
                  })
              }
            </Card>
          </Box>
      
        </Box>
    } 
  </>
  );
};

export default ItemSelectForEmail;