import React, { useEffect } from "react"; 
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";

export const useLocationCharts = () => {
  const [locations, setLocations] = React.useState({});

  useEffect(() => {
    if(firebase.apps.length !== 0) {

      if(firebase.auth().currentUser.uid === "u0KEzNCMxsQF46PgX0mCvTWnDey2") {
        setLocations({
          "San Ramon": "146868000000000000" ,
        });
      }
      if(firebase.auth().currentUser.uid === "R3fAqpd0nFZNd6q6xEb6igCw1G02") {
        setLocations({
          "San Mateo": "93895000000000000",
        });
      } 
 
      else  {
        setLocations({
          "All Stores": "All",
          "San Mateo": "93895000000000000",
          "San Carlos": "114032000000000000",
          "San Ramon": "146868000000000000" ,
        });
      }
   
    }
  }, []);

  return {locations: locations};

};


export const useLocationChecklist = () => {
  const [locations, setLocations] = React.useState({});
  
  useEffect(() => {
    if(firebase.apps.length !== 0) {
  
      if(firebase.auth().currentUser.uid === "u0KEzNCMxsQF46PgX0mCvTWnDey2") {
        setLocations({
          "sr": "San Ramon" ,
        });
      }
      if(firebase.auth().currentUser.uid === "R3fAqpd0nFZNd6q6xEb6igCw1G02") {
        setLocations({
          "sm": "San Mateo",
        });
      } 
      else  {
        setLocations({
          "sm": "San Mateo",
          "sc": "San Carlos",
          "sr": "San Ramon" ,
        });
      }
     
    }
  }, []);
  
  return {locations: locations};
  
};

export const useLocationComparisons = () => {
  const [locations, setLocations] = React.useState({});
    
  useEffect(() => {
    if(firebase.apps.length !== 0) {
    
      if(firebase.auth().currentUser.uid === "u0KEzNCMxsQF46PgX0mCvTWnDey2") {
        setLocations({
          "San Ramon": "146868000000000000" ,
        });
      }
      if(firebase.auth().currentUser.uid === "R3fAqpd0nFZNd6q6xEb6igCw1G02") {
        setLocations({
          "San Mateo": "93895000000000000",
        });
      } 
 
      else  {
        setLocations({
          "All Stores": "All",
          "San Mateo": "93895000000000000",
          "San Carlos": "114032000000000000",
          "San Ramon": "146868000000000000" ,
          "Store vs Store": "Store vs Store"
        });
      }
       
    }
  }, []);
    
  return {locations: locations};
    
};

export const useLocationsStoreVsStore = () => {
  const [locations, setLocations] = React.useState({});
    
  useEffect(() => {
    if(firebase.apps.length !== 0) {
      setLocations({ 
        "San Mateo": {id: "93895000000000000", name: "San Mateo"},
        "San Carlos": {id: "114032000000000000", name: "San Carlos"},
        "San Ramon": {id: "146868000000000000", name: "San Ramon"}, 
      });       
    }
  }, []);
    
  return {locations: locations};
    
};
  
export const useLocationOrders = () => {
  const [locations, setLocations] = React.useState({});
      
  useEffect(() => {
    if(firebase.apps.length !== 0) {
      
      if(firebase.auth().currentUser.uid === "u0KEzNCMxsQF46PgX0mCvTWnDey2") {
        setLocations([
          { label: "San Ramon", value: "146868000000000000" },]);
      }
      if(firebase.auth().currentUser.uid === "R3fAqpd0nFZNd6q6xEb6igCw1G02") {
        setLocations([
          { label: "San Mateo", value: "93895000000000000" },]);
      } 
 
      else  {
        setLocations([
          { label: "All Stores", value: "All" },
          { label: "San Mateo", value: "93895000000000000" },
          { label: "San Carlos", value: "114032000000000000" },
          { label: "San Ramon", value: "146868000000000000" },
        ]);
      }
         
    }
  }, []);
      
  return {locations: locations};
      
};
 
  

 