import { useState } from "react";

const API_URL = process.env.REACT_APP_API_URL;

export default function useReadDb() {
  const [isLoading, setIsLoading] = useState(false);
  const [apiResults, setApiResults] = useState({ok: "none"});
  const [serverError, setServerError] = useState(null);

  const readCollection = async (collectionName) => {
    setIsLoading(true);
    try {
      const res = await fetch(`${API_URL}/readDatabase`, {
        method: "POST",
        mode: "cors",
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          collectionName: collectionName,
          docName: ""
        })
      });

      const data = await res.json();
      setApiResults(data);
      if(data.detail) setServerError(data); 
      setIsLoading(false);
      return data; 
    } catch (error) {
      setServerError(error);
      setIsLoading(false);
    }
  };

  const readDocument = async (collectionName, docName) => {
    setIsLoading(true);
    try {
      const res = await fetch(`${API_URL}/readDatabase`, {
        method: "POST",
        mode: "cors",
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          collectionName: collectionName,
          docName: docName
        })
      });

      const data = await res.json();
      setApiResults(data);
      if(data.detail) setServerError(data); 
      setIsLoading(false);
      return data; 
    } catch (error) {
      setServerError(error);
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    apiResults,
    serverError,
    readCollection,
    readDocument
  };
}
