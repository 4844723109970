import { useEffect, useState, useRef } from "react";
import moment from "moment"; 

const API_URL = process.env.REACT_APP_API_URL;

export default function useCalculateInventory() { 
 
  const [loading, setLoading] = useState(false);  
  const [location, setLocation] = useState("93895000000000000");
  const [calculations, setCalculations] = useState({
    inventory: {},
    ingredients: [], 
    invoice: [], 
  });

  useEffect(() => {
    fetchData();
  }, [location]);

  const fetchData = async () => { 

    setLoading(true);
    const res = await fetch(
      `${API_URL}/calculateInventory?location=${location}`,
      {
        method: "GET",
        mode: "cors",  
      }
    );
    let result = await res.json();
    setCalculations(result); 
    setLoading(false); 
  };
 
  return { 
    fetchData,
    location,
    loading, 
    calculations,
    setLocation,
  };
}
