import  React, { useEffect } from "react"; 
import moment from "moment";

const API_URL = process.env.REACT_APP_API_URL;

export const useUploadCateringOrder = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [apiResults, setApiResults] = React.useState({ok: "none"});
  const [serverError, setServerError] = React.useState(null);

  const uploadOrder = async (orders, channel, location, discount, date) => {
    setIsLoading(true);
    try {
      const res = await fetch(`${API_URL}/cateringorder`, {
        method: "POST",
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({orders: orders, channel: channel, location: location, date: date, discount: discount ? discount : 0})
      });

      const data = await res.json();
      setApiResults(data);
      if(data.detail) setServerError(data); 
      setIsLoading(false);
    } catch (error) {
      setServerError(error);
      setIsLoading(false);
    }
  };

  const uploadLabelsList = async (orders, channel, location, discount, date, Company, orderId ) => {
    setIsLoading(true);
    try {
      const res = await fetch(`${API_URL}/saveLabelsList`, {
        method: "POST",
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({orders: orders, channel: channel, location: location, date: date, discount: discount ? discount : 0, Company: Company, orderId})
      });
      const data = await res.json();
      if(data.detail) setServerError(data);
      setIsLoading(false);
    } 
    catch (error) {
      setServerError(error);
      setIsLoading(false);
    }
  };

  return { isLoading, apiResults, serverError, uploadOrder, uploadLabelsList };

};

export const useCateringLabelsList = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [orders, setOrders] = React.useState([]);
  const [serverError, setServerError] = React.useState(null);
  const [startDate, setStartDate] = React.useState(moment().format("YYYY-MM-DD"));
  const [endDate, setEndDate] = React.useState(moment().format("YYYY-MM-DD"));
  const [location, setLocation] = React.useState("All Stores");


  const fetchOrders = async (startDate, endDate, location) => {
    setIsLoading(true);
    try {
      const res = await fetch(`${API_URL}/listSavedLabels`, {
        method: "POST",
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ startDate, endDate, location })
      });
      const data = await res.json();
      setOrders(data.data);
      setIsLoading(false);
    } catch (error) {
      setServerError(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    (async () => await fetchOrders(startDate, endDate, location))();
  }, [startDate, endDate, location]); 

  return {
    isLoading,
    orders,
    serverError,  
    startDate,
    endDate,
    location,
    setStartDate,
    setEndDate,
    setLocation
  };
};