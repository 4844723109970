import React from "react";  

const API_URL = process.env.REACT_APP_API_URL;

const usePlating = () => {
  const [loading, setLoading] = React.useState(false);
  const [gptGenerateLoading, setGptGenerateLoading] = React.useState(false);
  const [updatePlatingLoading, setUpdatePlatingLoading] = React.useState(false);

  const [error, setError] = React.useState(false);
  const [plating, setPlating] = React.useState([]);

  const fetchPlating = async () => {
    setLoading(true);
    const result = await fetch(`${API_URL}/plating`, {
      method: "GET",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      }, 
    });
    const data = await result.json();

    console.log(data);

    setPlating(data.data);

    setLoading(false);
  };

  React.useEffect(() => {
    fetchPlating();
  }, []);

  const setPlatingMutation = async (platings) => {
    setUpdatePlatingLoading(true);

    const result = await fetch(`${API_URL}/setPlating`, {
      method: "POST",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      }, 
      body: JSON.stringify({ plating: platings })
    }); 
    
    const { data } = await result.json();

    await fetchPlating();  

    setUpdatePlatingLoading(false);
  };

  const gptGeneratePlating = async (stringPlating) => {
    setGptGenerateLoading(true);
    const result = await fetch(`${API_URL}/gptGeneratePlating`, {
      method: "POST",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      }, 
      body: JSON.stringify({ query: stringPlating })
    }); 
    
    setGptGenerateLoading(false);

    const { data } = await result.json();
    return data;
  };

  return {
    loading,
    error,
    plating,
    fetchPlating,
    setPlatingMutation,
    updatePlatingLoading,
    gptGenerateLoading,
    gptGeneratePlating,
  };
};

export default usePlating;