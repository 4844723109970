import React from "react";
import styled from "styled-components";
import { Flex, Text, Box, Button } from "noshery-ui";
import Modal from "react-modal";
import moment from "moment";
import { CSVLink } from "react-csv";
import ItemsChart from "./charts/ItemsChart";

const StyledCard = styled(Flex)`
    flex-direction: ${(props) =>
    props.flexDirection ? props.flexDirection : "column"}
    border-radius: 10px;
    box-shadow: 0 0 3px 0 rgb(0, 0, 0, 0.2);
    ${(props) =>
    props.fullwidth
      ? "width: auto; justify-content: space-around;"
      : "width: 100% !important;"}
    background-color: white;
    max-height: 600px;
`;

const StyledCard2 = styled(Flex)`
  flex-direction: ${(props) =>
    props.flexDirection ? props.flexDirection : "row"}
  border-radius: 10px;
  box-shadow: 0 0 3px 0 rgb(0, 0, 0, 0.2);
  margin-bottom: 20px;
  ${(props) =>
    props.fullwidth
      ? "width: auto; justify-content: space-around;"
      : "width: 100% !important;"}
  background-color: white;
  max-height: 800px;
  margin-right: 20px;
`;

const SpacedBox = styled(Box)`
  display: grid;
  height: 100% !important;
  grid-template-columns: 33% 33% 34%;
  text-align: center;
  font-weight: ${(p) => (p.isTitle ? "bolder" : "normal")};
  padding: 10px;
  border-bottom: ${(p) => (p.isTitle ? "1px solid black" : "0px")};
  font-size: ${(p) => (p.isTitle ? "20px" : "15px")};
`;

const TitleSpacer = styled.div`
  text-align: center;
  width: 100%;
  font-weight: ${(p) => (p.isTitle ? "bolder" : "normal")};
  padding: 10px;
  border-bottom: ${(p) => (p.isTitle ? "1px solid black" : "0px")};
  font-size: ${(p) => (p.isTitle ? "20px" : "15px")};
  display: flex;
  flex-directoin: row;
  justify-content: space-between;
`;

const aggregate = (obj) => {
  let total = 0;
  Object.keys(obj).forEach((time) => {
    total += obj[time];
  });
  return total;
};

const sortMods = (data) => {
  let obj = {};
  Object.keys(data).forEach((item) => {
    if (!obj[item]) obj[item] = 0;
    obj[item] += aggregate(data[item]);
  });

  let arrayMods = Object.keys(obj).map((item) => {
    return { name: item, count: obj[item] };
  });

  const sortedArray = arrayMods.sort((a, b) =>
    a.count < b.count ? 1 : b.count < a.count ? -1 : 0
  );

  return sortedArray;
};

const Popup = ({ setPopupOpen, itemChosen, globalData, time }) => {
  let modifiersData = [];
  if (
    globalData["modifiers"] &&
    globalData["modifiers"][time] &&
    globalData["modifiers"][time][itemChosen.name]
  ) {
    console.log(
      "globalData[\"modifiers\"][time][itemChosen.name]",
      globalData["modifiers"][time][itemChosen.name]
    );
    modifiersData = Object.keys(
      globalData["modifiers"][time][itemChosen.name]
    ).map((name) => {
      const count = Object.values(
        globalData["modifiers"][time][itemChosen.name][name]
      )[0];
      const price = Object.values(
        globalData["modifiersPrice"][time][itemChosen.name][name]
      )[0];
      console.log("price", price);
      return { name, count, price};
    });
  }

  return (
    <>
      <Modal
        isOpen={true}
        onRequestClose={() => setPopupOpen(false)}
        contentLabel={`${itemChosen}`}
      >
        <StyledCard>
          <TitleSpacer isTitle={true}>
            <Text>{itemChosen.name}</Text>
            <Text
              style={{ cursor: "pointer", color: "red" }}
              onClick={() => setPopupOpen(false)}
            >
              X
            </Text>
          </TitleSpacer>
          {globalData["modifiers"] &&
            globalData["modifiers"][time] &&
            globalData["modifiers"][time][itemChosen.name] && (
            <Flex
              py={6}
              height="100%"
              px="300px"
              flexDirection="column"
              bg="#f2f2f2"
            >
              <Text fontWeight="800" fontSize="24px">
                {" "}
                  Modifiers:{" "}
              </Text>
              <Flex justifyContent="flex-end" mb={3}>
                <CSVLink
                  data={modifiersData}
                  filename={`modifiers-${moment().format(
                    "YYYY-MM-DD:HH:MM"
                  )}.csv`}
                >
                  <Button>Download me</Button>
                </CSVLink>
              </Flex>
              <StyledCard2 flexDirection="column">
                <SpacedBox isTitle={1}>
                  <Text style={{ cursor: "pointer" }}> Name </Text>
                  <Text style={{ cursor: "pointer" }}> Count </Text>
                  <Text style={{ cursor: "pointer" }}> Price </Text>
                </SpacedBox>
                <div style={{ maxHeight: "350px", overflowX: "scroll" }}>
                  {globalData["modifiers"][time][itemChosen.name] &&
                      sortMods(
                        globalData["modifiers"][time][itemChosen.name]
                      ).map((modifier) => {
                        return (
                          <Modifier
                            key={modifier.name}
                            name={modifier.name}
                            count={modifier.count}
                            price={globalData["modifiersPrice"][time][itemChosen.name][modifier.name][Object.keys(globalData["modifiersPrice"][time][itemChosen.name][modifier.name])[0]]}
                          />
                        );
                      })}
                </div>
              </StyledCard2>
            </Flex>
          )}
          <Flex width="100%" height="500px"> 
            <ItemsChart
              data={globalData}
              itemName={itemChosen.name}
              category={"orderCount"}
            />
            <ItemsChart
              data={globalData}
              itemName={itemChosen.name}
              category={"netSales"}
            />
          </Flex>
        </StyledCard>
      </Modal>
    </>
  );
};

const Modifier = ({ name, count, price }) => {
  return (
    <SpacedBox id={`${count}-${name}`} isTitle={0}>
      <Text style={{ paddingRight: "5px", textAlign: "left" }}> {name} </Text>
      <Text> {count} </Text>
      <Text> {price} </Text>
    </SpacedBox>
  );
};

export default Popup;
