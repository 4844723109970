export const OldforkableMenu = {
  "Burrito": {
    "Pollo Asado Burrito": {
      "brandName": "The Little Chihuahua",
      "displayName": "Classic Burritos",
      "itemGroupId": "17219ad1-22a2-4f03-8337-93c4d146b11b",
      "itemId": "0207c506-d9c8-49b5-8a71-dca2e6fe2a8b",
      "price": 12.0,
      "quantity": 1,
      "restaurantName": "The Little Chihuahua",
      "subtotal": 12.0,
      "tax": 1.4,
      "modifiers": [
        {
          "displayName": "Pollo Asado",
          "itemGuid": "dec8db70-4841-4b74-a18c-eef3d975b662"
        }
      ]
    },
    "Carne Asada Burrito": {
      "brandName": "The Little Chihuahua",
      "displayName": "Classic Burrito",
      "itemGroupId": "17219ad1-22a2-4f03-8337-93c4d146b11b",
      "itemId": "0207c506-d9c8-49b5-8a71-dca2e6fe2a8b",
      "price": 12.75,
      "quantity": 1,
      "restaurantName": "The Little Chihuahua",
      "subtotal": 12.75,
      "tax": 1.4,
      "modifiers": [
        {
          "displayName": "Carne Asada",
          "price": 0,
          "itemGuid": "f8c9f3d3-a435-4387-9706-f9b0cf4e6ebc"
        }
      ]
    },
    "Fried Plantain & Black Bean Burrito": {
      "brandName": "The Little Chihuahua",
      "displayName": "Fried Plantain Burrito",
      "itemGroupId": "17219ad1-22a2-4f03-8337-93c4d146b11b",
      "itemId": "ad560a71-6396-43eb-8751-48de6bb4be8a",
      "price": 13.95,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "The Little Chihuahua",
      "subtotal": 13.95,
      "tax": 1.34
    },
    "Super Pollo Asado Burrito": {
      "brandName": "The Little Chihuahua",
      "displayName": "Classic Burrito",
      "itemGroupId": "c361a3d8-c27d-4af0-9eb9-c0ae6c66f5dd",
      "itemId": "0207c506-d9c8-49b5-8a71-dca2e6fe2a8b",
      "price": 14.0,
      "quantity": 1,
      "restaurantName": "The Little Chihuahua",
      "subtotal": 14.0,
      "tax": 1.4,
      "modifiers": [
        {
          "displayName": "Pollo Asado",
          "itemGuid": "dec8db70-4841-4b74-a18c-eef3d975b662"
        },
        {
          "displayName": "Make Super (guac, sour cream, cheese)",
          "itemGuid": "dca618e9-edae-4f17-b399-3fd6b303a280"
        }
      ]
    },
    "Super Carne Asada Burrito": {
      "brandName": "The Little Chihuahua",
      "displayName": "Classic Burritos",
      "itemGroupId": "17219ad1-22a2-4f03-8337-93c4d146b11b",
      "itemId": "ad560a71-6396-43eb-8751-48de6bb4be8a",
      "price": 14.75,
      "quantity": 1,
      "restaurantName": "The Little Chihuahua",
      "subtotal": 14.75,
      "tax": 1.4,
      "modifiers": [
        {
          "displayName": "Carne Asada",
          "price": 0,
          "itemGuid": "f8c9f3d3-a435-4387-9706-f9b0cf4e6ebc"
        },
        {
          "displayName": "Make Super (guac, sour cream, cheese)",
          "guid": "dca618e9-edae-4f17-b399-3fd6b303a280"
        }
      ]
    },
    "Super Plantain & Black Bean Burrito": {
      "brandName": "The Little Chihuahua",
      "displayName": "Fried Plantain & Black Bean Burrito",
      "itemGroupId": "c361a3d8-c27d-4af0-9eb9-c0ae6c66f5dd",
      "itemId": "ad560a71-6396-43eb-8751-48de6bb4be8a",
      "price": 15.95,
      "quantity": 1,
      "restaurantName": "The Little Chihuahua",
      "subtotal": 15.95,
      "tax": 1.34,
      "modifiers": [
        {
          "displayName": "Make Super (guac, sour cream, cheese)",
          "guid": "dca618e9-edae-4f17-b399-3fd6b303a280"
        },
        {
          "displayName": "Make Super (guac, sour cream, cheese)",
          "itemGuid": "dca618e9-edae-4f17-b399-3fd6b303a280"
        }
      ]
    }
  },
  "Taco": {
    "Carne Asada Tacos": {
      "brandName": "The Little Chihuahua",
      "displayName": "Classic Tacos (2)",
      "itemGroupId": "791b1d76-c702-4819-bf36-18fdfcadc370",
      "itemId": "71c2f512-74a4-4770-a4fc-d1c872248c2c",
      "price": 10.75,
      "quantity": 1,
      "restaurantName": "The Little Chihuahua",
      "subtotal": 10.75,
      "tax": 0.95,
      "modifiers": [
        {
          "displayName": "Carne Asada",
          "guid": "f8c9f3d3-a435-4387-9706-f9b0cf4e6ebc"
        }
      ]
    },
    "Pollo Asado Tacos": {
      "brandName": "The Little Chihuahua",
      "displayName": "Classic Tacos (2)",
      "itemGroupId": "791b1d76-c702-4819-bf36-18fdfcadc370",
      "itemId": "71c2f512-74a4-4770-a4fc-d1c872248c2c",
      "price": 10,
      "quantity": 1,
      "restaurantName": "The Little Chihuahua",
      "subtotal": 10,
      "tax": 0.95,
      "modifiers": [
        {
          "displayName": "Pollo Asado",
          "itemGuid": "dec8db70-4841-4b74-a18c-eef3d975b662"
        }
      ]
    },
    "Plantain & Black Bean Tacos": {
      "brandName": "The Little Chihuahua",
      "displayName": "Fried Plantain & Black Bean Tacos (2)",
      "itemGroupId": "c361a3d8-c27d-4af0-9eb9-c0ae6c66f5dd",
      "itemId": "8a2a65c5-0ec1-4e21-a15d-b07360f0421e",
      "price": 11,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "The Little Chihuahua",
      "subtotal": 11,
      "tax": 0.95
    },
    "Super Carne Asada Tacos": {
      "brandName": "The Little Chihuahua",
      "displayName": "Classic Tacos (2)",
      "itemGroupId": "791b1d76-c702-4819-bf36-18fdfcadc370",
      "itemId": "71c2f512-74a4-4770-a4fc-d1c872248c2c",
      "price": 11.75,
      "quantity": 1,
      "restaurantName": "The Little Chihuahua",
      "subtotal": 11.75,
      "tax": 0.95,
      "modifiers": [
        {
          "displayName": "Carne Asada",
          "itemGuid": "f50dae70-2cc1-4740-8490-bc627def29fe"
        },
        {
          "displayName": "Make Super (guac, sour cream, cheese)",
          "price": 1,
          "itemGuid": "872bef4c-496a-4776-a9e5-efa0c82092c0"
        },
      ]
    },
    "Super Pollo Asado Tacos": {
      "brandName": "The Little Chihuahua",
      "displayName": "Classic Tacos (2)",
      "itemGroupId": "791b1d76-c702-4819-bf36-18fdfcadc370",
      "itemId": "71c2f512-74a4-4770-a4fc-d1c872248c2c",
      "price": 11,
      "quantity": 1,
      "restaurantName": "The Little Chihuahua",
      "subtotal": 11,
      "tax": 0.95,
      "modifiers": [
        {
          "displayName": "Pollo Asado",
          "itemGuid": "dec8db70-4841-4b74-a18c-eef3d975b662"
        },
        {
          "displayName": "Make Super (guac, sour cream, cheese)",
          "price": 1,
          "itemGuid": "872bef4c-496a-4776-a9e5-efa0c82092c0"
        },
      ]
    },
    "Super Plantain & Black Bean Tacos": {
      "brandName": "The Little Chihuahua",
      "displayName": "Fried Plantain & Black Bean Tacos (2)",
      "itemGroupId": "c361a3d8-c27d-4af0-9eb9-c0ae6c66f5dd",
      "itemId": "699c2e28-9784-4416-a662-a9b3795d2742",
      "price": 12,
      "quantity": 1,
      "restaurantName": "The Little Chihuahua",
      "subtotal": 12,
      "tax": 0.95,
      "modifiers": [
        {
          "displayName": "Make Super (guac, sour cream, cheese)",
          "price": 1,
          "itemGuid": "872bef4c-496a-4776-a9e5-efa0c82092c0"
        },
      ]
    },
    "Vegan Plantain & Black Bean Tacos": {
      "brandName": "The Little Chihuahua",
      "displayName": "Fried Plantain & Black Bean Tacos (2)",
      "itemGroupId": "c361a3d8-c27d-4af0-9eb9-c0ae6c66f5dd",
      "itemId": "699c2e28-9784-4416-a662-a9b3795d2742",
      "price": 11,
      "quantity": 1,
      "restaurantName": "The Little Chihuahua",
      "subtotal": 11,
      "tax": 0.95,
      "modifiers": []
    }
  },
  "Bowl": {
    "Pollo Asado Rice Bowl": {
      "brandName": "The Little Chihuahua",
      "displayName": "Classic Rice Bowls",
      "itemGroupId": "d35fa01c-6264-43a6-82d0-3397f631d74c",
      "itemId": "20f069fc-b22c-4c6b-840b-eac088392776",
      "price": 12,
      "quantity": 1,
      "restaurantName": "Noshery",
      "subtotal": 12,
      "tax": 1.36,
      "modifiers": [
        {
          "displayName": "Pollo Asado",
          "itemGuid": "dec8db70-4841-4b74-a18c-eef3d975b662"
        }
      ]
    },
    "Carne Asada Rice Bowl": {
      "brandName": "The Little Chihuahua",
      "displayName": "Classic Rice Bowls",
      "itemGroupId": "d35fa01c-6264-43a6-82d0-3397f631d74c",
      "itemId": "20f069fc-b22c-4c6b-840b-eac088392776",
      "price": 12.75,
      "quantity": 1,
      "restaurantName": "Noshery",
      "subtotal": 12.75,
      "tax": 1.36,
      "modifiers": [
        {
          "displayName": "Pollo Asado",
          "itemGuid": "dec8db70-4841-4b74-a18c-eef3d975b662"
        }
      ]
    },
    "Plantain & Black Bean Rice Bowl": {
      "brandName": "The Little Chihuahua",
      "displayName": "Fried Plantain & Black Bean Rice Bowl",
      "itemGroupId": "d35fa01c-6264-43a6-82d0-3397f631d74c",
      "itemId": "20f069fc-b22c-4c6b-840b-eac088392776",
      "price": 13.95,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal": 13.95,
      "tax": 1.36
    },
    "Super Pollo Asado Rice Bowl": {
      "brandName": "The Little Chihuahua",
      "displayName": "Classic Rice Bowls",
      "itemGroupId": "d35fa01c-6264-43a6-82d0-3397f631d74c",
      "itemId": "20f069fc-b22c-4c6b-840b-eac088392776",
      "price": 14,
      "quantity": 1,
      "restaurantName": "Noshery",
      "subtotal": 14,
      "tax": 1.36,
      "modifiers": [
        {
          "displayName": "Pollo Asado",
          "itemGuid": "dec8db70-4841-4b74-a18c-eef3d975b662"
        },
        {
          "displayName": "Make Super (guac, sour cream, cheese)",
          "guid": "dca618e9-edae-4f17-b399-3fd6b303a280"
        }
      ]
    },
    "Super Carne Asada Rice Bowl": {
      "brandName": "The Little Chihuahua",
      "displayName": "Classic Rice Bowls",
      "itemGroupId": "d35fa01c-6264-43a6-82d0-3397f631d74c",
      "itemId": "20f069fc-b22c-4c6b-840b-eac088392776",
      "price": 14.75,
      "quantity": 1,
      "restaurantName": "Noshery",
      "subtotal": 14.75,
      "tax": 1.36,
      "modifiers": [
        {
          "displayName": "Pollo Asado",
          "itemGuid": "dec8db70-4841-4b74-a18c-eef3d975b662"
        },
        {
          "displayName": "Make Super (guac, sour cream, cheese)",
          "guid": "dca618e9-edae-4f17-b399-3fd6b303a280"
        }
      ]
    },
    "Super Plantain & Black Bean Rice Bowl": {
      "brandName": "The Little Chihuahua",
      "displayName": "Fried Plantain & Black Bean Rice Bowl",
      "itemGroupId": "d35fa01c-6264-43a6-82d0-3397f631d74c",
      "itemId": "20f069fc-b22c-4c6b-840b-eac088392776",
      "price": 15.95,
      "quantity": 1,
      "restaurantName": "Noshery",
      "subtotal": 15.95,
      "tax": 1.36,
      "modifiers": [
        {
          "displayName": "Make Super (guac, sour cream, cheese)",
          "guid": "dca618e9-edae-4f17-b399-3fd6b303a280"
        }
      ]
    },
    "Chicken Rice Bowl": {
      "brandName": "Oren's Hummus",
      "displayName": "Orens Chicken Rice Bowl",
      "itemGroupId": "6181229a-4aeb-4876-8ca9-317add8f13de",
      "itemId": "705c7e52-abe3-48b2-82e3-4aa2d50ffee2",
      "price": 17.00,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal": 17.00,
      "tax": 1.63,
    },
    "Beef Kebab Rice Bowl": {
      "brandName": "Oren's Hummus",
      "displayName": "Orens Beef Kebab Rice Bowl",
      "itemGroupId": "6181229a-4aeb-4876-8ca9-317add8f13de",
      "itemId": "41ed2a1a-e894-4937-8e16-feddfec5d311",
      "price": 18.00,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal": 18.00,
      "tax": 1.63,
    },    
    "Roasted Lemongrass Chicken Noodle Bowl": {
      displayName: "Lemongrass Chicken Noddle Bowl",
      price: 12.79,
      quantity: 1,
      itemGroupId: "870f00c3-618f-413d-a551-09f309e3d292",
      tax: 1.27,
      subtotal: 14.06,
      itemId: "85d72ef0-52d8-42f0-969a-f9684b32489d",
      modifiers: [],
      restaurantName: "East Side Banh Mi",
      brandName: "East Side Banh Mi"
    },
    "Roasted Caramel Pork Noodle Bowl": {
      displayName: "Roasted Caramel Pork Noodle Bowl",
      price: 13.79,
      quantity: 1,
      itemGroupId: "870f00c3-618f-413d-a551-09f309e3d292",
      tax: 1.37,
      subtotal: 15.16,
      itemId: "85d72ef0-52d8-42f0-969a-f9684b32489d",
      modifiers: [],
      restaurantName: "East Side Banh Mi",
      brandName: "East Side Banh Mi"
    },
    "Chili Crisp Tofu & Roasted Eggplant Noodle Bowl": {
      displayName: "Chili Crips & Tofu Egglplant Noddle Bowl",
      price: 12.79,
      quantity: 1,
      itemGroupId: "870f00c3-618f-413d-a551-09f309e3d292",
      tax: 1.27,
      subtotal: 14.06,
      itemId: "85d72ef0-52d8-42f0-969a-f9684b32489d",
      modifiers: [],
      restaurantName: "East Side Banh Mi",
      brandName: "East Side Banh Mi"
    },
    "No Protein Noddle Bowl": {
      displayName: "Citrus Soy Noddle Bowl",
      price: 12.79,
      quantity: 1,
      itemGroupId: "870f00c3-618f-413d-a551-09f309e3d292",
      tax: 1.03,
      subtotal: 13.82,
      itemId: "85d72ef0-52d8-42f0-969a-f9684b32489d",
      modifiers: [],
      restaurantName: "East Side Banh Mi",
      brandName: "East Side Banh Mi"
    },
    "Roasted Caramel Pork Rice Bowl": {
      displayName: "Roasted Caramel Pork Rice Bowl",
      price: 13.79,
      quantity: 1,
      itemGroupId: "674cf641-b5e4-42c8-95bf-4a11a71fa485",
      tax: 1.29,
      subtotal: 15.08,
      itemId: "03655200-cdde-4817-98c4-e2f56edf684a",
      modifiers: [],
      restaurantName: "East Side Banh Mi",
      brandName: "East Side Banh Mi"
    },
    "Roasted Lemongrass Chicken Rice Bowl": {
      displayName: "Lemongrass Chicken Rice Bowl",
      price: 12.79,
      quantity: 1,
      itemGroupId: "870f00c3-618f-413d-a551-09f309e3d292",
      tax: 1.27,
      subtotal: 14.06,
      itemId: "85d72ef0-52d8-42f0-969a-f9684b32489d",
      modifiers: [],
      restaurantName: "East Side Banh Mi",
      brandName: "East Side Banh Mi"
    },
    "Chili Crisp Tofu & Roasted Eggplant Rice Bowl": {
      displayName: "Chili Crisp & Tofu Eggplant Rice Bowl",
      price: 12.79,
      quantity: 1,
      itemGroupId: "870f00c3-618f-413d-a551-09f309e3d292",
      tax: 1.27,
      subtotal: 14.06,
      itemId: "85d72ef0-52d8-42f0-969a-f9684b32489d",
      modifiers: [],
      restaurantName: "East Side Banh Mi",
      brandName: "East Side Banh Mi"
    },
    "No Protein Rice Bowl": {
      displayName: "Citrus Soy Rice Bowl",
      price: 9.79,
      quantity: 1,
      itemGroupId: "870f00c3-618f-413d-a551-09f309e3d292",
      tax: 1.03,
      subtotal: 10.82,
      itemId: "85d72ef0-52d8-42f0-969a-f9684b32489d",
      modifiers: [],
      restaurantName: "East Side Banh Mi",
      brandName: "East Side Banh Mi"
    },
  },
  "Salad": {
    "Large Israeli Salad": {
      "brandName": "Oren's Hummus",
      "displayName": "Large Israeli Salad",
      "itemGroupId": "6181229a-4aeb-4876-8ca9-317add8f13de",
      "itemId": "b2db79f3-45bf-46dc-a38f-e054bd2e5c57",
      "price": 12.00,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal": 12.00,
      "tax": 1.01,
    },
    "Beet & Goat Cheese Salad": {
      "brandName": "Oren's Hummus",
      "displayName": "Beet & Goat Cheese Salad",
      "itemGroupId": "6181229a-4aeb-4876-8ca9-317add8f13de",
      "itemId": "429a3d59-2640-4d6a-8343-5dc9267143da",
      "price": 14.95,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal": 14.95,
      "tax": 1.26,
    },
    "Fattoush Salad": {
      "brandName": "Oren's Hummus",
      "displayName": "Fattoush Salad",
      "itemGroupId": "6181229a-4aeb-4876-8ca9-317add8f13de",
      "itemId": "b2db79f3-45bf-46dc-a38f-e054bd2e5c57",
      "price": 16.00,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal": 16.00,
      "tax": 1.4,
    },
    "Roasted Lemongrass Chicken Salad Bowl": {
      displayName: "Lemongrass Chicken Salad",
      price: 12.79,
      quantity: 1,
      itemGroupId: "870f00c3-618f-413d-a551-09f309e3d292",
      tax: 1.22,
      subtotal: 14.21,
      itemId: "85d72ef0-52d8-42f0-969a-f9684b32489d",
      modifiers: [],
      restaurantName: "East Side Banh Mi",
      brandName: "East Side Banh Mi"
    },
    "Roasted Caramel Pork Salad Bowl": {
      displayName: "Roasted Caramel Pork Salad",
      price: 13.79,
      quantity: 1,
      itemGroupId: "870f00c3-618f-413d-a551-09f309e3d292",
      tax: 1.22,
      subtotal: 15.01,
      itemId: "85d72ef0-52d8-42f0-969a-f9684b32489d",
      modifiers: [],
      restaurantName: "East Side Banh Mi",
      brandName: "East Side Banh Mi"
    },
    "Chili Crisp Tofu & Roasted Eggplant Salad Bowl": {
      displayName: "Chili Crisp & Tofu Eggplant Salad",
      price: 12.79,
      quantity: 1,
      itemGroupId: "870f00c3-618f-413d-a551-09f309e3d292",
      tax: 1.22,
      subtotal: 14.01,
      itemId: "85d72ef0-52d8-42f0-969a-f9684b32489d",
      modifiers: [],
      restaurantName: "East Side Banh Mi",
      brandName: "East Side Banh Mi"
    },
    "No Protein Salad Bowl": {
      displayName: "Citrus Soy Salad",
      price: 9.79,
      quantity: 1,
      itemGroupId: "870f00c3-618f-413d-a551-09f309e3d292",
      tax: 1.03,
      subtotal: 10.82,
      itemId: "85d72ef0-52d8-42f0-969a-f9684b32489d",
      modifiers: [],
      restaurantName: "East Side Banh Mi",
      brandName: "East Side Banh Mi"
    },
  },
  "Extra White Pita": {
    "Extra White Pita": {
      "brandName": "Oren's Hummus",
      "displayName": "Extra White Pita",
      "itemGroupId": "620effda-741c-4fc8-92e6-2a349186024c",
      "itemId": "98663b35-5391-4d4d-a1d7-5be8d8038bcd",
      "itemGuid": "98663b35-5391-4d4d-a1d7-5be8d8038bcd",
      "price": 1.25,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal": 1.25,
      "tax": 1.58,
    }
  },
  "Pita": {
    "Pita Beef Kebab": {
      "brandName": "Oren's Hummus",
      "displayName": "Pita Beef Kebab",
      "itemGroupId": "6181229a-4aeb-4876-8ca9-317add8f13de",
      "itemId": "b991f4e3-a3a3-470e-b712-20252b6a2ae4",
      "price": 17.00,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal": 17.00,
      "tax": 1.63,
    },  
    "Pita Chicken": {
      "brandName": "Oren's Hummus",
      "displayName": "Pita Chicken",
      "itemGroupId": "6181229a-4aeb-4876-8ca9-317add8f13de",
      "itemId": "2a53cd3a-72f0-4cba-8c31-bc162e877dfa",
      "price": 16.00,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal": 16.00,
      "tax": 1.54,
    },
    "Pita Falafel": {
      "brandName": "Oren's Hummus",
      "displayName": "Pita Falafel",
      "itemGroupId": "6181229a-4aeb-4876-8ca9-317add8f13de",
      "itemId": "051e681a-621a-4d80-8e02-ac9617bec0bb",
      "price": 14.00,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal": 14.00,
      "tax": 1.05,
    },
    "White Pita": {
      "brandName": "Oren's Hummus",
      "displayName": "White Pita",
      "itemGroupId": "620effda-741c-4fc8-92e6-2a349186024c",
      "itemId": "98663b35-5391-4d4d-a1d7-5be8d8038bcd",
      "itemGuid": "98663b35-5391-4d4d-a1d7-5be8d8038bcd",
      "price":  0,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal":  0,
      "tax": 0,
    },
    "Gluten Free Pita": {
      "brandName": "Oren's Hummus",
      "displayName": "Gluten Free Pita",
      "itemGroupId": "6181229a-4aeb-4876-8ca9-317add8f13de",
      "itemId": "1353bb71-79bb-4c42-b21b-446b4df0ac27",
      "itemGuid": "1353bb71-79bb-4c42-b21b-446b4df0ac27",
      "price":  1.85,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal":  0,
      "tax": 0,
    },    
  }, 
  "Hummus": {
    "Hummus Classic": {
      "brandName": "Oren's Hummus",
      "displayName": "Hummus Classic",
      "itemGroupId": "6181229a-4aeb-4876-8ca9-317add8f13de",
      "itemId": "18ebf6c8-4a41-4033-891c-80e1679edf83",
      "price": 11.00,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal": 11.00,
      "tax": 0.86,
    },  
    "Hummus with Moroccan Beef": {
      "brandName": "Oren's Hummus",
      "displayName": "Hummus with Moroccan Beef",
      "itemGroupId": "6181229a-4aeb-4876-8ca9-317add8f13de",
      "itemId": "339ccad1-8363-4233-9433-afb57f59d11b",
      "price": 17.00,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal": 17.00,
      "tax": 1.58,
    },  
    "Hummus Chicken": {
      "brandName": "Oren's Hummus",
      "displayName": "Hummus Chicken",
      "itemGroupId": "6181229a-4aeb-4876-8ca9-317add8f13de",
      "itemId": "01c0c5ec-b983-4c77-be6f-4ed847e0bb71",
      "price": 16.00,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal": 16.00,
      "tax": 1.54,
    },  
    "Hummus Eggplant": {
      "brandName": "Oren's Hummus",
      "displayName": "Hummus Eggplant",
      "itemGroupId": "6181229a-4aeb-4876-8ca9-317add8f13de",
      "itemId": "1ed672fe-fa9f-4e6a-b7e6-24311afb124f",
      "price": 14.00,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal": 14.00,
      "tax": 1.34,
    },  
    "Hummus Beets": {
      "brandName": "Oren's Hummus",
      "displayName": "Hummus Beets",
      "itemGroupId": "6181229a-4aeb-4876-8ca9-317add8f13de",
      "itemId": "01615c34-7899-4611-bd33-9d30205020ac",
      "price": 15.00,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal": 15.00,
      "tax": 1.34,
    },  
  }, 
  "Skewer": {
    "Chicken Skewer": {
      "brandName": "Oren's Hummus",
      "displayName": "Chicken Skewer",
      "itemGroupId": "6181229a-4aeb-4876-8ca9-317add8f13de",
      "itemId": "35b5211f-c770-4df2-a3d8-e22354bfcc66",
      "price": 24.75,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal": 24.75,
      "tax": 1.58,
    },
    "Beef Kebab Skewer": {
      "brandName": "Oren's Hummus",
      "displayName": "Beef Kebab Skewer",
      "itemGroupId": "6181229a-4aeb-4876-8ca9-317add8f13de",
      "itemId": "5938bc17-b522-4e74-b5d4-788f0301402d",
      "price": 25.75,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal": 25.75,
      "tax": 1.58,
    }
  },
  "Falafel": {
    "Falafel Balls": {
      "brandName": "Oren's Hummus",
      "displayName": "Green Herb Falafel (5 pieces)",
      "itemGroupId": "6181229a-4aeb-4876-8ca9-317add8f13de",
      "itemId": "a99a442d-ff3d-4382-84ab-c4f648478605",
      "price": 6.18,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal": 6.18,
      "tax": 1.58,
    }
  },
  "Dessert": {
    "Snickerdoodle": {
      "brandName": "The Pastry Cupboard",
      "displayName": "1 Cookie",
      "itemGroupId": "d35fa01c-6264-43a6-82d0-3397f631d74c",
      "itemId": "827901d7-4362-484c-a163-1b7801c06bee",
      "price": 3.50,
      "quantity": 1,
      "restaurantName": "Noshery",
      "modifiers": [
        {
          "displayName": "Snickerdoodle",
          "guid": "dca618e9-edae-4f17-b399-3fd6b303a280"
        }
      ],
      "subtotal": 3.50,
      "tax": 0.25,
    },
    "White Chocolate & Cranberry": {
      "brandName": "The Pastry Cupboard",
      "displayName": "1 Cookie",
      "itemGroupId": "d35fa01c-6264-43a6-82d0-3397f631d74c",
      "itemId": "efc6cbc2-3146-4179-81cb-7b445d302f48",
      "price": 3.50,
      "quantity": 1,
      "restaurantName": "Noshery",
      "modifiers": [
        {
          "displayName": "White Chocolate & Cranberry",
          "guid": "dca618e9-edae-4f17-b399-3fd6b303a280"
        }
      ],
      "subtotal": 3.50,
      "tax": 0.25,
    },
    "Chocolate Chunk": {
      "brandName": "The Pastry Cupboard",
      "displayName": "1 Cookie",
      "itemGroupId": "d35fa01c-6264-43a6-82d0-3397f631d74c",
      "itemId": "cf727dce-1499-40bc-a501-7c7aedf0ea7f",
      "price": 3.50,
      "quantity": 1,
      "modifiers": [
        {
          "displayName": "Chocolate Chip",
          "guid": "dca618e9-edae-4f17-b399-3fd6b303a280"
        }
      ],
      "restaurantName": "Noshery",
      "subtotal": 3.50,
      "tax": 0.25,
    },
    "Oatmeal Raisin": {
      "brandName": "The Pastry Cupboard",
      "displayName": "1 Cookie",
      "itemGroupId": "d35fa01c-6264-43a6-82d0-3397f631d74c",
      "itemId": "93d70a2c-c665-4a63-a4e5-a62207c23050",
      "price": 3.50,
      "quantity": 1,
      "modifiers": [
        {
          "displayName": "Oatmeal Raisin",
          "guid": "dca618e9-edae-4f17-b399-3fd6b303a280"
        }
      ],
      "restaurantName": "Noshery",
      "subtotal": 3.50,
      "tax": 0.25,
    }
  },
  "Burger": {
    "MeltBurger": {
      "brandName": "The Melt",
      "displayName": "MeltBurger",
      "itemGroupId": "d35fa01c-6264-43a6-82d0-3397f631d74c",
      "itemId": "853186fe-978c-440e-92af-1e25c75d4c39",
      "price": 8.79,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal": 8.79,
      "tax": 0.95,
    },
    "BBQ Bacon MeltBurger": {
      "brandName": "The Melt",
      "displayName": "BBQ Bacon MeltBurger",
      "itemGroupId": "d35fa01c-6264-43a6-82d0-3397f631d74c",
      "itemId": "ecaafb73-3271-4cb6-a462-295b45303019",
      "price": 10.79,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal": 10.79,
      "tax": 1.0,
    },
    "Double MeltBurger": {
      "brandName": "The Melt",
      "displayName": "Double MeltBurger",
      "itemGroupId": "d35fa01c-6264-43a6-82d0-3397f631d74c",
      "itemId": "853186fe-978c-440e-92af-1e25c75d4c39",
      "price": 12.79,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal": 12.79,
      "tax": 0.95,
    },
    "Double BBQ Bacon MeltBurger": {
      "brandName": "The Melt",
      "displayName": "Double BBQ Bacon MeltBurger",
      "itemGroupId": "d35fa01c-6264-43a6-82d0-3397f631d74c",
      "itemId": "ecaafb73-3271-4cb6-a462-295b45303019",
      "price": 14.78,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal": 14.78,
      "tax": 1.0,
    },
    "Impossible MeltBurger": {
      "brandName": "The Melt",
      "displayName": "Impossible MeltBurger",
      "itemGroupId": "d35fa01c-6264-43a6-82d0-3397f631d74c",
      "itemId": "e1506618-3e88-4d8a-b3a7-32eb569dc953",
      "price": 12.99,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal": 12.99,
      "tax": 1.7,
    },
    "Vegan BBQ Impossible Burger": {
      "brandName": "The Melt",
      "displayName": "Impossible MeltBurger",
      "itemGroupId": "d35fa01c-6264-43a6-82d0-3397f631d74c",
      "itemId": "e1506618-3e88-4d8a-b3a7-32eb569dc953",
      "price": 12.99,
      "quantity": 1,
      "modifiers": [
        {
          "displayName": "Add Smoked Bacon",
          "guid": "dca618e9-edae-4f17-b399-3fd6b303a280"
        }
      ],
      "restaurantName": "Noshery",
      "subtotal": 12.99,
      "tax": 1.7,
    },
  }, 
  "Fries": {
    "Fries": {
      "brandName": "The Melt",
      "displayName": "Fries",
      "itemGroupId": "d35fa01c-6264-43a6-82d0-3397f631d74c",
      "itemId": "cbad4aba-040d-4b4c-ac8b-bd7a27ca63e2",
      "price": 3.29,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal": 3.29,
      "tax": 1.7,
    },
    "Cheddar Bacon Fries": {
      "brandName": "The Melt",
      "displayName": "Cheddar Bacon Fries",
      "itemGroupId": "d35fa01c-6264-43a6-82d0-3397f631d74c",
      "itemId": "8fec5382-d0f7-40e8-9a06-43f16ce69a62",
      "price": 4.79,
      "modifiers": [],
      "quantity": 1,
      "restaurantName": "Noshery",
      "subtotal": 4.79,
      "tax": 0.46,
    },
    "Garlic Parmesan Fries": {
      "brandName": "The Melt",
      "displayName": "Garlic Parmesan Fries",
      "itemGroupId": "d35fa01c-6264-43a6-82d0-3397f631d74c",
      "itemId": "bfbec05e-c552-4ec7-b908-dd915a0965b9",
      "price": 4.39,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal": 4.39,
      "tax": 0.41,
    },
  },
  "Banh Mi": {
    "Pork Deluxe Banh Mi": {
      displayName: "Pork Deluxe Banh Mi",
      price: 12,
      quantity: 1,
      itemGroupId: "674cf641-b5e4-42c8-95bf-4a11a71fa485",
      tax: 1.13,
      subtotal: 13.13, 
      itemId: "96efabd6-e840-4414-bcba-919ded45a562",
      modifiers: [],
      restaurantName: "East Side Banh Mi",
      brandName: "East Side Banh Mi"
    }, 
    "Chicken Deluxe Banh Mi": {
      displayName: "Chicken Deluxe Banh Mi",
      price: 11.5,
      quantity: 1,
      itemGroupId: "b19af785-5ae4-44ff-a174-df644bb73db9",
      tax: 1.17,
      subtotal: 12.67,
      itemId: "38be25ec-799e-4285-9db3-246b02e90d82",
      modifiers: [],
      restaurantName: "East Side Banh Mi",
      brandName: "East Side Banh Mi"
    },
    "Pork Banh Mi": {
      displayName: "Pork Banh Mi",
      price: 11,
      quantity: 1,
      itemGroupId: "b19af785-5ae4-44ff-a174-df644bb73db9",
      tax: 1.08,
      subtotal: 12.08, 
      itemId: "3edc2b9f-5f4c-44c8-83f2-d99b545da8c3",
      modifiers: [],
      restaurantName: "East Side Banh Mi",
      brandName: "East Side Banh Mi"
    },
    "Chicken Banh Mi": {
      displayName: "Chicken Banh Mi",
      price: 10.5,
      quantity: 1,
      itemGroupId: "b19af785-5ae4-44ff-a174-df644bb73db9",
      tax: 1.08,
      subtotal: 11.13, 
      itemId: "3edc2b9f-5f4c-44c8-83f2-d99b545da8c3",
      modifiers: [],
      restaurantName: "East Side Banh Mi",
      brandName: "East Side Banh Mi"
    },
    "Vegan Banh Mi": {
      displayName: "Vegan Banh Mi",
      price: 10.5,
      quantity: 1,
      itemGroupId: "b19af785-5ae4-44ff-a174-df644bb73db9",
      tax: 1.03,
      subtotal: 11.5,
      itemId: "145e38ee-ccfe-46cd-9544-fe4d0a0c25e6",
      modifiers: [],
      restaurantName: "East Side Banh Mi",
      brandName: "East Side Banh Mi"
    },
  },
  "Naan Roll": {
    "Chicken Tikka Masala Naan Roll": {
      "brandName": "Kasa",
      "displayName": "Naan Roll",
      "itemGroupId": "d35fa01c-6264-43a6-82d0-3397f631d74c",
      "itemId": "cbad4aba-040d-4b4c-ac8b-bd7a27ca63e2",
      "price": 13,
      "quantity": 1,
      "modifiers": [{
        "displayName": "Chicken Tikka Masala",
        "guid": "dca618e9-edae-4f17-b399-3fd6b303a280"
      }],
      "restaurantName": "Noshery",
      "subtotal": 13,
      "tax": 1.7
    }
  },
  "Sandwich": {
    "Three Cheese Sandwich":  {
      "brandName": "The Melt",
      "displayName": "Three Cheese Classic",
      "itemGroupId": "d35fa01c-6264-43a6-82d0-3397f631d74c",
      "itemId": "853186fe-978c-440e-92af-1e25c75d4c39",
      "price": 7.49,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal": 7.49,
      "tax": 0.95,
    },
  },
  "Soup": {
    "Tomato Soup":  {
      "brandName": "The Melt",
      "displayName": "Tomato Soup",
      "itemGroupId": "d35fa01c-6264-43a6-82d0-3397f631d74c",
      "itemId": "853186fe-978c-440e-92af-1e25c75d4c39",
      "price": 3.99,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal": 3.99,
      "tax": 0.95,
    },
  }
};

export const forkableMenu = {
  "Burrito": {
    "Pollo Asado Burrito": {
      "brandName": "The Little Chihuahua",
      "displayName": "Classic Burritos",
      "itemGroupId": "ac4ed596-6965-42c4-b435-db15919f6e14",
      "itemId": "28fbb2b4-3dac-486c-a222-b1cd73d2a6a4",
      "price": 12,
      "quantity": 1,
      "restaurantName": "Noshery",
      "subtotal": 12,
      "tax": 1.4,
      "modifiers": [
        {
          "displayName": "Pollo Asado",
          "itemGuid": "dec8db70-4841-4b74-a18c-eef3d975b662"
        }
      ]
    },
    "Carne Asada Burrito": {
      "brandName": "The Little Chihuahua",
      "displayName": "Classic Burrito",
      "itemGroupId": "ac4ed596-6965-42c4-b435-db15919f6e14",
      "itemId": "b9008bc3-8b29-4d99-a2e9-01e8db25cccc",
      "price": 12.75,
      "quantity": 1,
      "restaurantName": "Noshery",
      "subtotal": 12.75,
      "tax": 1.4,
      "modifiers": [
        {
          "displayName": "Carne Asada",
          "price": 0,
          "itemGuid": "f8c9f3d3-a435-4387-9706-f9b0cf4e6ebc"
        }
      ]
    },
    "Fried Plantain & Black Bean Burrito": {
      "brandName": "The Little Chihuahua",
      "displayName": "Fried Plantain Burrito",
      "itemGroupId": "ac4ed596-6965-42c4-b435-db15919f6e14",
      "itemId": "e02222b1-cec8-4c82-97e1-98f0496f4af2",
      "price": 13.95,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal": 13.95,
      "tax": 1.34
    },
    "Super Pollo Asado Burrito": {
      "brandName": "The Little Chihuahua",
      "displayName": "Classic Burrito",
      "itemGroupId": "ac4ed596-6965-42c4-b435-db15919f6e14",
      "itemId": "b6f001dd-5e27-40b0-9353-2fa367030212",
      "price": 14,
      "quantity": 1,
      "restaurantName": "Noshery",
      "subtotal": 14,
      "tax": 1.4,
      "modifiers": [
        {
          "displayName": "Pollo Asado",
          "itemGuid": "dec8db70-4841-4b74-a18c-eef3d975b662"
        },
        {
          "displayName": "Make Super (guac, sour cream, cheese)",
          "itemGuid": "dca618e9-edae-4f17-b399-3fd6b303a280"
        }
      ]
    },
    "Super Carne Asada Burrito": {
      "brandName": "The Little Chihuahua",
      "displayName": "Classic Burritos",
      "itemGroupId": "ac4ed596-6965-42c4-b435-db15919f6e14",
      "itemId": "5a9bb3ca-2ad8-4993-8609-f53ee15b55bb",
      "price": 14.75,
      "quantity": 1,
      "restaurantName": "Noshery",
      "subtotal": 14.75,
      "tax": 1.4,
      "modifiers": [
        {
          "displayName": "Carne Asada",
          "price": 0,
          "itemGuid": "f8c9f3d3-a435-4387-9706-f9b0cf4e6ebc"
        },
        {
          "displayName": "Make Super (guac, sour cream, cheese)",
          "guid": "dca618e9-edae-4f17-b399-3fd6b303a280"
        }
      ]
    },
    "Super Plantain & Black Bean Burrito": {
      "brandName": "The Little Chihuahua",
      "displayName": "Fried Plantain & Black Bean Burrito",
      "itemGroupId": "ac4ed596-6965-42c4-b435-db15919f6e14",
      "itemId": "05ee1663-bfd1-40ac-9ed7-b83f244c4574",
      "price": 15.95,
      "quantity": 1,
      "restaurantName": "Noshery",
      "subtotal": 15.95,
      "tax": 1.34,
      "modifiers": [
        {
          "displayName": "Make Super (guac, sour cream, cheese)",
          "guid": "dca618e9-edae-4f17-b399-3fd6b303a280"
        },
        {
          "displayName": "Make Super (guac, sour cream, cheese)",
          "itemGuid": "dca618e9-edae-4f17-b399-3fd6b303a280"
        }
      ]
    }
  },
  "Taco": {
    "Carne Asada Tacos": {
      "brandName": "The Little Chihuahua",
      "displayName": "Classic Tacos (2)",
      "itemGroupId": "ac4ed596-6965-42c4-b435-db15919f6e14",
      "itemId": "7ab6e5d9-5e05-4fb6-9479-07afe8cf3ae9",
      "price": 10.75,
      "quantity": 1,
      "restaurantName": "Noshery",
      "subtotal": 10.75,
      "tax": 0.95,
      "modifiers": [
        {
          "displayName": "Carne Asada",
          "guid": "f8c9f3d3-a435-4387-9706-f9b0cf4e6ebc"
        }
      ]
    },
    "Pollo Asado Tacos": {
      "brandName": "The Little Chihuahua",
      "displayName": "Classic Tacos (2)",
      "itemGroupId": "ac4ed596-6965-42c4-b435-db15919f6e14",
      "itemId": "d867f40d-8591-42ea-87d4-e17e81c791e4",
      "price": 10,
      "quantity": 1,
      "restaurantName": "Noshery",
      "subtotal": 10,
      "tax": 0.95,
      "modifiers": [
        {
          "displayName": "Pollo Asado",
          "itemGuid": "dec8db70-4841-4b74-a18c-eef3d975b662"
        }
      ]
    },
    "Plantain & Black Bean Tacos": {
      "brandName": "The Little Chihuahua",
      "displayName": "Fried Plantain & Black Bean Tacos (2)",
      "itemGroupId": "ac4ed596-6965-42c4-b435-db15919f6e14",
      "itemId": "d1da6517-f677-463b-97ae-545db9ad76be",
      "price": 11,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal": 11,
      "tax": 0.95
    },
    "Super Carne Asada Tacos": {
      "brandName": "The Little Chihuahua",
      "displayName": "Classic Tacos (2)",
      "itemGroupId": "ac4ed596-6965-42c4-b435-db15919f6e14",
      "itemId": "c1210d38-d649-4527-baa7-59ee72bbaf40",
      "price": 11.75,
      "quantity": 1,
      "restaurantName": "Noshery",
      "subtotal": 11.75,
      "tax": 0.95,
      "modifiers": [
        {
          "displayName": "Carne Asada",
          "itemGuid": "f50dae70-2cc1-4740-8490-bc627def29fe"
        },
        {
          "displayName": "Make Super (guac, sour cream, cheese)",
          "price": 1,
          "itemGuid": "872bef4c-496a-4776-a9e5-efa0c82092c0"
        }
      ]
    },
    "Super Pollo Asado Tacos": {
      "brandName": "The Little Chihuahua",
      "displayName": "Classic Tacos (2)",
      "itemGroupId": "ac4ed596-6965-42c4-b435-db15919f6e14",
      "itemId": "94d06694-03c8-445f-8298-3538bf3893cd",
      "price": 11,
      "quantity": 1,
      "restaurantName": "Noshery",
      "subtotal": 11,
      "tax": 0.95,
      "modifiers": [
        {
          "displayName": "Pollo Asado",
          "itemGuid": "dec8db70-4841-4b74-a18c-eef3d975b662"
        },
        {
          "displayName": "Make Super (guac, sour cream, cheese)",
          "price": 1,
          "itemGuid": "872bef4c-496a-4776-a9e5-efa0c82092c0"
        }
      ]
    },
    "Super Plantain & Black Bean Tacos": {
      "brandName": "The Little Chihuahua",
      "displayName": "Fried Plantain & Black Bean Tacos (2)",
      "itemGroupId": "ac4ed596-6965-42c4-b435-db15919f6e14",
      "itemId": "c1268a48-a250-4782-a5da-851a40aa5ee6",
      "price": 12,
      "quantity": 1,
      "restaurantName": "Noshery",
      "subtotal": 12,
      "tax": 0.95,
      "modifiers": [
        {
          "displayName": "Make Super (guac, sour cream, cheese)",
          "price": 1,
          "itemGuid": "872bef4c-496a-4776-a9e5-efa0c82092c0"
        }
      ]
    },
    "Vegan Plantain & Black Bean Tacos": {
      "brandName": "The Little Chihuahua",
      "displayName": "Fried Plantain & Black Bean Tacos (2)",
      "itemGroupId": "ac4ed596-6965-42c4-b435-db15919f6e14",
      "itemId": "1fcd7c80-f6cd-45b9-a8b7-dfe57b93653d",
      "price": 11,
      "quantity": 1,
      "restaurantName": "Noshery",
      "subtotal": 11,
      "tax": 0.95,
      "modifiers": []
    }
  },
  "Bowl": {
    "Pollo Asado Rice Bowl": {
      "brandName": "The Little Chihuahua",
      "displayName": "Classic Rice Bowls",
      "itemGroupId": "ac4ed596-6965-42c4-b435-db15919f6e14",
      "itemId": "d6924ecd-a6e1-41d1-840d-bcad99ba3634",
      "price": 12,
      "quantity": 1,
      "restaurantName": "Noshery",
      "subtotal": 12,
      "tax": 1.36,
      "modifiers": [
        {
          "displayName": "Pollo Asado",
          "itemGuid": "dec8db70-4841-4b74-a18c-eef3d975b662"
        }
      ]
    },
    "Carne Asada Rice Bowl": {
      "brandName": "The Little Chihuahua",
      "displayName": "Classic Rice Bowls",
      "itemGroupId": "ac4ed596-6965-42c4-b435-db15919f6e14",
      "itemId": "808f02b0-f807-4f3a-a8df-38d15d45d802",
      "price": 12.75,
      "quantity": 1,
      "restaurantName": "Noshery",
      "subtotal": 12.75,
      "tax": 1.36,
      "modifiers": [
        {
          "displayName": "Pollo Asado",
          "itemGuid": "dec8db70-4841-4b74-a18c-eef3d975b662"
        }
      ]
    },
    "Plantain & Black Bean Rice Bowl": {
      "brandName": "The Little Chihuahua",
      "displayName": "Fried Plantain & Black Bean Rice Bowl",
      "itemGroupId": "ac4ed596-6965-42c4-b435-db15919f6e14",
      "itemId": "0b161ff2-5d89-4012-af10-2388aa90c201",
      "price": 13.95,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal": 13.95,
      "tax": 1.36
    },
    "Super Pollo Asado Rice Bowl": {
      "brandName": "The Little Chihuahua",
      "displayName": "Classic Rice Bowls",
      "itemGroupId": "ac4ed596-6965-42c4-b435-db15919f6e14",
      "itemId": "1d28d771-dd58-49ff-afe1-f07c03de3255",
      "price": 14,
      "quantity": 1,
      "restaurantName": "Noshery",
      "subtotal": 14,
      "tax": 1.36,
      "modifiers": [
        {
          "displayName": "Pollo Asado",
          "itemGuid": "dec8db70-4841-4b74-a18c-eef3d975b662"
        },
        {
          "displayName": "Make Super (guac, sour cream, cheese)",
          "guid": "dca618e9-edae-4f17-b399-3fd6b303a280"
        }
      ]
    },
    "Super Carne Asada Rice Bowl": {
      "brandName": "The Little Chihuahua",
      "displayName": "Classic Rice Bowls",
      "itemGroupId": "ac4ed596-6965-42c4-b435-db15919f6e14",
      "itemId": "40273e88-bcce-4da7-8154-0a07c1e0cc3c",
      "price": 14.75,
      "quantity": 1,
      "restaurantName": "Noshery",
      "subtotal": 14.75,
      "tax": 1.36,
      "modifiers": [
        {
          "displayName": "Pollo Asado",
          "itemGuid": "dec8db70-4841-4b74-a18c-eef3d975b662"
        },
        {
          "displayName": "Make Super (guac, sour cream, cheese)",
          "guid": "dca618e9-edae-4f17-b399-3fd6b303a280"
        }
      ]
    },
    "Super Plantain & Black Bean Rice Bowl": {
      "brandName": "The Little Chihuahua",
      "displayName": "Fried Plantain & Black Bean Rice Bowl",
      "itemGroupId": "ac4ed596-6965-42c4-b435-db15919f6e14",
      "itemId": "53e18ba6-b6c7-4218-b05c-76448af5a6c7",
      "price": 15.95,
      "quantity": 1,
      "restaurantName": "Noshery",
      "subtotal": 15.95,
      "tax": 1.36,
      "modifiers": [
        {
          "displayName": "Make Super (guac, sour cream, cheese)",
          "guid": "dca618e9-edae-4f17-b399-3fd6b303a280"
        }
      ]
    },
    "Chicken Rice Bowl": {
      "brandName": "Oren's Hummus",
      "displayName": "Orens Chicken Rice Bowl",
      "itemGroupId": "ec3d2956-8751-4c94-bb70-c3c06620a1ab",
      "itemId": "c9739305-32be-42ac-9668-1df92c1d524f",
      "price": 17,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal": 17,
      "tax": 1.63
    },
    "Oren's Falafel Rice Bowl": {
      "brandName": "Oren's Hummus",
      "displayName": "Orens Chicken Rice Bowl",
      "itemGroupId": "ec3d2956-8751-4c94-bb70-c3c06620a1ab",
      "itemId": "c9739305-32be-42ac-9668-1df92c1d524f",
      "price": 17,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal": 17,
      "tax": 1.63
    },
    "Beef Kebab Rice Bowl": {
      "brandName": "Oren's Hummus",
      "displayName": "Orens Beef Kebab Rice Bowl",
      "itemGroupId": "ec3d2956-8751-4c94-bb70-c3c06620a1ab",
      "itemId": "9fc12a27-72d3-4e4f-b276-9bb16c429754",
      "price": 18,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal": 18,
      "tax": 1.63
    },
    "Roasted Lemongrass Chicken Noodle Bowl": {
      "displayName": "Lemongrass Chicken Noddle Bowl",
      "price": 12.79,
      "quantity": 1,
      "itemGroupId": "58da1327-adca-48eb-836d-c3a85e8d6e02",
      "tax": 1.27,
      "subtotal": 14.06,
      "itemId": "bcdf19c3-2e19-42e8-9d4d-397688676aae",
      "modifiers": [],
      "restaurantName": "Noshery",
      "brandName": "East Side Banh Mi"
    },
    "Roasted Caramel Pork Noodle Bowl": {
      "displayName": "Roasted Caramel Pork Noodle Bowl",
      "price": 13.79,
      "quantity": 1,
      "itemGroupId": "58da1327-adca-48eb-836d-c3a85e8d6e02",
      "tax": 1.37,
      "subtotal": 15.16,
      "itemId": "231de575-d88f-4f63-b385-cdbfe351c71a",
      "modifiers": [],
      "restaurantName": "Noshery",
      "brandName": "East Side Banh Mi"
    },
    "Chili Crisp Tofu & Roasted Eggplant Noodle Bowl": {
      "displayName": "Chili Crips & Tofu Egglplant Noddle Bowl",
      "price": 12.79,
      "quantity": 1,
      "itemGroupId": "58da1327-adca-48eb-836d-c3a85e8d6e02",
      "tax": 1.27,
      "subtotal": 14.06,
      "itemId": "9541da19-f2bb-4365-a3b1-3ed5aa1da92d",
      "modifiers": [],
      "restaurantName": "Noshery",
      "brandName": "East Side Banh Mi"
    },
    "No Protein Noddle Bowl": {
      "displayName": "Citrus Soy Noddle Bowl",
      "price": 12.79,
      "quantity": 1,
      "itemGroupId": "58da1327-adca-48eb-836d-c3a85e8d6e02",
      "tax": 1.03,
      "subtotal": 13.82,
      "itemId": "06aa1919-00b5-488c-8fe7-1f95b422e515",
      "modifiers": [],
      "restaurantName": "Noshery",
      "brandName": "East Side Banh Mi"
    },
    "Roasted Caramel Pork Rice Bowl": {
      "displayName": "Roasted Caramel Pork Rice Bowl",
      "price": 13.79,
      "quantity": 1,
      "itemGroupId": "58da1327-adca-48eb-836d-c3a85e8d6e02",
      "tax": 1.29,
      "subtotal": 15.08,
      "itemId": "361ef3a2-2278-4292-9e90-fccb38e25e1a",
      "modifiers": [],
      "restaurantName": "Noshery",
      "brandName": "East Side Banh Mi"
    },
    "Roasted Lemongrass Chicken Rice Bowl": {
      "displayName": "Lemongrass Chicken Rice Bowl",
      "price": 12.79,
      "quantity": 1,
      "itemGroupId": "58da1327-adca-48eb-836d-c3a85e8d6e02",
      "tax": 1.27,
      "subtotal": 14.06,
      "itemId": "297ef585-d340-4ccf-bb7e-6aedc90ad17b",
      "modifiers": [],
      "restaurantName": "Noshery",
      "brandName": "East Side Banh Mi"
    },
    "Chili Crisp Tofu & Roasted Eggplant Rice Bowl": {
      "displayName": "Chili Crisp & Tofu Eggplant Rice Bowl",
      "price": 12.79,
      "quantity": 1,
      "itemGroupId": "58da1327-adca-48eb-836d-c3a85e8d6e02",
      "tax": 1.27,
      "subtotal": 14.06,
      "itemId": "c55b3fa8-a64d-4ea5-b457-b935f859712e",
      "modifiers": [],
      "restaurantName": "Noshery",
      "brandName": "East Side Banh Mi"
    },
    "No Protein Rice Bowl": {
      "displayName": "Citrus Soy Rice Bowl",
      "price": 9.79,
      "quantity": 1,
      "itemGroupId": "58da1327-adca-48eb-836d-c3a85e8d6e02",
      "tax": 1.03,
      "subtotal": 10.82,
      "itemId": "28b49a4e-7d4f-40ea-b3b8-ff77251a014e",
      "modifiers": [],
      "restaurantName": "Noshery",
      "brandName": "East Side Banh Mi"
    }
  },
  "Salad": {
    "Large Israeli Salad": {
      "brandName": "Oren's Hummus",
      "displayName": "Large Israeli Salad",
      "itemGroupId": "ec3d2956-8751-4c94-bb70-c3c06620a1ab",
      "itemId": "6179ccd7-73ec-40d1-969c-e6abd313d1ab",
      "price": 12,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal": 12,
      "tax": 1.01
    },
    "Beet & Goat Cheese Salad": {
      "brandName": "Oren's Hummus",
      "displayName": "Beet & Goat Cheese Salad",
      "itemGroupId": "ec3d2956-8751-4c94-bb70-c3c06620a1ab",
      "itemId": "32f05a40-dd31-4f1a-a0b9-bbc5a99774a3",
      "price": 14.95,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal": 14.95,
      "tax": 1.26
    },
    "Fattoush Salad": {
      "brandName": "Oren's Hummus",
      "displayName": "Fattoush Salad",
      "itemGroupId": "ec3d2956-8751-4c94-bb70-c3c06620a1ab",
      "itemId": "d4d953c5-0341-4f72-874f-ce3c93ffdf16",
      "price": 16,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal": 16,
      "tax": 1.4
    },
    "Roasted Lemongrass Chicken Salad Bowl": {
      "displayName": "Lemongrass Chicken Salad",
      "price": 12.79,
      "quantity": 1,
      "itemGroupId": "58da1327-adca-48eb-836d-c3a85e8d6e02",
      "tax": 1.22,
      "subtotal": 14.21,
      "itemId": "d6c07903-23a3-41fe-a254-d016f1b94a82",
      "modifiers": [],
      "restaurantName": "Noshery",
      "brandName": "East Side Banh Mi"
    },
    "Roasted Caramel Pork Salad Bowl": {
      "displayName": "Roasted Caramel Pork Salad",
      "price": 13.79,
      "quantity": 1,
      "itemGroupId": "58da1327-adca-48eb-836d-c3a85e8d6e02",
      "tax": 1.22,
      "subtotal": 15.01,
      "itemId": "ed4531a8-4777-433e-a393-771165127dee",
      "modifiers": [],
      "restaurantName": "Noshery",
      "brandName": "East Side Banh Mi"
    },
    "Chili Crisp Tofu & Roasted Eggplant Salad Bowl": {
      "displayName": "Chili Crisp & Tofu Eggplant Salad",
      "price": 12.79,
      "quantity": 1,
      "itemGroupId": "58da1327-adca-48eb-836d-c3a85e8d6e02",
      "tax": 1.22,
      "subtotal": 14.01,
      "itemId": "58391d10-f0c6-4ff3-a20a-1339270bba57",
      "modifiers": [],
      "restaurantName": "Noshery",
      "brandName": "East Side Banh Mi"
    },
    "No Protein Salad Bowl": {
      "displayName": "Citrus Soy Salad",
      "price": 9.79,
      "quantity": 1,
      "itemGroupId": "58da1327-adca-48eb-836d-c3a85e8d6e02",
      "tax": 1.03,
      "subtotal": 10.82,
      "itemId": "d9ccf038-93b4-4e2b-b7bf-24a936090825",
      "modifiers": [],
      "restaurantName": "Noshery",
      "brandName": "East Side Banh Mi"
    }
  },
  "Extra White Pita": {
    "Extra White Pita": {
      "brandName": "Oren's Hummus",
      "displayName": "Extra White Pita",
      "itemGroupId": "ec3d2956-8751-4c94-bb70-c3c06620a1ab",
      "itemId": "0002ea97-87d1-4fe9-ad91-8f45ff8b7016",
      "itemGuid": "98663b35-5391-4d4d-a1d7-5be8d8038bcd",
      "price": 1.25,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal": 1.25,
      "tax": 1.58
    }
  },
  "Extra Gluten-Free Pita": {
    "Extra White Pita": {
      "brandName": "Oren's Hummus",
      "displayName": "Extra Gluten-Free Pita",
      "itemGroupId": "ec3d2956-8751-4c94-bb70-c3c06620a1ab",
      "itemId": "0002ea97-87d1-4fe9-ad91-8f45ff8b7016",
      "itemGuid": "98663b35-5391-4d4d-a1d7-5be8d8038bcd",
      "price": 2,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal": 2,
      "tax": 1.58
    }
  },
  "Pita": {
    "Pita Beef Kebab": {
      "brandName": "Oren's Hummus",
      "displayName": "Pita Beef Kebab",
      "itemGroupId": "ec3d2956-8751-4c94-bb70-c3c06620a1ab",
      "itemId": "4bbad8bb-efb3-43b9-8afc-25e719980ebc",
      "price": 17,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal": 17,
      "tax": 1.63
    },
    "Pita Chicken": {
      "brandName": "Oren's Hummus",
      "displayName": "Pita Chicken",
      "itemGroupId": "ec3d2956-8751-4c94-bb70-c3c06620a1ab",
      "itemId": "6f4cf0f7-c6dc-45aa-8d5e-f48397601ee5",
      "price": 16,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal": 16,
      "tax": 1.54
    },
    "Pita Falafel": {
      "brandName": "Oren's Hummus",
      "displayName": "Pita Falafel",
      "itemGroupId": "ec3d2956-8751-4c94-bb70-c3c06620a1ab",
      "itemId": "efa3a065-0a82-429e-b4ae-d8a783ddae8c",
      "price": 14,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal": 14,
      "tax": 1.05
    },
    "White Pita": {
      "brandName": "Oren's Hummus",
      "displayName": "White Pita",
      "itemGroupId": "ec3d2956-8751-4c94-bb70-c3c06620a1ab",
      "itemId": "f8580242-88d1-41cb-ab1a-4bc7ee6e3ed4",
      "itemGuid": "98663b35-5391-4d4d-a1d7-5be8d8038bcd",
      "price": 0,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal": 0,
      "tax": 0
    },
    "Gluten Free Pita": {
      "brandName": "Oren's Hummus",
      "displayName": "Gluten Free Pita",
      "itemGroupId": "ec3d2956-8751-4c94-bb70-c3c06620a1ab",
      "itemId": "bdc6fccd-1489-4653-84a5-a25d576e919e",
      "itemGuid": "1353bb71-79bb-4c42-b21b-446b4df0ac27",
      "price": 1.85,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal": 0,
      "tax": 0
    }
  },
  "Hummus": {
    "Hummus Classic": {
      "brandName": "Oren's Hummus",
      "displayName": "Hummus Classic",
      "itemGroupId": "ec3d2956-8751-4c94-bb70-c3c06620a1ab",
      "itemId": "916ee001-1a43-48f8-bb8c-16f2c0c4a9f0",
      "price": 11,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal": 11,
      "tax": 0.86
    },
    "Hummus with Moroccan Beef": {
      "brandName": "Oren's Hummus",
      "displayName": "Hummus with Moroccan Beef",
      "itemGroupId": "ec3d2956-8751-4c94-bb70-c3c06620a1ab",
      "itemId": "0703a9dd-174c-4b9e-9c64-8ab75efc131b",
      "price": 17,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal": 17,
      "tax": 1.58
    },
    "Hummus Chicken": {
      "brandName": "Oren's Hummus",
      "displayName": "Hummus Chicken",
      "itemGroupId": "ec3d2956-8751-4c94-bb70-c3c06620a1ab",
      "itemId": "5ccb0f80-00c9-4433-8aa1-d7fd6ef2ada8",
      "price": 16,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal": 16,
      "tax": 1.54
    },
    "Hummus Eggplant": {
      "brandName": "Oren's Hummus",
      "displayName": "Hummus Eggplant",
      "itemGroupId": "ec3d2956-8751-4c94-bb70-c3c06620a1ab",
      "itemId": "46b3fd29-1572-4880-a243-ce9799e9f011",
      "price": 14,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal": 14,
      "tax": 1.34
    },
    "Hummus Beets": {
      "brandName": "Oren's Hummus",
      "displayName": "Hummus Beets",
      "itemGroupId": "ec3d2956-8751-4c94-bb70-c3c06620a1ab",
      "itemId": "fda82712-1f2d-4663-8cf8-2aa5f949cfcb",
      "price": 15,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal": 15,
      "tax": 1.34
    }
  },
  "Skewer": {
    "Chicken Skewer": {
      "brandName": "Oren's Hummus",
      "displayName": "Chicken Skewer",
      "itemGroupId": "ec3d2956-8751-4c94-bb70-c3c06620a1ab",
      "itemId": "f3884918-7024-4383-95cb-f19bb3204eb7",
      "price": 24.75,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal": 24.75,
      "tax": 1.58
    },
    "Beef Kebab Skewer": {
      "brandName": "Oren's Hummus",
      "displayName": "Beef Kebab Skewer",
      "itemGroupId": "ec3d2956-8751-4c94-bb70-c3c06620a1ab",
      "itemId": "30be5c2e-3a49-4e05-a700-cabbaa26a133",
      "price": 25.75,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal": 25.75,
      "tax": 1.58
    }
  },
  "Falafel": {
    "Falafel Balls": {
      "brandName": "Oren's Hummus",
      "displayName": "Green Herb Falafel (5 pieces)",
      "itemGroupId": "ec3d2956-8751-4c94-bb70-c3c06620a1ab",
      "itemId": "13d10f76-16e3-4958-a2a4-9d6d8f668245",
      "price": 6.18,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal": 6.18,
      "tax": 1.58
    }
  },
  "Dessert": {
    "Snickerdoodle": {
      "brandName": "The Pastry Cupboard",
      "displayName": "1 Cookie",
      "itemGroupId": "9b363d8d-de36-425b-a9cb-b633b1f6a544",
      "itemId": "b84019a5-2275-49cd-9144-6bac712dfbbb",
      "price": 3.5,
      "quantity": 1,
      "restaurantName": "Noshery",
      "modifiers": [
        {
          "displayName": "Snickerdoodle",
          "guid": "dca618e9-edae-4f17-b399-3fd6b303a280"
        }
      ],
      "subtotal": 3.5,
      "tax": 0.25
    },
    "White Chocolate & Cranberry": {
      "brandName": "The Pastry Cupboard",
      "displayName": "1 Cookie",
      "itemGroupId": "9b363d8d-de36-425b-a9cb-b633b1f6a544",
      "itemId": "1ae979c5-7545-4944-9f14-a4108db604c8",
      "price": 3.5,
      "quantity": 1,
      "restaurantName": "Noshery",
      "modifiers": [
        {
          "displayName": "White Chocolate & Cranberry",
          "guid": "dca618e9-edae-4f17-b399-3fd6b303a280"
        }
      ],
      "subtotal": 3.5,
      "tax": 0.25
    },
    "Chocolate Chunk": {
      "brandName": "The Pastry Cupboard",
      "displayName": "1 Cookie",
      "itemGroupId": "9b363d8d-de36-425b-a9cb-b633b1f6a544",
      "itemId": "1cd023be-c692-4a52-8ad9-200c47eac451",
      "price": 3.5,
      "quantity": 1,
      "modifiers": [
        {
          "displayName": "Chocolate Chip",
          "guid": "dca618e9-edae-4f17-b399-3fd6b303a280"
        }
      ],
      "restaurantName": "Noshery",
      "subtotal": 3.5,
      "tax": 0.25
    },
    "Oatmeal Raisin": {
      "brandName": "The Pastry Cupboard",
      "displayName": "1 Cookie",
      "itemGroupId": "9b363d8d-de36-425b-a9cb-b633b1f6a544",
      "itemId": "876c557b-f4b6-4740-b239-846c87c34e61",
      "price": 3.5,
      "quantity": 1,
      "modifiers": [
        {
          "displayName": "Oatmeal Raisin",
          "guid": "dca618e9-edae-4f17-b399-3fd6b303a280"
        }
      ],
      "restaurantName": "Noshery",
      "subtotal": 3.5,
      "tax": 0.25
    }
  },
  "Burger": {
    "MeltBurger": {
      "brandName": "The Melt",
      "displayName": "MeltBurger",
      "itemGroupId": "05c84507-9c57-4a58-a83c-3ba3072c4009",
      "itemId": "bd09807f-0a57-433e-9666-5f34600ad008",
      "price": 8.99,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal": 8.99,
      "tax": 0.95
    },
    "BBQ Bacon MeltBurger": {
      "brandName": "The Melt",
      "displayName": "BBQ Bacon MeltBurger",
      "itemGroupId": "05c84507-9c57-4a58-a83c-3ba3072c4009",
      "itemId": "fa1da139-8096-45c6-9036-6e0cf8313c27",
      "price": 10.99,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal": 10.99,
      "tax": 1
    },
    "Double MeltBurger": {
      "brandName": "The Melt",
      "displayName": "Double MeltBurger",
      "itemGroupId": "05c84507-9c57-4a58-a83c-3ba3072c4009",
      "itemId": "7a6bb066-7b1e-4730-a0cd-c9bbbfcc7e3c",
      "price": 12.79,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal": 12.79,
      "tax": 0.95
    },
    "Double BBQ Bacon MeltBurger": {
      "brandName": "The Melt",
      "displayName": "Double BBQ Bacon MeltBurger",
      "itemGroupId": "05c84507-9c57-4a58-a83c-3ba3072c4009",
      "itemId": "b25757ee-eaf3-44ad-a3ee-8db7c2e13292",
      "price": 14.78,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal": 14.78,
      "tax": 1
    },
    "Impossible MeltBurger": {
      "brandName": "The Melt",
      "displayName": "Impossible MeltBurger",
      "itemGroupId": "05c84507-9c57-4a58-a83c-3ba3072c4009",
      "itemId": "cb72c51a-4988-4fc5-89f1-0781f895f4d4",
      "price": 12.99,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal": 12.99,
      "tax": 1.7
    },
    "Vegan BBQ Impossible Burger": {
      "brandName": "The Melt",
      "displayName": "Impossible MeltBurger",
      "itemGroupId": "05c84507-9c57-4a58-a83c-3ba3072c4009",
      "itemId": "e7fc48ef-8870-40c8-8510-b8170c50c15a",
      "price": 12.99,
      "quantity": 1,
      "modifiers": [
        {
          "displayName": "Add Smoked Bacon",
          "guid": "dca618e9-edae-4f17-b399-3fd6b303a280"
        }
      ],
      "restaurantName": "Noshery",
      "subtotal": 12.99,
      "tax": 1.7
    }
  },
  "Patty":{
    "Patty Melt": {
      "brandName": "The Melt",
      "displayName": "Patty Melt",
      "itemGroupId": "05c84507-9c57-4a58-a83c-3ba3072c4009",
      "itemId": "cb72c51a-4988-4fc5-89f1-0781f895f4d4",
      "price": 10.49,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal": 10.49,
      "tax": 1.7
    },
  },
  "Three Cheese":{
    "Three Cheese Classic": {
      "brandName": "The Melt",
      "displayName": "Three Cheese Classic",
      "itemGroupId": "05c84507-9c57-4a58-a83c-3ba3072c4009",
      "itemId": "cb72c51a-4988-4fc5-89f1-0781f895f4d4",
      "price": 7.79,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal": 7.79,
      "tax": 1.7
    },
  },
  "Swiss":{
    "Swiss & Shroom MeltBurger": {
      "brandName": "The Melt",
      "displayName": "Swiss & Shroom MeltBurger",
      "itemGroupId": "05c84507-9c57-4a58-a83c-3ba3072c4009",
      "itemId": "cb72c51a-4988-4fc5-89f1-0781f895f4d4",
      "price": 9.79,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal": 9.79,
      "tax": 1.7
    },
    "Swiss & Shroom Chicken Melt": {
      "brandName": "The Melt",
      "displayName": "Swiss & Shroom Chicken Melt",
      "itemGroupId": "05c84507-9c57-4a58-a83c-3ba3072c4009",
      "itemId": "cb72c51a-4988-4fc5-89f1-0781f895f4d4",
      "price": 9.79,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal": 9.79,
      "tax": 1.7
    },
    "Impossible Swiss & Shroom MeltBurger": {
      "brandName": "The Melt",
      "displayName": "Impossible Swiss & Shroom MeltBurger",
      "itemGroupId": "05c84507-9c57-4a58-a83c-3ba3072c4009",
      "itemId": "cb72c51a-4988-4fc5-89f1-0781f895f4d4",
      "price": 13.99,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal": 13.99,
      "tax": 1.7
    },
    
  },
  "Chicken Melt": {
    "Original Chicken Melt": {
      "brandName": "The Melt",
      "displayName": "Original Chicken Melt",
      "itemGroupId": "05c84507-9c57-4a58-a83c-3ba3072c4009",
      "itemId": "cb72c51a-4988-4fc5-89f1-0781f895f4d4",
      "price": 8.99,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal": 8.99,
      "tax": 1.7
    },   
    "BBQ Bacon Chicken Melt": {
      "brandName": "The Melt",
      "displayName": "BBQ Bacon Chicken Melt",
      "itemGroupId": "05c84507-9c57-4a58-a83c-3ba3072c4009",
      "itemId": "cb72c51a-4988-4fc5-89f1-0781f895f4d4",
      "price": 10.99,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal": 10.99,
      "tax": 1.7
    },
  },
  "Fries": {
    "Fries": {
      "brandName": "The Melt",
      "displayName": "Fries",
      "itemGroupId": "05c84507-9c57-4a58-a83c-3ba3072c4009",
      "itemId": "ee426134-bbba-402e-aab4-2f5308773a0b",
      "price": 3.79,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal": 3.79,
      "tax": 1.7
    },
    "Cheddar Bacon Fries": {
      "brandName": "The Melt",
      "displayName": "Cheddar Bacon Fries",
      "itemGroupId": "05c84507-9c57-4a58-a83c-3ba3072c4009",
      "itemId": "89f05f01-f0df-4cee-bbf1-d30440f6ce88",
      "price": 5.29,
      "modifiers": [],
      "quantity": 1,
      "restaurantName": "Noshery",
      "subtotal": 5.29,
      "tax": 0.46
    },
    "Garlic Parmesan Fries": {
      "brandName": "The Melt",
      "displayName": "Garlic Parmesan Fries",
      "itemGroupId": "05c84507-9c57-4a58-a83c-3ba3072c4009",
      "itemId": "f6d4f8bc-2def-4742-9330-b2aa206c2d26",
      "price": 4.79,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal": 4.79,
      "tax": 0.41
    },
    "French Fries": {
      "brandName": "Oren's Hummus",
      "displayName": "French Fries",
      "itemGroupId": "05c84507-9c57-4a58-a83c-3ba3072c4009",
      "itemId": "f6d4f8bc-2def-4742-9330-b2aa206c2d26",
      "price": 6,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal": 6,
      "tax": 0.41
    }
  },
  "Banh Mi": {
    "Pork Deluxe Banh Mi": {
      "displayName": "Pork Deluxe Banh Mi",
      "price": 12,
      "quantity": 1,
      "itemGroupId": "58da1327-adca-48eb-836d-c3a85e8d6e02",
      "tax": 1.13,
      "subtotal": 13.13,
      "itemId": "185eb75c-898b-4e0a-af94-42670feee8df",
      "modifiers": [],
      "restaurantName": "Noshery",
      "brandName": "East Side Banh Mi"
    },
    "Chicken Deluxe Banh Mi": {
      "displayName": "Chicken Deluxe Banh Mi",
      "price": 11.5,
      "quantity": 1,
      "itemGroupId": "58da1327-adca-48eb-836d-c3a85e8d6e02",
      "tax": 1.17,
      "subtotal": 12.67,
      "itemId": "035fbed8-753d-4fd5-9bdd-80f4eed8b1c5",
      "modifiers": [],
      "restaurantName": "Noshery",
      "brandName": "East Side Banh Mi"
    },
    "Pork Banh Mi": {
      "displayName": "Pork Banh Mi",
      "price": 11,
      "quantity": 1,
      "itemGroupId": "58da1327-adca-48eb-836d-c3a85e8d6e02",
      "tax": 1.08,
      "subtotal": 12.08,
      "itemId": "119ec554-c86a-4f6f-baa5-6bfc26514807",
      "modifiers": [],
      "restaurantName": "Noshery",
      "brandName": "East Side Banh Mi"
    },
    "Chicken Banh Mi": {
      "displayName": "Chicken Banh Mi",
      "price": 10.5,
      "quantity": 1,
      "itemGroupId": "58da1327-adca-48eb-836d-c3a85e8d6e02",
      "tax": 1.08,
      "subtotal": 11.13,
      "itemId": "494d73c8-6fa4-463f-9c5d-5a6ef89a4d9c",
      "modifiers": [],
      "restaurantName": "Noshery",
      "brandName": "East Side Banh Mi"
    },
    "Vegan Banh Mi": {
      "displayName": "Vegan Banh Mi",
      "price": 10.5,
      "quantity": 1,
      "itemGroupId": "58da1327-adca-48eb-836d-c3a85e8d6e02",
      "tax": 1.03,
      "subtotal": 11.5,
      "itemId": "2db4c356-6561-4d67-9ab8-e6dd884f9a2f",
      "modifiers": [],
      "restaurantName": "Noshery",
      "brandName": "East Side Banh Mi"
    }
  },
  "Naan Roll": {
    "Chicken Tikka Masala Naan Roll": {
      "brandName": "Kasa",
      "displayName": "Naan Roll",
      "itemGroupId": "313913e9-37c9-45ea-b8c6-9690a9b47972",
      "itemId": "0f9e20e2-f0f3-4835-9958-214e824a75c4",
      "price": 13,
      "quantity": 1,
      "modifiers": [
        {
          "displayName": "Chicken Tikka Masala",
          "guid": "dca618e9-edae-4f17-b399-3fd6b303a280"
        }
      ],
      "restaurantName": "Noshery",
      "subtotal": 13,
      "tax": 1.7
    }
  },
  "Sandwich": {
    "Three Cheese Sandwich": {
      "brandName": "The Melt",
      "displayName": "Three Cheese Classic",
      "itemGroupId": "05c84507-9c57-4a58-a83c-3ba3072c4009",
      "itemId": "7b8d7c7b-91b8-4487-96d6-55e633a9b15e",
      "price": 7.49,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal": 7.49,
      "tax": 0.95
    }
  },
  "Soup": {
    "Tomato Soup": {
      "brandName": "The Melt",
      "displayName": "Tomato Soup",
      "itemGroupId": "05c84507-9c57-4a58-a83c-3ba3072c4009",
      "itemId": "525bce5c-e809-4704-9fc7-d81bb4927ce7",
      "price": 4.49,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal": 4.49,
      "tax": 0.95
    }
  }
};

export const ezcater2 = {
  "Burrito": {
    "Pollo Asado Burrito": {
      "brandName": "The Little Chihuahua",
      "displayName": "Classic Burritos",
      "itemGroupId": "17219ad1-22a2-4f03-8337-93c4d146b11b",
      "itemId": "0207c506-d9c8-49b5-8a71-dca2e6fe2a8b",
      "price": 12.0,
      "quantity": 1,
      "restaurantName": "The Little Chihuahua",
      "subtotal": 12.0,
      "tax": 1.4,
      "modifiers": [
        {
          "displayName": "Pollo Asado",
          "itemGuid": "dec8db70-4841-4b74-a18c-eef3d975b662"
        }
      ]
    },
    "Carne Asada Burrito": {
      "brandName": "The Little Chihuahua",
      "displayName": "Classic Burrito",
      "itemGroupId": "17219ad1-22a2-4f03-8337-93c4d146b11b",
      "itemId": "0207c506-d9c8-49b5-8a71-dca2e6fe2a8b",
      "price": 12.75,
      "quantity": 1,
      "restaurantName": "The Little Chihuahua",
      "subtotal": 12.75,
      "tax": 1.4,
      "modifiers": [
        {
          "displayName": "Carne Asada",
          "price": 0,
          "itemGuid": "f8c9f3d3-a435-4387-9706-f9b0cf4e6ebc"
        }
      ]
    },
    "Fried Plantain & Black Bean Burrito": {
      "brandName": "The Little Chihuahua",
      "displayName": "Fried Plantain Burrito",
      "itemGroupId": "17219ad1-22a2-4f03-8337-93c4d146b11b",
      "itemId": "ad560a71-6396-43eb-8751-48de6bb4be8a",
      "price": 13.95,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "The Little Chihuahua",
      "subtotal": 13.95,
      "tax": 1.34
    },
    "Super Pollo Asado Burrito": {
      "brandName": "The Little Chihuahua",
      "displayName": "Classic Burrito",
      "itemGroupId": "c361a3d8-c27d-4af0-9eb9-c0ae6c66f5dd",
      "itemId": "0207c506-d9c8-49b5-8a71-dca2e6fe2a8b",
      "price": 14.0,
      "quantity": 1,
      "restaurantName": "The Little Chihuahua",
      "subtotal": 14.0,
      "tax": 1.4,
      "modifiers": [
        {
          "displayName": "Pollo Asado",
          "itemGuid": "dec8db70-4841-4b74-a18c-eef3d975b662"
        },
        {
          "displayName": "Make Super (guac, sour cream, cheese)",
          "itemGuid": "dca618e9-edae-4f17-b399-3fd6b303a280"
        }
      ]
    },
    "Super Carne Asada Burrito": {
      "brandName": "The Little Chihuahua",
      "displayName": "Classic Burritos",
      "itemGroupId": "17219ad1-22a2-4f03-8337-93c4d146b11b",
      "itemId": "ad560a71-6396-43eb-8751-48de6bb4be8a",
      "price": 14.75,
      "quantity": 1,
      "restaurantName": "The Little Chihuahua",
      "subtotal": 14.75,
      "tax": 1.4,
      "modifiers": [
        {
          "displayName": "Carne Asada",
          "price": 0,
          "itemGuid": "f8c9f3d3-a435-4387-9706-f9b0cf4e6ebc"
        },
        {
          "displayName": "Make Super (guac, sour cream, cheese)",
          "guid": "dca618e9-edae-4f17-b399-3fd6b303a280"
        }
      ]
    },
    "Super Plantain & Black Bean Burrito": {
      "brandName": "The Little Chihuahua",
      "displayName": "Fried Plantain & Black Bean Burrito",
      "itemGroupId": "c361a3d8-c27d-4af0-9eb9-c0ae6c66f5dd",
      "itemId": "ad560a71-6396-43eb-8751-48de6bb4be8a",
      "price": 15.95,
      "quantity": 1,
      "restaurantName": "The Little Chihuahua",
      "subtotal": 15.95,
      "tax": 1.34,
      "modifiers": [
        {
          "displayName": "Make Super (guac, sour cream, cheese)",
          "guid": "dca618e9-edae-4f17-b399-3fd6b303a280"
        },
        {
          "displayName": "Make Super (guac, sour cream, cheese)",
          "itemGuid": "dca618e9-edae-4f17-b399-3fd6b303a280"
        }
      ]
    }
  },
  "Taco": {
    "Carne Asada Tacos": {
      "brandName": "The Little Chihuahua",
      "displayName": "Classic Tacos (2)",
      "itemGroupId": "791b1d76-c702-4819-bf36-18fdfcadc370",
      "itemId": "71c2f512-74a4-4770-a4fc-d1c872248c2c",
      "price": 10.75,
      "quantity": 1,
      "restaurantName": "The Little Chihuahua",
      "subtotal": 10.75,
      "tax": 0.95,
      "modifiers": [
        {
          "displayName": "Carne Asada",
          "guid": "f8c9f3d3-a435-4387-9706-f9b0cf4e6ebc"
        }
      ]
    },
    "Pollo Asado Tacos": {
      "brandName": "The Little Chihuahua",
      "displayName": "Classic Tacos (2)",
      "itemGroupId": "791b1d76-c702-4819-bf36-18fdfcadc370",
      "itemId": "71c2f512-74a4-4770-a4fc-d1c872248c2c",
      "price": 10,
      "quantity": 1,
      "restaurantName": "The Little Chihuahua",
      "subtotal": 10,
      "tax": 0.95,
      "modifiers": [
        {
          "displayName": "Pollo Asado",
          "itemGuid": "dec8db70-4841-4b74-a18c-eef3d975b662"
        }
      ]
    },
    "Plantain & Black Bean Tacos": {
      "brandName": "The Little Chihuahua",
      "displayName": "Fried Plantain & Black Bean Tacos (2)",
      "itemGroupId": "c361a3d8-c27d-4af0-9eb9-c0ae6c66f5dd",
      "itemId": "8a2a65c5-0ec1-4e21-a15d-b07360f0421e",
      "price": 11,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "The Little Chihuahua",
      "subtotal": 11,
      "tax": 0.95
    },
    "Super Carne Asada Tacos": {
      "brandName": "The Little Chihuahua",
      "displayName": "Classic Tacos (2)",
      "itemGroupId": "791b1d76-c702-4819-bf36-18fdfcadc370",
      "itemId": "71c2f512-74a4-4770-a4fc-d1c872248c2c",
      "price": 11.75,
      "quantity": 1,
      "restaurantName": "The Little Chihuahua",
      "subtotal": 11.75,
      "tax": 0.95,
      "modifiers": [
        {
          "displayName": "Carne Asada",
          "itemGuid": "f50dae70-2cc1-4740-8490-bc627def29fe"
        },
        {
          "displayName": "Make Super (guac, sour cream, cheese)",
          "price": 1,
          "itemGuid": "872bef4c-496a-4776-a9e5-efa0c82092c0"
        },
      ]
    },
    "Super Pollo Asado Tacos": {
      "brandName": "The Little Chihuahua",
      "displayName": "Classic Tacos (2)",
      "itemGroupId": "791b1d76-c702-4819-bf36-18fdfcadc370",
      "itemId": "71c2f512-74a4-4770-a4fc-d1c872248c2c",
      "price": 11,
      "quantity": 1,
      "restaurantName": "The Little Chihuahua",
      "subtotal": 11,
      "tax": 0.95,
      "modifiers": [
        {
          "displayName": "Pollo Asado",
          "itemGuid": "dec8db70-4841-4b74-a18c-eef3d975b662"
        },
        {
          "displayName": "Make Super (guac, sour cream, cheese)",
          "price": 1,
          "itemGuid": "872bef4c-496a-4776-a9e5-efa0c82092c0"
        },
      ]
    },
    "Super Plantain & Black Bean Tacos": {
      "brandName": "The Little Chihuahua",
      "displayName": "Fried Plantain & Black Bean Tacos (2)",
      "itemGroupId": "c361a3d8-c27d-4af0-9eb9-c0ae6c66f5dd",
      "itemId": "699c2e28-9784-4416-a662-a9b3795d2742",
      "price": 12,
      "quantity": 1,
      "restaurantName": "The Little Chihuahua",
      "subtotal": 12,
      "tax": 0.95,
      "modifiers": [
        {
          "displayName": "Make Super (guac, sour cream, cheese)",
          "price": 1,
          "itemGuid": "872bef4c-496a-4776-a9e5-efa0c82092c0"
        },
      ]
    },
    "Vegan Plantain & Black Bean Tacos": {
      "brandName": "The Little Chihuahua",
      "displayName": "Fried Plantain & Black Bean Tacos (2)",
      "itemGroupId": "c361a3d8-c27d-4af0-9eb9-c0ae6c66f5dd",
      "itemId": "699c2e28-9784-4416-a662-a9b3795d2742",
      "price": 11,
      "quantity": 1,
      "restaurantName": "The Little Chihuahua",
      "subtotal": 11,
      "tax": 0.95,
      "modifiers": []
    }
  },
  "Bowl": {
    "Pollo Asado Rice Bowl": {
      "brandName": "The Little Chihuahua",
      "displayName": "Classic Rice Bowls",
      "itemGroupId": "d35fa01c-6264-43a6-82d0-3397f631d74c",
      "itemId": "20f069fc-b22c-4c6b-840b-eac088392776",
      "price": 12,
      "quantity": 1,
      "restaurantName": "Noshery",
      "subtotal": 12,
      "tax": 1.36,
      "modifiers": [
        {
          "displayName": "Pollo Asado",
          "itemGuid": "dec8db70-4841-4b74-a18c-eef3d975b662"
        }
      ]
    },
    "Carne Asada Rice Bowl": {
      "brandName": "The Little Chihuahua",
      "displayName": "Classic Rice Bowls",
      "itemGroupId": "d35fa01c-6264-43a6-82d0-3397f631d74c",
      "itemId": "20f069fc-b22c-4c6b-840b-eac088392776",
      "price": 12.75,
      "quantity": 1,
      "restaurantName": "Noshery",
      "subtotal": 12.75,
      "tax": 1.36,
      "modifiers": [
        {
          "displayName": "Pollo Asado",
          "itemGuid": "dec8db70-4841-4b74-a18c-eef3d975b662"
        }
      ]
    },
    "Plantain & Black Bean Rice Bowl": {
      "brandName": "The Little Chihuahua",
      "displayName": "Fried Plantain & Black Bean Rice Bowl",
      "itemGroupId": "d35fa01c-6264-43a6-82d0-3397f631d74c",
      "itemId": "20f069fc-b22c-4c6b-840b-eac088392776",
      "price": 13.95,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal": 13.95,
      "tax": 1.36
    },
    "Super Pollo Asado Rice Bowl": {
      "brandName": "The Little Chihuahua",
      "displayName": "Classic Rice Bowls",
      "itemGroupId": "d35fa01c-6264-43a6-82d0-3397f631d74c",
      "itemId": "20f069fc-b22c-4c6b-840b-eac088392776",
      "price": 14,
      "quantity": 1,
      "restaurantName": "Noshery",
      "subtotal": 14,
      "tax": 1.36,
      "modifiers": [
        {
          "displayName": "Pollo Asado",
          "itemGuid": "dec8db70-4841-4b74-a18c-eef3d975b662"
        },
        {
          "displayName": "Make Super (guac, sour cream, cheese)",
          "guid": "dca618e9-edae-4f17-b399-3fd6b303a280"
        }
      ]
    },
    "Super Carne Asada Rice Bowl": {
      "brandName": "The Little Chihuahua",
      "displayName": "Classic Rice Bowls",
      "itemGroupId": "d35fa01c-6264-43a6-82d0-3397f631d74c",
      "itemId": "20f069fc-b22c-4c6b-840b-eac088392776",
      "price": 14.75,
      "quantity": 1,
      "restaurantName": "Noshery",
      "subtotal": 14.75,
      "tax": 1.36,
      "modifiers": [
        {
          "displayName": "Pollo Asado",
          "itemGuid": "dec8db70-4841-4b74-a18c-eef3d975b662"
        },
        {
          "displayName": "Make Super (guac, sour cream, cheese)",
          "guid": "dca618e9-edae-4f17-b399-3fd6b303a280"
        }
      ]
    },
    "Super Plantain & Black Bean Rice Bowl": {
      "brandName": "The Little Chihuahua",
      "displayName": "Fried Plantain & Black Bean Rice Bowl",
      "itemGroupId": "d35fa01c-6264-43a6-82d0-3397f631d74c",
      "itemId": "20f069fc-b22c-4c6b-840b-eac088392776",
      "price": 15.95,
      "quantity": 1,
      "restaurantName": "Noshery",
      "subtotal": 15.95,
      "tax": 1.36,
      "modifiers": [
        {
          "displayName": "Make Super (guac, sour cream, cheese)",
          "guid": "dca618e9-edae-4f17-b399-3fd6b303a280"
        }
      ]
    },
    "Chicken Rice Bowl": {
      "brandName": "Oren's Hummus",
      "displayName": "Orens Chicken Rice Bowl",
      "itemGroupId": "6181229a-4aeb-4876-8ca9-317add8f13de",
      "itemId": "705c7e52-abe3-48b2-82e3-4aa2d50ffee2",
      "price": 17.51,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal": 17.51,
      "tax": 1.63,
    },
    "Beef Kebab Rice Bowl": {
      "brandName": "Oren's Hummus",
      "displayName": "Orens Beef Kebab Rice Bowl",
      "itemGroupId": "6181229a-4aeb-4876-8ca9-317add8f13de",
      "itemId": "41ed2a1a-e894-4937-8e16-feddfec5d311",
      "price": 18.54,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal": 18.54,
      "tax": 1.63,
    },    
    "Roasted Lemongrass Chicken Noodle Bowl": {
      displayName: "Lemongrass Chicken Noddle Bowl",
      price: 12.79,
      quantity: 1,
      itemGroupId: "870f00c3-618f-413d-a551-09f309e3d292",
      tax: 1.27,
      subtotal: 14.06,
      itemId: "85d72ef0-52d8-42f0-969a-f9684b32489d",
      modifiers: [],
      restaurantName: "East Side Banh Mi",
      brandName: "East Side Banh Mi"
    },
    "Roasted Caramel Pork Noodle Bowl": {
      displayName: "Roasted Caramel Pork Noodle Bowl",
      price: 13.79,
      quantity: 1,
      itemGroupId: "870f00c3-618f-413d-a551-09f309e3d292",
      tax: 1.37,
      subtotal: 15.16,
      itemId: "85d72ef0-52d8-42f0-969a-f9684b32489d",
      modifiers: [],
      restaurantName: "East Side Banh Mi",
      brandName: "East Side Banh Mi"
    },
    "Chili Crisp Tofu & Roasted Eggplant Noodle Bowl": {
      displayName: "Chili Crips & Tofu Egglplant Noddle Bowl",
      price: 12.79,
      quantity: 1,
      itemGroupId: "870f00c3-618f-413d-a551-09f309e3d292",
      tax: 1.27,
      subtotal: 14.06,
      itemId: "85d72ef0-52d8-42f0-969a-f9684b32489d",
      modifiers: [],
      restaurantName: "East Side Banh Mi",
      brandName: "East Side Banh Mi"
    },
    "No Protein Noddle Bowl": {
      displayName: "Citrus Soy Noddle Bowl",
      price: 12.79,
      quantity: 1,
      itemGroupId: "870f00c3-618f-413d-a551-09f309e3d292",
      tax: 1.03,
      subtotal: 13.82,
      itemId: "85d72ef0-52d8-42f0-969a-f9684b32489d",
      modifiers: [],
      restaurantName: "East Side Banh Mi",
      brandName: "East Side Banh Mi"
    },
    "Roasted Caramel Pork Rice Bowl": {
      displayName: "Roasted Caramel Pork Rice Bowl",
      price: 13.79,
      quantity: 1,
      itemGroupId: "674cf641-b5e4-42c8-95bf-4a11a71fa485",
      tax: 1.29,
      subtotal: 15.08,
      itemId: "03655200-cdde-4817-98c4-e2f56edf684a",
      modifiers: [],
      restaurantName: "East Side Banh Mi",
      brandName: "East Side Banh Mi"
    },
    "Roasted Lemongrass Chicken Rice Bowl": {
      displayName: "Lemongrass Chicken Rice Bowl",
      price: 12.79,
      quantity: 1,
      itemGroupId: "870f00c3-618f-413d-a551-09f309e3d292",
      tax: 1.27,
      subtotal: 14.06,
      itemId: "85d72ef0-52d8-42f0-969a-f9684b32489d",
      modifiers: [],
      restaurantName: "East Side Banh Mi",
      brandName: "East Side Banh Mi"
    },
    "Chili Crisp Tofu & Roasted Eggplant Rice Bowl": {
      displayName: "Chili Crisp & Tofu Eggplant Rice Bowl",
      price: 12.79,
      quantity: 1,
      itemGroupId: "870f00c3-618f-413d-a551-09f309e3d292",
      tax: 1.27,
      subtotal: 14.06,
      itemId: "85d72ef0-52d8-42f0-969a-f9684b32489d",
      modifiers: [],
      restaurantName: "East Side Banh Mi",
      brandName: "East Side Banh Mi"
    },
    "No Protein Rice Bowl": {
      displayName: "Citrus Soy Rice Bowl",
      price: 9.79,
      quantity: 1,
      itemGroupId: "870f00c3-618f-413d-a551-09f309e3d292",
      tax: 1.03,
      subtotal: 10.82,
      itemId: "85d72ef0-52d8-42f0-969a-f9684b32489d",
      modifiers: [],
      restaurantName: "East Side Banh Mi",
      brandName: "East Side Banh Mi"
    },
  },
  "Salad": {
    "Large Israeli Salad": {
      "brandName": "Oren's Hummus",
      "displayName": "Large Israeli Salad",
      "itemGroupId": "6181229a-4aeb-4876-8ca9-317add8f13de",
      "itemId": "b2db79f3-45bf-46dc-a38f-e054bd2e5c57",
      "price": 12.00,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal": 12.00,
      "tax": 1.01,
    },
    "Beet & Goat Cheese Salad": {
      "brandName": "Oren's Hummus",
      "displayName": "Beet & Goat Cheese Salad",
      "itemGroupId": "6181229a-4aeb-4876-8ca9-317add8f13de",
      "itemId": "429a3d59-2640-4d6a-8343-5dc9267143da",
      "price": 14.95,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal": 14.95,
      "tax": 1.26,
    },
    "Fatoush Salad": {
      "brandName": "Oren's Hummus",
      "displayName": "Fattoush Salad",
      "itemGroupId": "6181229a-4aeb-4876-8ca9-317add8f13de",
      "itemId": "b2db79f3-45bf-46dc-a38f-e054bd2e5c57",
      "price": 16.48,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal": 16.48,
      "tax": 1.4,
    },
    "Roasted Lemongrass Chicken Salad Bowl": {
      displayName: "Lemongrass Chicken Salad",
      price: 12.79,
      quantity: 1,
      itemGroupId: "870f00c3-618f-413d-a551-09f309e3d292",
      tax: 1.22,
      subtotal: 14.21,
      itemId: "85d72ef0-52d8-42f0-969a-f9684b32489d",
      modifiers: [],
      restaurantName: "East Side Banh Mi",
      brandName: "East Side Banh Mi"
    },
    "Roasted Caramel Pork Salad Bowl": {
      displayName: "Roasted Caramel Pork Salad",
      price: 13.79,
      quantity: 1,
      itemGroupId: "870f00c3-618f-413d-a551-09f309e3d292",
      tax: 1.22,
      subtotal: 15.01,
      itemId: "85d72ef0-52d8-42f0-969a-f9684b32489d",
      modifiers: [],
      restaurantName: "East Side Banh Mi",
      brandName: "East Side Banh Mi"
    },
    "Chili Crisp Tofu & Roasted Eggplant Salad Bowl": {
      displayName: "Chili Crisp & Tofu Eggplant Salad",
      price: 12.79,
      quantity: 1,
      itemGroupId: "870f00c3-618f-413d-a551-09f309e3d292",
      tax: 1.22,
      subtotal: 14.01,
      itemId: "85d72ef0-52d8-42f0-969a-f9684b32489d",
      modifiers: [],
      restaurantName: "East Side Banh Mi",
      brandName: "East Side Banh Mi"
    },
    "No Protein Salad Bowl": {
      displayName: "Citrus Soy Salad",
      price: 9.79,
      quantity: 1,
      itemGroupId: "870f00c3-618f-413d-a551-09f309e3d292",
      tax: 1.03,
      subtotal: 10.82,
      itemId: "85d72ef0-52d8-42f0-969a-f9684b32489d",
      modifiers: [],
      restaurantName: "East Side Banh Mi",
      brandName: "East Side Banh Mi"
    },
  },
  "Extra White Pita": {
    "Extra White Pita": {
      "brandName": "Oren's Hummus",
      "displayName": "Extra White Pita",
      "itemGroupId": "620effda-741c-4fc8-92e6-2a349186024c",
      "itemId": "98663b35-5391-4d4d-a1d7-5be8d8038bcd",
      "itemGuid": "98663b35-5391-4d4d-a1d7-5be8d8038bcd",
      "price": 1.03,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal": 1.03,
      "tax": 1.58,
    }
  },
  "Extra Gluten Free White Pita": {
    "Extra Gluten Free White Pita": {
      "brandName": "Oren's Hummus",
      "displayName": "Extra Gluteen Free White Pita",
      "itemGroupId": "620effda-741c-4fc8-92e6-2a349186024c",
      "itemId": "98663b35-5391-4d4d-a1d7-5be8d8038bcd",
      "itemGuid": "98663b35-5391-4d4d-a1d7-5be8d8038bcd",
      "price": 2.06,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal": 2.06,
      "tax": 1.58,
    }
  },
  "Pita": {
    "Pita Beef Kebab": {
      "brandName": "Oren's Hummus",
      "displayName": "Pita Beef Kebab",
      "itemGroupId": "6181229a-4aeb-4876-8ca9-317add8f13de",
      "itemId": "b991f4e3-a3a3-470e-b712-20252b6a2ae4",
      "price": 17.51,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal": 17.51,
      "tax": 1.63,
    },  
    "Pita Chicken": {
      "brandName": "Oren's Hummus",
      "displayName": "Pita Chicken",
      "itemGroupId": "6181229a-4aeb-4876-8ca9-317add8f13de",
      "itemId": "2a53cd3a-72f0-4cba-8c31-bc162e877dfa",
      "price": 16.48,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal": 16.48,
      "tax": 1.54,
    },
    "Pita Falafel": {
      "brandName": "Oren's Hummus",
      "displayName": "Pita Falafel",
      "itemGroupId": "6181229a-4aeb-4876-8ca9-317add8f13de",
      "itemId": "051e681a-621a-4d80-8e02-ac9617bec0bb",
      "price": 14.42,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal": 14.42,
      "tax": 1.05,
    },
    "White Pita": {
      "brandName": "Oren's Hummus",
      "displayName": "White Pita",
      "itemGroupId": "620effda-741c-4fc8-92e6-2a349186024c",
      "itemId": "98663b35-5391-4d4d-a1d7-5be8d8038bcd",
      "itemGuid": "98663b35-5391-4d4d-a1d7-5be8d8038bcd",
      "price":  0,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal":  0,
      "tax": 0,
    },
    "Gluten Free Pita": {
      "brandName": "Oren's Hummus",
      "displayName": "Gluten Free Pita",
      "itemGroupId": "6181229a-4aeb-4876-8ca9-317add8f13de",
      "itemId": "1353bb71-79bb-4c42-b21b-446b4df0ac27",
      "itemGuid": "1353bb71-79bb-4c42-b21b-446b4df0ac27",
      "price":  1.85,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal":  0,
      "tax": 0,
    },    
  }, 
  "Hummus": {
    "Hummus Classic": {
      "brandName": "Oren's Hummus",
      "displayName": "Hummus Classic",
      "itemGroupId": "6181229a-4aeb-4876-8ca9-317add8f13de",
      "itemId": "18ebf6c8-4a41-4033-891c-80e1679edf83",
      "price": 11.33,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal": 11.33,
      "tax": 0.86,
    },  
    "Hummus with Moroccan Beef": {
      "brandName": "Oren's Hummus",
      "displayName": "Hummus with Moroccan Beef",
      "itemGroupId": "6181229a-4aeb-4876-8ca9-317add8f13de",
      "itemId": "339ccad1-8363-4233-9433-afb57f59d11b",
      "price": 17.00,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal": 17.00,
      "tax": 1.58,
    },  
    "Hummus Chicken": {
      "brandName": "Oren's Hummus",
      "displayName": "Hummus Chicken",
      "itemGroupId": "6181229a-4aeb-4876-8ca9-317add8f13de",
      "itemId": "01c0c5ec-b983-4c77-be6f-4ed847e0bb71",
      "price": 16.48,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal": 16.48,
      "tax": 1.54,
    },  
    "Hummus Eggplant": {
      "brandName": "Oren's Hummus",
      "displayName": "Hummus Eggplant",
      "itemGroupId": "6181229a-4aeb-4876-8ca9-317add8f13de",
      "itemId": "1ed672fe-fa9f-4e6a-b7e6-24311afb124f",
      "price": 14.42,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal": 14.42,
      "tax": 1.34,
    },  
    "Hummus Beets": {
      "brandName": "Oren's Hummus",
      "displayName": "Hummus Beets",
      "itemGroupId": "6181229a-4aeb-4876-8ca9-317add8f13de",
      "itemId": "01615c34-7899-4611-bd33-9d30205020ac",
      "price": 15.45,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal": 15.45,
      "tax": 1.34,
    },  
  }, 
  "Skewer": {
    "Chicken Skewer": {
      "brandName": "Oren's Hummus",
      "displayName": "Chicken Skewer",
      "itemGroupId": "6181229a-4aeb-4876-8ca9-317add8f13de",
      "itemId": "35b5211f-c770-4df2-a3d8-e22354bfcc66",
      "price": 23.69,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal": 23.69,
      "tax": 1.58,
    },
    "Beef Kebab Skewer": {
      "brandName": "Oren's Hummus",
      "displayName": "Beef Kebab Skewer",
      "itemGroupId": "6181229a-4aeb-4876-8ca9-317add8f13de",
      "itemId": "5938bc17-b522-4e74-b5d4-788f0301402d",
      "price": 24.72,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal": 24.72,
      "tax": 1.58,
    },
    "Chicken Skewer a la carte": {
      "brandName": "Oren's Hummus",
      "displayName": "Chicken Skewer a la carte",
      "itemGroupId": "6181229a-4aeb-4876-8ca9-317add8f13de",
      "itemId": "35b5211f-c770-4df2-a3d8-e22354bfcc66",
      "price": 23.69,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal": 23.69,
      "tax": 1.58,
    },
    "Beef Kebab Skewer a la carte": {
      "brandName": "Oren's Hummus",
      "displayName": "Beef Kebab Skewer a la carte",
      "itemGroupId": "6181229a-4aeb-4876-8ca9-317add8f13de",
      "itemId": "5938bc17-b522-4e74-b5d4-788f0301402d",
      "price": 14.42,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal": 14.42,
      "tax": 1.58,
    },
  },
  "Falafel": {
    "Falafel Balls": {
      "brandName": "Oren's Hummus",
      "displayName": "Green Herb Falafel (5 pieces)",
      "itemGroupId": "6181229a-4aeb-4876-8ca9-317add8f13de",
      "itemId": "a99a442d-ff3d-4382-84ab-c4f648478605",
      "price": 6.18,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal": 6.18,
      "tax": 1.58,
    }
  },
  "Dessert": {
    "Snickerdoodle": {
      "brandName": "The Pastry Cupboard",
      "displayName": "1 Cookie",
      "itemGroupId": "d35fa01c-6264-43a6-82d0-3397f631d74c",
      "itemId": "827901d7-4362-484c-a163-1b7801c06bee",
      "price": 3.50,
      "quantity": 1,
      "restaurantName": "Noshery",
      "modifiers": [
        {
          "displayName": "Snickerdoodle",
          "guid": "dca618e9-edae-4f17-b399-3fd6b303a280"
        }
      ],
      "subtotal": 3.50,
      "tax": 0.25,
    },
    "White Chocolate & Cranberry": {
      "brandName": "The Pastry Cupboard",
      "displayName": "1 Cookie",
      "itemGroupId": "d35fa01c-6264-43a6-82d0-3397f631d74c",
      "itemId": "efc6cbc2-3146-4179-81cb-7b445d302f48",
      "price": 3.50,
      "quantity": 1,
      "restaurantName": "Noshery",
      "modifiers": [
        {
          "displayName": "White Chocolate & Cranberry",
          "guid": "dca618e9-edae-4f17-b399-3fd6b303a280"
        }
      ],
      "subtotal": 3.50,
      "tax": 0.25,
    },
    "Chocolate Chunk": {
      "brandName": "The Pastry Cupboard",
      "displayName": "1 Cookie",
      "itemGroupId": "d35fa01c-6264-43a6-82d0-3397f631d74c",
      "itemId": "cf727dce-1499-40bc-a501-7c7aedf0ea7f",
      "price": 3.50,
      "quantity": 1,
      "modifiers": [
        {
          "displayName": "Chocolate Chip",
          "guid": "dca618e9-edae-4f17-b399-3fd6b303a280"
        }
      ],
      "restaurantName": "Noshery",
      "subtotal": 3.50,
      "tax": 0.25,
    },
    "Oatmeal Raisin": {
      "brandName": "The Pastry Cupboard",
      "displayName": "1 Cookie",
      "itemGroupId": "d35fa01c-6264-43a6-82d0-3397f631d74c",
      "itemId": "93d70a2c-c665-4a63-a4e5-a62207c23050",
      "price": 3.50,
      "quantity": 1,
      "modifiers": [
        {
          "displayName": "Oatmeal Raisin",
          "guid": "dca618e9-edae-4f17-b399-3fd6b303a280"
        }
      ],
      "restaurantName": "Noshery",
      "subtotal": 3.50,
      "tax": 0.25,
    }
  },
  "Burger": {
    "MeltBurger": {
      "brandName": "The Melt",
      "displayName": "MeltBurger",
      "itemGroupId": "d35fa01c-6264-43a6-82d0-3397f631d74c",
      "itemId": "853186fe-978c-440e-92af-1e25c75d4c39",
      "price": 10.49,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal": 10.49,
      "tax": 0.95,
    },
    "BBQ Bacon MeltBurger": {
      "brandName": "The Melt",
      "displayName": "BBQ Bacon MeltBurger",
      "itemGroupId": "d35fa01c-6264-43a6-82d0-3397f631d74c",
      "itemId": "ecaafb73-3271-4cb6-a462-295b45303019",
      "price": 12.99,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal": 12.99,
      "tax": 1.0,
    },
    "Double MeltBurger": {
      "brandName": "The Melt",
      "displayName": "Double MeltBurger",
      "itemGroupId": "d35fa01c-6264-43a6-82d0-3397f631d74c",
      "itemId": "853186fe-978c-440e-92af-1e25c75d4c39",
      "price": 16.27,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal": 16.27,
      "tax": 0.95,
    },
    "Double BBQ Bacon MeltBurger": {
      "brandName": "The Melt",
      "displayName": "Double BBQ Bacon MeltBurger",
      "itemGroupId": "d35fa01c-6264-43a6-82d0-3397f631d74c",
      "itemId": "ecaafb73-3271-4cb6-a462-295b45303019",
      "price": 18.27,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal": 18.27,
      "tax": 1.0,
    },
    "Impossible MeltBurger": {
      "brandName": "The Melt",
      "displayName": "Impossible MeltBurger",
      "itemGroupId": "d35fa01c-6264-43a6-82d0-3397f631d74c",
      "itemId": "e1506618-3e88-4d8a-b3a7-32eb569dc953",
      "price": 15.49,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal": 15.49,
      "tax": 1.7,
    },
    "Vegan BBQ Impossible Burger": {
      "brandName": "The Melt",
      "displayName": "Impossible MeltBurger",
      "itemGroupId": "d35fa01c-6264-43a6-82d0-3397f631d74c",
      "itemId": "e1506618-3e88-4d8a-b3a7-32eb569dc953",
      "price": 12.99,
      "quantity": 1,
      "modifiers": [
        {
          "displayName": "Add Smoked Bacon",
          "guid": "dca618e9-edae-4f17-b399-3fd6b303a280"
        }
      ],
      "restaurantName": "Noshery",
      "subtotal": 12.99,
      "tax": 1.7,
    },
  }, 
  "Fries": {
    "Fries": {
      "brandName": "The Melt",
      "displayName": "Fries",
      "itemGroupId": "d35fa01c-6264-43a6-82d0-3397f631d74c",
      "itemId": "cbad4aba-040d-4b4c-ac8b-bd7a27ca63e2",
      "price": 4.49,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal": 4.49,
      "tax": 1.7,
    },
    "Cheddar Bacon Fries": {
      "brandName": "The Melt",
      "displayName": "Cheddar Bacon Fries",
      "itemGroupId": "d35fa01c-6264-43a6-82d0-3397f631d74c",
      "itemId": "8fec5382-d0f7-40e8-9a06-43f16ce69a62",
      "price": 5.99,
      "modifiers": [],
      "quantity": 1,
      "restaurantName": "Noshery",
      "subtotal": 5.99,
      "tax": 0.46,
    },
    "Garlic Parmesan Fries": {
      "brandName": "The Melt",
      "displayName": "Garlic Parmesan Fries",
      "itemGroupId": "d35fa01c-6264-43a6-82d0-3397f631d74c",
      "itemId": "bfbec05e-c552-4ec7-b908-dd915a0965b9",
      "price": 5.49,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal": 5.49,
      "tax": 0.41,
    },
  },
  "Banh Mi": {
    "Pork Deluxe Banh Mi": {
      displayName: "Pork Deluxe Banh Mi",
      price: 12,
      quantity: 1,
      itemGroupId: "674cf641-b5e4-42c8-95bf-4a11a71fa485",
      tax: 1.13,
      subtotal: 13.13, 
      itemId: "96efabd6-e840-4414-bcba-919ded45a562",
      modifiers: [],
      restaurantName: "East Side Banh Mi",
      brandName: "East Side Banh Mi"
    }, 
    "Chicken Deluxe Banh Mi": {
      displayName: "Chicken Deluxe Banh Mi",
      price: 11.5,
      quantity: 1,
      itemGroupId: "b19af785-5ae4-44ff-a174-df644bb73db9",
      tax: 1.17,
      subtotal: 12.67,
      itemId: "38be25ec-799e-4285-9db3-246b02e90d82",
      modifiers: [],
      restaurantName: "East Side Banh Mi",
      brandName: "East Side Banh Mi"
    },
    "Pork Banh Mi": {
      displayName: "Pork Banh Mi",
      price: 11,
      quantity: 1,
      itemGroupId: "b19af785-5ae4-44ff-a174-df644bb73db9",
      tax: 1.08,
      subtotal: 11, 
      itemId: "3edc2b9f-5f4c-44c8-83f2-d99b545da8c3",
      modifiers: [],
      restaurantName: "East Side Banh Mi",
      brandName: "East Side Banh Mi"
    },
    "Chicken Banh Mi": {
      displayName: "Chicken Banh Mi",
      price: 10.5,
      quantity: 1,
      itemGroupId: "b19af785-5ae4-44ff-a174-df644bb73db9",
      tax: 1.08,
      subtotal: 11.13, 
      itemId: "3edc2b9f-5f4c-44c8-83f2-d99b545da8c3",
      modifiers: [],
      restaurantName: "East Side Banh Mi",
      brandName: "East Side Banh Mi"
    },
    "Vegan Banh Mi": {
      displayName: "Vegan Banh Mi",
      price: 10.5,
      quantity: 1,
      itemGroupId: "b19af785-5ae4-44ff-a174-df644bb73db9",
      tax: 1.03,
      subtotal: 11.5,
      itemId: "145e38ee-ccfe-46cd-9544-fe4d0a0c25e6",
      modifiers: [],
      restaurantName: "East Side Banh Mi",
      brandName: "East Side Banh Mi"
    },
  },
  "Naan Roll": {
    "Chicken Tikka Masala Naan Roll": {
      "brandName": "Kasa",
      "displayName": "Naan Roll",
      "itemGroupId": "d35fa01c-6264-43a6-82d0-3397f631d74c",
      "itemId": "cbad4aba-040d-4b4c-ac8b-bd7a27ca63e2",
      "price": 13,
      "quantity": 1,
      "modifiers": [{
        "displayName": "Chicken Tikka Masala",
        "guid": "dca618e9-edae-4f17-b399-3fd6b303a280"
      }],
      "restaurantName": "Noshery",
      "subtotal": 13,
      "tax": 1.7
    }
  },
  "Sandwich": {
    "Three Cheese Sandwich":  {
      "brandName": "The Melt",
      "displayName": "Three Cheese Classic",
      "itemGroupId": "d35fa01c-6264-43a6-82d0-3397f631d74c",
      "itemId": "853186fe-978c-440e-92af-1e25c75d4c39",
      "price": 8.99,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal": 8.99,
      "tax": 0.95,
    },
  },
  "Soup": {
    "Tomato Soup":  {
      "brandName": "The Melt",
      "displayName": "Tomato Soup",
      "itemGroupId": "d35fa01c-6264-43a6-82d0-3397f631d74c",
      "itemId": "853186fe-978c-440e-92af-1e25c75d4c39",
      "price": 4.99,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal": 4.99,
      "tax": 0.95,
    },
  },
  "Water": {
    "Sparkling Water":  {
      "brandName": "Beverages",
      "displayName": "Spindrift Water",
      "itemGroupId": "d35fa01c-6264-43a6-82d0-3397f631d74c",
      "itemId": "d61cd6c6-0fc0-4c9b-b090-1eeb538f6d10",
      "price": 3,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal": 3,
      "tax": 1.58,
    },
    "Bottled Water":  {
      "brandName": "Beverages",
      "displayName": "Bottle Water",
      "itemGroupId": "d35fa01c-6264-43a6-82d0-3397f631d74c",
      "itemId": "d61cd6c6-0fc0-4c9b-b090-1eeb538f6d10",
      "price": 1.5,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal": 1.5,
      "tax": 1.58,
    },
  },
  "Soda": {
    "Coke Soda":  {
      "brandName": "Beverages",
      "displayName": "Coke",
      "itemGroupId": "d35fa01c-6264-43a6-82d0-3397f631d74c",
      "itemId": "d61cd6c6-0fc0-4c9b-b090-1eeb538f6d10",
      "price": 2,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal": 2,
      "tax": 1.58,
    },
    "Thumbs Up Soda":  {
      "brandName": "Beverages",
      "displayName": "Thumbs Up",
      "itemGroupId": "d35fa01c-6264-43a6-82d0-3397f631d74c",
      "itemId": "d61cd6c6-0fc0-4c9b-b090-1eeb538f6d10",
      "price": 3,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal": 3,
      "tax": 1.58,
    }
  },
  "Cookie": {
    "12 Cookies":  {
      "brandName": "The Pastry Cupboard",
      "displayName": "12 Cookie",
      "itemGroupId": "d35fa01c-6264-43a6-82d0-3397f631d74c",
      "itemId": "827901d7-4362-484c-a163-1b7801c06bee",
      "price": 35,
      "quantity": 1,
      "restaurantName": "Noshery",
      "modifiers": [
        {
          "displayName": "Chocolate Chip",
          "guid": "dca618e9-edae-4f17-b399-3fd6b303a280"
        }
      ],
      "subtotal": 35,
      "tax": 0.25,
    },
    "1 Cookie": {
      "brandName": "The Pastry Cupboard",
      "displayName": "1 Cookie",
      "itemGroupId": "d35fa01c-6264-43a6-82d0-3397f631d74c",
      "itemId": "cf727dce-1499-40bc-a501-7c7aedf0ea7f",
      "price": 3.50,
      "quantity": 1,
      "modifiers": [
        {
          "displayName": "Chocolate Chip",
          "guid": "dca618e9-edae-4f17-b399-3fd6b303a280"
        }
      ],
      "restaurantName": "Noshery",
      "subtotal": 3.50,
      "tax": 0.25,
    },
  }
};

export const ezcater = {
  "Chicken Pita Sandwich": {
    "brandName": "Oren's Hummus",
    "displayName": "Pita Chicken",
    "itemGroupId": "6181229a-4aeb-4876-8ca9-317add8f13de",
    "itemId": "2a53cd3a-72f0-4cba-8c31-bc162e877dfa",
    "price": 16.00,
    "quantity": 1,
    "modifiers": [],
    "restaurantName": "Noshery",
    "subtotal": 16.00,
    "tax": 1.54,
  },
  "Falafel Pita Sandwich": {
    "brandName": "Oren's Hummus",
    "displayName": "Pita Falafel",
    "itemGroupId": "6181229a-4aeb-4876-8ca9-317add8f13de",
    "itemId": "051e681a-621a-4d80-8e02-ac9617bec0bb",
    "price": 14.00,
    "quantity": 1,
    "modifiers": [],
    "restaurantName": "Noshery",
    "subtotal": 14.00,
    "tax": 1.05,
  },
  "Chicken Rice Bowl": {
    "brandName": "Oren's Hummus",
    "displayName": "Orens Chicken Rice Bowl",
    "itemGroupId": "6181229a-4aeb-4876-8ca9-317add8f13de",
    "itemId": "705c7e52-abe3-48b2-82e3-4aa2d50ffee2",
    "price": 17.00,
    "quantity": 1,
    "modifiers": [],
    "restaurantName": "Noshery",
    "subtotal": 17.00,
    "tax": 1.63,
  },
  "Beef Kebab Rice Bowl": {
    "brandName": "Oren's Hummus",
    "displayName": "Orens Beef Kebab Rice Bowl",
    "itemGroupId": "6181229a-4aeb-4876-8ca9-317add8f13de",
    "itemId": "41ed2a1a-e894-4937-8e16-feddfec5d311",
    "price": 18.00,
    "quantity": 1,
    "modifiers": [],
    "restaurantName": "Noshery",
    "subtotal": 18.00,
    "tax": 1.63,
  },    
  "Beef Kebab Pita Sandwich":  {
    "brandName": "Oren's Hummus",
    "displayName": "Pita Beef Kebab",
    "itemGroupId": "6181229a-4aeb-4876-8ca9-317add8f13de",
    "itemId": "b991f4e3-a3a3-470e-b712-20252b6a2ae4",
    "price": 17.00,
    "quantity": 1,
    "modifiers": [],
    "restaurantName": "Noshery",
    "subtotal": 17.00,
    "tax": 1.63,
  },  
  "Hummus Eggplant Bowl": {
    "brandName": "Oren's Hummus",
    "displayName": "Hummus Eggplant",
    "itemGroupId": "6181229a-4aeb-4876-8ca9-317add8f13de",
    "itemId": "1ed672fe-fa9f-4e6a-b7e6-24311afb124f",
    "price": 14.00,
    "quantity": 1,
    "modifiers": [],
    "restaurantName": "Noshery",
    "subtotal": 14.00,
    "tax": 1.34,
  },  
  "Hummus w/ Moroccan Beef Bowl": {
    "brandName": "Oren's Hummus",
    "displayName": "Hummus  with Moroccan Beef",
    "itemGroupId": "6181229a-4aeb-4876-8ca9-317add8f13de",
    "itemId": "339ccad1-8363-4233-9433-afb57f59d11b",
    "price": 17.00,
    "quantity": 1,
    "modifiers": [],
    "restaurantName": "Noshery",
    "subtotal": 17.00,
    "tax": 1.58,
  },  
  "Hummus Classic Bowl": {
    "brandName": "Oren's Hummus",
    "displayName": "Hummus Classic",
    "itemGroupId": "6181229a-4aeb-4876-8ca9-317add8f13de",
    "itemId": "18ebf6c8-4a41-4033-891c-80e1679edf83",
    "price": 11.00,
    "quantity": 1,
    "modifiers": [],
    "restaurantName": "Noshery",
    "subtotal": 11.00,
    "tax": 0.86,
  },  
  "Hummus Chicken Bowl": {
    "brandName": "Oren's Hummus",
    "displayName": "Hummus Chicken",
    "itemGroupId": "6181229a-4aeb-4876-8ca9-317add8f13de",
    "itemId": "01c0c5ec-b983-4c77-be6f-4ed847e0bb71",
    "price": 16.00,
    "quantity": 1,
    "modifiers": [],
    "restaurantName": "Noshery",
    "subtotal": 16.00,
    "tax": 1.54,
  },  
  "Hummus Beets Bowl": {
    "brandName": "Oren's Hummus",
    "displayName": "Hummus Beets",
    "itemGroupId": "6181229a-4aeb-4876-8ca9-317add8f13de",
    "itemId": "01615c34-7899-4611-bd33-9d30205020ac",
    "price": 15.00,
    "quantity": 1,
    "modifiers": [],
    "restaurantName": "Noshery",
    "subtotal": 15.00,
    "tax": 1.34,
  },  
  "Beef Kebab": {
    "brandName": "Oren's Hummus",
    "displayName": "Beef Kebab a la Carte",
    "itemGroupId": "6181229a-4aeb-4876-8ca9-317add8f13de",
    "price": 14.42,
    "quantity": 1,
    "modifiers": [],
    "restaurantName": "Noshery",
    "subtotal": 14.42,
    "tax": 1.34,
  },  
  "White Pita": {
    "brandName": "Oren's Hummus",
    "displayName": "White Pita",
    "itemGroupId": "620effda-741c-4fc8-92e6-2a349186024c",
    "itemId": "98663b35-5391-4d4d-a1d7-5be8d8038bcd",
    "itemGuid": "98663b35-5391-4d4d-a1d7-5be8d8038bcd",
    "price":  0,
    "quantity": 1,
    "modifiers": [],
    "restaurantName": "Noshery",
    "subtotal":  0,
    "tax": 0,
  },
  "Gluten Free Pita": {
    "brandName": "Oren's Hummus",
    "displayName": "Gluten Free Pita",
    "itemGroupId": "6181229a-4aeb-4876-8ca9-317add8f13de",
    "itemId": "1353bb71-79bb-4c42-b21b-446b4df0ac27",
    "itemGuid": "1353bb71-79bb-4c42-b21b-446b4df0ac27",
    "price":  1.85,
    "quantity": 1,
    "modifiers": [],
    "restaurantName": "Noshery",
    "subtotal":  0,
    "tax": 0,
  },    
  "Pork Deluxe Banh Mi": {
    displayName: "Pork Deluxe Banh Mi",
    price: 12,
    quantity: 1,
    itemGroupId: "674cf641-b5e4-42c8-95bf-4a11a71fa485",
    tax: 1.13,
    subtotal: 12, 
    itemId: "96efabd6-e840-4414-bcba-919ded45a562",
    modifiers: [],
    restaurantName: "East Side Banh Mi",
    brandName: "East Side Banh Mi",
  }, 
  "Chicken Deluxe Banh Mi": {
    displayName: "Chicken Deluxe Banh Mi",
    price: 11.5,
    quantity: 1,
    itemGroupId: "b19af785-5ae4-44ff-a174-df644bb73db9",
    tax: 1.17,
    subtotal: 11.5,
    itemId: "38be25ec-799e-4285-9db3-246b02e90d82",
    modifiers: [],
    restaurantName: "East Side Banh Mi",
    brandName: "East Side Banh Mi"
  },
  "Pork Banh Mi": {
    displayName: "Pork Banh Mi",
    price: 11,
    quantity: 1,
    itemGroupId: "b19af785-5ae4-44ff-a174-df644bb73db9",
    tax: 1.08,
    subtotal: 11, 
    itemId: "3edc2b9f-5f4c-44c8-83f2-d99b545da8c3",
    modifiers: [],
    restaurantName: "East Side Banh Mi",
    brandName: "East Side Banh Mi"
  },
  "Chicken Banh Mi": {
    displayName: "Chicken Banh Mi",
    price: 10.5,
    quantity: 1,
    itemGroupId: "b19af785-5ae4-44ff-a174-df644bb73db9",
    tax: 1.08,
    subtotal: 10.05, 
    itemId: "3edc2b9f-5f4c-44c8-83f2-d99b545da8c3",
    modifiers: [],
    restaurantName: "East Side Banh Mi",
    brandName: "East Side Banh Mi"
  },
  "Vegan Banh Mi": {
    displayName: "Vegan Banh Mi",
    price: 11,
    quantity: 1,
    itemGroupId: "b19af785-5ae4-44ff-a174-df644bb73db9",
    tax: 1.03,
    subtotal: 11,
    itemId: "145e38ee-ccfe-46cd-9544-fe4d0a0c25e6",
    modifiers: [],
    restaurantName: "East Side Banh Mi",
    brandName: "East Side Banh Mi"
  },
  "Lemongrass Chicken Noodle Bowl": {
    displayName: "Lemongrass Chicken Noodle Bowl",
    price: 12.79,
    quantity: 1,
    itemGroupId: "870f00c3-618f-413d-a551-09f309e3d292",
    tax: 1.27,
    subtotal: 14.06,
    itemId: "85d72ef0-52d8-42f0-969a-f9684b32489d",
    modifiers: [],
    restaurantName: "East Side Banh Mi",
    brandName: "East Side Banh Mi"
  },
  "Roasted Caramel Pork Noodle Bowl": {
    displayName: "Roasted Caramel Pork Noodle Bowl",
    price: 13.79,
    quantity: 1,
    itemGroupId: "870f00c3-618f-413d-a551-09f309e3d292",
    tax: 1.37,
    subtotal: 15.16,
    itemId: "85d72ef0-52d8-42f0-969a-f9684b32489d",
    modifiers: [],
    restaurantName: "East Side Banh Mi",
    brandName: "East Side Banh Mi"
  },
  "Chili Crisp & Tofu Eggplant Noodle Bowl": {
    displayName: "Chili Crisp & Tofu Eggplant Noodle Bowl",
    price: 12.79,
    quantity: 1,
    itemGroupId: "870f00c3-618f-413d-a551-09f309e3d292",
    tax: 1.27,
    subtotal: 14.06,
    itemId: "85d72ef0-52d8-42f0-969a-f9684b32489d",
    modifiers: [],
    restaurantName: "East Side Banh Mi",
    brandName: "East Side Banh Mi"
  },
  "Citrus Soy Noodle Bowl": {
    displayName: "Citrus Soy Noodle Bowl",
    price: 9.79,
    quantity: 1,
    itemGroupId: "870f00c3-618f-413d-a551-09f309e3d292",
    tax: 1.03,
    subtotal: 10.82,
    itemId: "85d72ef0-52d8-42f0-969a-f9684b32489d",
    modifiers: [],
    restaurantName: "East Side Banh Mi",
    brandName: "East Side Banh Mi"
  },
  "Roasted Caramel Pork Rice Bowl": {
    displayName: "Roasted Caramel Pork Rice Bowl",
    price: 13.79,
    quantity: 1,
    itemGroupId: "674cf641-b5e4-42c8-95bf-4a11a71fa485",
    tax: 1.29,
    subtotal: 15.08,
    itemId: "03655200-cdde-4817-98c4-e2f56edf684a",
    modifiers: [],
    restaurantName: "East Side Banh Mi",
    brandName: "East Side Banh Mi"
  },
  "Lemongrass Chicken Rice Bowl": {
    displayName: "Leomongrass Chicken Rice Bowl",
    price: 12.79,
    quantity: 1,
    itemGroupId: "870f00c3-618f-413d-a551-09f309e3d292",
    tax: 1.27,
    subtotal: 14.06,
    itemId: "85d72ef0-52d8-42f0-969a-f9684b32489d",
    modifiers: [],
    restaurantName: "East Side Banh Mi",
    brandName: "East Side Banh Mi"
  },
  "Chili Crisp & Tofu Eggplant Rice Bowl": {
    displayName: "Chili Crisp & Tofu Eggplant Rice Bowl",
    price: 12.79,
    quantity: 1,
    itemGroupId: "870f00c3-618f-413d-a551-09f309e3d292",
    tax: 1.27,
    subtotal: 14.06,
    itemId: "85d72ef0-52d8-42f0-969a-f9684b32489d",
    modifiers: [],
    restaurantName: "East Side Banh Mi",
    brandName: "East Side Banh Mi"
  },
  "Citrus Soy Rice Bowl": {
    displayName: "Citrus Soy Rice Bowl",
    price: 9.79,
    quantity: 1,
    itemGroupId: "870f00c3-618f-413d-a551-09f309e3d292",
    tax: 1.03,
    subtotal: 10.82,
    itemId: "85d72ef0-52d8-42f0-969a-f9684b32489d",
    modifiers: [],
    restaurantName: "East Side Banh Mi",
    brandName: "East Side Banh Mi"
  },
  "Lemongrass Chicken Salad": {
    displayName: "Lemongrass Chicken Salad",
    price: 12.79,
    quantity: 1,
    itemGroupId: "870f00c3-618f-413d-a551-09f309e3d292",
    tax: 1.22,
    subtotal: 14.21,
    itemId: "85d72ef0-52d8-42f0-969a-f9684b32489d",
    modifiers: [],
    restaurantName: "East Side Banh Mi",
    brandName: "East Side Banh Mi"
  },
  "Roasted Caramel Pork Salad": {
    displayName: "Roasted Caramel Pork Salad",
    price: 13.79,
    quantity: 1,
    itemGroupId: "870f00c3-618f-413d-a551-09f309e3d292",
    tax: 1.22,
    subtotal: 15.01,
    itemId: "85d72ef0-52d8-42f0-969a-f9684b32489d",
    modifiers: [],
    restaurantName: "East Side Banh Mi",
    brandName: "East Side Banh Mi"
  },
  "Chili Crisp & Tofu Eggplant Salad": {
    displayName: "Chili Crisp & Tofu Eggplant Salad",
    price: 12.79,
    quantity: 1,
    itemGroupId: "870f00c3-618f-413d-a551-09f309e3d292",
    tax: 1.22,
    subtotal: 14.01,
    itemId: "85d72ef0-52d8-42f0-969a-f9684b32489d",
    modifiers: [],
    restaurantName: "East Side Banh Mi",
    brandName: "East Side Banh Mi"
  },
  "Citrus Soy Salad": {
    displayName: "Citrus Soy Salad",
    price: 9.79,
    quantity: 1,
    itemGroupId: "870f00c3-618f-413d-a551-09f309e3d292",
    tax: 1.03,
    subtotal: 10.82,
    itemId: "85d72ef0-52d8-42f0-969a-f9684b32489d",
    modifiers: [],
    restaurantName: "East Side Banh Mi",
    brandName: "East Side Banh Mi"
  },
  "12 Cookies":  {
    "brandName": "The Pastry Cupboard",
    "displayName": "12 Cookie",
    "itemGroupId": "d35fa01c-6264-43a6-82d0-3397f631d74c",
    "itemId": "827901d7-4362-484c-a163-1b7801c06bee",
    "price": 35,
    "quantity": 1,
    "restaurantName": "Noshery",
    "modifiers": [
      {
        "displayName": "Snickerdoodle",
        "guid": "dca618e9-edae-4f17-b399-3fd6b303a280"
      },
      {
        "displayName": "White Cranberry White Chocolate Chip",
        "guid": "dca618e9-edae-4f17-b399-3fd6b303a280"
      },
      {
        "displayName": "Chocolate Chip",
        "guid": "dca618e9-edae-4f17-b399-3fd6b303a280"
      }
    ],
    "subtotal": 35,
    "tax": 0.25,
  },
  "Israeli Salad":  {
    "brandName": "Oren's Hummus",
    "displayName": "Large Israeli Salad",
    "itemGroupId": "6181229a-4aeb-4876-8ca9-317add8f13de",
    "itemId": "b2db79f3-45bf-46dc-a38f-e054bd2e5c57",
    "price": 12.00,
    "quantity": 1,
    "modifiers": [],
    "restaurantName": "Noshery",
    "subtotal": 12.00,
    "tax": 1.01,
  },
  "Chicken Skewer": {
    "brandName": "Oren's Hummus",
    "displayName": "Chicken Skewer",
    "itemGroupId": "6181229a-4aeb-4876-8ca9-317add8f13de",
    "itemId": "35b5211f-c770-4df2-a3d8-e22354bfcc66",
    "price": 24.75,
    "quantity": 1,
    "modifiers": [],
    "restaurantName": "Noshery",
    "subtotal": 24.75,
    "tax": 1.58,
  },
  "Beef Kebab Skewer": {
    "brandName": "Oren's Hummus",
    "displayName": "Beef Kebab Skewer",
    "itemGroupId": "6181229a-4aeb-4876-8ca9-317add8f13de",
    "itemId": "5938bc17-b522-4e74-b5d4-788f0301402d",
    "price": 25.75,
    "quantity": 1,
    "modifiers": [],
    "restaurantName": "Noshery",
    "subtotal": 25.75,
    "tax": 1.58,
  },
  "Sparkling Water": {
    "brandName": "Beverages",
    "displayName": "Sparkling Water",
    "itemGroupId": "d35fa01c-6264-43a6-82d0-3397f631d74c",
    "itemId": "d61cd6c6-0fc0-4c9b-b090-1eeb538f6d10",
    "price": 2,
    "quantity": 1,
    "modifiers": [],
    "restaurantName": "Noshery",
    "subtotal": 2,
    "tax": 1.58,
  },
  "The Pastry Cupboard Cookies": {
    "brandName": "The Pastry Cupboard",
    "displayName": "1 Cookie",
    "itemGroupId": "d35fa01c-6264-43a6-82d0-3397f631d74c",
    "itemId": "efc6cbc2-3146-4179-81cb-7b445d302f48",
    "price": 3.50,
    "quantity": 1,
    "restaurantName": "Noshery",
    "modifiers": [
      {
        "displayName": "White Chocolate & Cranberry",
        "guid": "dca618e9-edae-4f17-b399-3fd6b303a280"
      }
    ],
    "subtotal": 3.50,
    "tax": 0.25,
  },
};

// "White Pita": {
//   "White Pita": {
//     "brandName": "Oren's Hummus",
//     "displayName": "White Pita",
//     "itemGroupId": "d35fa01c-6264-43a6-82d0-3397f631d74c",
//     "itemId": "d61cd6c6-0fc0-4c9b-b090-1eeb538f6d10",
//     "price": 1.03,
//     "quantity": 1,
//     "modifiers": [],
//     "restaurantName": "Noshery",
//     "subtotal": 1.03,
//     "tax": 1.58,
//   }
// },

export const draperMenu = {  
  "Pita": {
    "Pita Beef Kebab": {
      "brandName": "Oren's Hummus",
      "displayName": "Pita Beef Kebab",
      "itemGroupId": "6181229a-4aeb-4876-8ca9-317add8f13de",
      "itemId": "aab898e6-0847-4657-9a03-32ac8e07d19d",
      "price": 16.95,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal": 16.95,
      "tax": 1.63,
    },  
    "Pita Chicken": {
      "brandName": "Oren's Hummus",
      "displayName": "Pita Chicken",
      "itemGroupId": "6181229a-4aeb-4876-8ca9-317add8f13de",
      "itemId": "30666d4f-487a-4ae4-bbca-490c7bde1379",
      "price": 15.95,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal": 15.95,
      "tax": 1.54,
    },
    "Pita Falafel": {
      "brandName": "Oren's Hummus",
      "displayName": "Pita Falafel",
      "itemGroupId": "6181229a-4aeb-4876-8ca9-317add8f13de",
      "itemId": "5be1cefb-1c70-415b-a9b7-46ae56f30d92",
      "price": 10.95,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal": 10.95,
      "tax": 1.05,
    },
    "White Pita": {
      "brandName": "Oren's Hummus",
      "displayName": "White Pita",
      "itemGroupId": "6181229a-4aeb-4876-8ca9-317add8f13de",
      "itemId": "ef60d29f-cf44-4fd3-8a58-7aa24f32e3fb",
      "price":  0,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal":  0,
      "tax": 0,
    },
    "Gluten Free Pita": {
      "brandName": "Oren's Hummus",
      "displayName": "Gluten Free Pita",
      "itemGroupId": "6181229a-4aeb-4876-8ca9-317add8f13de",
      "itemId": "908b63ad-55d7-47b9-8995-79b5c9e85dfb",
      "price":  1.85,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal":  0,
      "tax": 0,
    },
  }, 
  "Banh Mi": {
    "Pork Deluxe Banh Mi": {
      displayName: "Pork Deluxe Banh Mi",
      price: 12,
      quantity: 1,
      itemGroupId: "674cf641-b5e4-42c8-95bf-4a11a71fa485",
      tax: 1.13,
      subtotal: 13.13, 
      itemId: "96efabd6-e840-4414-bcba-919ded45a562",
      modifiers: [],
      restaurantName: "East Side Banh Mi",
      brandName: "East Side Banh Mi"
    }, 
    "Chicken Deluxe Banh Mi": {
      displayName: "Chicken Deluxe Banh Mi",
      price: 11.5,
      quantity: 1,
      itemGroupId: "b19af785-5ae4-44ff-a174-df644bb73db9",
      tax: 1.17,
      subtotal: 12.67,
      itemId: "38be25ec-799e-4285-9db3-246b02e90d82",
      modifiers: [],
      restaurantName: "East Side Banh Mi",
      brandName: "East Side Banh Mi"
    },
    "Pork Banh Mi": {
      displayName: "Pork Banh Mi",
      price: 11,
      quantity: 1,
      itemGroupId: "b19af785-5ae4-44ff-a174-df644bb73db9",
      tax: 1.08,
      subtotal: 12.08, 
      itemId: "3edc2b9f-5f4c-44c8-83f2-d99b545da8c3",
      modifiers: [],
      restaurantName: "East Side Banh Mi",
      brandName: "East Side Banh Mi"
    },
    "Chicken Banh Mi": {
      displayName: "Chicken Banh Mi",
      price: 10.5,
      quantity: 1,
      itemGroupId: "b19af785-5ae4-44ff-a174-df644bb73db9",
      tax: 1.08,
      subtotal: 11.13, 
      itemId: "3edc2b9f-5f4c-44c8-83f2-d99b545da8c3",
      modifiers: [],
      restaurantName: "East Side Banh Mi",
      brandName: "East Side Banh Mi"
    },
    "Vegan Banh Mi": {
      displayName: "Vegan Banh Mi",
      price: 10.5,
      quantity: 1,
      itemGroupId: "b19af785-5ae4-44ff-a174-df644bb73db9",
      tax: 1.03,
      subtotal: 11.5,
      itemId: "145e38ee-ccfe-46cd-9544-fe4d0a0c25e6",
      modifiers: [],
      restaurantName: "East Side Banh Mi",
      brandName: "East Side Banh Mi"
    },
  },
  "Naan Roll": {
    "Chicken Tikka Masala Naan Roll": {
      "brandName": "Kasa",
      "displayName": "Naan Roll",
      "itemGroupId": "d35fa01c-6264-43a6-82d0-3397f631d74c",
      "itemId": "cbad4aba-040d-4b4c-ac8b-bd7a27ca63e2",
      "price": 13,
      "quantity": 1,
      "modifiers": [{
        "displayName": "Chicken Tikka Masala",
        "guid": "dca618e9-edae-4f17-b399-3fd6b303a280"
      }],
      "restaurantName": "Noshery",
      "subtotal": 13,
      "tax": 1.7
    }
  },
  "Burrito": {
    "Pollo Asado Burrito": {
      "brandName": "The Little Chihuahua",
      "displayName": "Classic Burrito",
      "itemGroupId": "c361a3d8-c27d-4af0-9eb9-c0ae6c66f5dd",
      "itemId": "0207c506-d9c8-49b5-8a71-dca2e6fe2a8b",
      "price": 12.0,
      "quantity": 1,
      "restaurantName": "The Little Chihuahua",
      "subtotal": 12.0,
      "tax": 1.4,
      "modifiers": [
        {
          "displayName": "Pollo Asado",
          "itemGuid": "dec8db70-4841-4b74-a18c-eef3d975b662"
        }
      ]
    },
    "Carne Asada Burrito": {
      "brandName": "The Little Chihuahua",
      "displayName": "Classic Burrito",
      "itemGroupId": "c361a3d8-c27d-4af0-9eb9-c0ae6c66f5dd",
      "itemId": "0207c506-d9c8-49b5-8a71-dca2e6fe2a8b",
      "price": 12.75,
      "quantity": 1,
      "restaurantName": "The Little Chihuahua",
      "subtotal": 12.75,
      "tax": 1.4,
      "modifiers": [
        {
          "displayName": "Carne Asada",
          "guid": "f8c9f3d3-a435-4387-9706-f9b0cf4e6ebc"
        }
      ]
    },
    "Fried Plantain & Black Bean Burrito": {
      "brandName": "The Little Chihuahua",
      "displayName": "Fried Plantain & Black Bean Burrito",
      "itemGroupId": "c361a3d8-c27d-4af0-9eb9-c0ae6c66f5dd",
      "itemId": "ad560a71-6396-43eb-8751-48de6bb4be8a",
      "price": 13.95,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "The Little Chihuahua",
      "subtotal": 13.95,
      "tax": 1.34
    },
    "Super Pollo Asado Burrito": {
      "brandName": "The Little Chihuahua",
      "displayName": "Classic Burrito",
      "itemGroupId": "c361a3d8-c27d-4af0-9eb9-c0ae6c66f5dd",
      "itemId": "0207c506-d9c8-49b5-8a71-dca2e6fe2a8b",
      "price": 14.0,
      "quantity": 1,
      "restaurantName": "The Little Chihuahua",
      "subtotal": 14.0,
      "tax": 1.4,
      "modifiers": [
        {
          "displayName": "Pollo Asado",
          "itemGuid": "dec8db70-4841-4b74-a18c-eef3d975b662"
        },
        {
          "displayName": "Make Super (guac, sour cream, cheese)",
          "guid": "dca618e9-edae-4f17-b399-3fd6b303a280"
        }
      ]
    },
    "Super Carne Asada Burrito": {
      "brandName": "The Little Chihuahua",
      "displayName": "Classic Burrito",
      "itemGroupId": "c361a3d8-c27d-4af0-9eb9-c0ae6c66f5dd",
      "itemId": "0207c506-d9c8-49b5-8a71-dca2e6fe2a8b",
      "price": 14.75,
      "quantity": 1,
      "restaurantName": "The Little Chihuahua",
      "subtotal": 14.75,
      "tax": 1.4,
      "modifiers": [
        {
          "displayName": "Carne Asada",
          "guid": "f8c9f3d3-a435-4387-9706-f9b0cf4e6ebc"
        },
        {
          "displayName": "Make Super (guac, sour cream, cheese)",
          "guid": "dca618e9-edae-4f17-b399-3fd6b303a280"
        }
      ]
    },
    "Super Plantain & Black Bean Burrito": {
      "brandName": "The Little Chihuahua",
      "displayName": "Fried Plantain & Black Bean Burrito",
      "itemGroupId": "c361a3d8-c27d-4af0-9eb9-c0ae6c66f5dd",
      "itemId": "ad560a71-6396-43eb-8751-48de6bb4be8a",
      "price": 15.95,
      "quantity": 1,
      "restaurantName": "The Little Chihuahua",
      "subtotal": 15.95,
      "tax": 1.34,
      "modifiers": [
        {
          "displayName": "Make Super (guac, sour cream, cheese)",
          "guid": "dca618e9-edae-4f17-b399-3fd6b303a280"
        }
      ]
    },
    "Gluten Free Plantain & Black Bean Burrito": {
      "brandName": "The Little Chihuahua",
      "displayName": "Fried Plantain & Black Bean Burrito",
      "itemGroupId": "c361a3d8-c27d-4af0-9eb9-c0ae6c66f5dd",
      "itemId": "ad560a71-6396-43eb-8751-48de6bb4be8a",
      "price": 15.45,
      "quantity": 1,
      "restaurantName": "The Little Chihuahua",
      "subtotal": 15.45,
      "tax": 1.34,
      "modifiers": [
        {
          "displayName": "Make Super (guac, sour cream, cheese)",
          "guid": "dca618e9-edae-4f17-b399-3fd6b303a280"
        }
      ]
    }
  },
};

export const CateringMenu2 = {
  "Burrito": {
    "Pollo Asado Burrito": {
      "brandName": "The Little Chihuahua",
      "displayName": "Classic Burrito",
      "itemGroupId": "c361a3d8-c27d-4af0-9eb9-c0ae6c66f5dd",
      "itemId": "0207c506-d9c8-49b5-8a71-dca2e6fe2a8b",
      "price": 12.0,
      "quantity": 1,
      "restaurantName": "The Little Chihuahua",
      "subtotal": 12.0,
      "tax": 1.4,
      "modifiers": [
        {
          "displayName": "Pollo Asado",
          "itemGuid": "dec8db70-4841-4b74-a18c-eef3d975b662"
        }
      ]
    },
    "Carne Asada Burrito": {
      "brandName": "The Little Chihuahua",
      "displayName": "Classic Burrito",
      "itemGroupId": "c361a3d8-c27d-4af0-9eb9-c0ae6c66f5dd",
      "itemId": "0207c506-d9c8-49b5-8a71-dca2e6fe2a8b",
      "price": 12.75,
      "quantity": 1,
      "restaurantName": "The Little Chihuahua",
      "subtotal": 12.75,
      "tax": 1.4,
      "modifiers": [
        {
          "displayName": "Carne Asada",
          "guid": "f8c9f3d3-a435-4387-9706-f9b0cf4e6ebc"
        }
      ]
    },
    "Fried Plantain & Black Bean Burrito": {
      "brandName": "The Little Chihuahua",
      "displayName": "Fried Plantain & Black Bean Burrito",
      "itemGroupId": "c361a3d8-c27d-4af0-9eb9-c0ae6c66f5dd",
      "itemId": "ad560a71-6396-43eb-8751-48de6bb4be8a",
      "price": 13.95,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "The Little Chihuahua",
      "subtotal": 13.95,
      "tax": 1.34
    },
    "Super Burrito - Pollo Asado": {
      "brandName": "The Little Chihuahua",
      "displayName": "Classic Burrito",
      "itemGroupId": "c361a3d8-c27d-4af0-9eb9-c0ae6c66f5dd",
      "itemId": "0207c506-d9c8-49b5-8a71-dca2e6fe2a8b",
      "price": 14.0,
      "quantity": 1,
      "restaurantName": "The Little Chihuahua",
      "subtotal": 14.0,
      "tax": 1.4,
      "modifiers": [
        {
          "displayName": "Pollo Asado",
          "itemGuid": "dec8db70-4841-4b74-a18c-eef3d975b662"
        },
        {
          "displayName": "Make Super (guac, sour cream, cheese)",
          "guid": "dca618e9-edae-4f17-b399-3fd6b303a280"
        }
      ]
    },
    "Super Burrito - Carne Asada": {
      "brandName": "The Little Chihuahua",
      "displayName": "Classic Burrito",
      "itemGroupId": "c361a3d8-c27d-4af0-9eb9-c0ae6c66f5dd",
      "itemId": "0207c506-d9c8-49b5-8a71-dca2e6fe2a8b",
      "price": 14.75,
      "quantity": 1,
      "restaurantName": "The Little Chihuahua",
      "subtotal": 14.75,
      "tax": 1.4,
      "modifiers": [
        {
          "displayName": "Carne Asada",
          "guid": "f8c9f3d3-a435-4387-9706-f9b0cf4e6ebc"
        },
        {
          "displayName": "Make Super (guac, sour cream, cheese)",
          "guid": "dca618e9-edae-4f17-b399-3fd6b303a280"
        }
      ]
    },
    "Super  Burrito - Fried Plantain & Black Bean": {
      "brandName": "The Little Chihuahua",
      "displayName": "Fried Plantain & Black Bean Burrito",
      "itemGroupId": "c361a3d8-c27d-4af0-9eb9-c0ae6c66f5dd",
      "itemId": "ad560a71-6396-43eb-8751-48de6bb4be8a",
      "price": 15.95,
      "quantity": 1,
      "restaurantName": "The Little Chihuahua",
      "subtotal": 15.95,
      "tax": 1.34,
      "modifiers": [
        {
          "displayName": "Make Super (guac, sour cream, cheese)",
          "guid": "dca618e9-edae-4f17-b399-3fd6b303a280"
        }
      ]
    }
  },
  "Taco": {
    "Classic Carne Asada Taco": {
      "brandName": "The Little Chihuahua",
      "displayName": "Classic Tacos (2)",
      "itemGroupId": "c361a3d8-c27d-4af0-9eb9-c0ae6c66f5dd",
      "itemId": "8a2a65c5-0ec1-4e21-a15d-b07360f0421e",
      "price": 10.75,
      "quantity": 1,
      "restaurantName": "The Little Chihuahua",
      "subtotal": 10.75,
      "tax": 0.95,
      "modifiers": [
        {
          "displayName": "Carne Asada",
          "guid": "f8c9f3d3-a435-4387-9706-f9b0cf4e6ebc"
        }
      ]
    },
    "Classic Pollo Asado Taco": {
      "brandName": "The Little Chihuahua",
      "displayName": "Classic Tacos (2)",
      "itemGroupId": "c361a3d8-c27d-4af0-9eb9-c0ae6c66f5dd",
      "itemId": "8a2a65c5-0ec1-4e21-a15d-b07360f0421e",
      "price": 10,
      "quantity": 1,
      "restaurantName": "The Little Chihuahua",
      "subtotal": 10,
      "tax": 0.95,
      "modifiers": [
        {
          "displayName": "Pollo Asado",
          "itemGuid": "dec8db70-4841-4b74-a18c-eef3d975b662"
        }
      ]
    },
    "Fried Plantain & Black Bean Tacos": {
      "brandName": "The Little Chihuahua",
      "displayName": "Fried Plantain & Black Bean Tacos (2)",
      "itemGroupId": "c361a3d8-c27d-4af0-9eb9-c0ae6c66f5dd",
      "itemId": "8a2a65c5-0ec1-4e21-a15d-b07360f0421e",
      "price": 11,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "The Little Chihuahua",
      "subtotal": 11,
      "tax": 0.95
    },
    "Super Taco - Carne Asada": {
      "brandName": "THe Little Chihuahua",
      "displayName": "Classic Tacos (2)",
      "itemGroupId": "c361a3d8-c27d-4af0-9eb9-c0ae6c66f5dd",
      "itemId": "8a2a65c5-0ec1-4e21-a15d-b07360f0421e",
      "price": 11.75,
      "quantity": 1,
      "restaurantName": "The Little Chihuahua",
      "subtotal": 11.75,
      "tax": 0.95,
      "modifiers": [
        {
          "displayName": "Carne Asada",
          "guid": "f8c9f3d3-a435-4387-9706-f9b0cf4e6ebc"
        },
        {
          "displayName": "Make Super (guac, sour cream, cheese)",
          "guid": "dca618e9-edae-4f17-b399-3fd6b303a280"
        }
      ]
    },
    "Super Taco - Pollo Asado": {
      "brandName": "The Little Chihuahua",
      "displayName": "Classic Tacos (2)",
      "itemGroupId": "c361a3d8-c27d-4af0-9eb9-c0ae6c66f5dd",
      "itemId": "8a2a65c5-0ec1-4e21-a15d-b07360f0421e",
      "price": 11,
      "quantity": 1,
      "restaurantName": "The Little Chihuahua",
      "subtotal": 11,
      "tax": 0.95,
      "modifiers": [
        {
          "displayName": "Pollo Asado",
          "itemGuid": "dec8db70-4841-4b74-a18c-eef3d975b662"
        },
        {
          "displayName": "Make Super (guac, sour cream, cheese)",
          "guid": "dca618e9-edae-4f17-b399-3fd6b303a280"
        }
      ]
    },
    "Super Taco - Fried Plantain & Black Bean": {
      "brandName": "The Little Chihuahua",
      "displayName": "Fried Plantain & Black Bean Tacos (2)",
      "itemGroupId": "c361a3d8-c27d-4af0-9eb9-c0ae6c66f5dd",
      "itemId": "699c2e28-9784-4416-a662-a9b3795d2742",
      "price": 12,
      "quantity": 1,
      "restaurantName": "The Little Chihuahua",
      "subtotal": 12,
      "tax": 0.95,
      "modifiers": [
        {
          "displayName": "Make Super (guac, sour cream, cheese)",
          "guid": "dca618e9-edae-4f17-b399-3fd6b303a280"
        }
      ]
    },
    "Vegan Plantain & Black Bean Tacos": {
      "brandName": "The Little Chihuahua",
      "displayName": "Fried Plantain & Black Bean Tacos (2)",
      "itemGroupId": "c361a3d8-c27d-4af0-9eb9-c0ae6c66f5dd",
      "itemId": "699c2e28-9784-4416-a662-a9b3795d2742",
      "price": 12,
      "quantity": 1,
      "restaurantName": "The Little Chihuahua",
      "subtotal": 12,
      "tax": 0.95,
      "modifiers": [
        {
          "displayName": "Gluten free",
          "guid": "dca618e9-edae-4f17-b399-3fd6b303a280"
        }
      ]
    }
  },
  "Bowl": {
    "Pollo Asado Bowl": {
      "brandName": "The Little Chihuahua",
      "displayName": "Classic Rice Bowls",
      "itemGroupId": "d35fa01c-6264-43a6-82d0-3397f631d74c",
      "itemId": "20f069fc-b22c-4c6b-840b-eac088392776",
      "price": 12,
      "quantity": 1,
      "restaurantName": "Noshery",
      "subtotal": 12,
      "tax": 1.36,
      "modifiers": [
        {
          "displayName": "Pollo Asado",
          "itemGuid": "dec8db70-4841-4b74-a18c-eef3d975b662"
        }
      ]
    },
    "Carne Asada Bowl": {
      "brandName": "The Little Chihuahua",
      "displayName": "Classic Rice Bowls",
      "itemGroupId": "d35fa01c-6264-43a6-82d0-3397f631d74c",
      "itemId": "20f069fc-b22c-4c6b-840b-eac088392776",
      "price": 12.75,
      "quantity": 1,
      "restaurantName": "Noshery",
      "subtotal": 12.75,
      "tax": 1.36,
      "modifiers": [
        {
          "displayName": "Pollo Asado",
          "itemGuid": "dec8db70-4841-4b74-a18c-eef3d975b662"
        }
      ]
    },
    "Fried Plantain & Black Bean Rice Bowl": {
      "brandName": "The Little Chihuahua",
      "displayName": "Fried Plantain & Black Bean Rice Bowl",
      "itemGroupId": "d35fa01c-6264-43a6-82d0-3397f631d74c",
      "itemId": "20f069fc-b22c-4c6b-840b-eac088392776",
      "price": 13.95,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal": 13.95,
      "tax": 1.36
    },
    "Super Pollo Asado Bowl": {
      "brandName": "The Little Chihuahua",
      "displayName": "Classic Rice Bowls",
      "itemGroupId": "d35fa01c-6264-43a6-82d0-3397f631d74c",
      "itemId": "20f069fc-b22c-4c6b-840b-eac088392776",
      "price": 14,
      "quantity": 1,
      "restaurantName": "Noshery",
      "subtotal": 14,
      "tax": 1.36,
      "modifiers": [
        {
          "displayName": "Pollo Asado",
          "itemGuid": "dec8db70-4841-4b74-a18c-eef3d975b662"
        },
        {
          "displayName": "Make Super (guac, sour cream, cheese)",
          "guid": "dca618e9-edae-4f17-b399-3fd6b303a280"
        }
      ]
    },
    "Super Carne Asada Bowl": {
      "brandName": "The Little Chihuahua",
      "displayName": "Classic Rice Bowls",
      "itemGroupId": "d35fa01c-6264-43a6-82d0-3397f631d74c",
      "itemId": "20f069fc-b22c-4c6b-840b-eac088392776",
      "price": 14.75,
      "quantity": 1,
      "restaurantName": "Noshery",
      "subtotal": 14.75,
      "tax": 1.36,
      "modifiers": [
        {
          "displayName": "Pollo Asado",
          "itemGuid": "dec8db70-4841-4b74-a18c-eef3d975b662"
        },
        {
          "displayName": "Make Super (guac, sour cream, cheese)",
          "guid": "dca618e9-edae-4f17-b399-3fd6b303a280"
        }
      ]
    },
    "Super Fried Plantain & Black Bean Rice Bowl": {
      "brandName": "The Little Chihuahua",
      "displayName": "Fried Plantain & Black Bean Rice Bowl",
      "itemGroupId": "d35fa01c-6264-43a6-82d0-3397f631d74c",
      "itemId": "20f069fc-b22c-4c6b-840b-eac088392776",
      "price": 15.95,
      "quantity": 1,
      "restaurantName": "Noshery",
      "subtotal": 15.95,
      "tax": 1.36,
      "modifiers": [
        {
          "displayName": "Make Super (guac, sour cream, cheese)",
          "guid": "dca618e9-edae-4f17-b399-3fd6b303a280"
        }
      ]
    },
    "Chicken Rice Bowl": {
      "brandName": "Oren's Hummus",
      "displayName": "Orens Chicken Rice Bowl",
      "itemGroupId": "6181229a-4aeb-4876-8ca9-317add8f13de",
      "itemId": "31369d15-2cb1-4229-b416-ee450bc2698e",
      "price": 15.95,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal": 15.95,
      "tax": 1.63,
    },
    "Beef Kebab Rice Bowl": {
      "brandName": "Oren's Hummus",
      "displayName": "Beef Kebab Rice Bowl",
      "itemGroupId": "6181229a-4aeb-4876-8ca9-317add8f13de",
      "itemId": "80d4eb94-7c93-45b6-b238-16c41b74fe39",
      "price": 16.95,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal": 16.95,
      "tax": 1.63,
    }, 
    "Roasted Lemongrass Chicken Noodle Bowl": {
      displayName: "Lemongrass Chicken Noddle Bowl",
      price: 12.79,
      quantity: 1,
      itemGroupId: "870f00c3-618f-413d-a551-09f309e3d292",
      tax: 1.27,
      subtotal: 14.06,
      itemId: "85d72ef0-52d8-42f0-969a-f9684b32489d",
      modifiers: [],
      restaurantName: "East Side Banh Mi",
      brandName: "East Side Banh Mi"
    },
    "Roasted Caramel Pork Noodle Bowl": {
      displayName: "Roasted Caramel Pork Noodle Bowl",
      price: 13.79,
      quantity: 1,
      itemGroupId: "870f00c3-618f-413d-a551-09f309e3d292",
      tax: 1.37,
      subtotal: 15.16,
      itemId: "85d72ef0-52d8-42f0-969a-f9684b32489d",
      modifiers: [],
      restaurantName: "East Side Banh Mi",
      brandName: "East Side Banh Mi"
    },
    "Chili Crips Tofu & Roasted Egglplant Noddle Bowl": {
      displayName: "Chili Crips & Tofu Egglplant Noddle Bowl",
      price: 12.79,
      quantity: 1,
      itemGroupId: "870f00c3-618f-413d-a551-09f309e3d292",
      tax: 1.27,
      subtotal: 14.06,
      itemId: "85d72ef0-52d8-42f0-969a-f9684b32489d",
      modifiers: [],
      restaurantName: "East Side Banh Mi",
      brandName: "East Side Banh Mi"
    },
    "No Protein Noddle Bowl": {
      displayName: "Citrus Soy Noddle Bowl",
      price: 12.79,
      quantity: 1,
      itemGroupId: "870f00c3-618f-413d-a551-09f309e3d292",
      tax: 1.03,
      subtotal: 13.82,
      itemId: "85d72ef0-52d8-42f0-969a-f9684b32489d",
      modifiers: [],
      restaurantName: "East Side Banh Mi",
      brandName: "East Side Banh Mi"
    },
    "Roasted Caramel Pork Rice Bowl": {
      displayName: "Roasted Caramel Pork Rice Bowl",
      price: 13.79,
      quantity: 1,
      itemGroupId: "674cf641-b5e4-42c8-95bf-4a11a71fa485",
      tax: 1.29,
      subtotal: 15.08,
      itemId: "03655200-cdde-4817-98c4-e2f56edf684a",
      modifiers: [],
      restaurantName: "East Side Banh Mi",
      brandName: "East Side Banh Mi"
    },
    "Roasted Lemongrass Chicken Rice Bowl": {
      displayName: "Lemongrass Chicken Rice Bowl",
      price: 12.79,
      quantity: 1,
      itemGroupId: "870f00c3-618f-413d-a551-09f309e3d292",
      tax: 1.27,
      subtotal: 14.06,
      itemId: "85d72ef0-52d8-42f0-969a-f9684b32489d",
      modifiers: [],
      restaurantName: "East Side Banh Mi",
      brandName: "East Side Banh Mi"
    },
    "Chili Crisp Tofu & Roasted Eggplant Rice Bowl": {
      displayName: "Chili Crisp & Tofu Eggplant Rice Bowl",
      price: 12.79,
      quantity: 1,
      itemGroupId: "870f00c3-618f-413d-a551-09f309e3d292",
      tax: 1.27,
      subtotal: 14.06,
      itemId: "85d72ef0-52d8-42f0-969a-f9684b32489d",
      modifiers: [],
      restaurantName: "East Side Banh Mi",
      brandName: "East Side Banh Mi"
    },
    "No Protein Rice Bowl": {
      displayName: "Citrus Soy Rice Bowl",
      price: 9.79,
      quantity: 1,
      itemGroupId: "870f00c3-618f-413d-a551-09f309e3d292",
      tax: 1.03,
      subtotal: 10.82,
      itemId: "85d72ef0-52d8-42f0-969a-f9684b32489d",
      modifiers: [],
      restaurantName: "East Side Banh Mi",
      brandName: "East Side Banh Mi"
    },
  },
  "Salad": {
    "Large Israeli Salad": {
      "brandName": "Oren's Hummus",
      "displayName": "Large Israeli Salad",
      "itemGroupId": "6181229a-4aeb-4876-8ca9-317add8f13de",
      "itemId": "20f069fc-b22c-4c6b-840b-eac088392776",
      "price": 10,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal": 10,
      "tax": 1.01,
    },
    "Beet & Goat Cheese Salad": {
      "brandName": "Oren's Hummus",
      "displayName": "Beet & Goat Cheese Salad",
      "itemGroupId": "6181229a-4aeb-4876-8ca9-317add8f13de",
      "itemId": "429a3d59-2640-4d6a-8343-5dc9267143da",
      "price": 14.95,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal": 14.95,
      "tax": 1.26,
    },
    "Fatoush Salad": {
      "brandName": "Oren's Hummus",
      "displayName": "Fattoush Salad",
      "itemGroupId": "6181229a-4aeb-4876-8ca9-317add8f13de",
      "itemId": "a4cdf385-f39e-4f68-830f-2723445f9c2b",
      "price": 14.5,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal": 14.5,
      "tax": 1.4,
    },
    "Roasted Lemongrass Chicken Salad": {
      displayName: "Lemongrass Chicken Salad",
      price: 12.99,
      quantity: 1,
      itemGroupId: "870f00c3-618f-413d-a551-09f309e3d292",
      tax: 1.22,
      subtotal: 14.21,
      itemId: "85d72ef0-52d8-42f0-969a-f9684b32489d",
      modifiers: [],
      restaurantName: "East Side Banh Mi",
      brandName: "East Side Banh Mi"
    },
    "Roasted Caramel Pork Salad": {
      displayName: "Roasted Caramel Pork Salad",
      price: 13.79,
      quantity: 1,
      itemGroupId: "870f00c3-618f-413d-a551-09f309e3d292",
      tax: 1.22,
      subtotal: 15.01,
      itemId: "85d72ef0-52d8-42f0-969a-f9684b32489d",
      modifiers: [],
      restaurantName: "East Side Banh Mi",
      brandName: "East Side Banh Mi"
    },
    "Chili Crisp Tofu & Roasted Eggplant Rice Bowl": {
      displayName: "Chili Crisp & Tofu Eggplant Salad",
      price: 12.79,
      quantity: 1,
      itemGroupId: "870f00c3-618f-413d-a551-09f309e3d292",
      tax: 1.22,
      subtotal: 14.01,
      itemId: "85d72ef0-52d8-42f0-969a-f9684b32489d",
      modifiers: [],
      restaurantName: "East Side Banh Mi",
      brandName: "East Side Banh Mi"
    },
    "No Protein Salad": {
      displayName: "Citrus Soy Salad",
      price: 9.79,
      quantity: 1,
      itemGroupId: "870f00c3-618f-413d-a551-09f309e3d292",
      tax: 1.03,
      subtotal: 10.82,
      itemId: "85d72ef0-52d8-42f0-969a-f9684b32489d",
      modifiers: [],
      restaurantName: "East Side Banh Mi",
      brandName: "East Side Banh Mi"
    },
  },
  "Pita": {
    "Pita Beef Kebab": {
      "brandName": "Oren's Hummus",
      "displayName": "Pita Beef Kebab",
      "itemGroupId": "6181229a-4aeb-4876-8ca9-317add8f13de",
      "itemId": "aab898e6-0847-4657-9a03-32ac8e07d19d",
      "price": 16.95,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal": 16.95,
      "tax": 1.63,
    },  
    "Pita Chicken": {
      "brandName": "Oren's Hummus",
      "displayName": "Pita Chicken",
      "itemGroupId": "6181229a-4aeb-4876-8ca9-317add8f13de",
      "itemId": "30666d4f-487a-4ae4-bbca-490c7bde1379",
      "price": 15.95,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal": 15.95,
      "tax": 1.54,
    },
    "Pita Falafel": {
      "brandName": "Oren's Hummus",
      "displayName": "Pita Falafel",
      "itemGroupId": "6181229a-4aeb-4876-8ca9-317add8f13de",
      "itemId": "5be1cefb-1c70-415b-a9b7-46ae56f30d92",
      "price": 10.95,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal": 10.95,
      "tax": 1.05,
    },
    "White Pita": {
      "brandName": "Oren's Hummus",
      "displayName": "White Pita",
      "itemGroupId": "6181229a-4aeb-4876-8ca9-317add8f13de",
      "itemId": "ef60d29f-cf44-4fd3-8a58-7aa24f32e3fb",
      "price":  0,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal":  0,
      "tax": 0,
    },
    "Gluten Free Pita": {
      "brandName": "Oren's Hummus",
      "displayName": "Gluten Free Pita",
      "itemGroupId": "6181229a-4aeb-4876-8ca9-317add8f13de",
      "itemId": "908b63ad-55d7-47b9-8995-79b5c9e85dfb",
      "price":  0,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal":  0,
      "tax": 0,
    },
  }, 
  "Hummus": {
    "Hummus Classic": {
      "brandName": "Oren's Hummus",
      "displayName": "Hummus Classic",
      "itemGroupId": "6181229a-4aeb-4876-8ca9-317add8f13de",
      "itemId": "f1b4561d-06db-496c-93cc-f34373419b09",
      "price": 8.95,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal": 8.95,
      "tax": 0.86,
    },  
    "Hummus with Moroccan Beef": {
      "brandName": "Oren's Hummus",
      "displayName": "Hummus  with Moroccan Beef",
      "itemGroupId": "6181229a-4aeb-4876-8ca9-317add8f13de",
      "itemId": "d61cd6c6-0fc0-4c9b-b090-1eeb538f6d10",
      "price": 16.50,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal": 16.50,
      "tax": 1.58,
    },  
    "Hummus Chicken": {
      "brandName": "Oren's Hummus",
      "displayName": "Hummus Chicken",
      "itemGroupId": "6181229a-4aeb-4876-8ca9-317add8f13de",
      "itemId": "59f2a547-cdbd-4f76-aead-779d2a659faf",
      "price": 16.00,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal": 16.00,
      "tax": 1.54,
    },  
    "Hummus Eggplant": {
      "brandName": "Oren's Hummus",
      "displayName": "Hummus Eggplant",
      "itemGroupId": "6181229a-4aeb-4876-8ca9-317add8f13de",
      "itemId": "d1a680f9-50b1-4cc4-8c36-54da153117f4",
      "price": 13.95,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal": 13.95,
      "tax": 1.34,
    },  
    "Hummus Beets": {
      "brandName": "Oren's Hummus",
      "displayName": "Hummus Beets",
      "itemGroupId": "6181229a-4aeb-4876-8ca9-317add8f13de",
      "itemId": "1d0cbf49-b429-40d8-befd-77dea7b142fb",
      "price": 13.95,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal": 13.95,
      "tax": 1.34,
    },  
  }, 
  "Dessert": {
    "Snickerdoodle": {
      "brandName": "The Pastry Cupboard",
      "displayName": "1 Cookie",
      "itemGroupId": "d35fa01c-6264-43a6-82d0-3397f631d74c",
      "itemId": "827901d7-4362-484c-a163-1b7801c06bee",
      "price": 3.50,
      "quantity": 1,
      "restaurantName": "Noshery",
      "modifiers": [
        {
          "displayName": "Snickerdoodle",
          "guid": "dca618e9-edae-4f17-b399-3fd6b303a280"
        }
      ],
      "subtotal": 3.50,
      "tax": 0.25,
    },
    "White Chocolate & Cranberry": {
      "brandName": "The Pastry Cupboard",
      "displayName": "1 Cookie",
      "itemGroupId": "d35fa01c-6264-43a6-82d0-3397f631d74c",
      "itemId": "efc6cbc2-3146-4179-81cb-7b445d302f48",
      "price": 3.50,
      "quantity": 1,
      "restaurantName": "Noshery",
      "modifiers": [
        {
          "displayName": "White Chocolate & Cranberry",
          "guid": "dca618e9-edae-4f17-b399-3fd6b303a280"
        }
      ],
      "subtotal": 3.50,
      "tax": 0.25,
    },
    "Chocolate Chunk": {
      "brandName": "The Pastry Cupboard",
      "displayName": "1 Cookie",
      "itemGroupId": "d35fa01c-6264-43a6-82d0-3397f631d74c",
      "itemId": "cf727dce-1499-40bc-a501-7c7aedf0ea7f",
      "price": 3.50,
      "quantity": 1,
      "modifiers": [
        {
          "displayName": "Chocolate Chip",
          "guid": "dca618e9-edae-4f17-b399-3fd6b303a280"
        }
      ],
      "restaurantName": "Noshery",
      "subtotal": 3.50,
      "tax": 0.25,
    },
    "Oatmeal Raisin": {
      "brandName": "The Pastry Cupboard",
      "displayName": "1 Cookie",
      "itemGroupId": "d35fa01c-6264-43a6-82d0-3397f631d74c",
      "itemId": "93d70a2c-c665-4a63-a4e5-a62207c23050",
      "price": 3.50,
      "quantity": 1,
      "modifiers": [
        {
          "displayName": "Oatmeal Raisin",
          "guid": "dca618e9-edae-4f17-b399-3fd6b303a280"
        }
      ],
      "restaurantName": "Noshery",
      "subtotal": 3.50,
      "tax": 0.25,
    }
  },
  "Burger": {
    "MeltBurger": {
      "brandName": "The Melt",
      "displayName": "MeltBurger",
      "itemGroupId": "d35fa01c-6264-43a6-82d0-3397f631d74c",
      "itemId": "853186fe-978c-440e-92af-1e25c75d4c39",
      "price": 8.29,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal": 8.29,
      "tax": 0.95,
    },
    "BBQ Bacon MeltBurger": {
      "brandName": "The Melt",
      "displayName": "BBQ Bacon MeltBurger",
      "itemGroupId": "d35fa01c-6264-43a6-82d0-3397f631d74c",
      "itemId": "ecaafb73-3271-4cb6-a462-295b45303019",
      "price": 10.29,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal": 10.29,
      "tax": 1.0,
    },
    "Impossible MeltBurger": {
      "brandName": "The Melt",
      "displayName": "Impossible MeltBurger",
      "itemGroupId": "d35fa01c-6264-43a6-82d0-3397f631d74c",
      "itemId": "e1506618-3e88-4d8a-b3a7-32eb569dc953",
      "price": 12.99,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal": 12.99,
      "tax": 1.7,
    },
    "BBQ Impossible Burger": {
      "brandName": "The Melt",
      "displayName": "Impossible MeltBurger",
      "itemGroupId": "d35fa01c-6264-43a6-82d0-3397f631d74c",
      "itemId": "e1506618-3e88-4d8a-b3a7-32eb569dc953",
      "price": 12.99,
      "quantity": 1,
      "modifiers": [
        {
          "displayName": "Add Smoked Bacon",
          "guid": "dca618e9-edae-4f17-b399-3fd6b303a280"
        }
      ],
      "restaurantName": "Noshery",
      "subtotal": 12.99,
      "tax": 1.7,
    },
  }, 
  "Fries": {
    "Fries": {
      "brandName": "The Melt",
      "displayName": "Fries",
      "itemGroupId": "d35fa01c-6264-43a6-82d0-3397f631d74c",
      "itemId": "cbad4aba-040d-4b4c-ac8b-bd7a27ca63e2",
      "price": 12.99,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal": 12.99,
      "tax": 1.7,
    },
    "Cheddar Bacon Fries": {
      "brandName": "The Melt",
      "displayName": "Cheddar Bacon Fries",
      "itemGroupId": "d35fa01c-6264-43a6-82d0-3397f631d74c",
      "itemId": "8fec5382-d0f7-40e8-9a06-43f16ce69a62",
      "price": 4.79,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal": 4.79,
      "tax": 0.46,
    },
    "Garlic Parmesan Fries": {
      "brandName": "The Melt",
      "displayName": "Garlic Parmesan Fries",
      "itemGroupId": "d35fa01c-6264-43a6-82d0-3397f631d74c",
      "itemId": "bfbec05e-c552-4ec7-b908-dd915a0965b9",
      "price": 4.39,
      "quantity": 1,
      "modifiers": [],
      "restaurantName": "Noshery",
      "subtotal": 4.39,
      "tax": 0.41,
    },
  },
  "Banh Mi": {
    "Pork Deluxe Banh Mi": {
      displayName: "Pork Deluxe Banh Mi",
      price: 12,
      quantity: 1,
      itemGroupId: "674cf641-b5e4-42c8-95bf-4a11a71fa485",
      tax: 1.13,
      subtotal: 13.13, 
      itemId: "96efabd6-e840-4414-bcba-919ded45a562",
      modifiers: [],
      restaurantName: "East Side Banh Mi",
      brandName: "East Side Banh Mi"
    }, 
    "Chicken Deluxe Banh Mi": {
      displayName: "Chicken Deluxe Banh Mi",
      price: 12.5,
      quantity: 1,
      itemGroupId: "b19af785-5ae4-44ff-a174-df644bb73db9",
      tax: 1.17,
      subtotal: 13.67,
      itemId: "38be25ec-799e-4285-9db3-246b02e90d82",
      modifiers: [],
      restaurantName: "East Side Banh Mi",
      brandName: "East Side Banh Mi"
    },
    "Pork Banh MI": {
      displayName: "Pork Banh MI",
      price: 11.5,
      quantity: 1,
      itemGroupId: "b19af785-5ae4-44ff-a174-df644bb73db9",
      tax: 1.08,
      subtotal: 12.13, 
      itemId: "3edc2b9f-5f4c-44c8-83f2-d99b545da8c3",
      modifiers: [],
      restaurantName: "East Side Banh Mi",
      brandName: "East Side Banh Mi"
    },
    "Chicken Banh Mi": {
      displayName: "Chicken Banh Mi",
      price: 11.5,
      quantity: 1,
      itemGroupId: "b19af785-5ae4-44ff-a174-df644bb73db9",
      tax: 1.08,
      subtotal: 12.13, 
      itemId: "3edc2b9f-5f4c-44c8-83f2-d99b545da8c3",
      modifiers: [],
      restaurantName: "East Side Banh Mi",
      brandName: "East Side Banh Mi"
    },
    "Vegan Banh Mi": {
      displayName: "Vegan Banh Mi",
      price: 11,
      quantity: 1,
      itemGroupId: "b19af785-5ae4-44ff-a174-df644bb73db9",
      tax: 1.03,
      subtotal: 11.03,
      itemId: "145e38ee-ccfe-46cd-9544-fe4d0a0c25e6",
      modifiers: [],
      restaurantName: "East Side Banh Mi",
      brandName: "East Side Banh Mi"
    },
  },
};
