/* eslint-disable no-undef */
import  React from "react"; 
import moment from "moment";

const API_URL = process.env.REACT_APP_API_URL;

export const useKitchenList = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [apiResults, setApiResults] = React.useState({ok: "none"});
  const [serverError, setServerError] = React.useState(null);

  const genereateKitchenList = async (orders, channel, location, discount, date, company, orderId) => {
    setIsLoading(true);
    try {
      const res = await fetch(`${API_URL}/generateKitchenList`, {
        method: "POST",
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({orders: orders, channel: channel, location: location, date: date, Company: company, orderId })
      });

      const data = await res.blob();
      setApiResults(data);
      setIsLoading(false);

      return data;
    } catch (error) {
      setServerError(error);
      setIsLoading(false);
    }
  };

  return { 
    isLoading, 
    apiResults, 
    serverError, 
    genereateKitchenList, 
  };

};