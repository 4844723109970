import React, { useEffect } from "react";
import { Flex, Text } from "noshery-ui";
import PageLoader from "../../components/PageLoader";
import useReadDb from "../../hooks/useReadDb";
import styled from "styled-components";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";

const locationsOption = {
  "All": "All",
  "San Mateo": "San Mateo",
  "San Carlos": "San Carlos",
};

const StyledSelect = styled.select`
  border-radius: 10px;
  box-shadow: 0 0 3px 0 rgb(0, 0, 0, 0.2);
  border: none;
  background-color: white;
  font-size: ${(props) => (props.fontSize ? props.fontSize : "20px")}
  ${(props) =>
    props.position
      ? "position: absolute; right: 8px; top: 8px; z-index: 1;"
      : ""}
  padding: 12px;
  font-weight: 600;
  &:hover,
  &:focus {
    cursor: pointer;
  }
  :focus-visible {
    outline: solid orange 2px;
  }
`;

const milestonesLabels = {
  "orderCount": "Order Count",
  "banhMis": "Banh Mis",
  "bowls": "Bowls",
  "burgers": "Burgers",
  "cateringNetSales": "Catering Net Sales",
  "netSales": "Net Sales",
};

const labelSymbol = {
  "orderCount": "#",
  "banhMis": "#",
  "bowls": "#",
  "burgers": "#",
  "cateringNetSales": "$",
  "netSales": "$",
};

const sortLabels = {
  "netSales": 1,
  "orderCount": 2,
  "cateringNetSales": 3,
  "burgers": 4,
  "bowls": 5,
  "banhMis": 6,
};


const Milestones = () => {

  const { apiResults, isLoading, readCollection } = useReadDb();
  const [location, setLocation] = React.useState("All");
  const [results, setResults] = React.useState({});

  useEffect(() => {
    (async() => await readCollection("tickers"))();
  }, []); 

  useEffect(() => {
    if (apiResults && apiResults["data"] && Array.isArray(apiResults["data"])) {
      let obj = {};
      apiResults["data"].forEach((d) => {
        const {id} = d;
        if(id.includes(location)) {
          obj[id] = JSON.parse(JSON.stringify(d));
        }
      }); 
      Object.keys(obj[location]).forEach((e) => {
        obj[location][e] = {
          value: obj[location][e],
          percent: (obj[location][e]/obj[`thresholds ${location}`][e])*100,
        };
      }); 
      setResults(obj);
    }
  }, [apiResults, location]);

  return (
    <Box>
      <Flex width="100%" flexDirection="column" bg="#f2f2f2">
        <Text fontSize="30px" fontWeight="bold"> Milestones </Text>
        <StyledSelect
          value={location}
          onChange={(e) => setLocation(locationsOption[e.target.value])}
        >
          {Object.keys(locationsOption).map((d) => (
            <option value={d} key={d}>
              {d}
            </option>
          ))}
        </StyledSelect>
      </Flex>
      {isLoading ? (
        <PageLoader />
      ) : (
        <Flex
          flexDirection="column"
          minHeight="100vh"
          bg="#f2f2f2"
          width="100%"
          height="100%"
          style={{
            minHeight: "100vh",
          }}
        >
          {
            results[location] && (Object.keys(results[location])
              .filter((value) => value !== "id" && value !== "uniqueCustomers"))
              .sort((a, b) => sortLabels[a] - sortLabels[b]).map((d) => {
                return (
                  <Box sx={{
                    backgroundColor: "white",
                    borderRadius: "10px",
                    border: "2px solid #d3d3d3",
                    width: "95%",
                    padding: "1%",
                    marginY: "5%", 
                    transition: ".1s",
                    poninter: "cursor", 
                    "&:hover": {
                      transform: "scale(1.02)",
                    } 
                  }} key={results[location][d].value}>
                    <Flex style={{ width: "100%", marginLeft: "1%", justifyContent: "space-between"}}> 
                      <Text key={d} fontSize="20px" fontWeight="bold" style={{margin: ".1%", marginLeft: "1%"}}>{milestonesLabels[d]}: {labelSymbol[d]}{results[location][d].value.toFixed(1).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Text>
                      <Text key={d} fontSize="20px" fontWeight="bold" style={{margin: ".1%", marginLeft: "1%"}}>Milestone: {labelSymbol[d]}{results[`thresholds ${location}`][d].toFixed(1).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Text>
                    </Flex>
                    <Flex style={{alignContent: "center", justifyContent: "center", alignItems: "center", margin: ".5%",}}> 
                      <LinearProgress defaultValue={0} sx={{height: "20px", margin: "1%", width: "100%", borderRadius: "10px", transition: ".1s",}} 
                        variant="determinate" valueBuffer={results[`thresholds ${location}`][d]} value={results[location][d].percent}  />
                      <Text key={d} fontSize="20px" fontWeight="bold" style={{ marginLeft: "1%"}}> {results[location][d].percent.toFixed(1)}%</Text>
                    </Flex>
                  </Box>
                );
              }) 
          }
        </Flex>
      )}
    </Box>
  );
};

export default Milestones;