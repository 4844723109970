import { useState, useEffect } from "react";
import moment from "moment";

const API_URL = process.env.REACT_APP_API_URL;

const getInitialDate = (type = "start") => {
  const time = type === "start" ? "09:00" : "23:00";
  return moment(moment(new Date()).format("YYYY/MM/DD") + " " + time);
};

export default function useOrders() {
  const [endDate, setEndDate] = useState(getInitialDate("end"));
  const [startDate, setStartDate] = useState(getInitialDate("start"));
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);

  const getOrders = async () => {
    setLoading(true);
    const res = await fetch(
      `${API_URL}/toastOrders?startDate=${moment
        .utc(startDate)
        .format("YYYY-MM-DDTHH:mm:SS.000-0000")}&endDate=${moment
        .utc(endDate)
        .format("YYYY-MM-DDTHH:mm:SS.000-0000")}`
    );

    const { data } = await res.json();
    setOrders(data);
    setLoading(false);
  };

  useEffect(() => {
    getOrders();
  }, []);

  const onSelectStartDate = date => {
    if (date) {
      return setStartDate(date);
    }

    return setStartDate(null);
  };

  const onSelectEndDate = date => {
    if (date) {
      return setEndDate(date);
    }

    return setEndDate(null);
  };

  return {
    orders,
    getOrders,
    loading,
    startDate,
    endDate,
    onSelectStartDate,
    onSelectEndDate,
  };
}
