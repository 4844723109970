export const sourceFilterOptions = [
  { label: "Kiosk", value: "Kiosk" },
  { label: "Ubereats", value: "Ubereats" },
  { label: "Doordash", value: "Doordash" },
  { label: "Grubhub", value: "Grubhub" },
  { label: "Popmenu", value: "Popmenu" },
  { label: "Postmates", value: "Postmates" },
  { label: "Google", value: "Google" },
  
];

export const locations = {
  "All Stores": "All",
  "San Mateo": "93895000000000000",
  "San Carlos": "114032000000000000",
  "San Ramon": "146868000000000000" ,
};

export const locationsFilters = [
  { label: "All Stores", value: "All" },
  { label: "San Mateo", value: "93895000000000000" },
  { label: "San Carlos", value: "114032000000000000" },
  { label: "San Ramon", value: "146868000000000000" },
];

export const restaurantColors = {
  Noshery: "rgb(241 166 55)",
  "SVB EVENT": "blue",
  "Humphry Slocombe Ice Cream": "#0068b3",
  "The Pastry Cupboard": "rgb(133 178 51)",
  Kasa: "#b44185",
  "The Little Chihuahua": "red",
  "East Side Banh Mi": "black",
  "Bourbon Street West": "brown",
  "Chowly open item menu": "grey",
  "Road Food": "cyan",
  "C&C Curry House": "yellow",
  Beverages: "darkblue",
  "Oren's Hummus": "#5c1819",
  "The Melt": "orange",
  "Asian Box": "#545559",
  Kiosk: "purple",
  Ubereats: "forestgreen",
  Doordash: "red",
  Grubhub: "orange",
  Popmenu: "#FF8C69",
  Postmates: "black",
  Google: "darkblue",
  Bbot: "teal",
  Forkable: "violet",
  netSales: "red",
  Sharebite: "red",
  orderCount: "blue",
  "Noshery Catering": "#F1A637",
  "First Party": "rgb(241 166 55)",
  "Third Party": "purple",
};

export const Abbreviations = {
  "The Little Chihuahua": "TLC",
  "Asian Box": "Asian Box",
  "Oren's Hummus": "OH",
  "The Pastry Cupboard": "TPC",
  "The Melt": "The Melt",
  Melt: "Melt",
  Noshery: "Noshery",
  Humphry: "HSC",
  Cupboard: "TPC",
  Kasa: "Kasa",
  Chihuahua: "TLC",
  "Banh Mi": "ESB",
  Bourbon: "BSW",
  "Chowly open item menu": "COI",
  Road: "RF",
  "C&C": "C&C",
  Beverages: "Bevg.",
  Hummus: "OH",
};

export const pieChartLabels = {
  Noshery: "rgb(241 166 55)",
  Humphry: "#0068b3",
  "SVB": "blue",
  Cupboard: "rgb(133 178 51)",
  "Box": "#545559",
  Kasa: "#b44185",
  Chihuahua: "red",
  "Banh Mi": "black",
  Bourbon: "brown",
  "Chowly open item menu": "grey",
  Road: "cyan",
  "C&C": "yellow",
  Beverages: "darkblue",
  Hummus: "#5c1819",
  Melt: "orange",
  Kiosk: "purple",
  Ubereats: "forestgreen",
  Doordash: "red",
  Grubhub: "orange",
  Popmenu: "#FF8C69",
  Postmates: "black",
  Google: "darkblue",
  Bbot: "teal",
  Forkable: "violet",
  Sharebite: "red",
  "Noshery Catering": "#F1A637",
  netSales: "red",
  orderCount: "blue",
  "Non-Catering": "rgb(241 166 55)",
  "Non-catering": "rgb(241 166 55)",
  Other: "gray",
  TLC: "red",
  OH: "#5c1819",
  TPC: "rgb(133 178 51)",
  First: "rgb(241 166 55)",
  Third: "purple",
};

export const restaurantFilterOptions = [
  { label: "Noshery", value: "Noshery", color: "" },
  {
    label: "Humphry Slocombe Ice Cream",
    value: "Humphry Slocombe Ice Cream",
    color: "red",
  },
  {
    label: "The Pastry Cupboard",
    value: "The Pastry Cupboard",
    color: "#23d09b",
  },
  { label: "Kasa", value: "Kasa", color: "orange" },
  {
    label: "The Little Chihuahua",
    value: "The Little Chihuahua",
    color: "purple",
  },
  { label: "East Side Banh Mi", value: "East Side Banh Mi", color: "yellow" },
  {
    label: "Bourbon Street West",
    value: "Bourbon Street West",
    color: "black",
  },
  {
    label: "Chowly open item menu",
    value: "Chowly open item menu",
    color: "blue",
  },
  { label: "Road Food", value: "Road Food", color: "cyan" },
  { label: "C&C Curry House", value: "C&C Curry House", color: "darkgreen" },
  { label: "Beverages", value: "Beverages", color: "darkblue" },
  { label: "Oren's Hummus", value: "Oren's Hummus", color: "#2590ef" },
  { label: "The Melt", value: "The Melt", color: "yellow" },
  {
    label: "Asian Box", value: "Asian Box", color: "#545559"
  },
  {
    label: "SVB EVENT", value:"SVB EVENT", color: "blue"
  }
];
