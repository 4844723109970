import React from "react";
import moment from "moment";
import { ResponsiveLine } from "@nivo/line";
import useDeviceType from "../hooks/useDeviceType";

const ResponsiveLineChart = ({
  data,
  tickSize = 3,
  tickRotation = -50,
  // legend,
  axisLeftLegend,
  yFormat = "$,",
  xAxisFormat = "$",
}) => {
  const {isDesktop} = useDeviceType();

  return (
    <ResponsiveLine
      data={data}
      margin={{ top: 20, right: 110, bottom: 50, left: 60 }}
      xScale={{ type: "point" }}
      yScale={{
        type: "linear",
        min: "auto",
        max: "auto",
        stacked: false,
        reverse: false,
      }}
      yFormat={yFormat}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        orient: "bottom",
        tickSize: tickSize,
        tickPadding: tickSize,
        tickRotation: tickRotation,
        // legend: legend,
        format: (tick) =>
          tickSize === 3
            ? moment(tick).format("YY-MM-DD")
            : parseInt(tick) > 12
              ? `${tick-12} pm` : 
              parseInt(tick) === 12 ? `${tick} pm`
                : `${tick} am`,
        legendOffset: 30,
        legendPosition: "middle",
      }}
      axisLeft={{
        orient: "left",
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: axisLeftLegend || "count",
        legendOffset: -50,
        legendPosition: "middle",
        format: (tick) => `${xAxisFormat}${tick}`,
      }}
      pointSize={10}
      pointColor={{ theme: "background" }}
      pointBorderWidth={2}
      pointBorderColor={{ from: "serieColor" }}
      pointLabelYOffset={-12}
      useMesh={true}
      legends={[
        {
          anchor: "bottom-right",
          direction: "column",
          justify: false,
          translateX: 100,
          translateY: isDesktop ? 0 : 40,
          itemsSpacing: 0,
          itemDirection: "left-to-right",
          itemWidth: 80,
          itemHeight: 20,
          itemOpacity: 0.75,
          symbolSize: 12,
          symbolShape: "circle",
          symbolBorderColor: "rgba(0, 0, 0, .5)",
          effects: [
            {
              on: "hover",
              style: {
                itemBackground: "rgba(0, 0, 0, .03)",
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
    />
  );
};

export default ResponsiveLineChart;
